export const MNO_VERIFICATION_STATUS = {
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  CI_EXISTS: "EXISTS_USER_CI",
  INVALID_HASH: "INVALID_HASH",
  TIMEOUT: "TIMEOUT",
  POPUP_CLOSED: "POPUP_CLOSED"  //mno pop closed before completion
}


export const ARS_AUTH_STATUS = {
  ARS_AUTH_IN_PROGRESS : "ARS_AUTH_IN_PROGRESS",
  ARS_AUTH_DONE : "ARS_AUTH_DONE",
  ARS_AUTH_FAILED : "ARS_AUTH_FAILED",
  BANK_ACC_REG_FAILED : "BANK_ACC_REG_FAILED",
  BANK_ACC_REGISTERED : "BANK_ACC_REGISTERED"
}


export const SERVICES = {
  realestate: {id: 1, name: "부동산 가격 예측 서비스"},
  population: {id: 2, name: "인구 예측 서비스"},
}

export const ACCOUNT_TYPE = {
  PERSONAL: 1,
  CORPORATE: 2
}

export const ACCOUNT_ROLE = {
  REGULAR: 0,
  ADMIN: 1
}


export const TOS_POSITION = {
  REG_PERSONAL: "KONASD_REG_PERSONAL",
  REG_CORPORATE: "KONASD_REG_CORPORATE",
  PAYMENT_OPENAPI: "KONASD_PAYMENT_OPENAPI"
}


export const SEARCH_HISTORY_TYPE = {
  RECENT: "RECENT",
  FAVORITE: "FAVORITE",
}

export const SIMULATION_STATUS = {
  DONE: "COMPLETED",
  RUNNING: "RUNNING",
  NOT_STARTED: "NOT STARTED",
  ERROR: "ERROR",
  CANCEL: "CANCELLED"
}

export const API_BASE_URL = (process.env.NODE_ENV !== 'production') ? process.env.REACT_APP_API_HOST : document.location.origin; 
export const SOLIDEO_API_URL = (process.env.NODE_ENV !== 'production') ? process.env.REACT_APP_DEV_API_SOLIDEO : document.location.origin;