import React, {useRef} from 'react'
import MainContentWrapper from "../../components/MainContentWrapper";

const MainRealEstate = () => {
  const reAboutRef = useRef(null)

  const executeScroll = () => reAboutRef.current.scrollIntoView()    
  // run this function from an event handler or an effect to execute scroll 

  return (
    <MainContentWrapper>

      <div className="flex flex-col items-center space-y-8 py-20">
        <div className="flex flex-col space-y-4 items-center">
          <h1 className="text-[70px] leading-none font-bold"><span className="bg-clip-text text-transparent bg-gradient-to-r from-[#456BFC] to-[#209CFC]">빅데이터</span> 기반의 차별화된</h1>
          <h1 className="text-[70px] leading-none font-bold">부동산 가격 예측 기법</h1>
        </div>
        <div className="flex flex-col items-center text-xl">
          <p>미국 MIT의 System Dynamics의 분석 방법론과</p>
          <p>자체 개발한 부동산 매매가격 예측지수를 토대로</p>
          <p>부동산의 미래 가격을 예측해보세요. </p>
        </div>
        <button onClick={executeScroll} className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">더 알아보기</button>
      </div>


      <div className="h-[610px] flex items-center justify-center" style={{backgroundImage: `url(${require("../../assets/images/realestate_banner_1.jpg")})`}}>
        <img className="w-[500px]" src={require("../../assets/images/realestate_ipadscreen.png")} alt="" />
      </div>
      
      <div className="py-20 bg-konabg flex flex-col items-center space-y-14 scroll-mt-[100px]" ref={reAboutRef}>
        <h1 className="text-5xl font-bold"><span className="text-konared">부동산 예측 서비스</span> 특장점</h1>

        <div className="grid grid-cols-1 gap-8">
          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">1. 데이터에 근거한 가격 예측 시스템</div>
              <div className="p-kr pt-5 pl-7 text-xl">부동산 시장에 관한 주관적인 전망이 아닌, 부동산 시장과 관련된 거시경제 관련 데이터에 근거한 객관적인 분석과 예측을 통해 부동산 미래가격 정보에 대한 가치 창출 및 신뢰성 향상 (모델 설명력 95% 이상 유지)</div>
            </div>
          </div>

          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">2. 시뮬레이션을 통한 시나리오 분석 활용</div>
              <div className="p-kr pt-5 pl-7 text-xl">미래 예측 관점에서, 모델 변수에 대한 시뮬레이션을 통해 사용자의 니즈(Needs)에 부합하는 시나리오 분석이 가능함 사용자는 미래 전망 관련 변수의 값을 자유롭게 설정함으로써 부동산 가격 비교분석 가능함</div>
            </div>
          </div>

          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">3. 미래 가치 판단 및 타 분야 활용 가능</div>
              <div className="p-kr pt-5 pl-7 text-xl">주택 매매자 뿐만 아니라, 투자․중개․감정평가․건설 부문의 여러 의사결정자들이 주택의 가치 추정과 타 분야(정책수립 등)에 활용 가능</div>
            </div>
          </div>

          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">4. 편리성과 신뢰성이 우수한 서비스 제공</div>
              <div className="p-kr pt-5 pl-7 text-xl">사용하기 편리하고, 데이터에 대한 정기/수시 업데이트를 통해 신뢰성 확보로 타사와 차별화된 서비스 제공</div>
            </div>
          </div>

          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">5. 확장성과 발전성이 우수한 모델</div>
              <div className="p-kr pt-5 pl-7 text-xl">본 서비스에 활용된 시스템다이내믹스 모델은 부동산 가격예측 뿐만 아니라 인구변화 등 여러 사회환경 변화예측이 가능하며, 모델의 확장성과 발전성이 우수한 모델임</div>
            </div>
          </div>

        </div>

      </div>

      

    </MainContentWrapper>
  )
}

export default MainRealEstate