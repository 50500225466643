import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { DocumentTextIcon} from '@heroicons/react/24/solid'

const LoadingModalWithText = ({isOpen, delayLeaveCls, children}) => {
  const initialFocusRef = React.useRef(null)
  return (
    <Transition appear show={isOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={`ease-in duration-200 ${delayLeaveCls}`}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave={`ease-in duration-200 ${delayLeaveCls}`}
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="min-w-44  transform overflow-hidden rounded-2x p-6 transition-all">
                <div className="pt-4 flex flex-col space-y-4 w-full h-full relative focus:outline-none" ref={initialFocusRef} tabIndex={-1}>
                  {children}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default LoadingModalWithText