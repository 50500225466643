import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { 
	ArrowTopRightOnSquareIcon,
	PencilIcon,
	TrashIcon,
	EllipsisVerticalIcon } from '@heroicons/react/24/solid'

const SimTableOptionsButton = ({item, simGroup, viewLink, optionsFn: {handleDeleteSim, handleEditSim, setErrorIsOpen}}) => {
  const navigate = useNavigate();

  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button onClick={(e) => e.stopPropagation()} className="group align-middle p-2 rounded-full hover:bg-konagray/5">
        <EllipsisVerticalIcon className="h-6 group-hover:text-konared" />
      </Menu.Button>
      <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      >
      <Menu.Items onClick={(e) => e.stopPropagation()} className="px-1 py-1 absolute left-0 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
          <Menu.Item>
            {({ active }) => (
              <button 
                className={`${active ? 'bg-konared text-white' : 'text-konared'} group flex space-x-2 rounded-md cursor-pointer items-center w-full p-2 text-xs font-semibold`}
                onClick ={viewLink !== null ? () => navigate(viewLink, {state: { sim_id: item.id }}) : () => setErrorIsOpen(true)}
              >
                <ArrowTopRightOnSquareIcon className="h-4" />
                <span>VIEW</span>
              </button>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button 
                className={`${active ? 'bg-konared text-white' : 'text-konared'} group flex space-x-2 rounded-md cursor-pointer items-center w-full p-2 text-xs font-semibold`}
                onClick ={() => handleEditSim(item, simGroup.name)}
              >
                <PencilIcon className="h-4" />
                <span>EDIT</span>
              </button>
            
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <button 
                className={`${active ? 'bg-konared text-white' : 'text-konared'} group flex space-x-2 rounded-md cursor-pointer items-center w-full p-2 text-xs font-semibold`}
                onClick ={() => handleDeleteSim(item, simGroup.name)}
              >
                <TrashIcon className="h-4" />
                <span>DELETE</span>
              </button>
            )}
          </Menu.Item>
      </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default SimTableOptionsButton