import { API_BASE_URL, SOLIDEO_API_URL } from "../constants";
import { ApiError, bearerAuth, InvalidRequestBody, isEmpty, ResponseError } from "../helpers";

export const getDefaultIndustryModelApi = async (category, user) => {

  try {
    const query = `?page_size=1&sort=-id&category=${category}`;
    const res = await fetch(`${API_BASE_URL}/api/industry/models${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data[0];
    }
    else{
      throw new ApiError("Unable to load default model.");
    }

  } catch (err) {
      throw(err)
  }
}

export const getIndustryModelVariablesApi = async (modelId, user) => {

  try {
    const query = `?page_size=all&model_id=${modelId}&category__in=PARAMETER&expand=data_in`
    const res = await fetch(`${API_BASE_URL}/api/industry/variables${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load model variables.");
    }

  } catch (err) {
      throw(err)
  }
}

export const getIndustryModelBaseDataApi = async (modelId, user) => {

  try {
    const query =  `?page_size=1&sort=-id&model_id=${modelId}&type=BASE&status=COMPLETED`
    const res = await fetch(`${API_BASE_URL}/api/industry/simulations/with-datavar${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data[0];
    }
    else{
      throw new ApiError("Unable to load model base data");
    }

  } catch (err) {
      throw(err)
  }
}


export const getIndustrySimulationDataApi = async (simId, user) => {

  try {

    const query = `?page_size=all&simulation_id=${simId}&expand=variable`
    const res = await fetch(`${API_BASE_URL}/api/industry/data-out${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load industry simulation data");
    }

  } catch (err) {
      throw(err)
  }
}


export const getIndustryDataSourceApi = async (dataImportId, user) => {

  try {

    const query = `?page_size=all&data_import_id=${dataImportId}`
    const res = await fetch(`${API_BASE_URL}/api/industry/data-source${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load industry simulation data");
    }

  } catch (err) {
      throw(err)
  }
}


export const getIndustrySimulationsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id&page=1&type=USER`
    const res = await fetch(`${API_BASE_URL}/api/industry/simulations/user${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}


export const getIndustryModelsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id`
    const res = await fetch(`${API_BASE_URL}/api/industry/models${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}



export const deleteIndustrySimulation = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/industry/simulations/${simId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 


export const updateIndustrySimulation = async (body, simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/industry/simulations/${simId}`, {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({...body})
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 

export const getIndustrySimulationByIdApi = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/industry/simulations/${simId}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load Simulation");
    }

  } catch (err) {
      throw(err)
  }
} 



export const getIndustryModelByIdApi = async (modelId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/industry/models/${modelId}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load Real Estate model.");
    }

  } catch (err) {
      throw(err)
  }
}