import React from 'react'
import RegistrationContext, {ACTION as REG_ACTION} from "../context/RegistrationProvider";
import { ACCOUNT_TYPE, API_BASE_URL } from "../../utils/constants";
import RegSteps from "./RegSteps";
import Loader3 from "../loaders/Loader3";
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "../context/GlobalContext";




const CorporateRegProcess = () => {
  const navigate = useNavigate();
  const {handleLogout} = useGlobalStateContext();
  const {registrationState, registrationDispatch} = React.useContext(RegistrationContext);


  React.useEffect(() => {

    const saveRegistrationData = async () => {

      const formVal = {
        "business_no": registrationState.bizRegNo,
        "email": registrationState.email,
        "password": registrationState.password,
        "terms_list": registrationState.terms_agreed,
        "request_id": registrationState.requestId,
        "request_token": registrationState.requestToken
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formVal)
      };
  
      try {
          const response = await fetch(`${API_BASE_URL}/api/auth/sign-up-corporate`, requestOptions);
          const user = await response.json();
  
          if (response.status === 403) throw new Error(user.detail);
          if (response.ok)
          {
            registrationDispatch({type: REG_ACTION.REGISTRATION_SUCCESS});
          }
  
        } catch (err) {
          console.error(err.message);
        }
    }
    
    if(registrationState.regType === ACCOUNT_TYPE.CORPORATE && registrationState.isRegSubmitLoading === true){
      saveRegistrationData();
    }
    else{
      navigate("/register");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  return (

    <div className="py-20 px-48">
      <div className="flex flex-col items-center space-y-8 pb-10">
        <h1 className="text-4xl leading-none font-bold">기업 회원정보 입력</h1>
        <RegSteps prevStep={2} nextStep={registrationState.isRegSuccess ? 3 : 2} />
      </div>

      <div className="w-full max-w-4xl mx-auto">
            {(() => {
              if(registrationState.isRegSuccess === true && registrationState.isRegSubmitLoading === false){
                return(
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    
                    <div className="flex flex-col items-center py-8">
                      <p className="text-3xl font-bold text-konared">계정이 활성화 보류 중입니다.</p>
                      <p className="mt-8 text-xl">코나SD 예측 서비스 기업 회원가입을 완료되었습니다. </p>
                      <p className="text-xl">활성화 프로세스 중에는 계정을 사용할 수 없습니다. 활성화가 완료되면 이메일 메시지를 보내드립니다.</p>
                      <p className="text-xl">계정 상태에 대해 문의 사항이 있으시면 <a href="mailto:ikyjeong@naver.com" className="font-bold underline underline-offset-2">ikyjeong@naver.com</a>으로 연락주세요</p>
                    </div>
                    <div className="pt-10">
                      <div className="flex justify-center space-x-5">
                        <NavLink 
                            to="/login"
                            state={registrationState.locationState} 
                            className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary"
                          >로그인
                        </NavLink>
                      </div>
                    </div>
                  </div>
                )

              
              }
              else if(registrationState.isRegSubmitLoading === true){
                return (
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    <div><Loader3 /></div>
                    <span>개인회원 가입이 진행 중입니다</span>
                  </div>
                )
              }
              else {
                return(
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    <div className="flex flex-col items-center py-8">
                      {/* <ExclamationTriangleIcon className="h-32 w-32 text-konared" /> */}
                      <div className="border-4 border-konared rounded-full w-20 h-20 inline-flex justify-center items-center text-6xl font-light text-konared">!</div>
                      <p className="mt-4 text-xl"> 코나SD 예측 서비스 가입이 <span className="font-bold text-konared">실패했습니다</span>.</p>
                      <p className="text-xl">오류로 인해 개인회원 가입이 완료되지 않았습니다. 가입을 다시 시도해주세요</p>
                    </div>

                    <div className="pt-10">
                      <div className="flex justify-center space-x-5">
                        <NavLink 
                          onMouseDown={e => e.preventDefault()} 
                          to={registrationState.locationState ? registrationState.locationState?.prevPath : "/" }
                          state={registrationState.locationState} 
                          className="w-[180px] py-4 rounded-xl text-xl text-center font-semibold ripple-gray-outline"
                        >가입 취소
                        </NavLink>
                        <NavLink to="/register" className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">가입 다시하기</NavLink>
                      </div>
                    </div>
                  </div>
                )

              
              }
            })()}

      </div>
    </div>
  )
}

export default CorporateRegProcess