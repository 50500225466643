import React from 'react'
import { useForm} from "react-hook-form";
import {BiReset} from 'react-icons/bi'
import { getChartOptionIndustryBase, getChartOptionIndustryMySim } from "../../components/charts/PopulationEchartOptions";
import PopulationEcharts from "../../components/charts/PopulationEcharts";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import IndustryParamSlider from "../../components/industry/IndustryParamSlider";
import Loader2WithSuccess from "../../components/loaders/Loader2WithSuccess";
import Loader3 from "../../components/loaders/Loader3";
import MainContentWrapper from "../../components/MainContentWrapper"
import LoadingModalWithText from "../../components/modals/LoadingModalWithText";
import { CardContent, CardHeader, CardWrapper } from "../../components/population/CardWrapper";
import SearchListBox from "../../components/realestate/SearchListBox";
import { getDefaultIndustryModelApi, getIndustryDataSourceApi, getIndustryModelBaseDataApi, getIndustryModelVariablesApi, getIndustrySimulationDataApi } from "../../utils/api/industryApi";
import { API_BASE_URL } from "../../utils/constants";
import { ApiError, AppError, bearerAuth, customRound, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import MiniLoader from "../../components/loaders/MiniLoader";
import {floorToUnit} from "@toss/utils"

const ALL_INDUSTRY_SWITCH = {
  byIndustry: "0",
  byGdp : "1",
}

const MANUFACTURING_SWITCH = {
  byIndustry: "0",
  byGdp : "1",
}

const SIM_INDUSTRY_SELECTION = {
  IG0: {name: "전산업", modelCategory: "all_industry", disabled: false},
  IG1: {name: "농업, 임업 및 어업", modelCategory: "all_industry", disabled: false},
  IG2: {name: "광업", modelCategory: "all_industry", disabled: false},
  IG3: {name: "제조업", modelCategory: "all_industry", disabled: false},
  IG4: {name: "전기, 가스, 증기 및 공기조절 공급업", modelCategory: "all_industry", disabled: false},
  IG5: {name: "수도, 하수 및 폐기물 처리, 원료 재생업", modelCategory: "all_industry", disabled: false},
  IG6: {name: "건설업", modelCategory: "all_industry", disabled: false},
  IG7: {name: "도매 및 소매업", modelCategory: "all_industry", disabled: false},
  IG8: {name: "운수 및 창고업", modelCategory: "all_industry", disabled: false},
  IG9: {name: "숙박 및 음식점업", modelCategory: "all_industry", disabled: false},
  IG10: {name: "정보통신업", modelCategory: "all_industry", disabled: false},
  IG11: {name: "금융 및 보험업", modelCategory: "all_industry", disabled: false},
  IG12: {name: "부동산업", modelCategory: "all_industry", disabled: false},
  IG13: {name: "전문, 과학 및 기술 서비스업", modelCategory: "all_industry", disabled: false},
  IG14: {name: "사업시설 관리, 사업 지원 및 임대 서비스업", modelCategory: "all_industry", disabled: false},
  IG15: {name: "공공행정, 국방 및 사회보장 행정", modelCategory: "all_industry", disabled: false},
  IG16: {name: "교육 서비스업", modelCategory: "all_industry", disabled: false},
  IG17: {name: "보건업 및 사회복지 서비스업", modelCategory: "all_industry", disabled: false},
  IG18: {name: "예술, 스포츠 및 여가관련 서비스업", modelCategory: "all_industry", disabled: false},
  IG19: {name: "협회 및 단체, 수리 및 기타 개인 서비스업", modelCategory: "all_industry", disabled: false},
}

const SIM_MANUFACTURING_SELECTION = {
  I1: {name: "식료품제조업", modelCategory: "manufacturing", disabled: false},
  I2: {name: "음료제조업", modelCategory: "manufacturing", disabled: false},
  I3: {name: "담배제조업", modelCategory: "manufacturing", disabled: false},
  I4: {name: "섬유제품제조업;의복제외", modelCategory: "manufacturing", disabled: false},
  I5: {name: "의복,의복액세서리및모피제품제조업", modelCategory: "manufacturing", disabled: false},
  I6: {name: "가죽,가방및신발제조업", modelCategory: "manufacturing", disabled: false},
  I7: {name: "목재및나무제품제조업;가구제외", modelCategory: "manufacturing", disabled: false},
  I8: {name: "펄프,종이및종이제품제조업", modelCategory: "manufacturing", disabled: false},
  I9: {name: "인쇄및기록매체복제업", modelCategory: "manufacturing", disabled: false},
  I10: {name: "코크스,연탄및석유정제품제조업", modelCategory: "manufacturing", disabled: false},
  I11: {name: "화학물질및화학제품제조업;의약품제외", modelCategory: "manufacturing", disabled: false},
  I12: {name: "의료용물질및의약품제조업", modelCategory: "manufacturing", disabled: false},
  I13: {name: "고무및플라스틱제품제조업", modelCategory: "manufacturing", disabled: false},
  I14: {name: "비금속광물제품제조업", modelCategory: "manufacturing", disabled: false},
  I15: {name: "1차금속제조업", modelCategory: "manufacturing", disabled: false},
  I16: {name: "금속가공제품제조업;기계및가구제외", modelCategory: "manufacturing", disabled: false},
  I17: {name: "전자부품,컴퓨터,영상,음향및통신장비제조업", modelCategory: "manufacturing", disabled: false},
  I18: {name: "의료,정밀,광학기기및시계제조업", modelCategory: "manufacturing", disabled: false},
  I19: {name: "전기장비제조업", modelCategory: "manufacturing", disabled: false},
  I20: {name: "기타기계및장비제조업", modelCategory: "manufacturing", disabled: false},
  I21: {name: "자동차및트레일러제조업", modelCategory: "manufacturing", disabled: false},
  I22: {name: "기타운송장비제조업", modelCategory: "manufacturing", disabled: false},
  I23: {name: "가구제조업", modelCategory: "manufacturing", disabled: false},
  I24: {name: "기타제품제조업", modelCategory: "manufacturing", disabled: false},
  I25: {name: "산업용기계및장비수리업", modelCategory: "manufacturing", disabled: false},
}




const ALL_INDUSTRY_PARAMETERS = {
  "IG1": {title: "농업, 임업 및 어업", inputVarName: null, checkboxDefault:true},
  "IG2": {title: "광업", inputVarName: null, checkboxDefault:true},
  "IG3": {title: "제조업", inputVarName: null, checkboxDefault:true},
  "IG4": {title: "전기, 가스, 증기 및 공기조절 공급업", inputVarName: null, checkboxDefault:false},
  "IG5": {title: "수도, 하수 및 폐기물 처리, 원료 재생업", inputVarName: null, checkboxDefault:false},
  "IG6": {title: "건설업", inputVarName: null, checkboxDefault:false},
  "IG7": {title: "도매 및 소매업", inputVarName: null, checkboxDefault:false},
  "IG8": {title: "운수 및 창고업", inputVarName: null, checkboxDefault:false},
  "IG9": {title: "숙박 및 음식점업", inputVarName: null, checkboxDefault:false},
  "IG10": {title: "정보통신업", inputVarName: null, checkboxDefault:false},
  "IG11": {title: "금융 및 보험업", inputVarName: null, checkboxDefault:false},
  "IG12": {title: "부동산업", inputVarName: null, checkboxDefault:false},
  "IG13": {title: "전문, 과학 및 기술 서비스업", inputVarName: null, checkboxDefault:false},
  "IG14": {title: "사업시설 관리, 사업 지원 및 임대 서비스업", inputVarName: null, checkboxDefault:false},
  "IG15": {title: "공공행정, 국방 및 사회보장 행정", inputVarName: null, checkboxDefault:false},
  "IG16": {title: "교육 서비스업", inputVarName: null, checkboxDefault:false},
  "IG17": {title: "보건업 및 사회복지 서비스업", inputVarName: null, checkboxDefault:false},
  "IG18": {title: "예술, 스포츠 및 여가관련 서비스업", inputVarName: null, checkboxDefault:false},
  "IG19": {title: "협회 및 단체, 수리 및 기타 개인 서비스업", inputVarName: null, checkboxDefault:false},
}

const MANUFACTURING_SUB_PARAMETERS = {
  "I1": {title: "식료품제조업", inputVarName: null, checkboxDefault:true},
  "I2": {title: "음료제조업", inputVarName: null, checkboxDefault:true},
  "I3": {title: "담배제조업", inputVarName: null, checkboxDefault:true},
  "I4": {title: "섬유제품제조업;의복제외", inputVarName: null, checkboxDefault:false},
  "I5": {title: "의복,의복액세서리및모피제품제조업", inputVarName: null, checkboxDefault:false},
  "I6": {title: "가죽,가방및신발제조업", inputVarName: null, checkboxDefault:false},
  "I7": {title: "목재및나무제품제조업;가구제외", inputVarName: null, checkboxDefault:false},
  "I8": {title: "펄프,종이및종이제품제조업", inputVarName: null, checkboxDefault:false},
  "I9": {title: "인쇄및기록매체복제업", inputVarName: null, checkboxDefault:false},
  "I10": {title: "코크스,연탄및석유정제품제조업", inputVarName: null, checkboxDefault:false},
  "I11": {title: "화학물질및화학제품제조업;의약품제외", inputVarName: null, checkboxDefault:false},
  "I12": {title: "의료용물질및의약품제조업", inputVarName: null, checkboxDefault:false},
  "I13": {title: "고무및플라스틱제품제조업", inputVarName: null, checkboxDefault:false},
  "I14": {title: "비금속광물제품제조업", inputVarName: null, checkboxDefault:false},
  "I15": {title: "1차금속제조업", inputVarName: null, checkboxDefault:false},
  "I16": {title: "금속가공제품제조업;기계및가구제외", inputVarName: null, checkboxDefault:false},
  "I17": {title: "전자부품,컴퓨터,영상,음향및통신장비제조업", inputVarName: null, checkboxDefault:false},
  "I18": {title: "의료,정밀,광학기기및시계제조업", inputVarName: null, checkboxDefault:false},
  "I19": {title: "전기장비제조업", inputVarName: null, checkboxDefault:false},
  "I20": {title: "기타기계및장비제조업", inputVarName: null, checkboxDefault:false},
  "I21": {title: "자동차및트레일러제조업", inputVarName: null, checkboxDefault:false},
  "I22": {title: "기타운송장비제조업", inputVarName: null, checkboxDefault:false},
  "I23": {title: "가구제조업", inputVarName: null, checkboxDefault:false},
  "I24": {title: "기타제품제조업", inputVarName: null, checkboxDefault:false},
  "I25": {title: "산업용기계및장비수리업", inputVarName: null, checkboxDefault:false},
}


export const INDUSTRY_ACTION = {
  SET_SWITCH: "set-switch",
  SET_CURRENT: "set-current",
  ADD_SIM_RESULT: "add-sim-result",
  SET_PARAM_LOADING: "set-param-loading"
}

const initialValue = {
  selectedIndustryInfo: {subscript: "IG0", ...SIM_INDUSTRY_SELECTION.IG0},
  selectedSwitch: ALL_INDUSTRY_SWITCH.byIndustry,
  isSimRunning: false,
  defaultLoaded: false,
  modelInfo: {},
  modelData: {},
  simResults: {},
  paramInfo: {},
  paramLoading: true,
}

function reducer(state, {type, payload}){
  switch(type){
    case INDUSTRY_ACTION.SET_SWITCH: 
      return {...state, selectedSwitch: payload}
    case INDUSTRY_ACTION.SET_CURRENT: {

      var paramInfo = null;

      if(payload.selectedIndustryInfo.modelCategory === "all_industry") {
        if(payload.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG0.name) { 
          var paramGdp = ["e GDP increase rate"];
          var industryInfoList = Object.entries(ALL_INDUSTRY_PARAMETERS).map(([key, val]) => {return {sub:key, checkboxDefault: val.checkboxDefault}});
          paramInfo = {
            paramGroups : {
              [ALL_INDUSTRY_SWITCH.byGdp] : [...paramGdp],
            },
            industryInfoList: industryInfoList,
            defaultSwitchVar: ALL_INDUSTRY_SWITCH.byGdp, 
            hasSwitch: true,
          }
        }
        else if (payload.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG3.name) {
          var paramIndustry = ["e manufacturing sales rate", "e all industry labor productivity increase rate 1 x[IG3]"];
          var paramGdp = ["e GDP increase rate"];
          var industryInfoList = Object.entries(ALL_INDUSTRY_PARAMETERS).map(([key, val]) => {return {sub:key, checkboxDefault: val.checkboxDefault}});
          paramInfo = {
            paramGroups : {
              [ALL_INDUSTRY_SWITCH.byIndustry] : [...paramIndustry],
              [ALL_INDUSTRY_SWITCH.byGdp] : [...paramGdp],
            },
            industryInfoList: industryInfoList,
            defaultSwitchVar: ALL_INDUSTRY_SWITCH.byIndustry, 
            hasSwitch: true,
          }
        }
        else {
          var paramIndustry = [`e all industry sales increase rate x[${payload.selectedIndustryInfo.subscript}]`, `e all industry labor productivity increase rate 1 x[${payload.selectedIndustryInfo.subscript}]`];
          var paramGdp = ["e GDP increase rate"];
          var industryInfoList = Object.entries(ALL_INDUSTRY_PARAMETERS).map(([key, val]) => {return {sub:key, checkboxDefault: val.checkboxDefault}});
          paramInfo = {
            paramGroups : {
              [ALL_INDUSTRY_SWITCH.byIndustry] : [...paramIndustry],
              [ALL_INDUSTRY_SWITCH.byGdp] : [...paramGdp],
            },
            industryInfoList: industryInfoList,
            defaultSwitchVar: ALL_INDUSTRY_SWITCH.byIndustry, 
            hasSwitch: true,
          }
        }
      }
      else {
        var paramIndustry = [`e manufacturing sales rate`, `e Labor Productivity increase rate 1 x[${payload.selectedIndustryInfo.subscript}]`];
        var paramGdp = ["e GDP increase rate"];
        var industryInfoList = Object.entries(ALL_INDUSTRY_PARAMETERS).map(([key, val]) => {return {sub:key, checkboxDefault: val.checkboxDefault}});
        paramInfo = {
          paramGroups : {
            [ALL_INDUSTRY_SWITCH.byIndustry] : [...paramIndustry],
            [ALL_INDUSTRY_SWITCH.byGdp] : [...paramGdp],
          },
          industryInfoList: industryInfoList,
          defaultSwitchVar: MANUFACTURING_SWITCH.byIndustry, 
          hasSwitch: true,
        }
      }
      

      return {
        ...state, 
        modelInfo: payload.modelInfo, 
        modelData: payload.modelData, 
        defaultLoaded: true, 
        selectedIndustryInfo: payload.selectedIndustryInfo, 
        paramInfo: paramInfo,
        selectedSwitch: paramInfo.defaultSwitchVar,
        simResults: {},
        paramLoading: false,
      }
    }
    case INDUSTRY_ACTION.ADD_SIM_RESULT:
      return {...state, simResults: payload}
    case INDUSTRY_ACTION.SET_PARAM_LOADING:
      return {...state, paramLoading: true}
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}


const getIndustryIdsAndInfo = (selectedIndustry) => {
    const info = Object.entries({...SIM_INDUSTRY_SELECTION, ...SIM_MANUFACTURING_SELECTION}).find(([key, val]) => 
        val.name === selectedIndustry
      );

    return {subscript: info[0], ...info[1]};
}


const getSubscriptByIndustry = (selectedIndustry) => {
  return Object.entries(SIM_INDUSTRY_SELECTION).find(([key, val]) => 
    val.name === selectedIndustry
  )[0];
}



const IndustrySimulation = () => {
    const [state, dispatch] = React.useReducer(reducer, initialValue);
    const { user, state: {isSubscribedPopulation}, handleLogout } = useGlobalStateContext();
    const globalDispatch = useGlobalDispatchContext();
    const [isOpenSimLoading, setIsOpenSimLoading] = React.useState(false);
    const simResult = React.useRef(null);
    const paramRightScroll = React.useRef(null);
    const [isCollapse, setIsCollapse] = React.useState(false);


    const formDefaults = React.useMemo(() => {
   
      if(state.defaultLoaded) {
        const allParamVar = Object.values(state.paramInfo.paramGroups).flat();

        //NOTE:
        //convert paramter values to percentage and round to 2 decimal places. 
        //WARNING, this script is assuming all parameters are percentage.
        //this must be modified in cases where parameter is of different unit
        const paramVar = Object.fromEntries(allParamVar.map(iParam => [`${iParam.replace(/[\[\]]/g, "")}`, customRound(state.modelData.variable[iParam]?.data_in[0].value * 100, 2)] ));
        // const paramVar = Object.fromEntries(allParamVar.map(iParam => [`${iParam.replace(/[\[\]]/g, "")}`, state.modelData.variable[iParam]?.data_in[0].value] ));
        // const paramCheckboxDefaults = Object.fromEntries(state.paramInfo.industryInfoList.map( ix=> {return [`${ix.sub}-check`, ix.checkboxDefault ]} ));
        

        
        return {
          switchVar: state.paramInfo.defaultSwitchVar, 
          ...paramVar,
          // ...paramCheckboxDefaults, 
        };
      }

      return {};
    }, [state.selectedIndustryInfo]); 

    const form = useForm({defaultValues: formDefaults});
    
    React.useEffect(() => {
      //reset form defaults everytime default changes (when location_code chagnes)
      form.reset(formDefaults);
  
    }, [formDefaults]);


    var formPlusStyle = "focus:ring-blue-500 focus:ring-opacity-40 placeholder-gray-400"
            

    if(form.formState.errors?.simName){
      formPlusStyle = "focus:ring-konadanger focus:ring-opacity-50 focus:placeholder-red-400"
      form.formState.errors?.simName.ref.scrollIntoView({ behavior: 'smooth' })
    }

    const onSubmit = async (data) => {
      

      if (data.simName.trim() === ""){
        //required simname
        form.setError('simName', { type: 'custom', message: "시뮬레이션명을 필요합니다." }, { shouldFocus: true });
      }
      else{
      
        setIsOpenSimLoading(true);

        const allParamVar = Object.values(state.paramInfo.paramGroups[data.switchVar]).flat();
        
        //NOTE: convert back  parameter value to its original unit (percentage -> original), and round decimal to 0.00001
        const paramVar = Object.fromEntries(allParamVar.map(iParam => [iParam, floorToUnit(parseFloat(data[`${iParam.replace(/[\[\]]/g, "")}`]/ 100), 0.00001) ] ));

        const switchVar = state.paramInfo.hasSwitch ? {"switch scenario": parseInt(data.switchVar)} : {};

        // create result json obj for query
        const addSimJson = {
          "name": data.simName || "Untitled",
          "parameter": {...paramVar, ...switchVar}, 
          "model_id": state.modelInfo.id,
          "type": "USER",
          "user_id": user.user_info.id ,
        }

        
        // RUN Sim 
        // TODO: create single endpoint and process all steps in the backend
        try {
          // add new sim
          const response = await fetch(`${API_BASE_URL}/api/industry/simulations`,{
            method: "POST",
            headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
            body: JSON.stringify(addSimJson),
          });


          const newSimulation = await response.json();

          // run simulation
          const response2 = await fetch(`${API_BASE_URL}/api/industry/simulations/${newSimulation.id}/run`,{
            method: "POST",
            headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
          });
          
          if(response2.ok){
            const simOutput = await getIndustrySimulationDataApi(newSimulation.id, user);
            const outputData = Object.fromEntries(simOutput.map(e => [e.variable.name_en, e.value]));
            const simResults = {
              [newSimulation.id]: {outputData: outputData, simInfo: newSimulation }
            }

            dispatch({type: INDUSTRY_ACTION.ADD_SIM_RESULT, payload: simResults});
            setIsOpenSimLoading(false);
          }

          //scroll to results charts
          await delay(100);  //add delay to time overlay closing and moving the chart section, will also add fake delay to wait for chartref to be rendered first before scrollIntoView
          simResult.current.scrollIntoView()
                  
          //reset simName field when after running simulation
          form.setValue("simName", "", { shouldTouch: false, shouldDirty: false  });
        } catch (err) {
          console.error(err.message)
        }
      }
    }

    const handleReset = () => {

     
    }

    const handleSelectIndustryClick = async (value) => {
      paramRightScroll.current.scroll({
        top: 0,
      });
      await loadDefault(value);
    }



    const loadDefault = React.useCallback(async (selectedIndustry) => {

      const selectedIndustryInfo = getIndustryIdsAndInfo(selectedIndustry);
      if(user && (!state.defaultLoaded || selectedIndustryInfo.modelCategory != state.selectedIndustryInfo.modelCategory)){
      // if((isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds) || (reState.currentModelId && reState.model.allIds.includes(reState.currentModelId ))) && user){
        dispatch({type: INDUSTRY_ACTION.SET_PARAM_LOADING})
        try {
          const [defaultModel] = await Promise.all([
            getDefaultIndustryModelApi(selectedIndustryInfo.modelCategory, user),
          ]);

          const [modelVariables, modelBaseData, inputVariables] = await Promise.all([
            getIndustryModelVariablesApi(defaultModel.id, user),
            getIndustryModelBaseDataApi(defaultModel.id, user),
            getIndustryDataSourceApi(defaultModel.data_import_id, user)

          ]);


          const modelVariablesObj = Object.fromEntries(modelVariables.map(e => [e.name_en, e]));
          const modelBaseDataObj = Object.fromEntries(modelBaseData?.data_out.map(e => [e.variable.name_en, e.value]));
          const modelInputVariableObj = Object.fromEntries(inputVariables.map(e => [e.name_en, e.value]));
          const modelData = {
            variable: modelVariablesObj,
            baseData: modelBaseDataObj,
            inputVariables: modelInputVariableObj
          }

           

          dispatch({
            type: INDUSTRY_ACTION.SET_CURRENT, 
            payload: {modelInfo: defaultModel, modelData: modelData, selectedIndustryInfo: selectedIndustryInfo},
          })
      
        } catch (err) {
          if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
            const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
            globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
          }
          else{
            console.error(err)
          }
        }
        
      }
      else {
        dispatch({
          type: INDUSTRY_ACTION.SET_CURRENT, 
          payload: {modelInfo: {...state.modelInfo}, modelData: {...state.modelData}, selectedIndustryInfo: selectedIndustryInfo},
        })
      }
    }, [state.selectedIndustryInfo])
  
    React.useEffect(() => {
      loadDefault(SIM_INDUSTRY_SELECTION.IG0.name);
    },[]);


    return (
      <MainContentWrapper className="bg-konabg flex flex-col">
        <LoadingModalWithText isOpen={isOpenSimLoading} delayLeaveCls="delay-1000">
          <div className="flex flex-col w-full h-full relative">
            {isOpenSimLoading
            ? 
            <>
              <Loader2WithSuccess success={false} />
              <div className="mt-4 text-white text-xl font-light">시뮬레이션 실행 중...</div>
            </>
            : 
            <>
              <Loader2WithSuccess success={true} />
              <div className="mt-4 text-white text-xl font-light">시뮬레이션 완료</div>
            </>
            }
          </div>
        </LoadingModalWithText>

        {!state.defaultLoaded
          ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
          :
          <>
            <div className="bg-gray-400 bg-opacity-20  px-28 py-10 relative">
              <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="w-full max-w-5xl mx-auto flex flex-col">
                  <div className="flex">
                    <div className="flex-1 text-3xl font-bold text-konared">Parameter 설정하기</div>
                    <button onClick={handleReset} type="button" className="inline-flex items-center space-x-2 border border-konared px-4 rounded-full text-konared ripple-primary-outline text-sm font-semibold"><BiReset size={20} /><span>데이터 초기화</span></button>
                  </div>

                  <div className="h-1 mt-5 bg-gray-400"></div>
                  <div className="flex bg-white">
                    
                    <div className="flex-1 flex flex-col space-y-5 p-5 text-xl">

                      <div className="font-semibold">산업 선택</div>
                      <div className="inline-block overflow-hidden rounded-lg border">
                        <div className=" overflow-y-auto h-[524px] text-lg ">
                          {Object.entries(SIM_INDUSTRY_SELECTION).map(([key, val]) => {
                              if(key === "IG3") {
                                return (
                                  <div className="flex flex-col border-b">
                                    <div 
                                      key={`${key}l1`} 
                                      className={`px-5 py-3 border-b hover:bg-konared/20 cursor-pointer inline-flex items-center`}
                                      onClick={() => setIsCollapse(!isCollapse)}
                                    ><span className="flex-1">{val.name}</span> <ChevronDownIcon className={`h-6 transition duration-300  ${isCollapse ? "-rotate-180" : ""}`} /></div>
                                    <div className={`overflow-hidden delay-200 ${isCollapse ? "h-full" : "h-0"}`}>
                                      <div className={`flex flex-col bg-gray-50 transition-opacity duration-300 opacity-0 ${isCollapse ? "opacity-100" : "opacity-0"}`}>
                                        <div className={`text-base px-10 py-2 hover:bg-konared/20 cursor-pointer border-b border-dashed ${state.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG3.name ? "bg-konared/20" : ""}`} onClick={() => handleSelectIndustryClick(SIM_INDUSTRY_SELECTION.IG3.name)}>전제조업</div>
                                        {Object.entries(SIM_MANUFACTURING_SELECTION).map(([key, val]) => { 
                                          return (
                                            <div className={`text-base px-10 py-2 hover:bg-konared/20 cursor-pointer border-b border-dashed ${state.selectedIndustryInfo.name === val.name ? "bg-konared/20" : ""}`} onClick={() => handleSelectIndustryClick(val.name)}>{val.name}</div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                )
                              } else {
                                return (
                                  <div key={`${key}l1`} className={`px-5 py-3 border-b hover:bg-konared/20 cursor-pointer ${state.selectedIndustryInfo.name === val.name ? "bg-konared/20" : ""}`} onClick={() => handleSelectIndustryClick(val.name)}>{val.name}</div>
                                )
                              }
                              
                          })

                          }
                          
                        </div>
                      </div>
                    </div>
                    {/* end of left section */}

                    {/* divider */}
                    <div className="w-[0.5px] bg-gray-400/50"></div>

                    {/* right section */}
                    <div className="flex-1 flex flex-col">
                      <div className="flex justify-center space-x-10 text-xl font-semibold p-5">{state.selectedIndustryInfo.name}</div>

                      {state.paramLoading
                      ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
                      : <div className="" ref={paramRightScroll}>
                          {(() => {

                            if(state.selectedIndustryInfo.modelCategory === "all_industry") {
                              if(state.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG0.name) {
                                const p1 = `e GDP increase rate`;
                                  const param = {
                                    subscript: null,
                                    title: "GDP 증가율",
                                    switchVarValue: ALL_INDUSTRY_SWITCH.byGdp,
                                    paramVar: [state.modelData?.variable[p1]],
                                    overrideFormatterMultipler: 100,  //temporary fix to change paramter value format. example: 0.35 (original value) => 3.5 (percentage value)
                                    overrideUnit: "%", 
                                  }
                                  return (
                                    <div className="flex flex-col px-5 pb-5">
                                      <IndustryParamSlider param={param} form={form}  />
                                    </div>
                                  )
                              }
                              if(state.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG3.name) {
                                const selectedSubscript = state.selectedIndustryInfo.subscript;
                                const p1 = `e manufacturing sales rate`;
                                const p2 = `e all industry labor productivity increase rate 1 x[${selectedSubscript}]`;
                                const param = {
                                  subscript: selectedSubscript,
                                  title: "매출/노동생산성 증가율",
                                  switchVarValue: ALL_INDUSTRY_SWITCH.byIndustry,
                                  paramVar: [state.modelData?.variable[p1], state.modelData?.variable[p2]],
                                  overrideFormatterMultipler: 100,
                                  overrideUnit: "%", 
                                }
                                
                                const pGdp = `e GDP increase rate`;
                                const paramGdp = {
                                  subscript: selectedSubscript,
                                  title: "GDP 증가율",
                                  switchVarValue: ALL_INDUSTRY_SWITCH.byGdp,
                                  paramVar: [state.modelData?.variable[pGdp]],
                                  overrideFormatterMultipler: 100,
                                  overrideUnit: "%", 
                                }

                                return(
                                  <div className="flex flex-col px-5 pb-5">
                                    <IndustryParamSlider param={param} form={form} switchId={1}/>
                                    <IndustryParamSlider param={paramGdp} form={form} switchId={2} />
                                  </div>
                                )
                              }
                              else {
                                  const selectedSubscript = state.selectedIndustryInfo.subscript;
                                  const p1 = `e all industry sales increase rate x[${selectedSubscript}]`;
                                  const p2 = `e all industry labor productivity increase rate 1 x[${selectedSubscript}]`;
                                  const param = {
                                    subscript: selectedSubscript,
                                    title: "매출/노동생산성 증가율",
                                    switchVarValue: ALL_INDUSTRY_SWITCH.byIndustry,
                                    paramVar: [state.modelData?.variable[p1], state.modelData?.variable[p2]],
                                    overrideFormatterMultipler: 100,
                                    overrideUnit: "%", 
                                  }
                                  
                                  const pGdp = `e GDP increase rate`;
                                  const paramGdp = {
                                    subscript: selectedSubscript,
                                    title: "GDP 증가율",
                                    switchVarValue: ALL_INDUSTRY_SWITCH.byGdp,
                                    paramVar: [state.modelData?.variable[pGdp]],
                                    overrideFormatterMultipler: 100,
                                    overrideUnit: "%", 
                                  }
    
                                  return(
                                    <div className="flex flex-col px-5 pb-5">
                                      <IndustryParamSlider param={param} form={form} switchId={1}/>
                                      <IndustryParamSlider param={paramGdp} form={form} switchId={2} />
                                    </div>
                                  )
                              }
                            } else {
                              const selectedSubscript = state.selectedIndustryInfo.subscript;
                              const p1 = `e manufacturing sales rate`;
                              const p2 = `e Labor Productivity increase rate 1 x[${selectedSubscript}]`;
                              const param = {
                                subscript: selectedSubscript,
                                title: "매출/노동생산성 증가율",
                                switchVarValue: MANUFACTURING_SWITCH.byIndustry,
                                paramVar: [state.modelData?.variable[p1], state.modelData?.variable[p2]],
                                overrideFormatterMultipler: 100,
                                overrideUnit: "%", 
                              }
                              
                              const pGdp = `e GDP increase rate`;
                              const paramGdp = {
                                subscript: selectedSubscript,
                                title: "GDP 증가율",
                                switchVarValue: MANUFACTURING_SWITCH.byGdp,
                                paramVar: [state.modelData?.variable[pGdp]],
                                overrideFormatterMultipler: 100,
                                overrideUnit: "%", 
                              }

                              return(
                                <div className="flex flex-col px-5 pb-5">
                                  <IndustryParamSlider param={param} form={form} switchId={1}/>
                                  <IndustryParamSlider param={paramGdp} form={form} switchId={2} />
                                </div>
                              )
                            }

                          })()}
                        </div>
                      }
                      
                      


                      {/* sim button */}
                      <div className="flex-1 flex flex-col justify-end space-y-4 p-5">
                        <div className="flex flex-col space-y-1">
                          <input 
                            {...form.register("simName")} 
                            placeholder="시뮬레이션명 입력해주세요" 
                            type="text" 
                            className={`flex-1 text-xl text-gray-800 py-3 rounded-2xl border outline-none focus:duration-300  focus:ring-2 shadow-sm px-4 scroll-mt-[400px] ${formPlusStyle}`}
                            autoComplete="off" 
                          />
                        </div>
                        <button type="submit" className="bg-konared py-[15px] rounded-2xl text-xl text-white font-semibold ripple-primary inline-flex justify-center items-center">
                          {state.isSimRunning && 
                            <svg className="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                          }
                          <span>시뮬레이션 실행</span>
                        </button>
                      </div>
                      {/* end of sim button */}

                    </div>
                    
                    {/* end of right section */}

                  </div>
                </div>
              </form>
            </div>

            <div className="pt-10 pb-0 px-28 grid grid-cols-2 gap-6 mb-20"  ref={simResult}>
                <CardWrapper className="col-span-2 h-[500px]">
                  <CardHeader>
                      <div className='w-full flex flex-row items-center'>
                          <div className="flex-1 text-left">{state.selectedIndustryInfo.name} 노동 공급과 수요</div>
                          <div className='flex text-right text-lg'>단위: 명</div>
                      </div>
                  </CardHeader>
                  <CardContent className="p-5">

                  {(() => {

                    if(state.selectedIndustryInfo.modelCategory === "all_industry") {
                      if(state.selectedIndustryInfo.name === SIM_INDUSTRY_SELECTION.IG0.name) {
                        const outvarList = ["all industries total labor supply", "all industries total number of employees"];
                        const inputData = state.modelData?.inputVariables["Total All Industries Employees"];

                        if(isEmpty(state.simResults)) {
                          return (
                            <PopulationEcharts options={getChartOptionIndustryBase(outvarList, state.modelData?.baseData, inputData)} />
                          )
                        }
                        else {
                          return (
                            <PopulationEcharts options={getChartOptionIndustryMySim(outvarList, state.modelData?.baseData, Object.values(state.simResults)[0], inputData)} />
                          )
                        }
                      }
                      else {
                        const subscript = state.selectedIndustryInfo.subscript;
                        const outvarList = [`Labor supply by industry x[${subscript}]`, `Number of employess by industry x[${subscript}]`];
                        const inputData = state.modelData?.inputVariables[`All Industries Employees[${subscript}]`];
                        
                        if(isEmpty(state.simResults)) {
                          return (
                            <PopulationEcharts options={getChartOptionIndustryBase(outvarList, state.modelData?.baseData, inputData)} />
                          )
                        }
                        else {
                          return (
                            <PopulationEcharts options={getChartOptionIndustryMySim(outvarList, state.modelData?.baseData, Object.values(state.simResults)[0], inputData)} />
                          )
                        }
                      }
                    } else {
                      const subscript = state.selectedIndustryInfo.subscript;
                      const outvarList = [`sub industry labor supply x[${subscript}]`, `sub industry employees x[${subscript}]`];
                      const inputData = state.modelData?.inputVariables[`Manufacturing Employees[${subscript}]`];
                      
                      if(isEmpty(state.simResults)) {
                        return (
                          <PopulationEcharts options={getChartOptionIndustryBase(outvarList, state.modelData?.baseData, inputData)} />
                        )
                      }
                      else {
                        return (
                          <PopulationEcharts options={getChartOptionIndustryMySim(outvarList, state.modelData?.baseData, Object.values(state.simResults)[0], inputData)} />
                        )
                      }
                    }

                    })()}




                  </CardContent>
                </CardWrapper>
            </div>
          </>
        }
      </MainContentWrapper>
    )
    }

export default IndustrySimulation