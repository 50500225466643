import React from 'react'
import Header from "./Header"
import Footer from "./Footer"

const MainContentWrapper = props => {

  return (
    <div className="max-w-[1920px] min-w-[1400px] text-konamain mx-auto min-h-screen flex flex-col">
      <Header />
      <div className={`flex-1 ${props.className}`}>{props.children}</div>
      <Footer />
    </div>
  )
}

export default MainContentWrapper