import React from 'react'
import {round as lround} from 'lodash';
import Moment from 'moment';
import { customRound } from "../../utils/helpers";
import { getAddressTxt, varNameAptPriceIndexOut } from "../context/RealEstateContext";


const EvaluationMySim = ({usersimResult}) => {
  const currMonth = Moment().format("YYYY-MM");
  const {konasimResult, address} = usersimResult;
  const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
  let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);
  //table data array for base data (sequence: current, 3mo, 6mo, 9mo, 1yr, 2yr)
  const tblBaseIndex = [baseDataPriceVal[0][1], baseDataPriceVal[6][1], baseDataPriceVal[12][1], baseDataPriceVal[24][1]]
  const tblBaseRate = tblBaseIndex.map((val, idx, arr) => customRound((val-arr[0])/arr[0]*100, 2));

  //customRound((bData[0][1]-bData[0][1])/bData[0][1]*100, 2)
  //set values needed for description
  const indexAfter6mo = tblBaseIndex[1].toLocaleString() + "원";
  const indexAfter1yr = tblBaseIndex[2].toLocaleString() + "원";
  const indexAfter2yr = tblBaseIndex[3].toLocaleString() + "원";
  const rateAfter1yr = tblBaseRate[2];
  const rateAfter2yr = tblBaseRate[3];


  const {currentIndex} = konasimResult;
  const lastInputDate = konasimResult.inputDataPriceVal.at(-1)[0];
  // const lastInputDateStr = `${lastInputDate.split("-")[0]}년 ${lastInputDate.split("-")[1]}월`;
  const lastInputDateStr = `${currMonth.split("-")[0]}년 ${currMonth.split("-")[1]}월`;
  
  const simDataArr = Object.entries(usersimResult.simResults).map(([key,val]) => {
    // let indexOfLastInput = val.outputData[varNameAptPriceIndexOut].findIndex(d => d[0] == lastInputDate); 
    let indexOfLastInput = val.outputData[varNameAptPriceIndexOut].findIndex(d => d[0] == currMonth); 
    let simData = val.outputData[varNameAptPriceIndexOut].slice(indexOfLastInput);
    simData = simData.map(([x,y]) => [x, parseInt(usersimResult.address.info.resultAmt*(1+(lround(y,4)-currentIndex)/currentIndex))])  // round 2 decimal places

    var tblSimIndex = [simData[0][1], simData[6][1], simData[12][1], simData[24][1]]
    var tblSimRate = tblSimIndex.map((val, idx, arr) => lround((val-arr[0])/arr[0]*100, 2));
    return{
      simInfo: val.simInfo,
      tblSimIndex,
      tblSimRate
    }
    
  })
  
  return (
    <div>
      <div className="flex flex-col space-y-6 border rounded-xl p-4">
        <div className="flex flex-col space-y-2 text-xl p-kr">
          <p className="font-bold">KONA 예측</p>
          <p>{getAddressTxt(address)} 검색 물건의 <span className="font-extrabold">{lastInputDateStr}</span> 기준 매매 가격은 <span className="font-extrabold">{tblBaseIndex[0].toLocaleString()}원</span> 이며,</p>
          <p>KONA의 분석에 의한 예측 적정 가격은 6개월 후 <span className="font-extrabold">{indexAfter6mo}</span>, 1년 후 <span className="font-extrabold">{indexAfter1yr}</span>, 2년 후 <span className="font-extrabold">{indexAfter2yr}</span>으로 예측됩니다.</p>
          <p>최종적으로 분석한 결과, <span className="text-konared font-bold">현재로부터 1년 후 예측 가격은 현 시점보다 {rateAfter1yr >= 0 ? `${Math.abs(rateAfter1yr)}% 상승` : `${Math.abs(rateAfter1yr)}% 하락`}이 예상되고, 2년 후 {rateAfter2yr >= 0 ? `${Math.abs(rateAfter2yr)}% 상승` : `${Math.abs(rateAfter2yr)}% 하락`} 할 것으로 예측</span>됩니다</p>
        </div>

        {simDataArr.map((eachSim, index) => {
          let simIndexCurrent = eachSim.tblSimIndex[0].toLocaleString() + "원";
          let simIndexAfter6mo = eachSim.tblSimIndex[1].toLocaleString() + "원";
          let simIndexAfter1yr = eachSim.tblSimIndex[2].toLocaleString() + "원";
          let simIndexAfter2yr = eachSim.tblSimIndex[3].toLocaleString() + "원";
          let simRateAfter1yr = eachSim.tblSimRate[2];
          let simRateAfter2yr = eachSim.tblSimRate[3];

          return(
          <div className="flex flex-col space-y-2 text-xl p-kr" key={index}>
            <p className="font-bold">{eachSim.simInfo.name}</p>
            <p>{getAddressTxt(address)} 검색 물건의 <span className="font-extrabold">{lastInputDateStr}</span> 기준 매매 가격은 <span className="font-extrabold">{simIndexCurrent}원</span> 이며,</p>
            <p>KONA의 분석에 의한 예측 적정 가격은 6개월 후 <span className="font-extrabold">{simIndexAfter6mo}</span>, 1년 후 <span className="font-extrabold">{simIndexAfter1yr}</span>, 2년 후 <span className="font-extrabold">{simIndexAfter2yr}</span>으로 예측됩니다.</p>
            <p>최종적으로 분석한 결과, <span className="text-konared font-bold">현재로부터 1년 후 예측 가격은 현 시점보다 {simRateAfter1yr >= 0 ? `${Math.abs(simRateAfter1yr)}% 상승` : `${Math.abs(simRateAfter1yr)}% 하락`}이 예상되고, 2년 후 {simRateAfter2yr >= 0 ? `${Math.abs(simRateAfter2yr)}% 상승` : `${Math.abs(simRateAfter2yr)}% 하락`} 할 것으로 예측</span>됩니다</p>
          </div>
        )})}
       

      </div>
    </div>
  )
}

export default EvaluationMySim