import React from 'react'
import { getAddrshLastVisitedApi, getJaduAptPrice, getRealEstateModelBaseDataApi, getRealEstateModelVariablesApi } from "../../utils/api/realestateApi";
import { customRound, isEmpty } from "../../utils/helpers";
import systemDefaultAddress from "../../assets/default-address.json"
import Moment from 'moment';
import { factoryUseContext } from "./GlobalContext";

export const varNameAptPriceIndexIn = "D Apartment Price Index x";
export const varNameAptPriceIndexOut = "Apartment Price Index x";


export const getAddressTxt = (address) => {
  const hoTxt = address.ho.length > 0 && address.ho.indexOf("호") < 0 ? `${address.ho}호` : address.ho ;
  const dongTxt = address.dong.length > 0 && address.dong.indexOf("동") < 0 ? `${address.dong}동` : address.dong ;
  const addressTxt = `${address.address.rn} ${address.address.roadAddrPart2} ${dongTxt} ${hoTxt}`;
  return addressTxt;
}
export const getRealEstateSubscript = (variables) => {
  return variables[varNameAptPriceIndexIn]?.name_en.match(/\[([^\]]+)/)[1];
}

export const getKonaSimResults =  (variables, baseData, address) => {
  const currMonth = Moment().format("YYYY-MM");

  var inputDataVal = variables[varNameAptPriceIndexIn].data_in[0].value;
  var baseDataVal = baseData[varNameAptPriceIndexOut];

  const baseDataValIdxCurrM = baseDataVal.findIndex(d => d[0] === currMonth); 
  const baseDatavalIdxLastInData = baseDataVal.findIndex(d => d[0] === inputDataVal.at(-1)[0]); 
  const origLastInputDate = inputDataVal.at(-1)[0];  //original last input data date of historical data

  // const currentIndex = customRound(baseDataVal[baseDatavalIdxLastInData][1],4); //IMPORTANT: TODO: currently using 4 decimal place rounding. Do sensitivity study on which decimal place to use. 
  const currentIndex = customRound(inputDataVal.at(-1)[1],4);
  inputDataVal = inputDataVal.map(([x,y]) => [x, parseInt(address.info.resultAmt*(1+(customRound(y,4)-currentIndex)/currentIndex))]);  // round 4 decimal places 
  baseDataVal = baseDataVal.map(([x,y]) => [x, parseInt(address.info.resultAmt*(1+(customRound(y,4)-currentIndex)/currentIndex))])  // round 4 decimal places

  // if(origLastInputDate !== currMonth){
  //   //if current month does not match with last historical data, append the baseData to the inputData variable
    
  //   //append basedata from last inputdata date to baseDataCurrMonthIndex
  //   inputDataVal.push(...baseDataVal.slice(baseDatavalIdxLastInData+1,baseDataValIdxCurrM+1));
  // }

  return {baseDataPriceVal: baseDataVal, inputDataPriceVal: inputDataVal, currentIndex, origLastInputDate};
}

export const getRealEstateModelBaseDataObj = async (modelId, locationCode, user) => {
  try {
    const baseData = await getRealEstateModelBaseDataApi(modelId, locationCode, user);

    return Object.fromEntries(baseData?.data_out.map(e => [e.variable.name_en.split("[")[0], e.value]))

  } catch (err) {
    throw(err)
  }
}


export const getRealEstateModelVariablesObj = async (modelId, locationCode, user) => {
  try {
    const variables = await getRealEstateModelVariablesApi(modelId, locationCode, user);

    return Object.fromEntries(variables.map(e => [e.name_en.split("[")[0], e]))

  } catch (err) {
    throw(err)
  }
}


export const getDefaultAddressInfo = async (user) => {
  const lastVisitedAddress = await getAddrshLastVisitedApi(user);
  const defaultAddress = (lastVisitedAddress) ? lastVisitedAddress.parameters : systemDefaultAddress;
  const {chklist, address, dong, ho, locInfo} = defaultAddress;  
  const jaduPriceInfo = await getJaduAptPrice(chklist, dong, ho, user);

  return{
    chklist: chklist,
    address: address,
    dong: dong,
    ho: ho,
    info: jaduPriceInfo,
    locInfo: locInfo
  };
}

const MAX_MODEL_ITEMS = 5;
const MAX_MODELDATA_ITEMS = 5;
const setModelItems = (currentModel, modelInfo, modelData, modelId) => {
  const cItems = {...currentModel}; //create copy of current model items

  
  //if address items is more than MAX_MODEL_ITEMS pop first id entry in cItems.allIds, first entry is the oldest
  if(!isEmpty(cItems.allIds) && !cItems.allIds.includes(modelId) && cItems.allIds.length >= MAX_MODEL_ITEMS){
    const firstId = cItems.allIds.shift();
    delete cItems.byId[firstId];
  }
    
  const cItemData = !isEmpty(cItems.byId[modelId]?.data) ? {...cItems.byId[modelId]?.data} : { byId : {}, allIds : [] };
  if(!isEmpty(cItemData.allIds) && !cItemData.allIds.includes(modelData.locationCode) && cItemData.allIds.length >= MAX_MODELDATA_ITEMS){
    const firstDataId = cItemData.allIds.shift();
    delete cItemData.byId[firstDataId];
  }
  

  //if modelData information is already in state, delete current id to update allIds sequence
  cItemData.allIds = cItemData.allIds.filter(e => e !== modelData.locationCode); 
  //insert current modeldata at last of sequence
  cItemData.allIds = [...cItemData.allIds, modelData.locationCode];
  //update address value
  cItemData.byId[modelData.locationCode] = modelData;



  //if model information is already in state, delete current id to update allIds sequence
  cItems.allIds = cItems.allIds.filter(e => e !== modelId); 
  //insert current model at last of sequence
  cItems.allIds = [...cItems.allIds, modelId];
  //update address value
  cItems.byId[modelId] = {info:modelInfo, data:cItemData};

  return cItems;
}

const MAX_ADDRESS_ITEMS = 5;
const setAddressItems = (currentAddress, addressInfo, addressId) => {
  const cItems = {...currentAddress}; //create copy of current address items

  //if address items is more than MAX_ADDRESS_ITEMS pop first id entry in cItems.allIds, first entry is the oldest
  if(!isEmpty(cItems.allIds) && !cItems.allIds.includes(addressId) && cItems.allIds.length >= MAX_ADDRESS_ITEMS){
    const firstId = cItems.allIds.shift();
    delete cItems.byId[firstId];
  }

  //if address information is already in state, delete current id to update allIds sequence
  cItems.allIds = cItems.allIds.filter(e => e !== addressId); 
  //insert current address at last of sequence
  cItems.allIds = [...cItems.allIds, addressId];

  //update address value
  cItems.byId[addressId] = addressInfo;

  return cItems;
}




export const RealEstateStateContext = React.createContext(null);
export const RealEstateDispatchContext = React.createContext(null);

export const RE_ACTION = {
  SET_CURRENT: "set-current",
}

const initialValue = {
  currentModelId: null,
  currentAddressId: null,
  currentLocCode: null,
  model: { byId : {}, allIds : [] },
  address: { byId : {}, allIds : [] },
}

function reducer(state, {type, payload}){
  switch(type){
    case RE_ACTION.SET_CURRENT:{
      const {modelInfo, modelData, addressInfo} = payload;
      const currentModelId = modelInfo.id;
      const currentAddressId = addressInfo.address.innb+addressInfo.dong+addressInfo.ho;
      const currentLocCode = addressInfo.locInfo.location_code;

      return {
        ...state, 
        currentModelId,
        currentAddressId,
        currentLocCode,
        model: setModelItems(state.model, modelInfo, modelData, currentModelId),
        address: setAddressItems(state.address, addressInfo, currentAddressId)
      }
    }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

export default function RealEstateContextProvider({children}) {
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const values = React.useMemo(() => state, [state]);

  return (
    <RealEstateStateContext.Provider value={values}>
      <RealEstateDispatchContext.Provider value={dispatch} >
        {children}
      </RealEstateDispatchContext.Provider>
    </RealEstateStateContext.Provider>
  )
}


export const useReStateContext = factoryUseContext("RealEstateStateContext", RealEstateStateContext);
export const useReDispatchContext = factoryUseContext("RealEstateDispatchContext",RealEstateDispatchContext);