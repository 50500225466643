import { Navigate, useLocation } from 'react-router-dom';
import { useGlobalStateContext } from "./context/GlobalContext";

const Private = ({Component}) => {
    const location = useLocation();
    const {user} = useGlobalStateContext();

    return user ? <Component /> : <Navigate to="/login" state={{prevPath: location.pathname}} />
}

export default Private