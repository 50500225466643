import React from 'react'


const UserInfoContext = React.createContext()


export const USER_INFO_PAGES = {
  USER_DETAILS: "user-details",
  UPDATE_EMAIL: "update-email",
  UPDATE_PASSWORD: "update-password",
  UNSUBSCRIBE: "unsubscribe"
}

export const ACTION = {
  SET_FRAME_VIEW: "set-frame-view",
  START_SUBMIT_FORM: "start-submit-form",
  MYINFO_VERIFY_SUCCESS: "myinfo-verify-success",
  UPDATE_EMAIL_SUCCESS: "update-email-success",
  UPDATE_PASS_SUCCESS: "update-pass-success",
  SET_LOADING: "set-loading",  //explicitly set loader
  UNSUBSCRIBE_SUCCESS: "unsubscribe-success",
  CANCEL: "cancel"
}

export const initialValue = {
  userInfo: {},
  pwSet: false,
  formToken: null,
  transactionID: null,
  frameView: USER_INFO_PAGES.USER_DETAILS,
  formLoading: false
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_FRAME_VIEW:
      return {...state, frameView: payload};
    case ACTION.START_SUBMIT_FORM:
      return {...state, formLoading: true};
    case ACTION.MYINFO_VERIFY_SUCCESS:
      return {
        ...state,
        userInfo: payload.userInfo,
        formToken: payload.formToken, 
        transactionID: payload.transactionID, 
        frameView: USER_INFO_PAGES.USER_DETAILS,
        pwSet: true, 
        formLoading: false
      };
    case ACTION.UPDATE_EMAIL_SUCCESS:
      return {...state, userInfo: payload, frameView: USER_INFO_PAGES.USER_DETAILS, formLoading: false};
    case ACTION.UPDATE_PASS_SUCCESS:
      return {...state, userInfo: payload, frameView: USER_INFO_PAGES.USER_DETAILS, formLoading: false};
    case ACTION.SET_LOADING:
      return {...state, formLoading: payload};
    case ACTION.UNSUBSCRIBE_SUCCESS:
      return {...initialValue};
    case ACTION.CANCEL:
      return {...state, frameView: USER_INFO_PAGES.USER_DETAILS};
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}



export const UserInfoProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialValue);

  const value = {
    userInfoState: state,
    userInfoDispatch: dispatch,
  }

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  )
}

export default UserInfoContext