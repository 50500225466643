import React from 'react'

import { useForm, Controller, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import zxcvbn from 'zxcvbn';
import { NavLink, useParams } from "react-router-dom";
import MainContentWrapper from "../components/MainContentWrapper";
import { API_BASE_URL } from "../utils/constants";
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import MiniLoader from "../components/loaders/MiniLoader";
import { InvalidRequestBody, ResponseError } from "../utils/helpers";



const validationSchema = Yup.object().shape({
  password: Yup.string().required('비밀번호를 입력해주세요')
  .min(8, null),
  confirmpass: Yup.string()
    .required('비밀번호를 다시 입력해주세요')
    .oneOf([Yup.ref('password'), null], '암호가 일치하지 않습니다'),
});

const ResetPassword = () => {
  const {uid, resetToken} = useParams();
  const [isSending, setSending] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const passReq = { minStrength : 3, thresholdLength : 7 }
  
  const form = useForm(
  {
    defaultValues: {
      password: "",
      confirmpass: ""
    },
    mode:"onSubmit", 
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async data => {
    setSending(true);
    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify({uid: uid, reset_token: resetToken, password: data.password})
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/reset-pass`, requestOptions);
      const resJson = await response.json();

      if ([400,403].includes(response.status)) throw new ResponseError(resJson.detail);
      if (response.status === 422) throw new InvalidRequestBody(resJson.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")

      if(response.ok){
        setSubmitSuccess(true);
      }
      

    } catch (err) {
      alert(err.message)
    }
    setSending(false);
  } 


  //TODO: move to utility page
  const validatePasswordStrong = value => {
		if (value.length <= passReq.thresholdLength) return "비밀번호는 8자리 이상 입력해주세요";
		if (zxcvbn(value).score < passReq.minStrength) return "암호가 약합니다";
	};

  const InputPassword = ({ control, name }) => {
    const {field, fieldState:{error, isDirty}} = useController({name, control});
    const passwordScore = zxcvbn(field.value).score;
    const passwordLength = field.value.length;
    const passwordStrong = passwordScore >= passReq.minStrength;
		const passwordLong = passwordLength > passReq.thresholdLength;

    const counterClass = ['inline-flex items-center justify-center text-white text-lg absolute my-auto top-0 bottom-0 right-4 h-[40px] min-w-[50px] rounded-full px-3', passwordLong ? passwordStrong ? 'bg-konasuccess' : 'bg-konawarning' : 'bg-konadanger', field.value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
    const swrapClass = ['flex h-1 mt-4 divide-x-8 divide-white bg-gray-300 -ml-2 relative', field.value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
    
    //this code structure is intentional so that tailwid can purge the corresponding classes
    const styleSelector = [
      "w-[20%] bg-[#8b0000]",
      "w-[40%] bg-[#ff4500]",
      "w-[60%] bg-[#ffa500]",
      "w-[80%] bg-[#9acd32]",
      "w-[100%] bg-[#008000]",
    ]
    const strengthClass = ['h-1 absolute z-0 bg-konared transition-[width] duration-700', styleSelector[passwordScore]].join(' ').trim();

    let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
    if(isDirty && passwordStrong) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
    if(isDirty && (error || !passwordStrong)) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
    

    return (
      <div className="flex flex-col">
        <div className="relative flex">
          <input 
            {...field} 
            type="password"
            placeholder="비밀번호"
            className={`w-full px-8 py-4 rounded-xl border text-xl  outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
          />
          <span className={counterClass}>{ passwordLength ? passwordLong ? `${passReq.thresholdLength}+` : passwordLength : '' }</span>
        </div>
        
        {field.value.length > 0 &&
          <div className={swrapClass}>
            <div className={strengthClass}></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
          </div>
        }
        
        {((field.value.length > 0) || error) && <span className="text-red-500 mt-4">{(field.value.length > 0) ? validatePasswordStrong(field.value) : error?.message}</span>}
        
      </div>
    );
  }

  return (
    <MainContentWrapper> 
      <div className="min-h-[calc(100vh-200px)] py-20 px-48">
        <div className="border flex flex-col items-center w-full max-w-lg mx-auto p-8 rounded-3xl">
          <img className="h-24" src={require(`../assets/images/shield_red.svg`).default} alt="" />
          <h2 className="text-3xl font-semibold py-4">비밀번호 초기화</h2>
          <p className="text-base">새로운 비밀번호를 설정해 주세요.</p>
          {!submitSuccess
            ? <div className="flex flex-col pt-8 pb-4 w-full min-h-[200px]">
                <form className="flex flex-col space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
                  <InputPassword name="password" control={form.control} />
                  
                    <Controller
                      render={({ field, fieldState: {isDirty, error}}) => {
                        return(
                          <div className="flex flex-col">
                            <input 
                              {...field}
                              type="password"
                              placeholder="비밀번호 확인"
                              className={`w-full py-4 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                            />
                            {error && <span className="text-red-500 mt-4">{error?.message}</span>}
                          </div>
                        )
                      }}
                      name="confirmpass"
                      control={form.control}
                    />
                    
                  <div className="pt-4">
                    <button type="submit" className={`w-full flex justify-center items-center py-4 rounded-xl text-xl text-center text-white font-semibold ${!isSending ? "ripple-primary": "bg-konared/50"} `} disabled={isSending}>
                      {isSending
                        ? <MiniLoader className="h-7" />
                        : <span>비밀번호 초기화 하기</span>
                      }
                    </button>
                  </div>
                </form>
              </div>

            : <div className="flex flex-col items-center justify-center py-4 w-full min-h-[200px] text-lg text-konasuccess">
                <CheckCircleIcon className="h-8" />
                <p>새로운 비밀번호가 설정되었습니다.</p>
                <p>다시 로그인하세요.</p>
                
                <NavLink to="/login" className="mt-4 w-full py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">로그인 하기</NavLink>
              </div>
          }
        </div>

        
      </div>
    </MainContentWrapper>
  )
}

export default ResetPassword