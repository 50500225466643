import React, {useState } from 'react'
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

export const FAQAccordion = ({question, children}) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="flex flex-col space-y-5 p-9 hover:cursor-pointer" onClick={() => setIsActive(!isActive)}>
      <div className="flex" >
        <div className="font-bold text-xl flex space-x-2"><span>Q.</span><span className="p-kr">{question}</span></div>
        <div className="flex-1 inline-flex justify-end">{isActive ? <BsChevronUp size={30} /> : <BsChevronDown size={30} />}</div>
      </div>
      {isActive && children}
    </div>

  );
}