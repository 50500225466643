import React, { useReducer, useEffect } from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import logo from '../../assets/images/population.svg'
import welfareicon from '../../assets/images/welfare.svg'
import houseicon from '../../assets/images/household.svg'
import singleicon from '../../assets/images/singlehs.svg'
import fertilityicon from '../../assets/images/fertility.svg'
import internationalIcon from '../../assets/images/international.svg'
import pregnantIcon from '../../assets/images/pregnant.svg'
import mapIcon from '../../assets/images/movement.svg'
import marriageIcon from '../../assets/images/marriage.svg'
import planeIcon from '../../assets/images/plane.svg'
import timeIcon from '../../assets/images/time.svg'
import { getChartOptionDashboardPop, getChartOptionDashboardWelfare, getChartOptionDashboardHouse, getChartOptionInternationalData, getChartOptionDashboardTree, getTimelineOptionDashboardTree, getChartOptionDashboardFertility } from '../../components/charts/PopulationEchartOptions'
import DotLoader from '../../components/loaders/DotLoader'
import Echarts from '../../components/charts/Echarts'
import * as echarts from 'echarts'
import { NavLink } from 'react-router-dom'
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from "../../components/modals/APIUnavailableModal"
import { handleError } from '../../utils/pop-handle-response'

const ACTION = {
	SET_POPULATION_DATA: 'set-pop-data',
	SET_HOUSE_DATA: 'set-house-data',
	SET_SIDO: 'set-sido',
	// SET_TIME: 'set-time'
}

const initialState = {
	popdata: null,
	totalPopulation: 0,
	welfareDependency: null,
	welfareDependencyRatio: 0,
	houseData: null,
	fertilityData: null,
	house: 0,
	fertility: 0,
	single: 0,
	sidoMainData: null,
	sidoTreeData: null,
	yearlist: null
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_POPULATION_DATA:
			return {
				...state,
				popdata: payload.popdata,
				totalPopulation: payload.totalPopulation,
				welfareDependency: payload.welfareDependency,
				welfareDependencyRatio: payload.welfareDependencyRatio
			};
		case ACTION.SET_HOUSE_DATA:
			return {
				...state,
				houseData: payload.houseData,
				house: payload.house,
				single: payload.single,
				fertility: payload.fertility,
				fertilityData: payload.fertilityData
			};
		case ACTION.SET_SIDO:
			return {
				...state,
				sidoMainData: payload.sidoMainData,
				sidoTreeData: payload.sidoTreeData,
				yearlist: payload.yearlist
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}


function PopDashboard() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isOpen, setIsOpen] = React.useState(false);

	useEffect(() => {
		getPopulation()
		getSidoPopulation()
		getHouseInfo()
	}, [])


	const getPopulation = async () => {

		
		try {
			let query = `?page_size=all&loc_code=0000000000&name_en__sw=Population`
			let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`,{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			});

			
			let mainData = await response.json()

			if (!response.ok) throw new Error(handleError(response.status))

			if(mainData && mainData.data.length>0){

				let pastPopByAge = mainData.data.filter(item => item.name_en.includes('Population Data by age sex'))
				let futurePopByAge = mainData.data.filter(item => item.name_en.includes('Population Model by age sex'))
			
				
				let yearlyPopulation = pastPopByAge[0].value.slice(0, -1).reduce((acc, val, index)=>{return{...acc, [val[0]] :Object.assign({},Array(101).fill(0))}},{})
				yearlyPopulation = {...yearlyPopulation,	...futurePopByAge[0].value.reduce((acc, val, index)=>{return{...acc, [val[0]] :Object.assign({},Array(101).fill(0))}},{})}

				
				pastPopByAge.forEach(item => {
					let age = parseInt(item.name_en.split("[")[1].replace("]", "").split(",")[1].replace("A", ""))
					item.value.slice(0, -1).forEach((YearVal) => {
						yearlyPopulation[parseInt(YearVal[0])][age] += YearVal[1]
					})
				})
				futurePopByAge.forEach(item => {
					let age = parseInt(item.name_en.split("[")[1].replace("]", "").split(",")[1].replace("A", ""))
					item.value.forEach((YearVal) => {
						yearlyPopulation[parseInt(YearVal[0])][age] += YearVal[1]
					})
				})

				let total_pop_vals = {}
				let youngage_vals = {}
				let workage_vals = {}
				let oldage_vals = {}
				let welfare_ratio = {}
				let year_index = Object.keys(yearlyPopulation)
				let year_index_interval = year_index.filter(value => (value % 5) === 0)

				year_index.forEach((y) => {
					let totalObj = Object.entries(yearlyPopulation[y])
					let youngObj = Object.entries(yearlyPopulation[y]).filter(([key]) => (key < 15))
					let workingObj = Object.entries(yearlyPopulation[y]).filter(([key]) => (key >= 15 && key < 65))
					let oldObj = Object.entries(yearlyPopulation[y]).filter(([key]) => (key >= 65))

					//compute and assign
					total_pop_vals[y] = Object.values(totalObj).reduce((accumulator, value) => { return accumulator + parseInt(value[1]) }, 0);
					youngage_vals[y] = Object.values(youngObj).reduce((accumulator, value) => { return accumulator + parseInt(value[1]) }, 0);
					workage_vals[y] = Object.values(workingObj).reduce((accumulator, value) => { return accumulator + parseInt(value[1]) }, 0);
					oldage_vals[y] = Object.values(oldObj).reduce((accumulator, value) => { return accumulator + parseInt(value[1]) }, 0)
					welfare_ratio[y] = parseFloat((((youngage_vals[y] + oldage_vals[y]) / workage_vals[y])*100).toFixed(2))
				})
				
				dispatch({
					type: ACTION.SET_POPULATION_DATA,
					payload: {
						popdata: getChartOptionDashboardPop(total_pop_vals, youngage_vals, workage_vals, oldage_vals),
						totalPopulation: (Object.values(yearlyPopulation[year_index[year_index.length - 1]]).reduce((accumulator, value) => { return accumulator + parseInt(value) }, 0) / 10000).toLocaleString('en-US',{maximumFractionDigits: 0}),
						welfareDependency: getChartOptionDashboardWelfare(welfare_ratio, youngage_vals, workage_vals, oldage_vals, year_index_interval),
						welfareDependencyRatio: welfare_ratio[year_index[year_index.length - 1]].toFixed(2)
					}
				})
				
			}else{
				throw new Error(`No household data was retrieved`);
			}
		
		} catch (err) {
			setIsOpen(true)
			console.error('There has been a problem with your fetch operation:', err.message)
		}

		
	}

	const getHouseInfo = async () => {

		try {
			
			let query = `?name_en__sw=household&loc_code=0000000000`
			let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`,{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			});

			if(!response.ok) throw new Error(handleError(response.status))
				
			let mainData = await response.json()

			if(mainData && mainData.data.length>0){

				let fertilityData = mainData.data.filter(item => item.name_kr.includes('세대'))[0].value.map((item) => { return [item[0], parseFloat((item[1]).toFixed(2))] })
				let singleData = mainData.data.filter(item => item.name_kr.includes('일인'))[0].value.map((item) => { return [item[0], parseFloat((item[1] * 100).toFixed(2))] })
				let houseData = mainData.data.filter(item => item.name_kr.includes('가구 수'))[0].value.map((item) => { return [item[0], parseInt(item[1].toFixed(0))] }).filter(value => (value[0] % 5) === 0)

				dispatch({
					type: ACTION.SET_HOUSE_DATA,
					payload: {
						houseData: getChartOptionDashboardHouse(houseData, singleData),
						house: (houseData[houseData.length - 1][1] / 10000).toLocaleString('en-US',{maximumFractionDigits: 0}),
						single: singleData[singleData.length - 1][1],
						fertility: fertilityData[fertilityData.length - 1][1],
						fertilityData: getChartOptionDashboardFertility(fertilityData)
					}
			})
			}else{
				throw new Error(`No House Data was fetched`);
			}
		} catch (error) {
			setIsOpen(true)
			console.error(error.message)
		}
	}
	const getSidoPopulation = async () => {

		try {
			let query = `?page_size=all&name_en=Population Total By Sido`
			let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`,{
				method: "GET",
				headers: { "Content-Type": "application/json" },
			});

			
			if(!response.ok) throw new Error(handleError(response.status))
			
			let mainData = await response.json()

			if(mainData && mainData.data.length>0){
				const interval = 5
				
				let yearlist = mainData.data[0].value.filter(item => item[0] % interval === 0).map((item) => { return item[0] })

				if(mainData.data[0].value[0][0])
					yearlist = [mainData.data[0].value[0][0]].concat(yearlist)

				let sd_full= yearlist.map(y=>{
					let total = mainData.data.map(item=>{return item.value.filter(v => v[0] == y)[0][1]}).reduce((prev, current) => prev + current, 0)
					return {
						series : [
							{
								data: mainData.data.map(val => {
									return {
										name: val.name_kr,
										value: (val.value.filter(v => v[0] == y)[0][1]/total*100).toFixed(2)
									}
								})
							}
						]
					}
				})
	
				dispatch({
					type: ACTION.SET_SIDO,
					payload: {
						sidoMainData: mainData.data,
						sidoTreeData: getTimelineOptionDashboardTree(yearlist, sd_full),
						yearlist: yearlist
	
					}
				})
			}else{
				throw new Error(`No SD Population Data was fetched`);
			}
				
		} catch (error) {
			setIsOpen(true)
			console.error(error.message)
		}

	}

	return (
		<MainContentWrapper className=" bg-konabg" >
      		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
			<div className='pt-10 pb-20 px-28 grid grid-cols-10 gap-6  text-base'>
				<div className=' flex flex-col col-span-6 h-[518px]  rounded-lg bg-white shadow-md overflow-hidden p-7 align-middle' >
					<div className='flex h-8 font-bold items-start'>
						대한민국 인구 예측
					</div>
					<div className='flex-1 '>
						{
							state.popdata ?
								<Echarts options={state.popdata} />
								:
								<div className="h-full flex justify-center items-center"><DotLoader /></div>
						}
					</div>
				</div>
				<div className='col-span-4 h-[518px] rounded-lg bg-white shadow-md overflow-hidden p-7' >
					<div className='flex h-8 font-bold items-start'>
						2070년의 대한민국
					</div>
					<div className='flex-1 flex flex-col space-y-2'>
						<div className='flex flex-row space-x-2 text-3xl bg-konabg h-20 rounded-lg items-center pl-7 '>
							<div className="w-9" >
								<img className="w-[30px]" src={logo} alt="" />
							</div>
							<div className='font-semibold text-xl'>
								총인구
							</div>
							<div className='flex-1 text-konaupred text-4xl font-semibold text-right pr-7'>
								{state.totalPopulation ? state.totalPopulation : 0}만 명
							</div>
						</div>
						<div className='flex flex-row space-x-4  text-3xl bg-konabg h-20 rounded-lg items-center  pl-7 '>
							<div className="w-9" >
								<img className="w-[30px]" src={welfareicon} alt="" />
							</div>
							<div className='font-semibold text-xl'>
								총 부양 비율
							</div>
							<div className='flex-1 text-konaupred text-4xl font-semibold text-right pr-7'>
								{state.welfareDependencyRatio ? state.welfareDependencyRatio : 0}%
							</div>
						</div>
						<div className='flex flex-row space-x-2 text-3xl bg-konabg h-20 rounded-lg items-center  pl-7 ' >
							<div className="w-9" >
								<img className="w-[30px]" src={houseicon} alt="" />
							</div>
							<div className='font-semibold text-xl'>
								가구수
							</div>
							<div className='flex-1 text-konaupred text-4xl font-semibold text-right pr-7'>
								{state.house ? state.house : 0}만 가구
							</div>
						</div>
						<div className='flex flex-row space-x-2 text-3xl bg-konabg h-20 rounded-lg items-center  pl-7 '>
							<div className="w-9" >
								<img className="w-[30px]" src={singleicon} alt="" />
							</div>
							<div className='font-semibold text-xl'>
								1인 가구 비율
							</div>
							<div className='flex-1 text-konaupred text-4xl font-semibold text-right pr-7'>
								{state.single ? state.single : 0}%
							</div>
						</div>
						<div className='flex flex-row space-x-2  text-3xl bg-konabg h-20 rounded-lg items-center  pl-7 '>
							<div className="w-9" >
								<img className="w-[30px]" src={fertilityicon} alt="" />
							</div>
							<div className='font-semibold text-xl'>
								세대당 가구원 수
							</div>
							<div className='flex-1 text-konaupred text-4xl font-semibold text-right pr-7'>
								{state.fertility ? state.fertility : 0}명
							</div>
						</div>
					</div>


				</div>
				<div className='flex flex-col col-span-5 marker:h-[518px] rounded-lg bg-white shadow-md overflow-hidden p-7' >

					<div className='flex flex-row  h-8 font-bold items-start'>
						<div className='flex-1'>
						지역별 거주인구 구성비
						</div>
						<NavLink to="/pop-local" className=" bg-konared text-white text-xs font-semibold p-1 rounded">
							더 알아보기
						</NavLink>
					</div>
					<div className='flex-1'>
						{
							state.sidoTreeData ?
								<Echarts options={state.sidoTreeData} />
								:
								<div className="h-full flex justify-center items-center"><DotLoader /></div>
						}
					</div>
				</div>
				<div className='flex flex-col col-span-5 h-[518px] rounded-lg bg-white shadow-md overflow-hidden  p-7' >

					<div className='flex h-8 font-bold items-start'>
						<div className='flex-1'>
							생산가능인구
						</div>
						<NavLink to="/pop-national" className=" bg-konared text-white text-xs font-semibold mr-2 p-1 rounded">
							더 알아보기
						</NavLink>
					</div>
					<div className='flex-1 '>
						{
							state.welfareDependency ?
								<Echarts options={state.welfareDependency} />
								:
								<div className="h-full flex justify-center items-center"><DotLoader /></div>
						}
					</div>
				</div>

				<div className='flex flex-col col-span-5 h-[518px] rounded-lg bg-white shadow-md overflow-hidden p-7' >
					<div className='flex flex-row h-8 font-bold items-start'>
						<div className='flex-1'>
							가구 수 & 1인 가구 비율
						</div>
						<NavLink to="/pop-household" className=" bg-konared text-white text-xs font-semibold mr-2 p-1 rounded">
							더 알아보기
						</NavLink>
					</div>
					<div className='flex-1 '>
						{
							state.houseData ?
								<Echarts options={state.houseData} />
								:
								<div className="h-full flex justify-center items-center"><DotLoader /></div>
						}
					</div>

				</div>
				<div className='flex flex-col col-span-5 h-[518px] rounded-lg bg-white shadow-md overflow-hidden p-7' >

					<div className='flex flex-row h-8 font-bold items-start'>
						<div className='flex-1'>
							세대당 가구원 수
						</div>
						<NavLink to="/pop-household" className=" bg-konared text-white text-xs font-semibold mr-2 p-1 rounded">
							더 알아보기
						</NavLink>
					</div>
					<div className='flex-1 '>
						{
							state.fertilityData ?
								<Echarts options={state.fertilityData} />
								:
								<div className="h-full flex justify-center items-center"><DotLoader /></div>
						}
					</div>
				</div>
				<div className='col-span-6 rounded-lg bg-white shadow-md grid grid-cols-6 gap-3  p-5 text-sms' >
					<NavLink to="/pop-international" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={internationalIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>국제인구자료</div>
					</NavLink>

					<NavLink to="/pop-datalocal-birth" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={pregnantIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>출산율</div>
					</NavLink>

					<NavLink to="/pop-datalocal-mov" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={mapIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>전입전출</div>
					</NavLink>

					<NavLink to="/pop-datalocal-marriage" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={marriageIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>결혼율</div>
					</NavLink>

					<NavLink to="/pop-immigration" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={planeIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>결혼 이민</div>
					</NavLink>

					<NavLink to="/pop-extinction" className='aspect-square flex flex-col text-center items-center space-y-2 border  border-gray-100  shadow-md p-3 rounded-lg'>
						<div className='flex-1 flex items-center justify-center'><img className="h-12 [@media(min-width:1625px)]:h-[70px]" src={timeIcon} alt="" /></div>
						<div className='text-sm [@media(min-width:1625px)]:text-lg font-semibold'>소멸위험지수</div>
					</NavLink>
				</div>

				<div className='col-span-4 rounded-lg shadow-md overflow-hidden bg-cover' style={{ backgroundImage: `url(${require("../../assets/images/join.jpg")})` }}>
					<div className='bg-black h-full w-full bg-opacity-60 flex flex-row  items-center p-7 '>
						{/* div above to be removed once image is changed */}

						<div className='flex-1 text-2xl text-white [@media(min-width:1625px)]:text-4xl'>
							<p>
								시스템 이용에 도움이
							</p>
							<p>
								필요하신가요?
							</p>
						</div>
						<div className='flex font-bold items-center h-full'>
							<NavLink to="/population" state={{ scrollTo: "payment" }} className="">
								<button className='bg-konared rounded-xl p-5 h-full text-xl text-white hover:text-konared hover:bg-white'>
									이용권 구매하기
								</button>
							</NavLink>
						</div>
					</div>
				</div>

				<div className='col-span-10 h-28 rounded-lg bg-white shadow-md overflow-hidden p-7 text-lg items-center text-center [@media(min-width:1625px)]:text-xl'>
					<p>
						한국 인구 예측: 2021 ~ 2060 / 활용된 과거 자료: 2010 ~ 2021
					</p>
					<p>
						여기에서 제공하는 정보는 시스템다이내믹스 기법으로 개발한 모델을 이용하여 향후 40년을 시뮬레이션을 통해 생성되는 자료를 근거로 제공됩니다.
					</p>

				</div>
			</div>
		</MainContentWrapper >
	)
}

export default PopDashboard