import React from 'react'
import Moment from 'moment';
import {BiCaretDown, BiCaretUp} from 'react-icons/bi'

import { customRound } from "../../utils/helpers";

const TableKonaSim = ({konasimResult}) => {
  const currMonth = Moment().format("YYYY-MM");
  const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
  let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);

  //table data array for base data (sequence: current, 3mo, 6mo, 9mo, 1yr, 2yr)
  let tblBaseIndex = [baseDataPriceVal[0][1], baseDataPriceVal[6][1], baseDataPriceVal[12][1], baseDataPriceVal[24][1]]
  let tblBaseRate = tblBaseIndex.map((val, idx, arr) => customRound((val-arr[0])/arr[0]*100, 2));


  return (
    <div className="">
      <table className="tbl-simresult table-fixed mt-10 text-xl">
        <thead>
          <tr>
            <th></th>
            <th className="text-konadownblue">현재 가격 ({`${Moment().format('M')}월`})</th>
            <th className="text-konared">6개월 후 예측 가격</th>
            <th className="text-konared">1년 후 예측 가격</th>
            <th className="text-konared">2년 후 예측 가격</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" className="">KONA 예측 가격</th>
            {
              tblBaseIndex.map((val, idx, arr) =>  
                idx === 0 
                ? (<td key={idx}><span>{val.toLocaleString()}원</span></td>) 
                : (<td key={idx}><div className="flex flex-col py-2"><div>{val.toLocaleString()}원 </div> <div className={`text-base font-normal inline-flex justify-center items-center ${val < arr[0] ? 'text-konadownblue' : 'text-konaupred'}`}>{val < arr[0] ? <BiCaretDown size={25} className="text-blue-600" /> : <BiCaretUp size={25} className="text-red-600" />  }{tblBaseRate[idx]}%</div></div></td>)
              )
            }
          </tr>
          
          
        </tbody>
      </table>
    
    </div>
  )
}

export default TableKonaSim