import React from 'react'
import { bearerAuth } from "../../../utils/helpers";
import Moment from 'moment';
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";

const BillingHistory = () => {
  const [subHistory, setSubHistory] = React.useState();
  const [isLoading, setLoading] = React.useState(true);
  const {user} = useGlobalStateContext();

  React.useEffect(() => {

    const getSubscriptionHistory = async () => {
      try {
        
        const requestOptions = {
          method: 'GET',
          headers: {"Authorization" : bearerAuth(user)} 
        };
        const query = `?page_size=12&sort=-created_at`;
        const response = await fetch(`${API_BASE_URL}/api/subscription-history/user${query}`,requestOptions);
        const subH = await response.json();

        if(!response.ok) throw new Error(`Failed to fetch user subscription history`);
          
        if(subH.total_count > 0) setSubHistory(subH.data);  
  
        setLoading(false);
      
      } catch (err) {
        console.error(err.message)
      }
    }

    //load userInfo (bank_code, bank_account, oapi_terms)
    getSubscriptionHistory();

  },[]);

  return (
    <div className="py-4">
      <div className="text-lg font-semibold pb-2">Billing History</div>

      


      {(() => {
         if(isLoading){
          return (
            <div className="flex flex-col animate-pulse">
              <div className="grid grid-cols-6 gap-10 border-b py-4">
                <div className="h-2 w-full bg-slate-400 rounded"></div>
                <div className="h-2 w-full bg-slate-400 rounded"></div>
                <div className="h-2 w-full bg-slate-400 rounded"></div>
                <div className="h-2 w-full bg-slate-400 rounded"></div>
                <div className="h-2 w-full bg-slate-400 rounded"></div>
                <div className="h-2 w-full bg-slate-400 rounded"></div>
              </div>
              <div className="grid grid-cols-6 gap-10 border-b py-4">
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
              </div>
              <div className="grid grid-cols-6 gap-10 border-b py-4">
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
              </div>
              <div className="grid grid-cols-6 gap-10 border-b py-4">
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
                <div className="h-2 w-full bg-slate-200 rounded"></div>
              </div>
            </div>
          )
        }
        if(!isLoading && subHistory){
          return (
            <table className="table-auto w-full">
              <thead className="border-b">
                <tr className="text-gray-900 text-sm">
                  <th className="font-medium px-2 py-4 text-left">Transaction #</th>
                  <th className="font-medium px-2 py-4 text-left">Name</th>
                  <th className="font-medium px-2 py-4 text-left">Date</th>
                  <th className="font-medium px-2 py-4 text-left">Amount</th>
                  <th className="font-medium px-2 py-4 text-left">Status</th>
                  <th className="font-medium px-2 py-4 text-left">Payment Method</th>
                </tr>
              </thead>
              <tbody>
                {subHistory.map(sub => 
                  <tr className="border-b text-sm text-gray-900">
                    <td className="font-light px-2 py-4 whitespace-nowrap">{sub.transaction_id}</td>
                    <td className="font-light px-2 py-4 whitespace-nowrap">{sub.product_name2}</td>
                    <td className="font-light px-2 py-4 whitespace-nowrap">{Moment(sub.created_at).format("YYYY/MM/DD")}</td>
                    <td className="font-light px-2 py-4 whitespace-nowrap">{`₩${sub.amount.toLocaleString()}`}</td>
                    <td className="font-light px-2 py-4 whitespace-nowrap">{sub.payment_status}</td>
                    <td className="font-light px-2 py-4 whitespace-nowrap">{sub.bank_account}</td>
                  </tr>
                )}
                  
              
              </tbody>
            </table>

          )
        }
      })()}

    </div>
  )
}

export default BillingHistory