import { API_BASE_URL, MNO_VERIFICATION_STATUS } from "./constants"

const MNO_VERIFY_TIMEOUT = 60 * 5 //seconds,
export const MnoUtil = {
  config: {
    popupWindow: null,
    verifyCheckTimer: null,
  },
  verify,
}

function mountPopup() {
  const popupWindowSize = "height=750,width=550"
  const popupWindowTitle = "MNO Verification"
  MnoUtil.config.popupWindow = window.open(
    "about:blank",
    popupWindowTitle,
    popupWindowSize
  )
}

function checkIfSafariBrowser() {
  const userAgentString = navigator.userAgent
  // Detect chrome agent
  const chromeAgent = userAgentString.indexOf("Chrome") > -1
  // Detect Safari
  let safariAgent = userAgentString.indexOf("Safari") > 1
  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) {
    safariAgent = false
  }
  return safariAgent
}

function renderTemplate(data) {
  const baseUrl = document.location.origin
  fetch(`${baseUrl}/templates/verify-mno.html`)
    .then((res) => res.text())
    .then((html) => {
      const parser = new DOMParser()
      const doc = parser.parseFromString(html, "text/html")
      try {
        Object.keys(data).forEach((domId) => {
          doc.getElementById(domId).value = data[domId]
        })

        // If the browser is safari, then blob url is passed.
        // Otherwise, just new document content is written.
        if (checkIfSafariBrowser()) {
          // The URL.createObjectURL() static method creates a DOMString containing an URL representing the object
          // given in parameter. The URL lifetime is tied to the document in the window on which it was created.
          // The new object URL represents the specified File object or Blob object.

          // Here it is done like this, because if pop up needs permission and then after some time though the
          // permission is given, the content which needed to be shown is not displayed later. But in this approach
          // it works like after permission is given, the pop up window displays the content.
          const winUrl = URL.createObjectURL(
            new Blob([doc.documentElement.innerHTML], { type: "text/html" })
          )
          // The new window is opened with the blob url.
          MnoUtil.config.popupWindow.location = winUrl
        } else {
          // MnoUtil.config.popupWindow.document.open() //moved to verify() on first call
          MnoUtil.config.popupWindow.document.write(
            doc.documentElement.innerHTML
          )
          MnoUtil.config.popupWindow.document.close()
        }
      } catch (e) {
        console.log("renderTemplate => ", e)
      }
    })
}

function initializeMnoVerifyForm(data) {
  if (MnoUtil.config.popupWindow == null) {
    console.log("MNO window was not initialized.")
  } else {
    renderTemplate(data)
  }
}

async function startCheckingVerificationCheckCi(
  requestPayload,
  onSuccess,
  onFailure
) {
  //!IMPORTANT: this function is for findID and findPassword only
  //use startCheckingVerification() for registration page

  const verifyStartTime = new Date()
  MnoUtil.config.verifyCheckTimer = setInterval(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        request_id: requestPayload.requestId,
        request_token: requestPayload.requestToken,
      }),
    }

    fetch(`${API_BASE_URL}/api/mno/status-mno`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const verificationStatus = data

        if (verificationStatus === MNO_VERIFICATION_STATUS.PENDING) {
        } else if (verificationStatus === MNO_VERIFICATION_STATUS.CI_EXISTS) {
          //success if CI_EXISTS, means userInfo is found
          mnoVerifySuccess(requestPayload, onSuccess)
        } else {
          mnoVerifyFailedCheckCi(verificationStatus, onFailure)
        }
      })
    const currentUrl = window.location.href.split("?")[0]
    const elapseVerifyTime = (new Date() - verifyStartTime) / 1000 //reserved variable which will be used if limit of time interval will be needed
    if (elapseVerifyTime > MNO_VERIFY_TIMEOUT) {
      mnoVerifyFailedCheckCi(MNO_VERIFICATION_STATUS.TIMEOUT, onFailure)
    } else if (MnoUtil.config.popupWindow.closed) {
      mnoVerifyFailedCheckCi(MNO_VERIFICATION_STATUS.POPUP_CLOSED, onFailure)
    } else if (requestPayload.reqSourceUrl !== currentUrl) {
      mnoVerifyFailed(MNO_VERIFICATION_STATUS.POPUP_CLOSED, onFailure)
    }
  }, 1000)
}

function mnoVerifyFailedCheckCi(verificationStatus, onFailure) {
  stopCheckingVerification()
  let failedMsg = ""

  if (verificationStatus === MNO_VERIFICATION_STATUS.FAILED) {
    failedMsg = "본인 인증을 실패했습니다"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.SUCCESS) {
    //if status is SUCCESS, mean no account exists in the database
    failedMsg = "계정 정보가 없습니다"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.INVALID_HASH) {
    failedMsg = "Invalid Hash Key"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.TIMEOUT) {
    failedMsg = "시간이 초과되었습니다. 휴대폰 본인 인증을 다시 해주세요"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.POPUP_CLOSED) {
    failedMsg = "본인 인증을 완료되지 않았습니다"
  }

  closePopupWindow()
  onFailure && onFailure(failedMsg)
}

async function startCheckingVerification(requestPayload, onSuccess, onFailure) {
  const verifyStartTime = new Date()
  MnoUtil.config.verifyCheckTimer = setInterval(() => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        request_id: requestPayload.requestId,
        request_token: requestPayload.requestToken,
      }),
    }

    fetch(`${API_BASE_URL}/api/mno/status-mno`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        const verificationStatus = data

        if (verificationStatus === MNO_VERIFICATION_STATUS.PENDING) {
        } else if (verificationStatus === MNO_VERIFICATION_STATUS.SUCCESS) {
          mnoVerifySuccess(requestPayload, onSuccess)
        } else {
          mnoVerifyFailed(verificationStatus, onFailure)
        }
      })

    const currentUrl = window.location.href.split("?")[0]
    const elapseVerifyTime = (new Date() - verifyStartTime) / 1000 //reserved variable which will be used if limit of time interval will be needed
    //TODO: add clearInterval if more than certain call
    if (elapseVerifyTime > MNO_VERIFY_TIMEOUT) {
      mnoVerifyFailed(MNO_VERIFICATION_STATUS.TIMEOUT, onFailure)
    } else if (MnoUtil.config.popupWindow.closed) {
      mnoVerifyFailed(MNO_VERIFICATION_STATUS.POPUP_CLOSED, onFailure)
    } else if (requestPayload.reqSourceUrl !== currentUrl) {
      mnoVerifyFailed(MNO_VERIFICATION_STATUS.POPUP_CLOSED, onFailure)
    }
  }, 1000)
}

function mnoVerifySuccess(requestPayload, onSuccess) {
  stopCheckingVerification()
  closePopupWindow()
  onSuccess && onSuccess(requestPayload)
}

function mnoVerifyFailed(verificationStatus, onFailure) {
  stopCheckingVerification()
  let failedMsg = ""

  if (verificationStatus === MNO_VERIFICATION_STATUS.FAILED) {
    failedMsg = "본인 인증을 실패했습니다"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.CI_EXISTS) {
    failedMsg = "이미 등록 된 휴대폰 번호입니다"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.INVALID_HASH) {
    failedMsg = "Invalid Hash Key"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.TIMEOUT) {
    failedMsg = "시간이 초과되었습니다. 휴대폰 본인 인증을 다시 해주세요"
  } else if (verificationStatus === MNO_VERIFICATION_STATUS.POPUP_CLOSED) {
    failedMsg = "본인 인증을 완료되지 않았습니다"
  }

  closePopupWindow()
  onFailure && onFailure(failedMsg)
}

function stopCheckingVerification() {
  clearInterval(MnoUtil.config.verifyCheckTimer)
  MnoUtil.config.verifyCheckTimer = null
}

function closePopupWindow() {
  if (MnoUtil.config.popupWindow && !MnoUtil.config.popupWindow.closed) {
    MnoUtil.config.popupWindow.close()
    MnoUtil.config.popupWindow = null
  }
}

async function requestMnoVerification(requestPayload, onFailure) {
  try {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        account_type: requestPayload.accountType,
        request_token: requestPayload.requestToken,
      }),
    }

    const response = await fetch(
      `${API_BASE_URL}/api/mno/verify-mno`,
      requestOptions
    )

    if (response.ok) {
      const responseData = await response.json()
      return {
        ...responseData,
        Cryptokurl: "Y",
        CI_FIXCOMMID: "",
      }
    } else {
      throw new Error(response.detail)
    }
  } catch (err) {
    stopCheckingVerification()
    closePopupWindow()
    onFailure && onFailure(err.message)
  }
}

export async function verify(
  requestPayload,
  onSuccess,
  onFailure,
  checkCi = false
) {
  //checkCi reverses the logic of the verify() function
  //meaning, if true, verification is successful if CI exists,
  //and failed if MNO status is SUCCESS (no CI exists)
  //this is used for findID and findPassword

  //requestPayload parameter by default contains: accountType and reqSourceUrl (the current URL when MNO verification was requested)
  //the reqSourceUrl will be used to check if user is still within the current page where the MNO verification was triggered
  //otherwise if the page was changed, MNO pop-up should be closed and verification should be stopped.

  //moved here so that popup will not be blocked by browser
  // get mno window
  mountPopup()
  MnoUtil.config.popupWindow.document.open()

  try {
    if (
      typeof requestPayload !== "object" ||
      requestPayload === null ||
      !("accountType" in requestPayload) ||
      !("reqSourceUrl" in requestPayload)
    ) {
      throw new Error("requestPayload paramter is invalid")
    }

    const mnoFormData = await requestMnoVerification(requestPayload, onFailure)
    if (mnoFormData) {
      const mstr = JSON.parse(mnoFormData.MSTR)
      requestPayload.requestId = mstr.request_id
      requestPayload.requestToken = mstr.request_token

      // generate mno verify form and inject into the window
      initializeMnoVerifyForm(mnoFormData)

      // The verification check request is started right after opening window.
      if (checkCi) {
        startCheckingVerificationCheckCi(requestPayload, onSuccess, onFailure)
      } else {
        startCheckingVerification(requestPayload, onSuccess, onFailure)
      }
    }
  } catch (e) {
    console.log("Could not initiate mno verification")
    alert(e.message)
  }
}
