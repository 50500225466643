import { ACCOUNT_TYPE, API_BASE_URL, TOS_POSITION } from "../constants";
import { ApiError, bearerAuth, InvalidRequestBody, ResponseError } from "../helpers";

export const checkIsSubscribeApi = async (user, serviceId) => {

  try {
    const query = `?service_id=${serviceId}`;
    const res = await fetch(`${API_BASE_URL}/api/subscription/verify${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(res.ok) {
      return true;
    }
    else if(res.status === 403) {
      return false;
    }
    else{
      throw new ApiError("Unknown error occured during subscription verification. Please contact us for assistance.");
    }

  } catch (err) {
    throw(err)
  }
}


export const checkUserNotAgreedTOS = async (user) => {
   
  
  const tosPosition = user.user_info.account_type === ACCOUNT_TYPE.CORPORATE ? TOS_POSITION.REG_CORPORATE : TOS_POSITION.REG_PERSONAL
  try {
    const query = `?is_active=true&is_agree=false&is_mandatory=true&position_type=${tosPosition}`
    const res = await fetch(`${API_BASE_URL}/api/users/terms${query}`, {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    });

    const parseRes = await res.json();

    if ([400,403].includes(res.status)) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Something went wrong. Please contact admin.")

    return parseRes;
    
  } catch (err) {
    throw(err)
  }
}


export const verifyTokenApi = async (token) => {
  try {
    const res = await fetch(`${API_BASE_URL}/api/auth/verify`, {
      method: "GET",
      headers: {"Authorization" : `Bearer ${JSON.parse(token)}`} 
    });

    const userCredential = await res.json();

    if ([400,403].includes(res.status)) throw new ResponseError(userCredential.detail);
    if (res.status === 422) throw new InvalidRequestBody(userCredential.detail);
    if(!res.ok) throw new ApiError("Something went wrong. Please contact admin.")

    return userCredential;


  } catch (err) {
    console.error(err.message)  //do not throw for now, need to handle 403 error
  }
}


export const loginApi = async (email, password, account_type, persistLogin) => {

  try {
      const res = await fetch(`${API_BASE_URL}/api/auth/sign-in`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password, account_type })
      });

      const userCredential = await res.json();

      if (res.status === 403) throw new ApiError(userCredential.detail);
      
      // store user details and jwt token in session storage or local storage (if persist)
      if(persistLogin){
        localStorage.setItem('jwToken', JSON.stringify(userCredential.access_token));
      }
      else{
        sessionStorage.setItem('jwToken', JSON.stringify(userCredential.access_token));
      }

      return userCredential;

    } catch (err) {
      throw(err)
    }
}