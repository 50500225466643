import React from 'react'

const Report = () => {
  return (
    <div className="pt-10 pb-5 border-b-[1px] border-gray-200">
      <h1 className="text-2xl font-bold text-konared">Report</h1>
    </div>
  )
}

export default Report