import React from 'react'
import RegistrationContext, {ACTION as REG_ACTION} from "../context/RegistrationProvider";
import { ACCOUNT_TYPE, API_BASE_URL } from "../../utils/constants";
import RegSteps from "./RegSteps";
import Loader3 from "../loaders/Loader3";
import { NavLink, useNavigate } from "react-router-dom";




const PersonalRegProcess = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = React.useState();
  const {registrationState, registrationDispatch} = React.useContext(RegistrationContext);


  React.useEffect(() => {

    const saveRegistrationData = async () => {

      const formVal = {
        "name": registrationState.name,
        "email": registrationState.email,
        "password": registrationState.password,
        "terms_list": registrationState.terms_agreed,
      }

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formVal)
      };
  
      try {
          const response = await fetch(`${API_BASE_URL}/api/auth/sign-up`, requestOptions);
          const user = await response.json();
  
          if (response.status === 403) throw new Error(user.detail);
          if (response.ok)
          {
            registrationDispatch({type: REG_ACTION.REGISTRATION_SUCCESS});
            setUserName(user.name);
          }
  
        } catch (err) {
          console.error(err.message);
        }
    }
    
    if(registrationState.regType === ACCOUNT_TYPE.PERSONAL && registrationState.isRegSubmitLoading === true){
      saveRegistrationData();
    }
    else{
      navigate("/register");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (

    <div className="py-20 px-48">
      <div className="flex flex-col items-center space-y-8 pb-10">
        <h1 className="text-4xl leading-none font-bold">개인회원 회원정보 입력</h1>
        <RegSteps prevStep={2} nextStep={registrationState.isRegSuccess ? 3 : 2} />
      </div>

      <div className="w-full max-w-4xl mx-auto">
            {(() => {
              if(registrationState.isRegSuccess === true && registrationState.isRegSubmitLoading === false){
                return(
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    
                    <div className="flex flex-col items-center py-8">
                      <p className="text-3xl font-bold text-konared">코나SD 예측 서비스 개인 회원가입을 환영합니다.</p>
                      <p className="mt-8 text-xl"><span className="text-konared">{userName}</span>님, 코나SD 예측 서비스 가입이 완료되었습니다.</p>
                      <p className="text-xl">가입된 이메일으로 로그안하신 후 코나SD 예측 서비스를 이용해 주세요.</p>
                    </div>

                    <div className="pt-10">
                      <div className="flex justify-center space-x-5">
                        <NavLink 
                          to="/login"
                          state={registrationState.locationState} 
                          className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary"
                        >로그인
                        </NavLink>
                      </div>
                    </div>
                  </div>
                )

              
              }
              else if(registrationState.isRegSubmitLoading === true){
                return (
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    <div><Loader3 /></div>
                    <span>개인회원 가입이 진행 중입니다</span>
                  </div>
                )
              }
              else {
                return(
                  <div className="flex flex-col items-center justify-center space-y-2 min-h-[300px]">
                    <div className="flex flex-col items-center py-8">
                      {/* <ExclamationTriangleIcon className="h-32 w-32 text-konared" /> */}
                      <div className="border-4 border-konared rounded-full w-20 h-20 inline-flex justify-center items-center text-6xl font-light text-konared">!</div>
                      <p className="mt-4 text-xl"> 코나SD 예측 서비스 가입이 <span className="font-bold text-konared">실패했습니다</span>.</p>
                      <p className="text-xl">오류로 인해 개인회원 가입이 완료되지 않았습니다. 가입을 다시 시도해주세요</p>
                    </div>

                    <div className="pt-10">
                      <div className="flex justify-center space-x-5">
                        <NavLink 
                          onMouseDown={e => e.preventDefault()} 
                          to={registrationState.locationState ? registrationState.locationState?.prevPath : "/" }
                          state={registrationState.locationState} 
                          className="w-[180px] py-4 rounded-xl text-xl text-center font-semibold ripple-gray-outline"
                        >가입 취소
                        </NavLink>
                        <NavLink to="/register" className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">가입 다시하기</NavLink>
                      </div>
                    </div>
                  </div>
                )

              
              }
            })()}

      </div>
    </div>
  )
}

export default PersonalRegProcess