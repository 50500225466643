import React from 'react'
import { NavLink } from "react-router-dom"
import MainContentWrapper from '../../components/MainContentWrapper'
import FAQsPop from "../../components/population/FAQsPop";
import { HiHome, HiChevronRight } from 'react-icons/hi'

function PopContact() {
  return (
    <MainContentWrapper>

      <div className="pt-10 px-28">
        {/* breadcrumbs */}
        <nav className="flex pb-4" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
            <li className="inline-flex items-center">
              <NavLink to="/population" className="inline-flex items-center text-sm font-medium hover:text-konared">
                <span className="mr-2"><HiHome size={16} /></span>
                Home
              </NavLink>
            </li>
            <li>
              <div className="flex items-center">
                <span className=""><HiChevronRight size={24} /></span>
                <span className="ml-2">도움말</span>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <span className=""><HiChevronRight size={24} /></span>
                <span className="ml-2">문의</span>
              </div>
            </li>
          </ol>
        </nav>
        {/* end of breadcrumbs */}

      </div>

      {/* faq */}
      <FAQsPop />

      {/* contact */}

      <div className="pt-10 pb-20 px-28">
        <h1 className="text-3xl font-bold text-konared">시스템 문의사항</h1>
        <p className=" text-lg pt-2">  서비스 이용에 관한 문의사항은 ikyjeong@naver.com으로 연락주시기 바랍니다.</p>
      </div>
    </MainContentWrapper>
  )
}

export default PopContact