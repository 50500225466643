import React from 'react'
import { FAQAccordion } from "../Accordion"


const FAQsPop= () => {
  return (
    <div className="px-28">
      <h1 className="text-3xl font-bold text-konared">자주 묻는 질문</h1>
      <div className="mt-6 border-t-4 border-b-2 border-black flex flex-col divide-y text-xl">
        <FAQAccordion question="이용권을 구매해야 서비스를 이용할 수 있나요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <p className="p-kr">일반 사용자는 대쉬보드의 정보와 인구 동향정보 등 일반적인 인구관련 정보 확인이 가능합니다. 서비스 이용권을 구매하면 지역별 인구정보와 다양한 인구관련 정책 시뮬레이션을 실행할 수 있습니다.</p>
          </div>
        </FAQAccordion>
        <FAQAccordion question="정책 시뮬레이션의 지역 인구예측은 어떻게 하나요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <div className="flex flex-col space-y-2 w-full">
              <p>년간 사용자를 대상으로 지역별 정책 시뮬레이션 서비스를 개별로 제공합니</p>
              <p>다. 사용자가 원하는 정책 시뮬레이션을 미래예측 전문가팀이 제공해 드립니</p>
              <p>다. (문의: 곽미애 <span className="font-bold text-indigo-600">02-3440-9112</span> , <span className="font-bold text-indigo-600">ikyjeong@naver.com</span>)</p>
            </div>
          </div>
        </FAQAccordion>
        <FAQAccordion question="인구 예측방법론은 무엇인가요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <p className="p-kr">System Dynamics 방법론을 이용하여 우리나라 인구를 1세부터 100세까지 성별로 세분하여 예측합니다. 예측된 인구수는 우리나라 전체, 시도별, 시군구, 읍면동으로 확인 가능합니다.</p>
          </div>
        </FAQAccordion> 
      </div>
    </div>


  )
}

export default FAQsPop