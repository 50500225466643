import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'

const SearchAddressErrorModal = ({isOpen, setClose, errorObj}) => {
  const initialFocusRef = React.useRef(null);
  
  return (
    <Transition show={isOpen} as={React.Fragment} >
      {/* TODO: fix bug, onClose gets triggered automaticcaly */}
      <Dialog as="div" className="relative z-[9000]" onClose={()=> {}} initialFocus={initialFocusRef}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">검색 실패</Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">{errorObj.msg}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 focus:outline-none" ref={initialFocusRef} tabIndex={-1}>

                  {"confirmFn" in errorObj &&
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-konared px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700   sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => errorObj.confirmFn(errorObj.param)}
                    >
                      확인
                    </button>
                  }
                  {"cancelFn" in errorObj &&
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => errorObj.cancelFn()}
                      
                    >
                      취소
                    </button>
                  }


                  {/* {"confirmFn" in errorObj && "cancelFn" in errorObj
                    ? 
                    <>
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-konared px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700   sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => errorObj.confirmFn(errorObj.param)}
                      >
                        확인
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => errorObj.cancelFn()}
                        
                      >
                        취소
                      </button>
                    </>
                    
                    : 
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md border border-transparent focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-konared px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700   sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={setClose}
                    >
                      확인
                    </button>
                  } */}
                  
                  
                  
                </div>
              </Dialog.Panel>


            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default SearchAddressErrorModal