import { API_BASE_URL } from "../constants";
import { ApiError, bearerAuth, InvalidRequestBody, isEmpty, ResponseError } from "../helpers";

export const getNoticeListApi = async (page, pageSize, searchText) => {

  searchText = searchText !== null && searchText.length > 0 ? `&title__ilike=%${searchText}%` : "";

  try {
    const query = `?page=${page}&page_size=${pageSize}&sort=-created_at&expand=user` + searchText;
    const res = await fetch(`${API_BASE_URL}/api/notices${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}


export const getTotalNoticeItemsApi = async (searchText) => {

  searchText = searchText !== null && searchText.length > 0 ? `?title__ilike=%${searchText}%` : "";

  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/total-items` + searchText, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find notice information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load notice data.");
    }

  } catch (err) {
      throw(err)
  }
}


export const getNoticeByIdApi = async (id) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/${id}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find notice information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load notice data.");
    }

  } catch (err) {
      throw(err)
  }
}

export const addNoticeApi = async (title, content, user) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/notices`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({title: title, content : content}),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find notice information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to add new notice.");
    }

  } catch (err) {
      throw(err)
  }
}


export const updateNoticeByIdApi = async (title, content, postId, user) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/${postId}`, {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({title: title, content : content}),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find notice information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to add new notice.");
    }

  } catch (err) {
      throw(err)
  }
}

export const deleteNoticeByIdApi = async (postId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/${postId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; 

  } catch (err) {
      throw(err)
  }
}

export const COMMENT_POST_TYPE = {
  NEWS: {apiParentUrl: "news", keyword: "news"},
  NOTICE: {apiParentUrl: "notices", keyword: "notices"},
}

export const getCommentsApi = async (postType, page, pageSize, postId) => {

  try {
    const query = `?page=${page}&page_size=${pageSize}&sort=-created_at&expand=user`;
    const res = await fetch(`${API_BASE_URL}/api/${postType}/${postId}/comments${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}




export const addCommentApi = async (postType, postId, content, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/${postType}/${postId}/comments`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({content : content}),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; 

  } catch (err) {
      throw(err)
  }
}




export const deleteCommentApi = async (postType, postId, commentId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/${postType}/${postId}/comments/${commentId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; 

  } catch (err) {
      throw(err)
  }
}



export const getUserCommentReactionsApi = async (postType, postId, user) => {

  //when using comment_id__isnull=false, this will retrieve user reaction for comments

  if (user) {
    try {
      const query = `?page_size=all&comment_id__isnull=false`;
      const res = await fetch(`${API_BASE_URL}/api/${postType}/${postId}/user-reactions${query}`, {
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
  
      const parseRes = await res.json();
  
      if (res.status === 403) throw new ResponseError(parseRes.detail);
      if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
      
      return parseRes.data; //can return empty list
  
    } catch (err) {
        throw(err)
    }
  }

  return undefined;
}


export const getNoticeUserReactionsApi = async (postId, user) => {

  //when using comment_id__isnull=true, this will retrieve user reaction for post
  if (user) {
    try {
      const query = `?page_size=all&comment_id__isnull=true`;
      const res = await fetch(`${API_BASE_URL}/api/notices/${postId}/user-reactions${query}`, {
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
  
      const parseRes = await res.json();
  
      if (res.status === 403) throw new ResponseError(parseRes.detail);
      if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
      
      return parseRes.data; //can return empty list
  
    } catch (err) {
        throw(err)
    }
  }

  return undefined;

}


export const addNoticeUserReactionsApi = async (postId, commentId, user) => {

  //if commentId is null, reaction is for post
  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/${postId}/user-reactions`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        comment_id: commentId,
        is_like: true,  //currently always true because dislike feature is disabled 
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; //can return empty list

  } catch (err) {
      throw(err)
  }
}

export const deleteNoticeUserReactionsApi = async (postId, commentId, user) => {

  //if commentId is null, reaction is for post

  try {
    const res = await fetch(`${API_BASE_URL}/api/notices/${postId}/user-reactions`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        comment_id: commentId,
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; //can return empty list

  } catch (err) {
      throw(err)
  }
}



// NEWS Api
export const NEWS_CATEGORY = {
  ARTICLE : "ARTICLE",
  INTERVIEW : "INTERVIEW",
  COLUMN : "COLUMN",
}

export const NEWS_GROUP = {
  POPULATION : "POPULATION",
}




export const getNewsListApi = async (group, category, page, pageSize, searchText) => {

  group = group !== null && group.length > 0 ? `&group=${group}` : "";
  category = category !== null && category.length > 0 ? `&category=${category}` : "";
  searchText = searchText !== null && searchText.length > 0 ? `&title__ilike=%${searchText}%` : "";

  try {
    const query = `?page=${page}&page_size=${pageSize}&sort=-created_at&expand=user`+ group + category + searchText;
    const res = await fetch(`${API_BASE_URL}/api/news${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}

export const getTotalNewsItemsApi = async (group, category, searchText) => {

  //group must be first query because it is requeired and must prepend "?" as a starting query paramter
  group = group !== null && group.length > 0 ? `?group=${group}` : "";
  category = category !== null && category.length > 0 ? `&category=${category}` : "";
  searchText = searchText !== null && searchText.length > 0 ? `&title__ilike=%${searchText}%` : "";

  try {
    const res = await fetch(`${API_BASE_URL}/api/news/total-items`+ group + category + searchText, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find news information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load news data.");
    }

  } catch (err) {
      throw(err)
  }
}


export const getNewsByIdApi = async (id) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/news/${id}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json"},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find news information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load news data.");
    }

  } catch (err) {
      throw(err)
  }
}

export const addNewsApi = async (title, url, group, category, user) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/news`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        title: title, 
        url : url,
        category : category,
        group : group,
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find news information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to add new news.");
    }

  } catch (err) {
      throw(err)
  }
}

export const updateNewsByIdApi = async (title, url, group, category, postId, user) => {


  try {
    const res = await fetch(`${API_BASE_URL}/api/news/${postId}`, {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        title: title, 
        url : url,
        category : category,
        group : group,
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if (res.status === 404) throw new ApiError("Cannot find news information.");
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to add new news.");
    }

  } catch (err) {
      throw(err)
  }
}


export const deleteNewsByIdApi = async (postId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/news/${postId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; 

  } catch (err) {
      throw(err)
  }
}




export const getNewsUserReactionsApi = async (postId, user) => {

  //when using comment_id__isnull=true, this will retrieve user reaction for post
  if (user) {
    try {
      const query = `?page_size=all&comment_id__isnull=true`;
      const res = await fetch(`${API_BASE_URL}/api/news/${postId}/user-reactions${query}`, {
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
  
      const parseRes = await res.json();
  
      if (res.status === 403) throw new ResponseError(parseRes.detail);
      if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
      
      return parseRes.data; //can return empty list
  
    } catch (err) {
        throw(err)
    }
  }

  return undefined;

}


export const addNewsUserReactionsApi = async (postId, commentId, user) => {

  //if commentId is null, reaction is for post
  try {
    const res = await fetch(`${API_BASE_URL}/api/news/${postId}/user-reactions`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        comment_id: commentId,
        is_like: true,  //currently always true because dislike feature is disabled 
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; //can return empty list

  } catch (err) {
      throw(err)
  }
}

export const deleteNewsUserReactionsApi = async (postId, commentId, user) => {

  //if commentId is null, reaction is for post

  try {
    const res = await fetch(`${API_BASE_URL}/api/news/${postId}/user-reactions`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        comment_id: commentId,
      }),
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes; //can return empty list

  } catch (err) {
      throw(err)
  }
}
