import React from 'react'
import { useForm, useController} from "react-hook-form";
import BillingContext, { ACTION as BILLING_ACTION, BILLING_PAGES } from "../../context/BillingProvider";
import { CheckIcon } from '@heroicons/react/24/outline'
import { bearerAuth, InvalidRequestBody, ResponseError } from "../../../utils/helpers";
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";

const BillingKonaOApiTerms = () => {
  const {user} = useGlobalStateContext();
  const {billingState, billingDispatch} = React.useContext(BillingContext);
  const form = useForm({mode:"onChange"});

  const onSubmit = async data => {
     //if submit is triggered, it is assumed all required TOS was checked
     billingDispatch({type: BILLING_ACTION.SET_TERMS_LOADING});
     delete data["tAll"];
     
     // const termsAgreed = Object.entries(data)
     //   .map(([key, val]) => (
     //     {
     //       tcId: parseInt(key.split("_")[1]), 
     //       acceptedByUser: val
     //     }
     //   ));
     
     const termsAgreedArray = Object.entries(data)
     .filter(([key, val]) => val === true)
     .map(([key, val]) => parseInt(key.split("_")[1]));
 
 
     const termsAgreed = Object.entries(data)
       .map(([key, val]) => (
         {
           id: parseInt(key.split("_")[1]), 
           is_agree: val,
           source: key.split("_")[2]
         }
       ));
 
 
     //if oapi_id exisist(verified user), use update-terms endpoint
     if(billingState.userInfo.oapiInfo.oapi_id !== null){

      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
        body: JSON.stringify({
          oapi_id: billingState.userInfo.oapiInfo.oapi_id, 
          terms_list: termsAgreed,
        })
      };
      
      try {

        const response = await fetch(`${API_BASE_URL}/api/payment/update-terms`, requestOptions);
        const paymentInfo = await response.json();

        if (response.status === 403) throw new ResponseError(paymentInfo.detail);
        if (response.status === 422) throw new InvalidRequestBody(paymentInfo.detail);
        
        if(response.ok){
          //do nothing
        }
        else{
          throw new Error("Error in fetch request")
        }

      } catch (err) {
        if (err instanceof ResponseError) {
          console.error("ResponseError", err.message, err.__detail);
        } else if (err instanceof InvalidRequestBody) {
          console.error("InvalidInput", err.message, err.__detail);
        } else {
          throw(err)
        }
      }

     }

      


     //otherwise proceed to oapi registration and update terms after registration
     const termsList = billingState.termsList.map(terms => ({...terms, is_agree: termsAgreedArray.includes(terms.tid) }));
     billingDispatch({type: BILLING_ACTION.SET_TERMS_AGREED, payload: {
       termsList, 
       termsAgreed,
       frameView: BILLING_PAGES.BANK_REGISTRATION
     }});
 


    // const requestOptions = {
    //   method: 'POST',
    //   headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    //   body: JSON.stringify({
    //     oapi_id: billingState.userInfo.oapiInfo.oapi_id, 
    //     terms_list: termsAgreed,
    //   })
    // };
    
    // try {

    //   const response = await fetch(`${API_BASE_URL}/api/payment/update-terms`, requestOptions);
    //   const paymentInfo = await response.json();

    //   if (response.status === 403) throw new ResponseError(paymentInfo.detail);
    //   if (response.status === 422) throw new InvalidRequestBody(paymentInfo.detail);
      
    //   if(response.ok){
    //     const terms = await loadTerms(user);

    //     let frameView =  BILLING_PAGES.BANK_REGISTRATION;


    //     billingDispatch({type: BILLING_ACTION.UPDATE_TERMS_SUCCESS, payload: {terms, frameView}})
    //   }
    //   else{
    //     throw new Error("Error in fetch request")
    //   }

    // } catch (err) {
    //   if (err instanceof ResponseError) {
    //     console.error("ResponseError", err.message, err.__detail);
    //   } else if (err instanceof InvalidRequestBody) {
    //     console.error("InvalidInput", err.message, err.__detail);
    //   } else {
    //     throw(err)
    //   }
    // }
    
  } 

  const handleAllSelect = (e) => {
    const tAllVal = e.target.checked;
    Object.values(billingState.termsList).forEach(terms => {
      form.setValue(`t_${terms.tid}_${terms.source}`, tAllVal, { shouldValidate: true });
    });
    // form.setValue("t1", tAllVal, { shouldValidate: true });
    // form.setValue("m1", tAllVal, { shouldValidate: true });
  }

  const handleTermsInputChange = (e) => {
    const tVals = form.getValues();
    delete tVals["tAll"];

    Object.values(tVals).forEach(val => {
      if (val === false) form.setValue("tAll", false);
    });
  }

  const ErrorMessage = errors => {
    const reqMsg = "필수약관에 모두 동의해주셔야 계좌등록을 진행할 수 있습니다.";
    const message = Object.keys(errors).length > 0 ? Object.values(errors)[0].type==="required" ? reqMsg : "unknown error" : null;
    return (
      <div>{message}</div>
    )
  }

  const TermsInput = ({ control, name, termInfo}) => {
    const {field} = useController({name, control, rules: { required : termInfo.is_mandatory }, defaultValue: false});
    return (
      <div className="flex items-center px-3 py-4 border-b border-gray-300">
        <div className="flex-1">
          <input className="hidden" id={name} type="checkbox" {...form.register(name, {onChange: (e) => handleTermsInputChange(e)})} />
          <label className="flex items-center space-x-2 cursor-pointer" htmlFor={name}>
            <div className={`inline-flex items-center justify-center h-8 w-8 rounded-full p-1 ${form.watch(name) ? "bg-konared":"border border-gray-300"}`}><CheckIcon className={`${form.watch(name) ? "text-white":"text-gray-300"}`} /></div>
            <span className="text-konagray">{`[${termInfo.is_mandatory ? '필수' : '선택'}] ${termInfo.title}`}</span> 
          </label>
        </div>
        <a className="border border-gray-300 rounded-md py-1 px-4 text-center text-sm text-konagray hover:bg-konared hover:text-white hover:border-0" target="_blank" href={termInfo.url}>보기</a>
      </div>
     
    );
  }

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <div className="flex flex-col">
        <div className="font-bold text-2xl text-center">약관 확인 및 동의</div>
        <div className="mt-4 bg-konabg text-center p-4">오픈뱅킹 이용을 위해 계좌등록시 <br /> 아래 약관동의가 필요합니다.</div>

        <div className="flex flex-col mt-8">
          
          

          {(() => {
            if(billingState.termsList.length > 0){
              return(
                <>
                  {/* tAll */}
                  <div className="px-3 py-4 border-y border-konagray">
                    <input className="hidden" id="tAll" type="checkbox" {...form.register("tAll")} onClick={(e) => handleAllSelect(e)} />
                    <label className="flex items-center space-x-2 cursor-pointer" htmlFor="tAll" >
                      <div className={`inline-flex items-center justify-center h-8 w-8 rounded-full p-1 ${form.watch(`tAll`) ? "bg-konared":"border border-gray-300"}`}><CheckIcon className={`${form.watch(`tAll`) ? "text-white":"text-gray-300"}`} /></div>
                      <span className="text-lg text-konagray">이용약관 전체 동의</span> 
                    </label>
                  </div>

                  {billingState.termsList.filter(item => item.is_agree === false).map((item, index) => {
                      return (
                        <TermsInput name={`t_${item.tid}_${item.source}`} control={form.control} termInfo={item} key={index} />
                      );
                    })
                  }
                </>
              )

              
            }
            else{
              //TODO add loader or error if TOS was not loaded successfully
            }
          })()}
        </div>


        <div className="flex flex-col space-y-2 py-10 items-center">
          <div className="text-sm font-semibold text-konared"><ErrorMessage {...form.formState.errors} /></div>
          <button  type="submit" className="bg-konared text-white inline-flex items-center justify-center w-48 leading-5 rounded py-4 ripple-primary" disabled={billingState.termsFormLoading}>
            {billingState.termsFormLoading && 
              <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            <span>동의하고 계속하기</span>
          </button>

          {!billingState.termsFormLoading && <button type="button" onClick={() => billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.BILLIN_MAIN})} className="text-sm ">Cancel</button>}
        </div>

      </div>
    </form>
  )
}

export default BillingKonaOApiTerms