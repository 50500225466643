import { Dialog, Transition } from '@headlessui/react'
import React from "react"
import { API_BASE_URL } from "../../../utils/constants";
import { bearerAuth } from "../../../utils/helpers";
import { useGlobalStateContext } from "../../context/GlobalContext";
import DotLoader from "../../loaders/DotLoader";
import { ACTION } from "./BillingSubscriptions";

const CancelSubscriptionModal = ({subState, subDispatch, onSuccess}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [subHistory, setSubHistory] = React.useState();
  const {user} = useGlobalStateContext();
  const initialFocusRef = React.useRef(null)

  const handleUnsubscribe = async () => {
    subDispatch({type: ACTION.UNSUB_START});
    try {
        
      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
        body: JSON.stringify({subscription_id: subState.unsubInfo.id})
      };
      const response = await fetch(`${API_BASE_URL}/api/subscription/unsubscribe`,requestOptions);

      if(!response.ok) throw new Error(`Failed to process cancelling subscription`);
        
      subDispatch({type: ACTION.UNSUB_SUCCESS});
    } catch (err) {
      console.error(err.message)
    }

  } 

  const handleClose = () => {
    subDispatch({type: ACTION.CLOSE_MODAL});
  }

  const handleSuccessConfirm = async () => {
    await onSuccess();
  }


  React.useEffect(() => {
    //need to load here because expanding subscription with subscription-history does not handled sorting yet
    const getSubscriptionHistory = async () => {
      try {
        
        const requestOptions = {
          method: 'GET',
          headers: {"Authorization" : bearerAuth(user)} 
        };
        const query = `?page_size=1&sort=-end_date&subscription_id=${subState.unsubInfo.id}`;
        const response = await fetch(`${API_BASE_URL}/api/subscription-history/user${query}`,requestOptions);
        const subH = await response.json();

        if(!response.ok) throw new Error(`Failed to fetch user subscription history`);
        
        if(subH.total_count > 0) setSubHistory(subH.data[0]);  
  
      
      } catch (err) {
        console.error(err.message)
      }
    }

    //load userInfo (bank_code, bank_account, oapi_terms)
    if(subState.unsubInfo) getSubscriptionHistory();

  },[subState.unsubInfo]);


  return (
    <Transition appear show={subState.unsubModalOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="m-w-72 min-h-72  transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                {(() => {
                  if(!isLoading && !subState.unsubSuccess){
                    return (
                      <div className="p-6 flex flex-col w-96 items-center text-center focus:outline-none min-h-[280px]">
                        <div className="flex-1 flex flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                            <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#FA6161" stopOpacity="100%" />
                                <stop offset="100%" stopColor="#F7CD1C" stopOpacity="100%" />
                            </linearGradient>
                            <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                          </svg>
                          <div>Are you sure you want to unsubscribe?</div>
                          {/* <div>You can still use the service until: <span className="font-bold">{subHistory?.end_date}</span></div> */}
                          <div>Unsubscribing now will have immediate effect on terminating the selected service.</div>
                          <div className="text-sm py-8 w-full text-left flex flex-col text-gray-500">
                            <span className="font-bold">Refund Information:</span>
                            <span className="ml-4">To request refund please contact us at <b>ikyjeong@naver.com</b> </span>
                            <span className="ml-4">Refund Amount: </span>
                            <ul class="ml-8 list-disc list-outside">
                              <li>100% if requested within 7 days from start of subscription and no existing simulations</li>
                            </ul>
                          </div>
                        </div>
                        <div className="flex space-x-4">
                          <button type="button" className="text-gray-700 inline-flex leading-none rounded px-10 py-3 border border-gray-300 shadow-sm hover:bg-gray-50 " onClick={handleClose}>취소</button>
                          <button onClick={handleUnsubscribe} type="button" className="text-white inline-flex leading-none rounded px-10 py-3 ripple-primary">확인</button>
                        </div>
                      </div>
                    )
                  }
                  else  if(!isLoading && subState.unsubSuccess){
                    return (
                      <div className="p-6 flex flex-col w-96 items-center text-center focus:outline-none min-h-[280px]">
                        <div className="flex-1 flex flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                            <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#4ade80" stopOpacity="100%" />
                                <stop offset="100%" stopColor="#3b82f6" stopOpacity="100%" />
                            </linearGradient>
                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                          </svg>
                          <div>Successfully unsubscribed.</div>
                          <div>Subscription: <span className="font-bold">{subState?.unsubInfo?.product.name2}</span></div>
                        </div>
                        <button onClick={handleSuccessConfirm} type="button" className="text-white inline-flex leading-none rounded px-10 py-3 ripple-primary">확인</button>
                      </div>
                    )
                  }
                  else  if(isLoading && !subState.unsubSuccess){
                    return (
                      <div className="p-6 flex flex-col w-96 items-center text-center focus:outline-none min-h-[280px]">
                       <DotLoader />
                      </div>
                    )
                  }
                })()}

                

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default CancelSubscriptionModal