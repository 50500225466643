import React from 'react'
import PaymentContext, {ACTION as PAYMENT_ACTION, PAYMENT_PAGES} from "../context/PaymentProvider";

const BankInfo = () => {
  const {paymentState, paymentDispatch} = React.useContext(PaymentContext);

  return (
    <div className="flex flex-col px-4 min-h-[200px] border-b">
      <div className="font-semibold flex items-center space-x-2"><span>계좌 간편결제</span></div>
      <div className="flex-1 flex justify-center items-center">

        {(() => {
          if(paymentState.userInfo.oapiInfo.bank_account !== null){
            return (
              <div className="flex flex-col w-56 h-[120px] border-2 p-2 rounded-md bg-gray-50">
                <div className="flex-1 flex">
                  <div className="flex-1"><img className="h-8" src={require(`../../assets/images/bank_logo/img_logo_${paymentState.userInfo.oapiInfo.bank_code}.png`)} alt="" /></div>
                  <div className=""><button onClick={() => paymentDispatch({type: PAYMENT_ACTION.SET_FRAME_VIEW, payload: PAYMENT_PAGES.BANK_REGISTRATION})} className="text-xs bg-konagray/5  border border-konagray/30 hover:bg-konagray/20 rounded-xl py-1 px-2">계좌변경</button></div>
                </div>
                <div className="text-xs text-konasub/60">계좌번호</div>
                <div className="text-sm">{paymentState.userInfo.oapiInfo.bank_account}</div>
              </div>
            )
          }
          else{
            return (
              <div 
                onClick={() => paymentDispatch({type: PAYMENT_ACTION.SET_FRAME_VIEW, payload: PAYMENT_PAGES.BANK_REGISTRATION})} 
                className="flex flex-col items-center justify-center w-56 h-[120px] border-2 p-2 border-dashed border-konagray/40 rounded-md bg-gray-50 hover:bg-red-50 cursor-pointer"
              >
                <div className="bg-konared text-white h-6 w-6 mb-2 flex justify-center items-center rounded-full">+</div>
                <div className="text-base">계좌 등록</div>
              </div>
            )
          }
        })()}
      </div>
  </div>
  )
}

export default BankInfo