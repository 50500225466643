import { API_BASE_URL, SOLIDEO_API_URL } from "../constants";
import { ApiError, bearerAuth, InvalidRequestBody, isEmpty, ResponseError } from "../helpers";

export const deletePopulationSimulation = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/population/simulations/${simId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 


export const updatePopulationSimulation = async (body, simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/population/simulations/${simId}`, {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({...body})
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 



export const getPopulationSimulationsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id&page=1&type=USER`
    const res = await fetch(`${API_BASE_URL}/api/population/simulations/user${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}


export const getPopulationModelsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id`
    const res = await fetch(`${API_BASE_URL}/api/population/models${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}



export const getFirstMarriageDataApi = async (user) => {

  try {
    const query = `?page_size=all&name_en__sw=first marriage`
    const res = await fetch(`${API_BASE_URL}/api/population/data-source${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load first marriage data");
    }

  } catch (err) {
      throw(err)
  }
}

