import React, { useRef, useReducer, useEffect, useState } from "react"
import {
  CardHeader,
  CardWrapper,
  CardContent,
} from "../../components/population/CardWrapper"
import MainContentWrapper from "../../components/MainContentWrapper"
import DotLoader from "../../components/loaders/DotLoader"
import PopulationEcharts from "../../components/charts/PopulationEcharts"
import {
  getChartOptionSimAge,
  getChartOptionLineForecast,
  getTimelineOptionForAgeStruct,
  getChartOptionWorkingPopulationComp,
  getChartOptionWorkingPopulationCompKonaSim,
  getChartOptionWelfareCostKonaSim,
  getChartOptionDependencyRatioKonaSim,
} from "../../components/charts/PopulationEchartOptions"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import ChartDetailsModal from "../../components/modals/ChartDetailsModal"
import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom"
import { BiReset } from "react-icons/bi"
import { bearerAuth } from "../../utils/helpers"
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from "../../components/modals/APIUnavailableModal"
import LoadingModal from "../../components/modals/LoadingModal"
import GenerateReportModal from "../../components/modals/GenerateReportModal"
import { handleError } from "../../utils/pop-handle-response"
import { checkSubscribed } from "../../utils/pop-subscription-checker"
import { useGlobalStateContext } from "../../components/context/GlobalContext"

const MAX_LENGTH_SIMNAME = 50

const pop_input_list = [
  {
    group: "인구지표",
    type: "range",
    name: "e total first marriage rate adj",
    info: "합계결혼율과 유사한 의미이나, 두 번째 이후의 결혼은 제외한 개념입니다. 합계초혼율은 출산율과 관계가 있는 중요한 변수입니다. 제시된 그래프를 참고로 합계초혼율 변수를 조정 가능합니다. 합계초혼율 1.0은 2021년 (0.489) 와 동일한 수준을 의미합니다. 합계초혼율 1.1은 2021년 의 값보다 +10% 변화된 수준입니다.  (이때 변화는 3년에 걸쳐 발생되는 것으로 적용되었습니다)",
    ytitle: "합계 초혼율",
    max: 3,
  },
  {
    group: "인구지표",
    type: "range",
    name: "e number of children adj",
    info: "자녀수는 한 부부가 몇 명의 자녀를 출산하느냐의 의미이며 이는 출산율과 연동되어 있습니다. 이값은 직접적인 자녀수를 입력하는 것이 아니고. 출산율과 연동된 숫자로 조정 가능합니다 제시된 그래프를 참고로 자녀수 변수를 조정 가능합니다. 자녀수 1.0은 2021년 출산율 (0.793) 과 동일한 수준을 의미합니다. (기준 시나리오) 자녀수 1.1은 2021년 값보다 +10% 변화된 수준입니다.  (이때 변화는 3년에 걸쳐 발생되는 것으로 적용되었습니다) ",
    ytitle: "합계 출산율",
    max: 3,
  },
  {
    group: "인구지표",
    type: "range",
    name: "e new family culture adj",
    info: "전통적인 가족문화가 요즘의 새로운 가족문화로 변화해 가는 정도를 반영합니다. 새가족문화 변수 역시 합계출산율과 연동됩니다. 새가족문화변수는 합계출산율과 연동된 숫자로 조정 가능합니다 새가족문화 1.0은 2021년 출산율 (0.793)과 동일한 수준을 의미합니다. (기준 시나리오) 새가족문화변수 1.1은 기준시나리오보다  +10% 변화된 수준을 의미합니다.  (이때 변화는 3년에 걸쳐 발생되는 것으로 적용되었습니다)",
    ytitle: "합계 출산율",
    max: 3,
  },
  {
    group: "이민정책",
    type: "range",
    name: "e marriage immigrant factor",
    info: "남편이 외국인인 경우와 아내가 외국인인 경우를 합한 값입니다. 실제자료가 있는 마지막 연도 (예:2021년)의 값을 1로 산정하고 시나리오값은 이에 대한 상대적인 수치를 의미합니다.  2인 경우 2021년도 보다 2배가 됩니다. 증가율은 지수함수형태를 따릅니다 (그림 참조 , 시나리오 설정값을 1 ~5 까지 변경했을때의 국제결혼건수를 보여줍니다)",
    ytitle: "건수",
  },
  {
    group: "이민정책",
    type: "switch",
    name: "switch international student",
    info: "졸업 후 체류 유학생을 국내 인구에 가산",
    ytitle: "%",
  },
  {
    group: "이민정책",
    type: "range",
    name: "e student annual growth rate",
    info: "정보가 없습니다",
    ytitle: "%",
  },
  {
    group: "이민정책",
    type: "range",
    name: "c percent of naturalization of foreign students",
    info: "정보가 없습니다",
    ytitle: "%",
  },
]

const ACTION = {
  SET_BASE_DATA: "set-main-data",
  SET_MULTI_DATA: "set-base-data",
  CHANGE_TIME: "change-time",
  SET_VALUES: "set-values",
  SIMULATE: "simulate",
  RESET: "reset-values",
}

const initialState = {
  model: null,
  main_data: null,
  popData: null,
  yearlist: null,
  curYear: 2060,
  ageData: null,
  welfareData: null,
  welfareAgeData: null,
  pregnancyData: null,
  maleAgeData: null,
  femaleAgeData: null,
  inputvals: null,
  category: null,
  defaultInputVals: null,
  userMaleAgeData: null,
  userFealeAgeData: null,
  userMaleAgeDataUser: null,
  userFemaleAgeDataUser: null,
  simID: 0,
  baseSimID: null,
  inputVars: null,
  simName: null,
  simulation_input: null,
  simResults: {},
}

const reducer = (state, { type, payload }) => {
  switch (type) {
    case ACTION.SET_BASE_DATA:
      return {
        ...state,
        model: payload.model,
        popData: payload.popData,
        ageData: payload.ageData,
        welfareData: payload.welfareData,
        welfareAgeData: payload.welfareAgeData,
        pregnancyData: payload.pregnancyData,
        yearlist: payload.yearlist,
        maleAgeData: payload.maleAgeData,
        femaleAgeData: payload.femaleAgeData,
        inputvals: payload.inputvals,
        baseSimID: payload.baseSimID,
        defaultInputVals: payload.defaultInputVals,
        simulation_input: payload.simulation_input,
        popOld: payload.popOld,
        popYoung: payload.popYoung,
        popWorking: payload.popWorking,
        popTotal: payload.popTotal,
        dependencyRatioYoung: payload.dependencyRatioYoung,
        dependencyRatioOld: payload.dependencyRatioOld,
        dependencyRatioTotal: payload.dependencyRatioTotal,
      }
    case ACTION.SET_MULTI_DATA:
      return {
        ...state,
        simID: payload.simID,
        model: payload.model,
        popData: payload.popData,
        ageData: payload.ageData,
        welfareData: payload.welfareData,
        welfareAgeData: payload.welfareAgeData,
        pregnancyData: payload.pregnancyData,
        yearlist: payload.yearlist,
        maleAgeData: payload.maleAgeData,
        femaleAgeData: payload.femaleAgeData,
        inputvals: payload.inputvals,
        defaultInputVals: payload.defaultInputVals,
        userMaleAgeDataUser: payload.userMaleAgeDataUser,
        userFemaleAgeDataUser: payload.userFemaleAgeDataUser,
        baseSimID: payload.baseSimID,
        simulation_input: payload.simulation_input,
        popOld: payload.popOld,
        popYoung: payload.popYoung,
        popWorking: payload.popWorking,
        popTotal: payload.popTotal,
        dependencyRatioYoung: payload.dependencyRatioYoung,
        dependencyRatioOld: payload.dependencyRatioOld,
        dependencyRatioTotal: payload.dependencyRatioTotal,
        simResults: payload.simResults,
      }
    case ACTION.SET_VALUES:
      return {
        ...state,
        inputvals: payload.inputvals,
      }

    case ACTION.SIMULATE:
      return {
        ...state,
        simID: payload.simID,
        popData: payload.popData,
        pregnancyData: payload.pregnancyData,
        welfareData: payload.welfareData,
        welfareAgeData: payload.welfareAgeData,
        userMaleAgeDataUser: payload.userMaleAgeDataUser,
        userFemaleAgeDataUser: payload.userFemaleAgeDataUser,
        ageData: payload.ageData,
        simResults: payload.simResults,
      }
    case ACTION.RESET:
      return {
        ...state,
        inputvals: payload.inputvals,
      }
    default:
      throw new Error(`Unknown action type: ${type}`)
  }
}

function SimulationNational(props) {
  const location = useLocation()
  const simResult = useRef(null)
  const [isSimRunning, setSimRunning] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isReportOpen, setReportOpen] = useState(false)
  const [simName, setSimName] = useState("")
  const [isSubscribed, setSubscribed] = useState(false)

  let navigate = useNavigate()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [isChartDetailOpen, setIsChartOpen] = useState(false)
  const [topic, setTopic] = useState(null)
  const { user, handleLogout } = useGlobalStateContext()

  const loadBase = async () => {
    try {
      //get model first
      let query = `?page_size=1&sort=-created_at&description=national`
      let response = await fetch(
        `${API_BASE_URL}/api/population/models${query}`,
        {
          method: "GET",
          headers: { Authorization: bearerAuth(user) },
        }
      )

      if (!response.ok) throw new Error(handleError(response.status))

      let model = await response.json()
      if (!model.data || model.data.length <= 0)
        throw Error("No model was available")

      //setting input values
      let inputvals = []
      let defaultInputVals = []
      let simulation_input = []

      //get input variables from the database for the model
      query = `?page_size=all&model_id=${model.data[0].id}&category__in=PARAMETER&expand=data_in`
      response = await fetch(
        `${API_BASE_URL}/api/population/variables${query}`,
        {
          method: "GET",
          headers: { Authorization: bearerAuth(user) },
        }
      )

      if (!response.ok) throw new Error(handleError(response.status))

      let model_input = await response.json()

      if (!model_input.data || model_input.data.length <= 0)
        throw new Error("No simulation input data was retrieved")

      model_input.data.forEach((varItem) => {
        if (!varItem.data_in || varItem.data_in.length <= 0)
          throw new Error("No initial value provided")
        let item = pop_input_list.filter(
          (item) => item.name === varItem.name_en
        )

        if (item.length > 0) {
          inputvals.push(varItem.data_in[0].value)
          defaultInputVals.push(varItem.data_in[0].value)
          simulation_input.push({
            name: varItem.name_en,
            display: varItem.name_kr,
            min: varItem.e_min,
            max: item[0]?.max ? item[0]?.max : varItem.e_max, //override original model max setting in the fronted : temporary hotfix
            inc: varItem.e_inc,
            info: item[0].info,
            group: item[0].group,
            type: item[0].type,
            value: varItem.data_in[0].value,
            ytitle: item[0].ytitle,
          })
        }
      })

      query = `?page_size=1&sort=-created_at&model_id=${model.data[0].id}&type=BASE`
      response = await fetch(
        `${API_BASE_URL}/api/population/simulations/with-datavar${query}`,
        {
          method: "GET",
          headers: { Authorization: bearerAuth(user) },
        }
      )

      if (!response.ok) throw new Error(handleError(response.status))
      //we are expecting an array of objects
      let parseRes = await response.json()

      if (!parseRes.data || parseRes.data.length <= 0)
        throw Error("No base simulation data was retrieved")

      // let allSimData = {}
      let year_index = []
      let popYearXAgeData = {}
      let popYearXAgeDataM = {}
      let popYearXAgeDataF = {}
      let pregnancyData = {}
      let outputData = parseRes.data[0].data_out //all data
      let baseSimID = parseRes.data[0].id

      let popYoung = []
      let popOld = []
      let popWorking = []

      if (outputData.length > 1) {
        let row = outputData[0]
        row.value.forEach((item) => {
          year_index.push(item[0])
          let ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeData[item[0]] = ageKeyVal

          ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeDataM[item[0]] = ageKeyVal

          ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeDataF[item[0]] = ageKeyVal
        })
      }

      // not sure if faster but simpler in terms of coding
      outputData.forEach((item) => {
        //store information in age
        if (item.variable.name_en.includes("Population by age sex")) {
          let age = item.variable.name_en
            .split(",")[1]
            .split("]")[0]
            .replace("A", "")
          let gender = item.variable.name_en.split(",")[0].split("[")[1]

          item.value.forEach((data_pair) => {
            // to be revised
            popYearXAgeData[data_pair[0]][age] += Math.round(data_pair[1])
            if (gender === "M") {
              popYearXAgeDataM[data_pair[0]][age] += Math.round(data_pair[1])
            } else {
              popYearXAgeDataF[data_pair[0]][age] += Math.round(data_pair[1])
            }
          })
        } else if (item.variable.name_en.includes("Population age 65 above")) {
          popOld = item.value
        } else if (item.variable.name_en.includes("Population age 15 64")) {
          popWorking = item.value
        } else if (item.variable.name_en.includes("Population age 0 14")) {
          popYoung = item.value
        } else if (item.variable.name_en.includes("pregnant popuation")) {
          item.value.forEach((pair_val) => {
            let yearval = parseInt(pair_val[0])
            pregnancyData[yearval] = Math.round(pair_val[1])
          })
        }
      })

      //calculate dependency ratio

      const dependencyRatioYoung = popYoung.map((val, index) => [
        val[0],
        (val[1] / popWorking[index][1]) * 100,
      ])
      const dependencyRatioOld = popOld.map((val, index) => [
        val[0],
        (val[1] / popWorking[index][1]) * 100,
      ])
      const dependencyRatioTotal = dependencyRatioYoung.map((val, index) => [
        val[0],
        val[1] + dependencyRatioOld[index][1],
      ])

      //population trend across the years
      let popYearlyData = []
      year_index.forEach((y) => {
        let values = Object.values(popYearXAgeData[y])
        let sum = values.reduce((accumulator, value) => {
          return accumulator + value
        }, 0)
        popYearlyData.push([y, sum])
      })

      let popData = getChartOptionLineForecast([
        {
          name: "기준 시나리오",
          type: "line",
          lineStyle: {
            type: "solid",
          },
          smooth: true,
          showSymbol: true,
          data: popYearlyData,
        },
      ])

      let agebar = year_index.map((y) => {
        return {
          title: { text: y + "년도" },
          series: [
            {
              data: Object.entries(popYearXAgeDataM[y]).map(([key, val]) => {
                return { name: key, gender: "male", value: val }
              }),
              stack: "base",
            },
            {
              data: Object.entries(popYearXAgeDataF[y]).map(([key, val]) => {
                return { name: key, gender: "female", value: val * -1 }
              }),
              stack: "base",
            },
          ],
        }
      })
      let ageSeries = [
        { name: "기준 시나리오 남성", type: "bar", stack: "base" },
        { name: "기준 시나리오 여성", type: "bar", stack: "base" },
      ]
      let ageData = getTimelineOptionForAgeStruct(year_index, agebar, ageSeries)
      let youngage_vals = {}
      let workage_vals = {}
      let oldage_vals = {}
      let welfare_ratio = {}

      //welfare population ratio
      year_index.forEach((y) => {
        let youngObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key < 15
        )
        let workingObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key >= 15 && key < 65
        )
        let oldObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key >= 65
        )

        //compute and assign
        youngage_vals[y] = Object.values(youngObj).reduce(
          (accumulator, value) => {
            return accumulator + parseInt(value[1])
          },
          0
        )
        workage_vals[y] = Object.values(workingObj).reduce(
          (accumulator, value) => {
            return accumulator + parseInt(value[1])
          },
          0
        )
        oldage_vals[y] = Object.values(oldObj).reduce((accumulator, value) => {
          return accumulator + parseInt(value[1])
        }, 0)
        welfare_ratio[y] = parseFloat(
          ((youngage_vals[y] + oldage_vals[y]) / workage_vals[y]).toFixed(4)
        )
      })

      let welfareData = getChartOptionLineForecast([
        {
          name: "기준 시나리오",
          type: "line",
          lineStyle: {
            type: "solid",
          },
          smooth: true,
          showSymbol: true,
          data: Object.entries(welfare_ratio).map(([key, val]) => {
            return [key, val]
          }),
        },
      ])

      let welfareAgeData = getChartOptionSimAge([
        {
          name: "기준 시나리오-노인",
          type: "bar",
          stack: "total",
          data: Object.entries(oldage_vals).map(([key, val]) => {
            return [key, val]
          }),
        },
        {
          name: "기준 시나리오-유아",
          type: "bar",
          stack: "total",
          data: Object.entries(youngage_vals).map(([key, val]) => {
            return [key, val]
          }),
        },
      ])

      let pgData = getChartOptionLineForecast([
        {
          name: "기준 시나리오",
          type: "line",
          lineStyle: {
            type: "solid",
          },
          smooth: true,
          showSymbol: true,
          data: Object.entries(pregnancyData).map(([key, val]) => {
            return [key, val]
          }),
        },
      ])

      return {
        popData: popData,
        ageData: ageData,
        ageSeries: ageSeries,
        welfareData: welfareData,
        welfareAgeData: welfareAgeData,
        pregnancyData: pgData,
        yearlist: year_index,
        maleAgeData: popYearXAgeDataM,
        femaleAgeData: popYearXAgeDataF,
        inputvals: inputvals,
        defaultInputVals: defaultInputVals,
        model: parseRes.data[0],
        baseSimID: baseSimID,
        simulation_input: simulation_input,
        popYoung: popYoung,
        popOld: popOld,
        popWorking: popWorking,
        popTotal: popYearlyData,
        dependencyRatioYoung: dependencyRatioYoung,
        dependencyRatioOld: dependencyRatioOld,
        dependencyRatioTotal: dependencyRatioTotal,
      }
    } catch (error) {
      setIsOpen(true)
      console.error(error.message)
      return null
    }
  }

  useEffect(() => {
    initialLoad()
  }, [])

  const initialLoad = async () => {
    let isUserSubscribed = false

    try {
      isUserSubscribed = await checkSubscribed(user)

      setSimRunning(true)

      if (isUserSubscribed) {
        setSubscribed(true)

        let initialData = await loadBase()

        if (initialData) {
          if (location.state && location.state.sim_id) {
            let simData = await getDataViaSimID(location.state.sim_id)
            initialData.popData.series = [
              ...initialData.popData.series,
              simData.popData,
            ]
            let options = initialData.ageData.options.map((item) => {
              return {
                title: item.title,
                series: [
                  ...item.series,
                  ...simData.ageData.filter(
                    (x) => item.title.text === x.title.text
                  )[0].series,
                ],
              }
            })

            initialData.ageData.options = options
            initialData.ageData.legend.data = [
              ...initialData.ageData.legend.data,
              ...simData.ageSeries,
            ]
            initialData.ageData.series = [
              ...initialData.ageData.series,
              ...simData.ageSeries,
            ]
            initialData.welfareData.series = [
              ...initialData.welfareData.series,
              simData.welfareData,
            ]
            initialData.welfareAgeData.series = [
              ...initialData.welfareAgeData.series,
              ...simData.welfareAgeData,
            ]
            initialData.pregnancyData.series = [
              ...initialData.pregnancyData.series,
              simData.pregnancyData,
            ]

            for (const [key, value] of Object.entries(simData.parameters)) {
              initialData.inputvals[
                initialData.simulation_input.findIndex((x) => x.name === key)
              ] = value
            }

            dispatch({
              type: ACTION.SET_MULTI_DATA,
              payload: {
                simID: location.state.sim_id,
                popData: initialData.popData,
                ageData: initialData.ageData,
                welfareData: initialData.welfareData,
                welfareAgeData: initialData.welfareAgeData,
                pregnancyData: initialData.pregnancyData,
                yearlist: initialData.yearlist,
                maleAgeData: initialData.maleAgeData,
                femaleAgeData: initialData.femaleAgeData,
                inputvals: initialData.inputvals,
                defaultInputVals: initialData.defaultInputVals,
                model: initialData.model,
                userMaleAgeDataUser: simData.userMaleAgeDataUser,
                userFemaleAgeDataUser: simData.userFemaleAgeDataUser,
                baseSimID: initialData.baseSimID,
                simulation_input: initialData.simulation_input,
                popOld: initialData.popOld,
                popYoung: initialData.popYoung,
                popWorking: initialData.popWorking,
                popTotal: initialData.popTotal,
                dependencyRatioYoung: initialData.dependencyRatioYoung,
                dependencyRatioOld: initialData.dependencyRatioOld,
                dependencyRatioTotal: initialData.dependencyRatioTotal,
                simResults: {
                  simName: simData.simulationName,
                  popYoung: simData.popYoung,
                  popOld: simData.popOld,
                  popWorking: simData.popWorking,
                  popTotal: simData.popTotal,
                  dependencyRatioYoung: simData.dependencyRatioYoung,
                  dependencyRatioOld: simData.dependencyRatioOld,
                  dependencyRatioTotal: simData.dependencyRatioTotal,
                },
              },
            })
            simResult.current.scrollIntoView()
          } else {
            dispatch({
              type: ACTION.SET_BASE_DATA,
              payload: {
                popData: initialData.popData,
                ageData: initialData.ageData,
                welfareData: initialData.welfareData,
                welfareAgeData: initialData.welfareAgeData,
                pregnancyData: initialData.pregnancyData,
                yearlist: initialData.yearlist,
                maleAgeData: initialData.maleAgeData,
                femaleAgeData: initialData.femaleAgeData,
                inputvals: initialData.inputvals,
                defaultInputVals: initialData.defaultInputVals,
                model: initialData.model,
                baseSimID: initialData.baseSimID,
                simulation_input: initialData.simulation_input,
                popOld: initialData.popOld,
                popYoung: initialData.popYoung,
                popWorking: initialData.popWorking,
                popTotal: initialData.popTotal,
                dependencyRatioYoung: initialData.dependencyRatioYoung,
                dependencyRatioOld: initialData.dependencyRatioOld,
                dependencyRatioTotal: initialData.dependencyRatioTotal,
              },
            })
          }
        }
      }
    } catch (error) {
      setIsOpen(true)
      console.error(error.message)
    } finally {
      setSimRunning(false)

      if (!isUserSubscribed) navigate("/pop-sub-redirect")
      else navigate(location.pathname, { replace: true })
    }
  }

  const HandleReset = () => {
    let vals = state.defaultInputVals.map((item) => {
      return item
    })

    dispatch({
      type: ACTION.RESET,
      payload: {
        inputvals: vals,
      },
    })
  }

  //run sim
  const Simulate = async () => {
    // trim simulation name input first
    let cont = true
    let newSimName = simName.trim()

    if (newSimName.length > MAX_LENGTH_SIMNAME) {
      newSimName = newSimName.slice(0, MAX_LENGTH_SIMNAME)
    }

    if (newSimName === "기준 시나리오") {
      alert("Please do not use 기준 시나리오 as sim name")
      cont = false
    }

    if (newSimName.length === 0) {
      alert("시뮬레이션명 입력해주세요")
      cont = false
    }

    if (cont) {
      try {
        setSimName(newSimName)

        var today = new Date()
        var date =
          today.getFullYear() +
          "-" +
          (today.getMonth() + 1) +
          "-" +
          today.getDate()
        // var scenario = '사용자 시나리오'

        setSimRunning(true)

        // prepare the parameters
        const param = {}

        for (var i = 0; i < state.inputvals.length; i++) {
          param[state.simulation_input[i]["name"]] = state.inputvals[i]
        }

        // prepare simulation parameters
        const simJson = {
          name: newSimName,
          parameter: { ...param },
          model_id: state.model.model_id,
          type: "USER",
          user_id: user.user_info.id,
        }

        let response = await fetch(
          `${API_BASE_URL}/api/population/simulations`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: bearerAuth(user),
            },
            body: JSON.stringify(simJson),
          }
        )

        if (!response.ok) throw new Error(handleError(response.status))

        const newSimulation = await response.json()

        if (!newSimulation) throw Error("Error creating simulation")

        const response2 = await fetch(
          `${API_BASE_URL}/api/population/simulations/${newSimulation.id}/run`,
          {
            method: "POST",
            headers: { Authorization: bearerAuth(user) },
          }
        )
        if (!response2.ok) throw new Error(handleError(response2.status))

        let simData = await getDataViaSimID(newSimulation.id)

        let series = [
          state.popData.series.filter(
            (item) => item.name === "기준 시나리오"
          )[0],
        ]
        series.push(simData.popData)
        let popData = getChartOptionLineForecast(series)

        series = [
          state.pregnancyData.series.filter(
            (item) => item.name === "기준 시나리오"
          )[0],
        ]
        series.push(simData.pregnancyData)
        let pgData = getChartOptionLineForecast(series)

        series = state.welfareAgeData.series
          .filter((item) => item.name.includes("기준 시나리오"))
          .map((item) => {
            return item
          })
        series = [...series, ...simData.welfareAgeData]
        let welfareAgeData = getChartOptionSimAge(series)

        series = [
          state.welfareData.series.filter(
            (item) => item.name === "기준 시나리오"
          )[0],
        ]

        series.push(simData.welfareData)
        let welfareData = getChartOptionLineForecast(series)

        let ageOptions = state.ageData.options.map((item) => {
          return {
            title: item.title,
            series: item.series
              .filter((s) => s.stack === "base")
              .map((val) => {
                return val
              }),
          }
        })

        let ageOptionsMixed = ageOptions.map((item) => {
          return {
            title: item.title,
            series: [
              ...item.series,
              ...simData.ageData.filter(
                (x) => item.title.text === x.title.text
              )[0].series,
            ],
          }
        })

        let ageSeries = [
          ...state.ageData.series
            .filter((s) => s.stack === "base")
            .map((val) => {
              return val
            }),
          ...simData.ageSeries,
        ]
        let ageData = getTimelineOptionForAgeStruct(
          state.yearlist,
          ageOptionsMixed,
          ageSeries
        )

        dispatch({
          type: ACTION.SIMULATE,
          payload: {
            simID: newSimulation.id,
            popData: popData,
            pregnancyData: pgData,
            welfareData: welfareData,
            welfareAgeData: welfareAgeData,
            ageData: ageData,
            userMaleAgeDataUser: simData.userMaleAgeDataUser,
            userFemaleAgeDataUser: simData.userFemaleAgeDataUser,
            simResults: {
              simName: simData.simulationName,
              popYoung: simData.popYoung,
              popOld: simData.popOld,
              popWorking: simData.popWorking,
              popTotal: simData.popTotal,
              dependencyRatioYoung: simData.dependencyRatioYoung,
              dependencyRatioOld: simData.dependencyRatioOld,
              dependencyRatioTotal: simData.dependencyRatioTotal,
            },
          },
        })
        setSimName("")
        simResult.current.scrollIntoView()
      } catch (err) {
        setIsOpen(true)
        console.error(err.message)
      } finally {
        setSimRunning(false)
      }
    }
  }

  const getDataViaSimID = async (id) => {
    try {
      let query = `?page_size=1&sort=-created_at&id=${id}`
      let response = await fetch(
        `${API_BASE_URL}/api/population/simulations/with-datavar${query}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: bearerAuth(user),
          },
        }
      )

      if (!response.ok) throw new Error(handleError(response.status))

      const parseRes = await response.json()
      if (!parseRes.data || parseRes.data.length <= 0)
        throw Error("Simulation data fetch error")

      let simulationName = parseRes.data[0].name
      let outputData = parseRes.data[0].data_out
      let parameters = parseRes.data[0].parameter
      let year_index = []
      let popYearXAgeData = {}
      let popYearXAgeDataM = {}
      let popYearXAgeDataF = {}
      let pregnancyData = {}

      let popYoung = []
      let popOld = []
      let popWorking = []

      // initalize the age values in each years to 0
      //get years to be used to access yearly information
      //create a population data {year} => [..age:value]
      if (outputData.length > 1) {
        let row = outputData[0]
        row.value.forEach((item) => {
          year_index.push(item[0])
          let ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeData[item[0]] = ageKeyVal

          ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeDataM[item[0]] = ageKeyVal

          ageKeyVal = {}
          for (let i = 0; i <= 100; i++) {
            ageKeyVal[i] = 0
          }
          popYearXAgeDataF[item[0]] = ageKeyVal
        })
      }

      // not sure if faster but simpler in terms of coding
      outputData.forEach((item) => {
        //store information in age
        if (item.variable.name_en.includes("Population by age sex")) {
          let age = item.variable.name_en
            .split(",")[1]
            .split("]")[0]
            .replace("A", "")
          let gender = item.variable.name_en.split(",")[0].split("[")[1]

          item.value.forEach((data_pair) => {
            // to be revised
            popYearXAgeData[parseInt(data_pair[0])][age] += Math.round(
              data_pair[1]
            )
            if (gender === "M") {
              popYearXAgeDataM[parseInt(data_pair[0])][age] += Math.round(
                data_pair[1]
              )
            } else {
              popYearXAgeDataF[parseInt(data_pair[0])][age] += Math.round(
                data_pair[1]
              )
            }
          })
        } else if (item.variable.name_en.includes("Population age 65 above")) {
          popOld = item.value
        } else if (item.variable.name_en.includes("Population age 15 64")) {
          popWorking = item.value
        } else if (item.variable.name_en.includes("Population age 0 14")) {
          popYoung = item.value
        } else if (item.variable.name_en.includes("pregnant popuation")) {
          item.value.forEach((pair_val) => {
            let yearval = parseInt(pair_val[0])
            pregnancyData[yearval] = Math.round(pair_val[1])
          })
        }
      })

      //calculate dependecy ratio
      const dependencyRatioYoung = popYoung.map((val, index) => [
        val[0],
        (val[1] / popWorking[index][1]) * 100,
      ])
      const dependencyRatioOld = popOld.map((val, index) => [
        val[0],
        (val[1] / popWorking[index][1]) * 100,
      ])
      const dependencyRatioTotal = dependencyRatioYoung.map((val, index) => [
        val[0],
        val[1] + dependencyRatioOld[index][1],
      ])

      //population trend across the years
      let popYearlyData = []
      year_index.forEach((y) => {
        let values = Object.values(popYearXAgeData[y])
        let sum = values.reduce((accumulator, value) => {
          return accumulator + value
        }, 0)
        popYearlyData.push([y, sum])
      })

      let popData = {
        name: simulationName,
        type: "line",
        lineStyle: {
          type: "dashed",
        },
        smooth: true,
        showSymbol: true,
        data: popYearlyData,
      }

      let pgData = {
        name: simulationName,
        type: "line",
        lineStyle: {
          type: "dashed",
        },
        smooth: true,
        showSymbol: true,
        data: Object.entries(pregnancyData).map(([key, val]) => {
          return [key, val]
        }),
      }

      let youngage_vals = {}
      let workage_vals = {}
      let oldage_vals = {}
      let welfare_ratio = {}

      //welfare population ratio
      // welfare population by age group
      year_index.forEach((y) => {
        let youngObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key < 15
        )
        let workingObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key >= 15 && key < 65
        )
        let oldObj = Object.entries(popYearXAgeData[y]).filter(
          ([key]) => key >= 65
        )

        //compute and assign
        youngage_vals[y] = Object.values(youngObj).reduce(
          (accumulator, value) => {
            return accumulator + parseInt(value[1])
          },
          0
        )
        workage_vals[y] = Object.values(workingObj).reduce(
          (accumulator, value) => {
            return accumulator + parseInt(value[1])
          },
          0
        )
        oldage_vals[y] = Object.values(oldObj).reduce((accumulator, value) => {
          return accumulator + parseInt(value[1])
        }, 0)
        welfare_ratio[y] = parseFloat(
          ((youngage_vals[y] + oldage_vals[y]) / workage_vals[y]).toFixed(4)
        )
      })

      let welfareData = {
        name: simulationName,
        type: "line",
        lineStyle: {
          type: "dashed",
        },
        smooth: true,
        showSymbol: true,
        data: Object.entries(welfare_ratio).map(([key, val]) => {
          return [key, val]
        }),
      }
      let welfareAgeData = [
        {
          name: simulationName + "-노인",
          type: "bar",
          stack: "user",
          data: Object.entries(oldage_vals).map(([key, val]) => {
            return [key, val]
          }),
        },
        {
          name: simulationName + "-유아",
          type: "bar",
          stack: "user",
          data: Object.entries(youngage_vals).map(([key, val]) => {
            return [key, val]
          }),
        },
      ]
      let ageData = year_index.map((y) => {
        return {
          title: { text: y + "년도" },
          series: [
            {
              data: Object.entries(popYearXAgeDataM[y]).map(([key, val]) => {
                return { name: key, gender: "male", value: val }
              }),
              stack: "user",
            },
            {
              data: Object.entries(popYearXAgeDataF[y]).map(([key, val]) => {
                return { name: key, gender: "female", value: val * -1 }
              }),
              stack: "user",
            },
          ],
        }
      })
      let ageSeries = [
        { name: simulationName + " 남성", type: "bar", stack: "user" },
        { name: simulationName + " 여성", type: "bar", stack: "user" },
      ]

      return {
        popData: popData,
        pregnancyData: pgData,
        welfareData: welfareData,
        welfareAgeData: welfareAgeData,
        userMaleAgeDataUser: popYearXAgeDataM,
        userFemaleAgeDataUser: popYearXAgeDataF,
        ageData: ageData,
        ageSeries: ageSeries,
        parameters: parameters,
        popYoung: popYoung,
        popOld: popOld,
        popWorking: popWorking,
        popTotal: popYearlyData,
        dependencyRatioYoung: dependencyRatioYoung,
        dependencyRatioOld: dependencyRatioOld,
        dependencyRatioTotal: dependencyRatioTotal,
        simulationName: simulationName,
      }
    } catch (err) {
      setIsOpen(true)
      console.error(err.message)
    }
  }

  // const handleValueChange = (position, e) => {

  // 	let x = state.inputvals
  // 	let y = []
  // 	let val = e.target.value ? parseFloat(e.target.value) : parseFloat(0)

  // 	//check max and min value
  // 	if (val < state.simulation_input[position].min) {
  // 		val = state.simulation_input[position].min
  // 	}

  // 	if (val > state.simulation_input[position].max) {
  // 		val = state.simulation_input[position].max
  // 	}

  // 	y = x.map((item) => { return item })

  // 	if (state.simulation_input[position].name === "switch international student") {
  // 		(y[position] === 1) ? y[position] = 0 : y[position] = 1
  // 	} else {
  // 		if (val < 5)
  // 			y[position] = Math.round((val + Number.EPSILON) * 100) / 100
  // 		else
  // 			y[position] = val
  // 	}

  // 	dispatch({
  // 		type: ACTION.SET_VALUES,
  // 		payload: {
  // 			inputvals: y,
  // 		}
  // 	})

  // }

  const handleValueChange = (position, e) => {
    let x = state.inputvals
    let y = []
    let val = e.target.value

    y = x.map((item) => {
      return item
    })

    // if (state.simulation_input[position].name === "switch international student") {
    // 	y[position] = (y[position] === 1) ?  0 : 1;
    // } else {
    // 	if (parseFloat(val) < 5)
    // 		y[position] = Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100
    // 	else
    // 		y[position] = val
    // }

    if (
      state.simulation_input[position].name === "switch international student"
    ) {
      y[position] = y[position] === 1 ? 0 : 1
    } else {
      y[position] = val
    }

    dispatch({
      type: ACTION.SET_VALUES,
      payload: {
        inputvals: y,
      },
    })
  }

  const openChartDetails = (val) => {
    setTopic(val)
    setIsChartOpen(true)
  }
  const downloadReport = async () => {
    try {
      setReportOpen(true)

      const reportJSON = {
        base_id: state.baseSimID,
        simulation_id: state.simID,
      }

      let response = await fetch(`${API_BASE_URL}/api/population/reports/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearerAuth(user),
        },
        body: JSON.stringify(reportJSON),
      })

      if (!response.ok) throw new Error(handleError(response.status))

      let pdfBlob = await response.blob()
      let pdfFile = new Blob([pdfBlob], { type: "application/pdf" })

      let filename = response.headers
        .get("content-disposition")
        .split(";")
        .find((n) => n.includes("filename="))
        .replace("filename=", "")
        .trim()

      const link = document.createElement("a")
      link.href = window.URL.createObjectURL(pdfFile)
      link.download = filename
      link.click()
    } catch (error) {
      setIsOpen(true)
      console.error(error.message)
    } finally {
      setReportOpen(false)
    }
  }
  const handleSimName = (e) => {
    // Timming and checking is relegated upon submission
    // TO DO: real time validation
    let val = e.target.value
    setSimName(val)
  }

  return (
    <MainContentWrapper className=" bg-konabg relative">
      <APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <LoadingModal isOpen={isSimRunning} />
      <GenerateReportModal isOpen={isReportOpen} />
      {/* <div className="flex flex-col select-none"> */}
      <div className="flex flex-col pt-10 pb-20 select-none px-28 bg-gray-400 bg-opacity-20  items-center ">
        {(() => {
          if (!isSubscribed && !isSimRunning) {
            return (
              <div className="absolute bg-black h-full w-full left-0 top-0 bg-opacity-70 z-50 flex justify-center items-start backdrop-blur-sm">
                <div className="flex flex-col items-center pt-64">
                  <span className="text-3xl font-semibold text-white">
                    이 기능을 사용하기 위해서는 사용자권한 up-grade가
                    필요합니다.
                  </span>
                  <span className="text-3xl font-semibold text-white mt-3">
                    서비스제공범위 내용을 확인하세요.
                  </span>
                  <NavLink
                    to="/population"
                    state={{ scrollTo: "payment" }}
                    className="mt-10 bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-400"
                  >
                    확인하기
                  </NavLink>
                  <button
                    onClick={handleLogout}
                    className="text-white hover:underline mt-4"
                  >
                    Login with another account
                  </button>
                </div>
              </div>
            )
          }
        })()}
        <div className="flex flex-row space-x-7 justify-center  w-full ">
          <div className="min-w-[300px] w-1/3 text-left text-3xl font-bold text-konared pt-10 pb-5">
            지수 설정하기
          </div>
          <div className="min-w-[300px] w-1/3 text-right text-3xl font-bold text-konared pt-10 pb-5">
            <button
              onClick={HandleReset}
              type="button"
              className="inline-flex py-[5px] space-x-2 border border-konared px-4 rounded-xl text-konared ripple-primary-outline justify-center items-center text-xl font-semibold"
            >
              <BiReset size={20} />
              <span>데이터 초기화</span>
            </button>
          </div>
        </div>
        <div className="flex flex-row space-x-7 justify-center  w-full ">
          <div className="min-w-[300px] w-1/3 bg-white flex flex-col shadow-md">
            <div className="h-1 bg-gray-400"></div>
            <div className="text-2xl p-4 font-bold">인구지표 (합계출산율)</div>
            <div className="flex-1 flex flex-col space-y-4 p-4">
              {state.inputvals
                ? state.simulation_input.map(
                    ({ group, display, value, min, max, inc, info }, index) => {
                      return group === "인구지표" ? (
                        <div
                          className="flex flex-col py-2 text-base "
                          key={index}
                        >
                          <div className="text-xl font-semibold">
                            <span>{display}</span>
                            {info !== "정보가 없습니다" ? (
                              <button
                                type="button"
                                className="ml-2 text-slate-500 hover:text-orange-400 hover:drop-shadow-md"
                              >
                                <BsFillQuestionCircleFill
                                  size={16}
                                  className=""
                                  onClick={() =>
                                    openChartDetails(
                                      state.simulation_input[index]
                                    )
                                  }
                                />
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="flex flex-row p-1 space-x-4  items-center">
                            <div className="flex-1 relative  min-w-[200px] ">
                              <input
                                type="range"
                                min={min}
                                max={max}
                                step={inc}
                                className="w-full sim-input-slider py-5"
                                value={state.inputvals[index]}
                                onChange={(e) => handleValueChange(index, e)}
                              />
                              <span className="absolute bottom-0 left-1 text-sm text-gray-400 font-medium">
                                {min}
                              </span>
                              <span className="absolute bottom-0 right-1 text-sm text-gray-400 font-medium">
                                {max}
                              </span>
                            </div>
                            <div className="w-[60px] flex py-2 ">
                              <input
                                type="text"
                                min={min}
                                max={max}
                                step={inc}
                                value={state.inputvals[index]}
                                onChange={(e) => handleValueChange(index, e)}
                                className="block text-base rounded-lg w-full py-2 border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm appearance-none m-0"
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )
                    }
                  )
                : ""}
            </div>
          </div>
          <div className=" min-w-[300px] w-1/3 bg-white flex flex-col shadow-md">
            <div className="h-1 bg-gray-400"></div>
            <div className="text-2xl p-4 font-bold">이민정책</div>
            <div className="flex-1 flex flex-col space-y-4 p-4">
              {state.inputvals
                ? state.simulation_input.map(
                    (
                      {
                        group,
                        display,
                        value,
                        min,
                        name,
                        max,
                        inc,
                        ytitle,
                        type,
                        info,
                      },
                      index
                    ) => {
                      return group === "이민정책" ? (
                        (name.includes("e student annual growth rate") ===
                          false &&
                          name.includes(
                            "c percent of naturalization of foreign students"
                          ) === false) ||
                        state.inputvals[
                          state.simulation_input
                            .map((object) => object.name)
                            .indexOf("switch international student")
                        ] === 1 ? (
                          <div
                            className="flex flex-col py-2 text-base "
                            key={index}
                          >
                            <div className="text-xl font-semibold">
                              <span>{display}</span>
                              {info !== "정보가 없습니다" ? (
                                <button
                                  type="button"
                                  className="ml-2 text-slate-500 hover:text-orange-400 hover:drop-shadow-md"
                                >
                                  <BsFillQuestionCircleFill
                                    size={16}
                                    className=""
                                    onClick={() =>
                                      openChartDetails(
                                        state.simulation_input[index]
                                      )
                                    }
                                  />
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            {type === "range" ? (
                              <div className="flex flex-row p-1 space-x-4  items-center">
                                <div className="flex-1 relative  min-w-[200px] ">
                                  <input
                                    type="range"
                                    min={min}
                                    max={max}
                                    step={inc}
                                    className="w-full sim-input-slider py-5"
                                    value={state.inputvals[index]}
                                    onChange={(e) =>
                                      handleValueChange(index, e)
                                    }
                                  />
                                  <span className="absolute bottom-0 left-1 text-sm text-gray-400 font-medium">
                                    {min}
                                  </span>
                                  <span className="absolute bottom-0 right-1 text-sm text-gray-400 font-medium">
                                    {max}
                                  </span>
                                </div>
                                <div className="w-[60px] flex ">
                                  <input
                                    type="text"
                                    min={min}
                                    max={max}
                                    step={inc}
                                    value={state.inputvals[index]}
                                    onChange={(e) =>
                                      handleValueChange(index, e)
                                    }
                                    className="block text-base rounded-lg w-full py-2 border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm appearance-none m-0"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div className="pt-2 flex flex-col mb-2 space-y-1">
                                <div className="flex-1 pt-4 pb-3">
                                  <label>
                                    <input
                                      id={index}
                                      value={1}
                                      type={"checkbox"}
                                      className="mr-2 py-0 focus:red-400 focus:ring-2"
                                      checked={state.inputvals[index] === 1}
                                      onChange={(e) =>
                                        handleValueChange(index, e)
                                      }
                                    />
                                    {info}
                                  </label>
                                </div>
                              </div>
                            )}
                            <div></div>
                          </div>
                        ) : (
                          ""
                        )
                      ) : (
                        ""
                      )
                    }
                  )
                : ""}
            </div>
            <div className="flex-1 flex flex-col justify-end space-y-4 p-4">
              {
                <input
                  placeholder="시뮬레이션명 입력해주세요"
                  type="text"
                  value={simName}
                  className={`flex-1 text-xl text-gray-800 py-3 rounded-2xl border outline-none focus:duration-300  focus:ring-2 shadow-sm px-4 scroll-mt-[400px] focus:ring-blue-500 focus:ring-opacity-40 placeholder-gray-400`}
                  onChange={handleSimName}
                  maxLength={50}
                />
              }

              <button
                className="bg-konared py-[15px] rounded-2xl text-xl text-white font-semibold ripple-primary inline-flex justify-center items-center"
                onClick={Simulate}
              >
                {isSimRunning && (
                  <svg
                    className="animate-spin mr-3 h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                )}
                시뮬레이션 실행
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6" ref={simResult}>
        <CardWrapper className="col-span-2 h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">총 인구</div>
              <div className="flex text-right text-lg">단위: 명</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 ">
            {state.popData ? (
              <PopulationEcharts options={state.popData} />
            ) : (
              <div className="h-full flex justify-center items-center"></div>
            )}
          </CardContent>
        </CardWrapper>
        <CardWrapper className="h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">연령별 인구 구조</div>
              <div className="flex text-right  text-lg">단위: 명</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 flex flex-col">
            <div className=" h-full inline-flex items-center">
              {state.ageData ? (
                <PopulationEcharts options={state.ageData} />
              ) : (
                <div className="h-full flex justify-center items-center"></div>
              )}
            </div>
          </CardContent>
        </CardWrapper>
        <CardWrapper className=" h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">가임 여성</div>
              <div className="flex text-right  text-lg">단위: 명</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 ">
            {state.pregnancyData ? (
              <PopulationEcharts options={state.pregnancyData} />
            ) : (
              <div className="h-full flex justify-center items-center"></div>
            )}
          </CardContent>
        </CardWrapper>

        {/* 					
					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">
									부양비율
								</div>
								<div className='flex text-right  text-lg'>
									단위: %, 명
								</div>
							</div>
						</CardHeader>
						<CardContent className="flex flex-row w-full space-x-4 px-5" >
							<div className='basis-1/2 py-5 ' >
								{
									state.welfareData ?
										<PopulationEcharts options={state.welfareData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}

							</div>
							<div className='basis-1/2 py-5' >
								{
									state.welfareAgeData ?
										<PopulationEcharts options={state.welfareAgeData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}

							</div>
						</CardContent>
					</CardWrapper> */}

        <CardWrapper className="col-span-2 h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">부양비율</div>
              <div className="flex text-right text-lg">단위: %</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 ">
            {state.popData ? (
              <PopulationEcharts
                options={getChartOptionDependencyRatioKonaSim(
                  state.dependencyRatioYoung,
                  state.dependencyRatioOld,
                  state.dependencyRatioTotal,
                  state.simResults
                )}
              />
            ) : (
              <div className="h-full flex justify-center items-center"></div>
            )}
          </CardContent>
        </CardWrapper>

        <CardWrapper className=" h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">생산연령인구 비중 변화 추이</div>
              <div className="flex text-right  text-lg">단위: %</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 ">
            {state.popWorking ? (
              <PopulationEcharts
                options={getChartOptionWorkingPopulationCompKonaSim(
                  state.popYoung,
                  state.popOld,
                  state.popWorking,
                  state.popTotal,
                  state.simResults
                )}
              />
            ) : (
              <div className="h-full flex justify-center items-center"></div>
            )}
          </CardContent>
        </CardWrapper>

        <CardWrapper className=" h-[500px]">
          <CardHeader>
            <div className="w-full flex flex-row items-center">
              <div className="flex-1">복지비</div>
              <div className="flex text-right  text-lg">단위: 원</div>
            </div>
          </CardHeader>
          <CardContent className="p-5 ">
            {state.popWorking ? (
              <PopulationEcharts
                options={getChartOptionWelfareCostKonaSim(
                  state.popWorking,
                  state.dependencyRatioTotal,
                  state.simResults
                )}
              />
            ) : (
              <div className="h-full flex justify-center items-center"></div>
            )}
          </CardContent>
        </CardWrapper>

        {state.baseSimID && state.simID ? (
          <div className="col-span-2 flex justify-center p-10">
            <button
              onClick={downloadReport}
              className="text-xl text-white inline-flex leading-none rounded px-10 py-3 ripple-primary"
            >
              Download Report
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {/* </div> */}
      <ChartDetailsModal
        isOpen={isChartDetailOpen}
        setIsOpen={setIsChartOpen}
        item={topic}
      />
      <span>ㄴㅇㄹㅇㄹ</span>
    </MainContentWrapper>
  )
}

export default SimulationNational
