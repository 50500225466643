import React from 'react'
import UserInfoContext, {USER_INFO_PAGES} from "../../context/UserInfoProvider";
import SetPassword from "./SetPassword"
import Unsubscribe from "./Unsubscribe";
import UpdateEmail from "./UpdateEmail";
import UpdatePassword from "./UpdatePassword";
import UserInfoDetails from "./UserInfoDetails"

const UserInfoContent = () => {
  const {userInfoState} = React.useContext(UserInfoContext);
  return (
    <>
      {(() => {
        if(userInfoState.pwSet === false){
          return <SetPassword />
        }
        else{
          switch(userInfoState.frameView) {
            case USER_INFO_PAGES.USER_DETAILS:
              return <UserInfoDetails />;
            // Update email is disabled due to KONA CARD OPEN API restriction
            // case USER_INFO_PAGES.UPDATE_EMAIL:
            //   return <UpdateEmail />;
            case USER_INFO_PAGES.UPDATE_PASSWORD:
              return <UpdatePassword />;
            case USER_INFO_PAGES.UNSUBSCRIBE:
              return <Unsubscribe />;
            default:
              return <SetPassword />;
          }
        }
      })()}
    </>
  )
}

export default UserInfoContent