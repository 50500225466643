import React from 'react'
import {BsFillQuestionCircleFill} from 'react-icons/bs'
import { useFieldArray, useController} from "react-hook-form";
import ParamInfoModal from "../modals/ParamInfoModal";
import { useReStateContext } from "../context/RealEstateContext";
import { SIM_PARAMETERS } from "../context/ReMysimContext";


const SimParamSlider = ({param, form}) => {
  const reState = useReStateContext();
  const [isOpen, setIsOpen] = React.useState(false)

  const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];
  const paramInfo = {...modelData.variable[param], ...SIM_PARAMETERS[param], paramName: param}
  const inputVarInfo = modelData.variable[paramInfo.inputVarName];

  const {fields, append, remove} = useFieldArray({ control: form.control, name: param }); //name of field array is the variable name


  const SliderWithInput = ({ control, name, min, max, step, yearTxt }) => {
    const {field} = useController({name: `${name}.value`, control});

    return (
      <div className="inline-flex items-center space-x-4 w-full">
      <div className="flex-1 relative py-2">
        <span className="absolute top-1 left-0 text-xs font-bold">{yearTxt}년</span>
        <input type="hidden"  {...form.register(`${name}.date`)}  defaultValue={parseInt(yearTxt)}   />
        <input 
          {...field} 
          type="range"
          min={min}
          max={max}
          step={step}
          className="w-full sim-input-slider" 
        />
        <span className="absolute bottom-1 left-0 text-[10px] text-gray-400 font-medium">{min}</span>
        <span className="absolute bottom-1 right-0 text-[10px] text-gray-400 font-medium">{max}</span>
      </div>
      <div className="w-[60px]">
        <input 
          {...field} 
          type="text" 
          className="block text-xs py-2 rounded-lg w-full border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm" 
          autoComplete="off"
        />
      </div>
    </div>
    );
  }

  return (
    <div className="py-2 bg-white flex flex-col">
      <ParamInfoModal isOpen={isOpen} setIsOpen={setIsOpen} paramInfo={paramInfo} inputVarInfo={inputVarInfo} />
      
      <div className="flex items-center">
        <input className="inp-cbx hidden" id={param} type="checkbox"  {...form.register(`${param}-check`)} />
        <label className="cbx flex items-center space-x-2" htmlFor={param}>
          <span className="h-5 w-5 flex justify-center items-center"><svg className="h-2/3 w-2/3" viewBox="0 0 12 9"><polyline points="1 5 4 8 11 1"></polyline></svg></span>
          <span className="text-xl font-semibold">{paramInfo.title}</span> 
        </label>
        <button onClick={() => setIsOpen(true)} type="button" className="ml-2 text-slate-500 hover:text-orange-400 hover:drop-shadow-md"><BsFillQuestionCircleFill size={16} className="" /></button>
      </div>

      
      {/* <div className="flex flex-col space-y-2 mt-4 p-4 border rounded-xl relative">
        {fields.map((item, index) => {
          return (
             <SliderWithInput 
                key={item.id}
                name={`${param}.${index}`} 
                control={form.control} 
                min={paramInfo.e_min}
                max={paramInfo.e_max}
                step={paramInfo.e_inc}
                yearTxt={parseInt(paramInfo.data_in[0].value[0][0])+index}
              />
               
          );
        })}

        {(fields.length < 3) && <button onClick={() => append({value: paramInfo.data_in[0].value[0][1]})} type="button" className="self-center inline-flex items-center text-xs space-x-2 ripple-gray px-2 py-1 rounded-xl"><span>{parseInt(paramInfo.data_in[0].value[0][0])+fields.length}년 추가</span></button>}
      </div> */}


      <div className={`overflow-hidden transition-[height] duration-500 ${form.watch(`${param}-check`) ? "h-full" : "duration-300 h-0"}`}>
        <div className={`flex flex-col space-y-4 mt-4 p-4 border rounded-xl relative transition-opacity duration-1000 opacity-0 ${form.watch(`${param}-check`) ? "opacity-100" : "opacity-0"}`}>
          {fields.map((item, index) => {
            return (
              <SliderWithInput 
                key={item.id}
                name={`${param}.${index}`} 
                control={form.control} 
                min={paramInfo.e_min}
                max={paramInfo.e_max}
                step={paramInfo.e_inc}
                yearTxt={parseInt(paramInfo.data_in[0].value[0][0])+index}
              />
            );
          })}

          {(fields.length < 3) && <button onClick={() => append({value: paramInfo.data_in[0].value[0][1]})} type="button" className="self-center inline-flex items-center text-xs space-x-2 ripple-gray px-2 py-1 rounded-xl"><span>{parseInt(paramInfo.data_in[0].value[0][0])+fields.length}년 추가</span></button>}
        </div>
      </div>

    </div>
  )
}

export default SimParamSlider