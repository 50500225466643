import { API_BASE_URL, SOLIDEO_API_URL } from "../constants";
import { ApiError, bearerAuth, InvalidRequestBody, isEmpty, ResponseError } from "../helpers";

export const getAddrshLastVisitedApi = async (user) => {

  try {
    const query = `?page_size=1&sort=-updated_at`
    const res = await fetch(`${API_BASE_URL}/api/addrsh${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });
    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data[0];
    }

  } catch (err) {
      throw(err)
  }
}

export const getChklist = async (address, user) => {

  const pnu = address.innb;
  const rncode = address.rnMgtSn;
  const buldMnnmNo = address.buldMnnm;
  const buldSlnoNo = address.buldSlno;
    
  try {
    const query = `?pnu=${pnu}&rncode=${rncode}&buldMnnmNo=${buldMnnmNo}&buldSlnoNo=${buldSlnoNo}`
    const res = await fetch(`${SOLIDEO_API_URL}/api/solideo/getprposchklist${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();
    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

    if(parseRes?.code == 200 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError(`JADU API fetch error: ${parseRes?.message}`);
    }

  } catch (err) {
      throw(err)
  }
}

export const getJaduAptPrice = async (chklist, dong, ho, user) => {
  // fetch apartment price from JADU\
  const pnu = chklist.pnuCnvr;
  const adresGb = chklist.prposChkList[0].prposChkNm;
  const dongNm = dong;
  const hoNm = ho;
  const searchPnu = chklist.pnuCnvr;
  const rnAddrYn = chklist.rnAddrYn;
  const roadPnu = chklist?.roadPnu;
    
  try {
    const query = `?pnu=${pnu}&adresGb=${adresGb}&dongNm=${dongNm}&hoNm=${hoNm}&searchPnu=${searchPnu}&rnAddrYn=${rnAddrYn}`+(roadPnu? `&roadPnu=${roadPnu}` : "");
    const res = await fetch(`${SOLIDEO_API_URL}/api/solideo/gettargetinfo${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

    if(parseRes?.code == 200 && !isEmpty(parseRes?.data)) {
      return {...parseRes.data, resultAmt:parseInt(parseRes.data.resultAmt.replace(/,/g, ''))}
    }
    else{
      throw new ApiError(`JADU API fetch error: ${parseRes?.message}`);
    }

  } catch (err) {
      throw(err)
  }
}

export const getDefaultRealEstateModelApi = async (user) => {

  try {
    const query = `?page_size=1&sort=-id`;
    const res = await fetch(`${API_BASE_URL}/api/models${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data[0];
    }
    else{
      throw new ApiError("Unable to load default model.");
    }

  } catch (err) {
      throw(err)
  }
}

export const getRealEstateModelByIdApi = async (modelId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/models/${modelId}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load Real Estate model.");
    }

  } catch (err) {
      throw(err)
  }
}

export const getRealEstateModelVariablesApi = async (modelId, locationCode, user) => {

  try {
    const query = `?page_size=all&model_id=${modelId}&category__in=PARAMETER,INPUT&loc_code__in=null,${locationCode}&expand=data_in`
    const res = await fetch(`${API_BASE_URL}/api/variables${query}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load model variables.");
    }

  } catch (err) {
      throw(err)
  }
}


export const getRealEstateModelBaseDataApi = async (modelId, locationCode, user) => {

  try {
    const query =  `?page_size=1&sort=-id&model_id=${modelId}&type=BASE&status=COMPLETED&loc_code=${locationCode}`
    const res = await fetch(`${API_BASE_URL}/api/simulations/with-datavar${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data[0];
    }
    else{
      throw new ApiError("Unable to load model base data");
    }

  } catch (err) {
      throw(err)
  }
}



export const downloadRealEstateMySimReportApi = async (simIds, user)=> {
		
  try {
    

    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({simulation_id : simIds})
    };

    const res = await fetch(`${API_BASE_URL}/api/reports`, requestOptions);
    if (res.status === 403) throw new ResponseError((await res.json()).detail);
    if (res.status === 422) throw new InvalidRequestBody((await res.json()).detail);
    if(!res.ok) throw new ApiError("Something went wrong. Please contact admin.")

    let pdfBlob = await res.blob()
    let pdfFile = new Blob([pdfBlob],{ type: 'application/pdf'})
    
    let filename= res.headers.get('content-disposition')
      .split(';')
      .find(n => n.includes('filename='))
      .replace('filename=', '')
      .trim()

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(pdfFile);
    link.download = filename;
    link.click();


  } catch (err) {
    throw(err)
  }

}

export const getRealEstateSimulationByIdApi = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/simulations/${simId}`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(!isEmpty(parseRes)) {
      return parseRes;
    }
    else{
      throw new ApiError("Unable to load Simulation");
    }

  } catch (err) {
      throw(err)
  }
} 


export const getRealEstateDataOutWithVarinfo = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/data-out?simulation_id=${simId}&expand=variable`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    if(parseRes?.total_count > 0 && !isEmpty(parseRes?.data)) {
      return parseRes.data;
    }
    else{
      throw new ApiError("Unable to load model simulation data");
    }

  } catch (err) {
      throw(err)
  }
} 


export const deleteRealEstateSimulation = async (simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/simulations/${simId}`, {
      method: "DELETE",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 


export const updateRealEstateSimulation = async (body, simId, user) => {

  try {
    const res = await fetch(`${API_BASE_URL}/api/simulations/${simId}`, {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({...body})
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")

  } catch (err) {
      throw(err)
  }
} 



export const getRealEstateSimulationsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id&page=1&type=USER`
    const res = await fetch(`${API_BASE_URL}/api/simulations/user${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}


export const getRealEstateModelsApi = async (user) => {

  try {
    const query =  `?page_size=all&sort=-id`
    const res = await fetch(`${API_BASE_URL}/api/models${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await res.json();

    if (res.status === 403) throw new ResponseError(parseRes.detail);
    if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
    if(!res.ok) throw new ApiError("Unknown error occured. Please contact us for assistance.")
    
    return parseRes.data; //can return empty list

  } catch (err) {
      throw(err)
  }
}