import React from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/24/solid'

const PageNumListBox = ({dataList, selectedVal, onChangeVal, disabled=false}) => {
  return (
    <Listbox
      as="div"
      value={selectedVal}
      onChange={onChangeVal}
      className="h-full flex w-14"
      disabled={disabled}
    >
      {({ open }) => (
        <div className="relative z-10 w-full">
          <Listbox.Button className="cursor-default relative w-full h-full bg-white rounded-lg  border border-gray-300 py-[2px] text-center pr-2  focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            <span className="block truncate">{selectedVal}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon className="h-4 text-gray-400" />
            </span>
          </Listbox.Button>
          <Transition
            show={open}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="absolute top-[50%] w-full rounded-md bg-white shadow-lg overflow-hidden -z-10"
          >
            <Listbox.Options
              static
              className="max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 "
            >
              {dataList.map((data,i) => (
                <Listbox.Option key={i} value={data}>
                  {({ selected, active }) => (
                    <div className={`${active? "text-black bg-konahover": "text-black"} ${selected ? "bg-konahover" : ""} cursor-default select-none relative py-2 text-center`}>
                      <span className={`${selected ? "font-semibold text-konared" : "font-normal"} block truncate`}>{data}</span>
                    </div>
                  )}
                </Listbox.Option>
              ))}
        
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox >
  )
}

export default PageNumListBox