import React from 'react'
import '../../assets/css/loaders.css'

const DotLoader = ({text}) => {
  return (
    <div className="flex-1 flex flex-col space-y-2 items-center justify-center">
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
      </div>
      <span className="text-konared text-xl font-semibold">{text}</span>
    </div>
  )
}

export default DotLoader