import React from 'react'
import { useNavigate } from "react-router-dom";
import { checkIsSubscribeApi, checkUserNotAgreedTOS, loginApi, verifyTokenApi } from "../../utils/api/core";
import { SERVICES } from "../../utils/constants";
import { ApiError, AppError, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import Loader3 from "../loaders/Loader3";
import MainContentWrapper from "../MainContentWrapper";
import ApiErrorModal from "../modals/ApiErrorModal";

export const GlobalStateContext = React.createContext(null);
export const GlobalDispatchContext = React.createContext(null);

export const GLOBAL_ACTION = {
  SET_SUBSCRIBED_SERVICES: "set-subscribed-services",
  SET_API_ERROR: "set-api-error",
}

const initialValue = {
  isSubscribedRealEstate: false,
  isSubscribedPopulation: false,
  isApiError: false,
  apiErrorMsg: {title: null, message: null}
}

function reducer(state, {type, payload}){
  switch(type){
    case GLOBAL_ACTION.SET_SUBSCRIBED_SERVICES:{
      return {
        ...state,
        isSubscribedRealEstate: payload.isSubscribedRealEstate,
        isSubscribedPopulation: payload.isSubscribedPopulation,
      }
    
    }
    case GLOBAL_ACTION.SET_API_ERROR:{
      return {
        ...state, 
        apiErrorMsg: payload.apiErrorMsg, 
        isApiError: payload.isApiError === false ? false : true
      }
    }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

export default function GlobalContextProvider({children}) {
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  //user initial value is false (meaning not yet initialized)
  //false value will be used to render children only when curren user is identified (loggedin or not)
  //if token is read, this will become undefined or null or obj
  const [user, setUser] = React.useState(false);


  const checkAuth = React.useCallback(async () => {
    const token = sessionStorage.jwToken || localStorage.jwToken;
    const user = token && await verifyToken(token);
    setUser((prevState) => JSON.stringify(prevState) === JSON.stringify(user) ? prevState : user);
    
  }, [])

  React.useEffect(() => {
    checkAuth();
  },[checkAuth]);



  const verifyToken = async (token) => {
    try {
      
      const userCredential = await verifyTokenApi(token);
      const userUpdatedTos = await checkUserNotAgreedTOS(userCredential);
      return {...userCredential, userUpdatedTos}

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        dispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
  }


  const handleLogin = async (email, password, account_type, persistLogin) => {

    try{
      const userCredential = await loginApi(email, password, account_type, persistLogin);
      const userUpdatedTos = await checkUserNotAgreedTOS(userCredential);
      
      setUser({...userCredential, userUpdatedTos});
      return {...userCredential, userUpdatedTos};

    } catch (err) {
      throw(err)
    }
    
    
  }

  const handleLogout = () => {
    sessionStorage.removeItem('jwToken');
    localStorage.removeItem('jwToken');
    setUser(null);
    navigate("/login"); //redirect user to home when logout
  }

  const clearAuthUser = () => {
    sessionStorage.removeItem('jwToken');
    localStorage.removeItem('jwToken');
    setUser(null);
  }

  React.useEffect(() => {
    const checkSubscriptions = async () => {

      if(user) {
        try {
            const [isSubscribedRealEstate, isSubscribedPopulation] = await Promise.all([
              checkIsSubscribeApi(user, SERVICES.realestate.id),
              checkIsSubscribeApi(user, SERVICES.population.id),
            ]);
            dispatch({
              type: GLOBAL_ACTION.SET_SUBSCRIBED_SERVICES,
              payload: {isSubscribedRealEstate, isSubscribedPopulation}
            })
        } catch (err) {
          if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
            const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
            dispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
          }
          else{
            console.error(err)
          }
        }
      }
    }
    checkSubscriptions();
  },[user]);


  const values = React.useMemo(() => ({
    user,
    state,
    handleLogin,
    handleLogout,
    clearAuthUser,
    checkAuth
  }), [user, state]);


  return (
    <GlobalStateContext.Provider value={values}>
      <GlobalDispatchContext.Provider value={dispatch} >
        <ApiErrorModal />
        {user === false? <MainContentWrapper className="bg-konabg flex flex-col"><div className="flex-1 flex items-center justify-center"><Loader3 /></div></MainContentWrapper> : children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}


export const factoryUseContext = (name, context) => () => {
  const ctx = React.useContext(context);
  if (!ctx) throw new Error(`use${name}Context must be used withing a ${name}.Provider.`);
  return ctx;
}


export const useGlobalStateContext = factoryUseContext("GlobalStateContext", GlobalStateContext);
export const useGlobalDispatchContext = factoryUseContext("GlobalDispatchContext",GlobalDispatchContext);
