import React from 'react'
import { NavLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { addNoticeUserReactionsApi, COMMENT_POST_TYPE, deleteNoticeByIdApi, deleteNoticeUserReactionsApi, getNoticeByIdApi, getNoticeUserReactionsApi } from "../../utils/api/referenceApi";
import ReferenceHeader from "./ReferenceHeader";
import { 
  UserIcon, 
  EyeIcon,
  HandThumbUpIcon,
  ShareIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  TrashIcon,
  DocumentPlusIcon,
  PencilIcon,
  ClockIcon  } from '@heroicons/react/24/outline'
import { 
  HomeIcon, 
  ChevronRightIcon, 
  ShareIcon as ShareIconSolid,
  HandThumbUpIcon as HandThumbUpIconSolid } from "@heroicons/react/24/solid";
import { ApiError, AppError, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import Loader3 from "../../components/loaders/Loader3";
import PostComments from "./PostComments";
import ReferenceLoginRequireModal from "../../components/modals/ReferenceLoginRequireModal";
import { ACCOUNT_ROLE } from "../../utils/constants";
import DeletePostModal from "../../components/modals/DeletePostModal";
import PageNotFound from "../PageNotFound";


const POST_TYPE = COMMENT_POST_TYPE.NOTICE;

export const ACTION = {
	LOAD_NOTICE_REACTION: "load-notice-reaction",
	SET_COMMENT_COUNT: "set-comment-count",
  SET_REACTION: "set-reaction",
  SET_DELETE_MODAL: "set-delete-modal",
  SET_LOGIN_MODAL: "set-login-modal",
}

const initialState = {
	isLoaded: false,
  noticeInfo: null,
  isLike: false,
  isOpenDeleteModal: false,
  isOpenLoginModal: false,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.LOAD_NOTICE_REACTION:
			return {
        ...state, 
        noticeInfo: payload.noticeInfo, 
        isLike: payload.isLike,
        isLoaded: true
      };
    case ACTION.SET_COMMENT_COUNT:
      return {...state, noticeInfo: {...state.noticeInfo, comments: payload}};
    case ACTION.SET_REACTION:
      return {...state, isLike: payload.isLike, noticeInfo: {...state.noticeInfo, likes: payload.likes}};
    case ACTION.SET_LOGIN_MODAL:
      return {...state, isOpenLoginModal: payload};
    case ACTION.SET_DELETE_MODAL:
      return {...state, isOpenDeleteModal: payload};

		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}




export default function NoticeDetails () {
  const {id} = useParams();
	const { user } = useGlobalStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [isNotFound, setIsNotFound] = React.useState(false);
  
  const gotoUrl = `/reference/notice/${id}`;
  const parentUrl = `/reference/notice/`;

  const loadPostAndReaction = React.useCallback(async () => {
    try {

      const [notice, reaction] = await Promise.all([
        getNoticeByIdApi(id),
        getNoticeUserReactionsApi(id, user),
      ]);


      dispatch({type: ACTION.LOAD_NOTICE_REACTION, payload: {noticeInfo: notice, isLike: !isEmpty(reaction)}});

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        if(err.message === "Cannot find notice information."){
          setIsNotFound(true);
        }
        else {
          const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
          globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})

        }
      }
      else{
        console.error(err)
      }
    }

    
	}, [])


  React.useEffect(() => {
		loadPostAndReaction();
	},[]);
  

  function setCommentCount(value) {
    dispatch({type: ACTION.SET_COMMENT_COUNT, payload: value});
  }

  const submitLike = async () => {
    if(state.isLike) {
      const reaction = await deleteNoticeUserReactionsApi(id, null, user);
      dispatch({type: ACTION.SET_REACTION, payload: {isLike: false, likes: state.noticeInfo?.likes-1}});
    } else {
      const reaction = await addNoticeUserReactionsApi(id, null, user);
      dispatch({type: ACTION.SET_REACTION, payload: {isLike: true, likes: state.noticeInfo?.likes+1}});
    }
  }


  async function deleteNotice() {

    try {
      await deleteNoticeByIdApi(id, user);

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
  }


  function closeLoginModal () {
    dispatch({type: ACTION.SET_LOGIN_MODAL, payload: false});
  }

  function openLoginModal () {
    dispatch({type: ACTION.SET_LOGIN_MODAL, payload: true})
  }

  function closeDeleteModal () {
    dispatch({type: ACTION.SET_DELETE_MODAL, payload: false});
  }

  function openDeleteModal () {
    
    dispatch({type: ACTION.SET_DELETE_MODAL, payload: true});
  }


  const submitCommentLike = async (isLike, commentId) => {
    if(isLike) {
      const reaction = await deleteNoticeUserReactionsApi(id, commentId, user);
    } else {
      const reaction = await addNoticeUserReactionsApi(id, commentId, user);
    }
  }


  if(isNotFound) {
    return <PageNotFound />
  }
  else {
    return (
      <div className="flex-1 flex flex-col pb-10">
        <ReferenceLoginRequireModal isOpen={state.isOpenLoginModal} closeFn={closeLoginModal} gotoUrl={gotoUrl} />
        <DeletePostModal isOpen={state.isOpenDeleteModal} info={{content: state.noticeInfo?.title}} closeFn={closeDeleteModal} confirmCallback={deleteNotice} parentUrl={parentUrl} />
        <ReferenceHeader>공지사항</ReferenceHeader>
        <div className="flex justify-center">
          <div className="flex-1 max-w-7xl p-10">
            <div className="flex justify-end pb-5"><BreadCrumbs /></div>
            {!state.isLoaded ? (
              <div className="mt-10"><Loader3 /></div>
            ) : (
              <>            
                <PostHeader post={state.noticeInfo} user={user} deletePostFn={openDeleteModal} />
                <div className="px-5 py-10 border-b">
                  <div className="no-tailwindcss-base" dangerouslySetInnerHTML={{ __html: state.noticeInfo.content }}></div>
                  <div className="flex space-x-2 mt-14 items-center">
                    <button className="flex items-center p-2 border border-konagray rounded-lg space-x-1 hover:text-konared hover:border-konared"><ShareIcon className="h-6" /><span>공유</span></button>
                    <button 
                      className={`flex items-center p-2 border rounded-lg space-x-1 hover:text-konared hover:border-konared ${state.isLike ? "border-konared text-white bg-konared hover:text-white": "border-konagray"}`}  
                      onClick={() => (user) ? submitLike() : openLoginModal()}
                    >
                        {state.isLike ? <HandThumbUpIconSolid className="h-6 text-white"  /> : <HandThumbUpIcon className="h-6"  />}
                        <span>좋아요</span>
                    </button>
                    <span>{state.noticeInfo.likes}명이 좋아합니다.</span>
                  </div>
                </div>
                <div className="p-5 max-w-xl">
                  <PostComments postType={POST_TYPE.apiParentUrl} user={user} postId={id} totalComments={state.noticeInfo.comments} callbackFn={{setCommentCount, submitCommentLike}} callbackUrl={gotoUrl} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    )
  }
}




function PostHeader({post, user, deletePostFn}) {


  return (
    <div className="border-b">
      <div className="text-3xl">{post.title}</div>
      <div className="py-5 flex items-center space-x-2 text-konagray/80">
        <div className="flex items-center space-x-1"><UserIcon className="h-4" /><span>{post?.user?.name}</span></div>
        <div className="border-l pl-2 flex items-center space-x-1"><ClockIcon className="h-4" /><span>{dayjs(post.created_at).format("YYYY-MM-DD")}</span></div>
        <div className="border-l pl-2 flex items-center space-x-1"><EyeIcon className="h-4" /><span>{post.views}</span></div>
        <div className="border-l pl-2 flex items-center space-x-1"><HandThumbUpIcon className="h-4" /><span>{post.likes}</span></div>
        <div className="border-l pl-2 flex items-center space-x-1"><ChatBubbleOvalLeftEllipsisIcon className="h-4" /><span>{post.comments}</span></div>
        <div className="flex-1 flex justify-end">
          {(user?.user_info?.role === ACCOUNT_ROLE.ADMIN) && 
            <div className="flex space-x-2">
              <NavLink to="/reference/notice/create" className="p-2 border border-konared text-konared rounded-lg hover:text-white hover:bg-konared" ><DocumentPlusIcon className="h-6" /></NavLink>
              <NavLink to={`/reference/notice/update/${post.id}`} className="p-2 border border-konared text-konared rounded-lg hover:text-white hover:bg-konared" ><PencilIcon className="h-6" /></NavLink>
              <button className="p-2 border border-konared text-konared rounded-lg hover:text-white hover:bg-konared" onClick={() => deletePostFn()}><TrashIcon className="h-6" /></button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}


function BreadCrumbs() {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
        <li className="inline-flex items-center">
          <NavLink to="/" className="inline-flex items-center text-sm font-medium hover:text-konared">
            <span className="mr-2"><HomeIcon className="h-4" /></span>
            Home
          </NavLink>
        </li>
        <li>
          <div className="flex items-center">
            <span><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference" className="ml-2 hover:text-konared">자료실</NavLink>
          </div>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <span className=""><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference/notice" className="ml-2 hover:text-konared">공지사항</NavLink>
          </div>
        </li>
      </ol>
    </nav>
  )
}

