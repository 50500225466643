import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '../assets/css/index.css'
import Login from "./Login";
import Main from "./Main";
import MainRealEstate from "./realestate/MainRealEstate";
import RealEstateAbout from "./realestate/RealEstateAbout";
import RealEstateKonaSim from "./realestate/RealEstateKonaSim";
import Private from "../components/PrivateRoute";
import Register from "./Register";
import RealEstateMySimBase from "./realestate/RealEstateMySimBase";
import FindPassword from "./FindPassword";
import FindEmail from "./FindEmail";
import DataLibrary from "./realestate/DataLibrary";
import DataLibraryDetails from "./realestate/DataLibraryDetails";
import MainPopulation from './population/MainPopulation';
import PopIntroduction from './population/PopIntroduction';
import PopContact from './population/PopContact';
import PopLocalBirth from './population/PopLocalBirth';
import ResetPassword from "./ResetPassword";
import PopLocalMovement from './population/PopLocalMovement';
import PopMarriage from './population/PopMarriage';
import PopHousehold from './population/PopHousehold';
import PopImmigrationMarriage from './population/PopImmigrationMarriage';
import PopInternational from './population/PopInternational';
import PopDashboard from './population/PopDashboard';
import PopNationalForecast from './population/PopNationalForecast';
import PopLocal from './population/PopLocal';
import SimulationNational from './population/SimulationNational';
import PopExtinction from './population/PopExtinction';
import MyPage from "./MyPage";
import NotFound from "./NotFound";
import Simulation from "../components/mypage/simulations/Simulation";
import Report from "../components/mypage/Report";
import Billing from "../components/mypage/billing/Billing";
import UserInfo from "../components/mypage/userinfo/UserInfo";
import Payment from "./Payment";
import SimulationSido from './population/SimulationSido';
import PopReportPreview from './population/PopReportPreview';
import MyPageTerms from "../components/mypage/MyPageTerms";
import SelectRegType from "../components/registration/SelectRegType";
import CorporateRegTerms from "../components/registration/CorporateRegTerms";
import PersonalRegTerms from "../components/registration/PersonalRegTerms";
import PersonalRegInfo from "../components/registration/PersonalRegInfo";
import PersonalRegProcess from "../components/registration/PersonalRegProcess";
import SimulationWorkforce from './population/SimulationWorkforce';
import CorporateRegInfo from "../components/registration/CorporateRegInfo";
import CorporateRegProcess from "../components/registration/CorporateRegProcess";
import SubscribeRedirect from './population/SubscribeRedirect';
import SubscriptionRoutePopulation from "../components/SubscriptionRoutePopulation";
import RealEstate from "./realestate/RealEstate";
import GlobalContextProvider from "../components/context/GlobalContext";
import RealEstateContextProvider from "../components/context/RealEstateContext";
import RealEstateMySimView from "./realestate/RealEstateMySimView";
import IndustrySimulation from './population/IndustrySimulation';
import SimulationRegional from './population/SimulationRegional';
import IndustryMySimView from "./population/IndustryMySimView";
import Reference from "./reference/Rerence";
import Notice from "./reference/Notice";
import PopulationNews from "./reference/PopulationNews";
import NoticeDetails from "./reference/NoticeDetails";
import CreateNotice from "./reference/CreateNotice";
import AdminRoute from "../components/AdminRoute";
import UpdateNotice from "./reference/UpdateNotice";
import PopulationNewsDetails from "./reference/PopulationNewsDetails";
import CreatePopulationNews from "./reference/CreatePopulationNews";
import UpdatePopulationNews from "./reference/UpdatePopulationNews";
import PopMarriage2 from "./population/PopMarriage2";

const App = () => {
  
  return (
    <BrowserRouter>
        <GlobalContextProvider>
          <RealEstateContextProvider>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/login" element={<Login />} />
              {/* <Route path="/register" element={<Register />} /> */}
              <Route path="/findpass" element={<FindPassword />} />
              <Route path="/findemail" element={<FindEmail />} />

              <Route path="/mypage/" element={<Private Component={MyPage} />}>
                <Route index element={<Navigate to="simulation" replace />} />
                <Route path="simulation" element={<Private Component={Simulation} />} />
                <Route path="report" element={<Private Component={Report} />} />
                <Route path="userinfo" element={<Private Component={UserInfo} />} />
                <Route path="billing" element={<Private Component={Billing} />} />
                <Route path="terms" element={<Private Component={MyPageTerms} />} />
              </Route>

              <Route path="/register/" element={<Register />}>
                <Route index element={<Navigate to="select-regtype" replace />} />
                <Route path="select-regtype" element={<SelectRegType/>} />
                <Route path="preg-terms" element={<PersonalRegTerms/>} />
                <Route path="preg-info" element={<PersonalRegInfo/>} />
                <Route path="preg-proc" element={<PersonalRegProcess/>} />
                <Route path="creg-terms" element={<CorporateRegTerms/>} />
                <Route path="creg-info" element={<CorporateRegInfo/>} />
                <Route path="creg-proc" element={<CorporateRegProcess/>} />
              </Route>

              <Route path="/realestate/" element={<RealEstate />}>
                <Route index element={<Navigate to="main" replace />} />
                <Route path="main" element={<MainRealEstate />} />
                <Route path="about" element={<RealEstateAbout />} />
                <Route path="konasim" element={<Private Component={RealEstateKonaSim} />} />
                <Route path="mysim" element={<Private Component={RealEstateMySimBase} />} />
                <Route path="viewsim" element={<Private Component={RealEstateMySimView} />} />
                <Route path='datalibrary' element={<Private Component={DataLibrary} />} />
                <Route path='datalibrary-details/:linkID' element={<Private Component={DataLibraryDetails} />} />
              </Route>


              <Route path="/reference/" element={<Reference />}>
                <Route index element={<Navigate to="notice" replace />} />
                <Route path="notice" element={<Notice />} />
                <Route path="notice/:id" element={<NoticeDetails />} />
                <Route path="notice/create" element={<AdminRoute Component={CreateNotice} />} />
                <Route path="notice/update/:id" element={<AdminRoute Component={UpdateNotice} />} />
                <Route path="population-news" element={<PopulationNews />} />
                <Route path="population-news/:id" element={<PopulationNewsDetails />} />
                <Route path="population-news/create" element={<AdminRoute Component={CreatePopulationNews} />} />
                <Route path="population-news/update/:id" element={<AdminRoute Component={UpdatePopulationNews} />} />

              </Route>



              <Route path="/payment" element={<Private Component={Payment} />} />

              <Route path="/resetpass/:uid/:resetToken" element={<ResetPassword />} />
              <Route path='/population' element={<MainPopulation />} />
              <Route path='/pop-intro' element={<PopIntroduction />} />
              <Route path='/pop-contact' element={<PopContact/>} />
              <Route path='/pop-datalocal-birth' element={<SubscriptionRoutePopulation Component={PopLocalBirth} />} />
              <Route path='/pop-datalocal-mov' element={<Private Component={PopLocalMovement} />} />
              <Route path='/pop-datalocal-marriage' element={<SubscriptionRoutePopulation  Component={PopMarriage} />} />
              <Route path='/pop-datalocal-marriage2' element={<Private  Component={PopMarriage2} />} />
              <Route path='/pop-household' element={<Private Component={PopHousehold} />} />
              <Route path='/pop-immigration' element={<Private Component={PopImmigrationMarriage} />} />
              <Route path='/pop-international' element={<SubscriptionRoutePopulation Component={PopInternational} />} />
              <Route path='/pop-dashboard' element={<Private Component={PopDashboard} />} />
              <Route path='/pop-national' element={<Private Component={PopNationalForecast} />} />
              <Route path='/pop-local' element={<Private Component={PopLocal} />} />
              <Route path='/pop-simulation-national' element={<SubscriptionRoutePopulation Component={SimulationNational} />} />
              <Route path='/pop-extinction' element={<SubscriptionRoutePopulation Component={PopExtinction} />} />
              <Route path='/pop-simulation-sido' element={<SubscriptionRoutePopulation Component={SimulationRegional} />} />
              <Route path='/pop-report-view' element={<Private Component={PopReportPreview} />} />
              <Route path='/pop-simulation-workforce' element={<SubscriptionRoutePopulation Component={IndustrySimulation} />} />
              <Route path='/industry-viewsim' element={<Private Component={IndustryMySimView} />} />
              <Route path='/pop-sub-redirect' element={<SubscribeRedirect/>} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </RealEstateContextProvider>
        </GlobalContextProvider>
    </BrowserRouter>
    
  )
}

export default App