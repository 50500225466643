import { useState, Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { HiX } from 'react-icons/hi'
import { getChartOptionSimChartDetails } from '../charts/PopulationEchartOptions'
import PopulationEcharts from '../charts/PopulationEcharts'
import DotLoader from '../loaders/DotLoader'
import { API_BASE_URL } from "../../utils/constants"

const mapperItem={
	'자녀수' : 'chart info children',
	"합계초혼율" : 'chart info first marriage',
	'새가족 문화' : 'chart info family',
	'국제결혼건수' : 'chart info marriage migrant',
}

function ChartDetailsModal({ isOpen, item, setIsOpen }) {

	const [chartData, setData] = useState(null)
	
	useEffect(() => {
		const loadDefault = async () => {
			let query = `?page_size=all&name_en__sw=chart info`
			let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
			method: "GET",
			headers: { "Content-Type": "application/json" },
			});
			
			//we are expecting an array of objects
			let parseRes = await response.json();

			if (parseRes && parseRes.data.length > 0) {
				let dataSrc = {}

				let var_names = parseRes.data.map(item => { return item.name_en })
				var_names = [...new Set(var_names)]

				var_names.forEach(variable => {

					let dataSet = parseRes.data.filter(row => row.name_en === variable)
					if (dataSet.length > 0) {
						let dataSeries = []
						dataSet.forEach(row => {
							dataSeries.push({
								name: row.name_kr,
								type: 'line',
								lineStyle: {
									type: 'solid',
									width: 1
								},
								smooth: true,
								showSymbol: false,
								data: row.value
							})
						})
						dataSrc[variable] = getChartOptionSimChartDetails(dataSeries)

					}
				})
				
				setData(dataSrc)
			}
		}

		loadDefault();
	}, [])

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-70" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-[500px] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title
									as="h3"
									className="flex items-center"
								>
									<span className="flex-1 text-xl font-bold text-gray-900">{item ? item.display : ''}</span>
									<button onClick={() => setIsOpen(false)} className="hover:text-orange-400"><HiX size={20} /></button>
								</Dialog.Title>
								{
									(chartData && item) ?
										(item.display in mapperItem) ?
											<div className='flex flex-col'>
												<div className='py-5 text-justify overflow-y-auto h-fit max-h-72'>
													{item ? item.info : ''}
												</div>
												<div className='pb-5 text-justify h-64'>
													<PopulationEcharts options={chartData[mapperItem[item.display]]} />
												</div>
											</div>
											:
											<div className='w-full py-5 text-justify'>
												{item ? item.info : ''}
											</div>
										:
										<div className='p-5 text-justify h-full flex justify-center items-center'>
											<DotLoader />
										</div>
								}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}

export default ChartDetailsModal