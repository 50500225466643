import React from 'react'
import { useGlobalStateContext } from "./GlobalContext"
import { getOApiInfo, loadTerms, mergeTerms } from "./PaymentProvider"


const BillingContext = React.createContext()

export const BILLING_PAGES = {
  BILLIN_MAIN: "billing-main",
  BANK_REGISTRATION: "bank-registration",
  OAPI_TERMS: "oapi-terms",
  OAPI_REGISTER: "oapi-register"
}

export const ACTION = {
  SET_FRAME_VIEW: "set-frame-view",
  SET_BILLING_INFO: "set-billing-info",
  SET_TERMS_LOADING: "set-terms-loading",
  SET_TERMS_AGREED: "set-terms-agreed",
  SET_BANK_ACCOUNT: "set-bank-account",
  OAPI_REGISTER_SUCCESS: "oapi-register-success",
  LOAD_BANK_LIST: "load-bank-list"
}

export const initialValue = {
  userInfo: null,
  frameView: BILLING_PAGES.BILLIN_MAIN,
  termsFormLoading: false,
  termsList: [],
  termsAgreed: [],
  bankList: null,
  userSubscribedServices: [],
}



const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_FRAME_VIEW:
      return {...state, frameView: payload};
    case ACTION.SET_BILLING_INFO:
      return {
        ...state, 
        userInfo: {...payload.userInfo},
        termsList: payload.termsList,
        userSubscribedServices: payload.subscribedServices
      }
    case ACTION.SET_TERMS_LOADING:
      return {...state, termsFormLoading: true};
    case ACTION.SET_TERMS_AGREED: {

      return {
        ...state,
        termsList: payload.termsList,
        termsAgreed: payload.termsAgreed,
        termsFormLoading: false,
        frameView: payload.frameView  //needed because one scenario when new TOS was added and redirection is needed to payment information page
      }
    }
    case ACTION.SET_BANK_ACCOUNT:{
      let userInfo = state.userInfo;
      userInfo.oapiInfo.bank_account = payload.bank_account;
      userInfo.oapiInfo.bank_code = payload.bank_code;
      return {...state, userInfo, arsLoading: false };
    }
    case ACTION.OAPI_REGISTER_SUCCESS:
      return {
        ...state,
        userInfo:{...state.user_info, oapiInfo: payload.oapiInfo},
        frameView: payload.frameView
      }
    case ACTION.LOAD_BANK_LIST:
      return {
        ...state,
        bankList: payload
      }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

export const BillingProvider = ({children}) => {
  const {user} = useGlobalStateContext();
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  

  React.useEffect(() => {

    const loadUserInfo = async () => {
      try {

        let [oapiInfo, termsListKonaSD] = await Promise.all([
          getOApiInfo(user),
          loadTerms(user),
        ]);

        

     
        //merge terms from KONASD and OPENAPI
        let termsList = mergeTerms([], termsListKonaSD, "KONASD");
        termsList = mergeTerms(termsList, oapiInfo.terms_list, "OPENAPI");

        dispatch({
          type: ACTION.SET_BILLING_INFO, 
          payload: {
            userInfo:{...user.user_info, oapiInfo}, 
            termsList,
            subscribedServices: []
          }
        })

  
      } catch (err) {
        console.error(err.message)
      }
    }

    //load userInfo (bank_code, bank_account, oapi_terms)
    loadUserInfo();

  },[]);


  const value = {
    billingState: state,
    billingDispatch: dispatch,
  }

  return (
    <BillingContext.Provider value={value}>
      {children}
    </BillingContext.Provider>
  )
}

export default BillingContext