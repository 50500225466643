import { Dialog, Transition } from '@headlessui/react'
import React from "react"
import { ARS_AUTH_STATUS } from "../../utils/constants"
import PaymentContext, {ACTION as PAYMENT_ACTION} from "../context/PaymentProvider"

const ArsModal = ({arsState, closeArs, oapiInfo, bankList}) => {
  const initialFocusRef = React.useRef(null)


  const handleOutsideClose = (status) => {
    if(status === ARS_AUTH_STATUS.ARS_AUTH_IN_PROGRESS || status === ARS_AUTH_STATUS.ARS_AUTH_DONE){
      return;
    }
    else{
      closeArs();
    }
  }

  return (
    <Transition appear show={arsState.arsModalOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => handleOutsideClose(arsState.arsStatus)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="m-w-72 min-h-72  transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                {(() => {
                  if(arsState.arsStatus === ARS_AUTH_STATUS.BANK_ACC_REGISTERED){
                    return (
                      <div className="p-6 flex flex-col w-96 items-center text-center focus:outline-none min-h-[280px]">
                        <div className="w-full flex-1 flex flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                            <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" stopColor="#4ade80" stopOpacity="100%" />
                                <stop offset="100%" stopColor="#3b82f6" stopOpacity="100%" />
                            </linearGradient>
                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                          </svg>
                          <div className="font-semibold text-2xl">계좌가 연결되었습니다.</div>
                          <div className="mt-4 bg-konagray/10 w-full flex flex-col py-4 px-8 rounded-xl space-y-2 text-left">
                            <div className="flex text-base"><div className="flex-1">연결은행</div><div className="font-bold">{bankList[oapiInfo?.bank_code]}</div></div>
                            <div className="flex text-base"><div className="flex-1">계좌번호</div><div className="font-bold">{oapiInfo?.bank_account}</div></div>
                          </div>
                          <div className="text-left text-xs py-4">
                            <p className="font-bold py-1 text-sm">꾹 확인하세요!</p>
                            <p className="p-kr">은행에서 자동이체 관련 문자를 받을 수 있습니다.</p>
                            <p className="p-kr">자동이체가 아닌, 총전 시 1회성 출금에 등록되었다는 뜻이므로 안심하세요. 고객님의 요청 없이 출금/이체를 하지 않습니다.</p>
                          </div>
                        </div>
                        <button onClick={() => handleOutsideClose(arsState.arsStatus)} className="bg-konared text-white inline-flex leading-none rounded px-10 py-3 ripple-primary">확인</button>
                        
                      </div>
                    )
                  }
                  else  if(arsState.arsStatus === ARS_AUTH_STATUS.ARS_AUTH_FAILED){
                    return (
                      <div className="p-6 flex flex-col w-72 items-center justify-center text-center focus:outline-none min-h-[320px]">
                        <div className="flex-1 flex flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient-fail)" className="w-28 h-28">
                            <linearGradient id="linear-gradient-fail" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                              <stop offset="0%" stopColor="#FE0944" stopOpacity="100%" />
                              <stop offset="100%" stopColor="#FEAE96" stopOpacity="100%" />
                            </linearGradient>
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                          </svg>
                          <div className="font-semibold text-2xl">ARS 인증 실패</div>
                          <div className="">
                            <p className="p-kr">ARS 인증이 실패하였습니다.</p>
                          </div>
                        </div>
                        <button onClick={() => handleOutsideClose(arsState.arsStatus)} className="bg-konared text-white inline-flex leading-none rounded justify-center w-20 py-3 ripple-primary">확인</button>
                        
                      </div>
                    )
                  }
                  else  if(arsState.arsStatus === ARS_AUTH_STATUS.BANK_ACC_REG_FAILED){
                    return (
                      <div className="p-6 flex flex-col w-72 items-center justify-center text-center focus:outline-none min-h-[320px]">
                        <div className="flex-1 flex flex-col items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient-fail)" className="w-28 h-28">
                            <linearGradient id="linear-gradient-fail" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                              <stop offset="0%" stopColor="#FE0944" stopOpacity="100%" />
                              <stop offset="100%" stopColor="#FEAE96" stopOpacity="100%" />
                            </linearGradient>
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
                          </svg>
                          <div className="font-semibold text-2xl">계좌 등록에 실패</div>
                          <div className="">
                            <p className="p-kr">계좌 등록이 실패하였습니다.</p>
                            <p className="p-kr">각 은행에서 계좌상태를 확인 후, 다시 시도해주시기 바랍니다.</p>
                          </div>
                        </div>
                        <button onClick={() => handleOutsideClose(arsState.arsStatus)} className="bg-konared text-white inline-flex leading-none rounded justify-center w-20 py-3 ripple-primary">확인</button>
                        
                      </div>
                    )
                  }
                  else{

                    let arsMsg = "";

                    if(arsState.arsStatus === ARS_AUTH_STATUS.ARS_AUTH_IN_PROGRESS){
                      arsMsg = "ARS 인증이 진행 중입니다";
                    }
                    else if(arsState.arsStatus === ARS_AUTH_STATUS.ARS_AUTH_DONE){
                      arsMsg = "계좌를 등록 중입니다";
                    }

                    return (
                      <div className="px-6 py-8 flex flex-col w-72 justify-center text-center focus:outline-none min-h-[320px]" ref={initialFocusRef} tabIndex={-1}>
                        <div className="font-bold text-xl">잠시 후 ARS로 연결됩니다</div>
                        <div className="mt-4 text-base text-konasub/50 leading-none">음성 안내에 따라 아래 두 자리 수를 입력해 주세요</div>
                        <div className="text-[60pt] font-bold text-konared">{arsState.arsInfo?.auth_number}</div>
                        <div className="font-semibold bg-konabg p-2 text-base rounded-lg">휴대폰번호 {arsState.arsInfo?.phone}</div>
                        
                        {(arsState.arsStatus !== null) &&
                          <div className="text-sm text-konared mt-2 flex items-center justify-center">
                            <svg className="animate-spin mr-3 h-4 w-4 text-konared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <span>{arsMsg}</span>
                          </div>
                        }
                        

                      </div>
                    )
                  }
                })()}

                

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ArsModal