import React from 'react'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MainContentWrapper from "../components/MainContentWrapper";
import { CheckIcon } from '@heroicons/react/24/outline'

import {MnoUtil} from "../utils/mno-utils"
import { ACCOUNT_TYPE, API_BASE_URL } from "../utils/constants";
import { BuildingOffice2Icon, UserCircleIcon, PresentationChartLineIcon, DocumentTextIcon,ChartBarIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import MiniLoader from "../components/loaders/MiniLoader";


const cssLoginHeadActive = "border-black border-b-0";
const cssLoginHeadNotActive = "border-gray-200 border-b-black text-gray-400 bg-gray-50";


const validationSchema = Yup.object().shape({
  email: Yup.string()
      .required('이메일 주소를 입력해주세요'),
  password: Yup.string()
      .required('비밀번호를 입력해주세요'),
});

const FindEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSending, setSending] = React.useState(false);
  const [loginType, setLoginType] = React.useState(location?.state?.accountType || ACCOUNT_TYPE.PERSONAL);
 
  const form = useForm(
  {
    defaultValues: {
      email: "",
      password: "",
    },
    mode:"onSubmit", 
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async data => {
    

  } 



  const ErrorMessage = errors => {
    const message = Object.keys(errors).length > 0 ? errors.email ? errors.email.message : errors.password.message : null;
    // const message = errors ? "true" : "false";
    return (
      <div>{message}</div>
    )
  }

  return (
    <MainContentWrapper> 
      
      <div className="py-20 px-48">
        <div className="flex flex-col items-center space-y-8 pb-10">
          <h1 className="text-4xl leading-none font-bold">아이디 찾기</h1>
        </div>

        <div className="w-full max-w-xl mx-auto">
          <div className="flex-1 pr-8">
            <div className="flex text-lg font-bold">
              <div className={`flex-1 flex justify-center items-center space-x-2 py-4 text-center cursor-pointer border ${loginType === ACCOUNT_TYPE.PERSONAL? cssLoginHeadActive : cssLoginHeadNotActive}`} onClick={() => setLoginType(ACCOUNT_TYPE.PERSONAL)}><UserCircleIcon className="w-6" /><span>개인회원</span></div>
              <div className={`flex-1 flex justify-center items-center space-x-2 py-4 text-center cursor-pointer border ${loginType === ACCOUNT_TYPE.CORPORATE? cssLoginHeadActive : cssLoginHeadNotActive}`} onClick={() => setLoginType(ACCOUNT_TYPE.CORPORATE)}><BuildingOffice2Icon className="w-6" /><span>기업회원</span></div>
            </div>
            <form className="flex flex-col" onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex space-x-8 justify-center py-4 mt-4 bg-gray-200 font-semibold text-base">
                <div className="inline-flex space-x-2 items-center">
                  <input type="radio" id="findEmalByEmail" />
                  <label className="" htmlFor="findEmalByEmail">이메일주소</label>
                </div>
                <div className="inline-flex space-x-2 items-center">
                  <input type="radio" id="findEmalByPhone" />
                  <label className="" htmlFor="findEmalByPhone">휴대폰 인증</label>
                </div>
              </div>
              <div className="flex-1 flex flex-col space-y-3 pt-4 pb-8">
                
                <Controller
                  render={({field}) => {
                    return(
                      <input 
                        {...field}
                        tabIndex={1}
                        placeholder="이메일주소"
                        className={`w-full py-3 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                      />
                    )
                  }}
                  name="email"
                  control={form.control}
                />
                <Controller
                  render={({ field}) => {

                    return(
                      <input 
                        {...field} 
                        tabIndex={2}
                        type="password"
                        placeholder="비밀번호"
                        className={`w-full py-3 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                      />
                    )
                  }}
                  name="password"
                  control={form.control}
                />
              </div>  
              <div className="flex justify-center pb-4 -mt-4 text-konared">{isSending ? <MiniLoader className={`h-5 w-5 ${loginType === ACCOUNT_TYPE.PERSONAL ? "text-konared": "text-indigo-600"}`} /> : <ErrorMessage {...form.formState.errors} />}</div>
              <button type="submit" className={`w-full py-4 rounded-xl text-xl text-center text-white font-semibold  ${loginType===ACCOUNT_TYPE.PERSONAL? "ripple-primary": "ripple-blue"}`} disabled={isSending}>아이디 찾기</button>
            </form>
          </div>
        </div>


        
      </div>
    </MainContentWrapper>
  )
}

export default FindEmail