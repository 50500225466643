import React from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { getIndustryModelByIdApi, getIndustrySimulationByIdApi, getIndustrySimulationDataApi } from "../../utils/api/industryApi";
import { ApiError, AppError, InvalidRequestBody, ResponseError } from "../../utils/helpers";


const INDUSTRY_VAR_MAPPER = {

}


const IndustryMySimView = () => {
  const navigate = useNavigate();
  const lc = useLocation();
  const userSelectedSimId = lc?.state?.sim_id || null;
  const globalDispatch = useGlobalDispatchContext();
  const {user} = useGlobalStateContext();
  
  React.useEffect(() => {
    const loadUserSimulation = async () => {
      if(userSelectedSimId !== null){
        try{
          const simulation = await getIndustrySimulationByIdApi(userSelectedSimId, user);

          const [mInfo, modelVariables, modelBaseData] = await Promise.all([
            getIndustryModelByIdApi(simulation.model_id, user),
          ]);

        } catch (err) {
          if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
            const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
            globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
          }
          else{
            console.error(err)
          }
        }
      }
    
    }

    loadUserSimulation();
    //clear browserhistory state so that simulation will not persists when users clicks reload button 
    // navigate(lc.pathname, { replace: true });
  }, [userSelectedSimId]);

  return (
    <div>IndustryMySimView</div>
  )
}

export default IndustryMySimView