import React, { useRef, useReducer, useEffect, useState } from 'react'
import { CardHeader, CardWrapper, CardContent } from '../../components/population/CardWrapper'
import MainContentWrapper from '../../components/MainContentWrapper'
import DotLoader from '../../components/loaders/DotLoader'
import PopulationEcharts from '../../components/charts/PopulationEcharts'
import { getChartOptionSimAge, getChartOptionLineForecast } from '../../components/charts/PopulationEchartOptions'
import { BsFillQuestionCircleFill } from 'react-icons/bs';
import ChartDetailsModal from '../../components/modals/ChartDetailsModal';
import { useLocation, useNavigate } from "react-router-dom";
import LoadingModal from "../../components/modals/LoadingModal"
import { BiReset } from 'react-icons/bi'


function PopReportPreview() {


	return (
		<MainContentWrapper className="bg-konabg">
			<div className="flex flex-col bg-white py-10 px-28 text-2xl">
				Test
			</div>

		</MainContentWrapper>

	)

}

export default PopReportPreview