import React from 'react'
import PaymentContext, {ACTION as PAYMENT_ACTION, PAYMENT_PAGES} from "../context/PaymentProvider";
import {MnoUtil} from "../../utils/mno-utils"
import { bearerAuth, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { API_BASE_URL } from "../../utils/constants";
import { useGlobalStateContext } from "../context/GlobalContext";

export const ACTION = {
  START_MNO_VERIFICATION: 'start-mno-verification',
  VERIFICATION_SUCCESS: 'verification-success',
  VERIFICATION_FAILED: 'verification-failed',
}

export const initialValue = {
  isLoading: false,
  verificationLoading: false,
  errorMsg: null,
  isVerified: false,
  requestId: null,
  requestToken: null,
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.START_MNO_VERIFICATION:
      return {...state, verificationLoading: true, isVerified: false, errorMsg: null};
    case ACTION.VERIFICATION_SUCCESS:
      return {
        ...state, 
        verificationLoading: false, 
        isVerified: true, 
        errorMsg: null,
      };
    case ACTION.VERIFICATION_FAILED:
      return {...state, verificationLoading: false, isVerified: false, errorMsg: payload.errorMsg};
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}


const KonaOApiRegister = () => {
  const {user} = useGlobalStateContext();
  const {paymentState, paymentDispatch} = React.useContext(PaymentContext);
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  
  const handleMnoVerification = async () => {
    try {
      dispatch({type: ACTION.START_MNO_VERIFICATION})

      const requestPayload = {accountType: user.user_info.account_type, reqSourceUrl: window.location.href.split('?')[0]}
      await MnoUtil.verify(requestPayload, onSuccess, onFailure);

    } catch (err) {
      console.error(err.message)
    }
  }


  const onSuccess = (requestPayload) => {

    fetch(`${API_BASE_URL}/api/payment/register-oapi`, {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        request_id: requestPayload.requestId, 
        request_token: requestPayload.requestToken,
        terms_list: paymentState.termsAgreed
      })
    })
    .then(response => {
      
      

      if ([400,403].includes(response.status)) {
        return response.json().then(errJson => { throw new ResponseError(errJson.detail); })
      }
      if (response.status === 422) {
        return response.json().then(errJson => { throw new InvalidRequestBody(errJson.detail); })
      }

      if(response.ok){
        return response.json();
      }

    })
    .then(data => {
      const oapiInfo = data;
      paymentDispatch({
        type: PAYMENT_ACTION.OAPI_REGISTER_SUCCESS, 
        payload: {oapiInfo, frameView: PAYMENT_PAGES.BANK_REGISTRATION}
      })
    })
    .catch(err => {
      if (err instanceof ResponseError) {
        dispatch({type: ACTION.VERIFICATION_FAILED, payload: {errorMsg: err.message}});
        console.error("ResponseError", err.message);
      } else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      } else {
        throw(err);
      }
    });  

  }
  
  const onFailure = (msg) => {
    dispatch({type: ACTION.VERIFICATION_FAILED, payload: {errorMsg: msg}});
    // form.setError('isVerified', { type: 'custom', message: msg }, { shouldFocus: true });
  }


  return (
    <div className="flex flex-col">
      <div className="font-bold text-2xl text-center">통신사 인증</div>
      <div className="mt-4 bg-konabg text-center p-4 min-h-[88px] flex items-center justify-center">아래 버튼을 클릭하여 확인 절차를 완료하십시오.</div>

      <div className="flex flex-col mt-8 items-center">
        
        {state.errorMsg !== null && <div className="text-sm font-semibold text-konared mb-4">{state.errorMsg}</div>}
        {/* <button onClick={()=> handleMnoVerification()} type="button" className="h-[70px] px-10 mb-4 border border-konared text-konared text-xl enabled:hover:bg-red-50 disabled:border-gray-300 disabled:text-gray-300" disabled={state.verificationLoading}>
          {state.verificationLoading && 
            <svg className="animate-spin mr-3 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          }
          <span>휴대폰 본인 인증</span>
        </button> */}
        {/* {!state.verificationLoading && <button type="button" onClick={() => paymentDispatch({type: PAYMENT_ACTION.SET_FRAME_VIEW, payload: PAYMENT_PAGES.PAYMENT_DETAILS})} className="text-sm ">Cancel</button>} */}
        
        {
            (() => {
              if(!state.verificationLoading) {
                return (
                  <>
                  <button onClick={()=> handleMnoVerification()} type="button" className="h-[70px] px-10 mb-4 border border-konared text-konared text-xl enabled:hover:bg-red-50 disabled:border-gray-300 disabled:text-gray-300" disabled={state.verificationLoading}>
                    <span>휴대폰 본인 인증</span>
                  </button>
                  <button type="button" onClick={() => paymentDispatch({type: PAYMENT_ACTION.SET_FRAME_VIEW, payload: PAYMENT_PAGES.PAYMENT_DETAILS})} className="text-sm ">Cancel</button>
                  </>
                )
              } else if (state.verificationLoading) {
                return (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-konared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="text-xl text-konared mt-4">본인인증을 확인 중입니다</span>
                  </>
                )
              }
            })()
          }


        {/* <div className="flex space-x-2 justify-center items-center">
          {
            (() => {
              if(state.isVerified) {
                return (
                  <>
                    <div className="inline-flex items-center justify-center h-10 w-10 bg-konasuccess rounded-full p-1"><CheckIcon className="text-white" /></div>
                    <span className="text-xl text-konasuccess">인증 완료되었습니다</span>
                  </>
                )
              } else if (!state.isVerified && state.verificationLoading) {
                return (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span className="text-xl text-gray-400">본인인증을 확인 중입니다</span>
                  </>
                )
              }
            })()
          }
          
        </div> */}

        {/* {(() => {
          if(paymentState.userInfo.oapiInfo.terms_list.length > 0){
            return(
              <>

                {paymentState.userInfo.oapiInfo.terms_list.filter(item => item.is_agree === false).map((item, index) => {
                    return (
                      <TermsInput name={`t_${item.id}`} control={form.control} termInfo={item} key={index} />
                    );
                  })
                }
              </>
            )

            
          }
          else{
            //TODO add loader or error if TOS was not loaded successfully
          }
        })()} */}
      </div>


      <div className="flex flex-col space-y-2 py-10 items-center">
        {/* <div className="text-sm font-semibold text-konared"><ErrorMessage {...form.formState.errors} /></div>
        <button  type="submit" className="bg-konared text-white inline-flex items-center justify-center w-48 leading-5 rounded py-4 ripple-primary" disabled={paymentState.termsFormLoading}>
          {paymentState.termsFormLoading && 
            <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          }
          <span>동의하고 계속하기</span>
        </button> */}

        
      </div>

    </div>
  )
}

export default KonaOApiRegister