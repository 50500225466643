import React from 'react'
import { NavLink } from 'react-router-dom';
import GoToTop from "../utils/go-top";

const Footer = () => {
  return (
    <footer className="">
      <GoToTop />
      <div className="py-4 px-24 border-t">
        <div className="flex divide-x font-semibold">
          <div className="pr-8"><a className="hover:underline underline-offset-4" target="_blank" rel="noopener noreferrer" href="https://ksd-contents.konasd.com/WEB/tos/20221212/01-01-20221212.pdf">서비스 이용약관</a></div>
          <div className="px-8"><a className="hover:underline underline-offset-4" target="_blank" rel="noopener noreferrer" href="https://ksd-contents.konasd.com/WEB/tos/20221212/01-03-20221212.pdf">개인정보처리방침</a></div>
          <div className="px-8"><NavLink to="/re-about" className="hover:underline underline-offset-4">부동산 예측 서비스 소개</NavLink></div>
          <div className="px-8"><NavLink to="/population" className="hover:underline underline-offset-4">인구 예측 서비스 소개</NavLink></div>
        </div>
      </div>
      <div className="py-8 px-24 bg-[#14181F] text-white flex">
        <div className="flex-1">
        <div className=""><img className="h-4" src={require("../assets/images/konai_logo_darkbg.svg").default} alt="" /></div>
          <div className="flex flex-col mt-6">
            <span>코나아이(주) 서울특별시 영등포구 은행로 3 (익스콘벤처타워) 8층</span>
            <div className="flex space-x-8">
              <span className="pl-0">대표: 조정일</span>
              <span>사업자등록번호: 109-81-53365</span>
              <span>개인정보관리자 : 김나나</span>
            </div>
            <div>COPYRIGHT &copy; 코나아이(주). ALL RIGHTS RESERVED.</div>
          </div>
        </div>
        
        <div className="flex items-center space-x-8">
          <div className="flex flex-col space-y-2">
            <div className="flex flex-col">
              <div className="inline-flex">
                <span className="w-16">평일</span>
                <span>09:00 ~ 17:00</span>
              </div>
              <div className="inline-flex text-gray-400 text-xs">
                <span className="w-16"></span>
                <span>점심시간 11:00~12:00과 주말 및 공휴일 제외</span>
              </div>
            </div>
            <div className="inline-flex">
              <span className="w-16">문의</span>
              <span>ikyjeong@naver.com</span>
            </div>
          </div>
        </div>
      </div>
     
      

    </footer>
  )
}

export default Footer