import React from 'react'
import { BillingProvider } from "../../context/BillingProvider"
import BillingContent from "./BillingContent"

const Billing = () => {
  return (
    <BillingProvider>
      <BillingContent />
    </BillingProvider>
  )
}

export default Billing