import React from 'react'
import { Map, MapMarker, CustomOverlayMap } from "react-kakao-maps-sdk"

//KONAI coordinates
const xyDefault = {
  lng: 126.919665809662,
  lat: 37.5275117444834,
}

const SearchkakaoMap = ({location, height, zoomLvl=3}) => {
  return (
    <>
    <Map // 지도를 표시할 Container
        center={{
          lng: location.address?.xCrdnt || xyDefault.lng,
          lat: location.address?.yCrdnt || xyDefault.lat,
        }}
        style={{width: "100%",height: height,}}
        level={zoomLvl} // 지도의 확대 레벨
    >
        <MapMarker position={{ lat: location.address?.yCrdnt, lng: location.address?.xCrdnt}} />
        <CustomOverlayMap position={{ lat: location.address?.yCrdnt, lng: location.address?.xCrdnt}} yAnchor={0} >
        <div className="bg-white font-bold p-4 rounded-lg translate-y-2 shadow-md">
            <span className="title">{location.info?.resultAmt.toLocaleString()}원</span>
        </div>
        </CustomOverlayMap>
      </Map>
    </>
  )
}

export default SearchkakaoMap