import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext } from 'react'
import {HiX} from 'react-icons/hi'
import { getChartOptionUpEventInfo, getChartOptionDownEventInfo } from "../charts/EchartOptions"
import Echarts from "../charts/Echarts"
import { useReStateContext, varNameAptPriceIndexIn } from "../context/RealEstateContext"



const EventInfoModal = ({ isOpen, setIsOpen, eventType }) => {
  const reState = useReStateContext();
  const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];
  const aptIndexData = modelData.variable[varNameAptPriceIndexIn].data_in[0].value;

  switch (eventType) {
    case "EA":
      var title = "부동산 상승 이벤트";
      break;
    case "EB":
      var title = "부동산 하락 이벤트";
      break;
    default:
      console.error(`Invalid event type ${eventType}.`);
  }
  

  const renderSwitch = (eventType) => {
    switch(eventType) {
      case "EA":
        title = "부동산 상승 이벤트";
        return (
          <div className="flex flex-col space-y-3 mt-3 overflow-y-auto max-h-[900px]">
            <p className="p-kr text-justify text-base">사용자가 판단하는 미래 가격상승 요인을 반영하여 시뮬레이션 할 수 있음 상승 요인 반영 값은 0과 1사이의 값으로 입력</p>
            <p className="p-kr text-justify text-base">예로, 2019년부터 급등한 아파트 과열현상을 과거 추세와 비교하여 산출한 상승지수 수준은 서울 평균 0.14, 서울 강남은 0.28 그리고 서울 강북은 0.1로 분석되었음</p>
            <div className="h-[180px]"><Echarts options={getChartOptionUpEventInfo(aptIndexData)} /></div>
          </div>
        );
      case "EB":
        title = "부동산 하락 이벤트";
        return (
          <div className="flex flex-col space-y-3 mt-3 overflow-y-auto max-h-[900px]">
            <p className="p-kr text-justify text-base">사용자가 판단하는 미래 가격하락 요인을 반영하여 시뮬레이션 할 수 있음 하락 요인 반영 값은 0과 1사이의 값으로 입력</p>
            <p className="p-kr text-justify text-base">예로, 2017년 수차례의 부동산규제 정책발표로 하락한 아파트 하락현상을 과거 추세와 비교하여 산출한 하락지수 수준은 서울 평균 0.1, 서울 강남은 0.3 그리고 서울 강북은 0.7로 분석되었음</p>
            <div className="h-[180px]"><Echarts options={getChartOptionDownEventInfo(aptIndexData)} /></div>
          </div>
        );
      default:
        throw new Error("Invalid event type.");
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-md  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex items-center"
                >
                  <span className="flex-1 text-xl font-bold text-gray-900">{title}</span>
                  <button onClick={() => setIsOpen(false)} className="hover:text-orange-400"><HiX size={20} /></button>
                </Dialog.Title>
                
                <div className="flex flex-col space-y-3 w-full py-4">
                  {renderSwitch(eventType)}
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default EventInfoModal