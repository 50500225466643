import React from 'react'
import { useLocation, useNavigate } from "react-router-dom"
import { ACCOUNT_TYPE, API_BASE_URL, TOS_POSITION } from "../../utils/constants"

const RegistrationContext = React.createContext()




export const ACTION = {
  INIT_TOS: 'init-tos',
  SET_TOS: 'set-tos',
  START_MNO_VERIFICATION: 'start-mno-verification',
  VERIFICATION_SUCCESS: 'verification-success',
  VERIFICATION_FAILED: 'verification-failed',
  SUBMIT_REG_FORM_PERSONAL: 'submit-reg-form-personal',
  SUBMIT_REG_FORM_CORPORATE: 'submit-reg-form-corporate',
  REGISTRATION_SUCCESS: 'registration-success',
  SET_REG_TYPE: 'set-reg-type',
  SET_LOCATION_STATE: 'set-location-state'
  //TODO: registration fail
}

export const initialValue = {
  regPersonalTerms: [],
  regCorporateTerms: [],
  terms_agreed: [],
  name: "",
  email: "",
  password: "",
  bizRegNo: "",
  verificationLoading: false,
  isVerified: false,
  isRegSubmitLoading: false,
  requestId: null,
  requestToken: null,
  isRegSuccess: false, 
  regType: null,
  locationState: null
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_LOCATION_STATE:
      return {...state, locationState: payload.locationState, regType: payload.regType}
    case ACTION.SET_REG_TYPE:
      return {...state, regType: payload}
    case ACTION.INIT_TOS: 
      const personalTerms = payload.filter(val => val.position_type === TOS_POSITION.REG_PERSONAL);
      const corporateTerms = payload.filter(val => val.position_type === TOS_POSITION.REG_CORPORATE);
      return {...state, regPersonalTerms: personalTerms, regCorporateTerms: corporateTerms};
    case ACTION.SET_TOS:
      return {...state, terms_agreed:payload};
    case ACTION.START_MNO_VERIFICATION:
      return {...state, verificationLoading: true, isVerified: false};
    case ACTION.VERIFICATION_SUCCESS:
      return {
        ...state, 
        verificationLoading: false, 
        isVerified: true, 
        requestId: payload.requestId,
        requestToken: payload.requestToken
      };
    case ACTION.VERIFICATION_FAILED:
      return {...state, verificationLoading: false, isVerified: false};
    case ACTION.SUBMIT_REG_FORM_PERSONAL:
      return {
        ...state,
        name: payload.name,
        email: payload.email,
        password: payload.password,
        isRegSubmitLoading: true,
      };
    case ACTION.SUBMIT_REG_FORM_CORPORATE:
      return {
        ...state,
        bizRegNo: payload.bizRegNo,
        email: payload.email,
        password: payload.password,
        isRegSubmitLoading: true,
      };
    case ACTION.REGISTRATION_SUCCESS:
      return {
        ...initialValue,  // reset everything to initial value
        locationState: state.locationState, //do not reset locationState
        isRegSuccess: true
      }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

export const RegistrationProvider = ({children}) => {
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const location = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {

    const loadTerms = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {"Content-Type" : "application/json"} 
        };
        const query = `?page_size=all&is_active=true&position_type__in=${TOS_POSITION.REG_PERSONAL},${TOS_POSITION.REG_CORPORATE}&sort=order`
        const response = await fetch(`${API_BASE_URL}/api/terms${query}`,requestOptions);
        const terms = await response.json();

        if(response.ok && terms.total_count > 0){
          dispatch({type: ACTION.INIT_TOS, payload: terms.data})
        }
  
      } catch (err) {
        console.error(err.message)
      }
    }

    if(location?.state && location.state?.accountType){
      dispatch({type: ACTION.SET_LOCATION_STATE, payload: {locationState: location.state, regType: location.state?.accountType}})
      if(location.state?.accountType === ACCOUNT_TYPE.PERSONAL){
        navigate("/register/preg-terms");
      }
      else if (location.state?.accountType === ACCOUNT_TYPE.CORPORATE){
        navigate("/register/creg-terms");
      }
    }
    //load terms and condition

    loadTerms();



  },[]);


  const value = {
    registrationState: state,
    registrationDispatch: dispatch,
  }
  return (
    <RegistrationContext.Provider value={value}>
      {children}
    </RegistrationContext.Provider>
  )
}

export default RegistrationContext