import React from 'react'
import { useForm, Controller, useController} from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import zxcvbn from 'zxcvbn';
import RegistrationContext, {ACTION as REG_ACTION, initialValue as regDefault} from "../context/RegistrationProvider";
import { CheckIcon } from '@heroicons/react/24/outline'
import {MnoUtil} from "../../utils/mno-utils"
import { ACCOUNT_TYPE, API_BASE_URL } from "../../utils/constants";
import RegSteps from "./RegSteps";
import { InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { PatternFormat } from "react-number-format";

const validationSchema = Yup.object().shape({
  bizRegNo: Yup.string()
      .required('사업자 등록번호을 입력해 주세요')
      .matches(/^\d{10}$/ugi, "유효하지 않은 사업자번호입니다."),
  email: Yup.string()
    .email('적합한 이메일 형식이 아닙니다')
    .required('이메일 주소를 입력해주세요'),
  isVerified: Yup.bool()
    .oneOf([true], "통신사 인증을 완료되지 않았습니다."),
  password: Yup.string().required('비밀번호를 입력해주세요')
  .min(8, null),
  confirmpass: Yup.string()
    .required('비밀번호를 다시 입력해주세요')
    .oneOf([Yup.ref('password'), null], '암호가 일치하지 않습니다'),
});

const CorporateRegInfo = () => {
  const navigate = useNavigate();
  const {registrationState, registrationDispatch} = React.useContext(RegistrationContext);
  const {bizRegNo, email, isVerified, password, confirmpass=""} = regDefault;
  const preloadedValues = {bizRegNo, email, isVerified, password, confirmpass};
  const passReq = { minStrength : 3, thresholdLength : 7 }

  const form = useForm({defaultValues: preloadedValues, mode:"onTouched", resolver: yupResolver(validationSchema)});


  React.useEffect(() => {

    if(registrationState.regType !== ACCOUNT_TYPE.CORPORATE){
      navigate("/register");
    }

  },[]);

  const onSubmit = async data => {
    try {
      
      await checkUserByEmail(data.email);
      registrationDispatch({type: REG_ACTION.SUBMIT_REG_FORM_CORPORATE, payload: {bizRegNo: data.bizRegNo, email: data.email, password: data.password}});
      navigate("/register/creg-proc");
    } 
    catch (err) {
      if (err instanceof ResponseError) {
        const errObj = JSON.parse(err.message)
        if (errObj.error_code === 406){
          form.setError('email', { type: 'custom', message: errObj.msg }, { shouldFocus: true });
        }
        else{
          form.setError('email', { type: 'custom', message: "이메일 확인 중에 오류가 발생했습니다. 관리자에게 문의해주세요: ikyjeong@naver.com" }, { shouldFocus: true });
        }
      }
      else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      }
      else {
        throw(err)
      }
    }

  } 


  

  const validatePasswordStrong = value => {
		if (value.length <= passReq.thresholdLength) return "비밀번호는 8자리 이상 입력해주세요";
		if (zxcvbn(value).score < passReq.minStrength) return "암호가 약합니다";
	};

  const InputPassword = ({ control, name }) => {
    const {field, fieldState:{error, isDirty}} = useController({name, control});
    const passwordScore = zxcvbn(field.value).score;
    const passwordLength = field.value.length;
    const passwordStrong = passwordScore >= passReq.minStrength;
		const passwordLong = passwordLength > passReq.thresholdLength;

    const counterClass = ['inline-flex items-center justify-center text-white text-lg absolute my-auto top-0 bottom-0 right-4 h-[40px] min-w-[50px] rounded-full px-3', passwordLong ? passwordStrong ? 'bg-konasuccess' : 'bg-konawarning' : 'bg-konadanger', field.value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
    const swrapClass = ['flex h-1 mt-4 divide-x-8 divide-white bg-gray-300 -ml-2 relative', field.value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
    
    //this code structure is intentional so that tailwid can purge the corresponding classes
    const styleSelector = [
      "w-[20%] bg-[#8b0000]",
      "w-[40%] bg-[#ff4500]",
      "w-[60%] bg-[#ffa500]",
      "w-[80%] bg-[#9acd32]",
      "w-[100%] bg-[#008000]",
    ]
    const strengthClass = ['h-1 absolute z-0 bg-konared transition-[width] duration-700', styleSelector[passwordScore]].join(' ').trim();

    let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
    if(isDirty && passwordStrong) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
    if(isDirty && (error || !passwordStrong)) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
    

    return (
      <div className="flex flex-col">
        <div className="relative flex">
          <input 
            {...field} 
            type="password"
            placeholder="영문(대소문자), 숫자, 특수문자 포함 8자리 이상"
            className={`w-full px-8 py-4 rounded-xl border text-xl  outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
          />
          <span className={counterClass}>{ passwordLength ? passwordLong ? `${passReq.thresholdLength}+` : passwordLength : '' }</span>
        </div>
        
        {field.value.length > 0 &&
          <div className={swrapClass}>
            <div className={strengthClass}></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
            <div className="flex-1 z-10"></div>
          </div>
        }
        
        {((field.value.length > 0) || error) && <span className="text-red-500 mt-4">{(field.value.length > 0) ? validatePasswordStrong(field.value) : error?.message}</span>}
        
      </div>
    );
  }


  const handleMnoVerification = async () => {
    try {
      registrationDispatch({type: REG_ACTION.START_MNO_VERIFICATION})
      
      const requestPayload = {accountType: ACCOUNT_TYPE.CORPORATE, reqSourceUrl: window.location.href.split('?')[0]}
      await MnoUtil.verify(requestPayload, onSuccess, onFailure);
    } catch (err) {
      console.error(err.message)
    }
  }




  const onSuccess = (requestPayload) => {
    const {requestId, requestToken} = requestPayload;
    registrationDispatch({type: REG_ACTION.VERIFICATION_SUCCESS, payload: {requestId, requestToken}});
    form.setValue("isVerified", true, { shouldTouch: true, shouldDirty: true, shouldValidate: true  });
  }
  
  const onFailure = (msg) => {
    registrationDispatch({type: REG_ACTION.VERIFICATION_FAILED});
    form.setError('isVerified', { type: 'custom', message: msg }, { shouldFocus: true });
  }

  /*TODO: move inside form submission for security. */
  const checkUserByEmail = async (email) => {
    try {
      const requestOptions = {
        method: 'GET',
        headers: {"Content-Type" : "application/json"} 
      };

      const query = `?email=${email}`
      const response = await fetch(`${API_BASE_URL}/api/common/check-user-byemail${query}`,requestOptions);
      const res = await response.json();

      if ([400, 406, 403].includes(response.status)) throw new ResponseError(JSON.stringify({error_code: response.status, msg: res.detail}));
      if (response.status === 422) throw new InvalidRequestBody(res.detail);
      if (!response.ok) throw new Error(res.detail);

    } catch (err) {
      throw(err)
    }
  } 
  

  return (
    <div className="py-20 px-48">
      <div className="flex flex-col items-center space-y-8 pb-10">
        <h1 className="text-4xl leading-none font-bold">기업 회원정보 입력</h1>
        <RegSteps prevStep={1} nextStep={2} />
      </div>

      <div className="w-full max-w-4xl mx-auto">
        <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
          
            <div className="flex flex-col">

            <div className="flex px-4 py-5 border-t border-t-konagray border-b border-gray-300">
                <div className="w-60">
                  <div className="font-semibold text-xl"><span>사업자 등록번호</span><span className="ml-1 text-konared">*</span></div>
                </div>
                <div className="flex-1">
                  <Controller
                      render={({ field, fieldState: {isDirty, error}}) => {

                        let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                        if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                        if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                        return(
                          <div className="flex flex-col">
                           
                            <input 
                              {...field} 
                              autoFocus
                              placeholder="사업자 등록번호 입력 (10자리)"
                              onChange={(e) => field.onChange(e.target.value.replace(/[^\d]/g, ''))}
                              maxLength={10}
                              className={`uppercase w-full py-4 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
                            />
                            {error && <span className="text-red-500 mt-4">{error?.message}</span>}
                          </div>
                        )
                      }}
                      name="bizRegNo"
                      control={form.control}
                    />
                </div>
              </div>
              

              <div className="flex px-4 py-5 border-b border-gray-300">
                <div className="w-60">
                  <div className="font-semibold text-xl"><span>담당자 본인 인증</span><span className="ml-1 text-konared">*</span></div>
                </div>
                <div className="flex-1">
                  <Controller
                      render={({ field, fieldState: {isDirty, error}}) => {

                        let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                        if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                        if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                        return(
                          <div className="flex flex-col">
                            <div className="flex items-center">
                            <input className="hidden" type="checkbox" {...field} />
                            <button onMouseDown={e => e.preventDefault()}  onClick={()=> handleMnoVerification()} type="button" className="flex-initial w-1/2 py-4 px-8 rounded-xl border border-konared text-konared text-xl enabled:hover:bg-red-50 disabled:border-gray-300 disabled:text-gray-300" disabled={registrationState.isVerified}>휴대폰 본인 인증</button>
                            {
                              (() => {
                                if(registrationState.isVerified) {
                                  return (
                                    <div className="flex-1 flex space-x-2 ml-8 items-center">
                                      <div className="h-10 w-10 bg-konasuccess rounded-full p-1"><CheckIcon className="text-white" /></div>
                                      <span className="text-xl text-konasuccess">인증 완료되었습니다</span>
                                    </div>
                                  )
                                } else if (!registrationState.isVerified && registrationState.verificationLoading) {
                                  return (
                                    <div className="flex-1 flex space-x-2 ml-8">
                                      <svg className="animate-spin h-7 w-7 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                      </svg>
                                      <span className="text-xl text-gray-400">본인인증을 확인 중입니다</span>
                                    </div>
                                  )
                                }
                              })()
                            }
                            </div>
                            {error && <span className="text-red-500 mt-4">{error?.message}</span>}
                          </div>
                        )
                      }}
                      name="isVerified"
                      control={form.control}
                    />
                </div>
              </div>



              <div className="flex px-4 py-5 border-b border-gray-300">
                <div className="w-60">
                  <div className="font-semibold text-xl"><span>이메일</span><span className="ml-1 text-konared">*</span></div>
                </div>
                <div className="flex-1">
                  <Controller
                      render={({ field, fieldState: {isDirty, error}}) => {

                        let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                        if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                        if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                        return(
                          <div className="flex flex-col">
                            <input 
                              {...field} 
                              placeholder="이메일 주소 입력 해주세요"
                              className={`w-full py-4 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
                            />
                            {error && <span className="text-red-500 mt-4">{error?.message}</span>}
                          </div>
                        )
                      }}
                      name="email"
                      control={form.control}
                    />
                </div>
              </div>



              <div className="flex px-4 py-5 border-b border-gray-300">
                <div className="w-60">
                  <div className="font-semibold text-xl"><span>비밀번호</span><span className="ml-1 text-konared">*</span></div>
                </div>
                <div className="flex-1">
                  <InputPassword name="password" control={form.control} />
                </div>
              </div>

              <div className="flex px-4 py-5 border-b border-gray-300">
                <div className="w-60">
                  <div className="font-semibold text-xl"><span>비밀번호 확인</span><span className="ml-1 text-konared">*</span></div>
                </div>
                <div className="flex-1">
                  <Controller
                    render={({ field, fieldState: {isDirty, error}, formState : {isSubmitted, isValidating} }) => {
                        let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                        if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                        if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                      return(
                        <div className="flex flex-col">
                          <input 
                            {...field} 
                            type="password"
                            placeholder="비밀번호 다시 입력해주세요"
                            className={`w-full py-4 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4  ${formPlusStyle}`}
                          />
                          {error && <span className="text-red-500 mt-4">{error?.message}</span>}
                        </div>
                      )
                    }}
                    name="confirmpass"
                    control={form.control}
                  />
                </div>
              </div>




              <div className="pt-10">
                <div className="flex justify-center space-x-5">
                  <NavLink 
                    onMouseDown={e => e.preventDefault()} 
                    to={registrationState.locationState ? registrationState.locationState?.prevPath : "/" }
                    state={registrationState.locationState} 
                    className="w-[180px] py-4 rounded-xl text-xl text-center font-semibold ripple-gray-outline"
                  >가입 취소
                  </NavLink>
                  <button type="submit" className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">다음 단계</button>
                </div>
              </div>

            </div>


          

        </form>
      </div>


      
    </div>
    
  )
}

export default CorporateRegInfo