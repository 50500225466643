import React from 'react'
import { isEmpty } from "lodash";
import SearchkakaoMap from "../../components/realestate/SearchKakaoMap";
import { getAddressTxt, getKonaSimResults, getRealEstateModelBaseDataObj, getRealEstateModelVariablesObj, getRealEstateSubscript, RE_ACTION, useReDispatchContext, useReStateContext } from "../../components/context/RealEstateContext";
import MainContentWrapper from "../../components/MainContentWrapper";
import Loader3 from "../../components/loaders/Loader3";
import { useLocation, useNavigate } from "react-router-dom";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { ApiError, AppError, bearerAuth, customRound, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { downloadRealEstateMySimReportApi, getRealEstateDataOutWithVarinfo, getRealEstateModelByIdApi, getRealEstateSimulationByIdApi } from "../../utils/api/realestateApi";
import Echarts from "../../components/charts/Echarts";
import { getChartOptionsMySim } from "../../components/charts/EchartOptions";
import { MapPinIcon } from "@heroicons/react/24/solid";
import EvaluationMySim from "../../components/realestate/EvaluationMySim";
import { DocumentTextIcon } from "@heroicons/react/24/outline";
import TableMySim from "../../components/realestate/TableMySim";
import LoadingModalWithText from "../../components/modals/LoadingModalWithText";
import { SIM_EVENT_PARAMETERS, SIM_PARAMETERS } from "../../components/context/ReMysimContext";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

export const ACTION = {
  ADD_SIM_RESULT: "add-sim-result",
}
const initialValue = {
  simResultsGroup: {},
  modelData: null,
  modelInfo: null
}

function reducer(state, {type, payload}){
  switch(type){
    case ACTION.ADD_SIM_RESULT: {
      const [key, val] = Object.entries(payload.simResultsGroup)[0];
      var simResultsGroup;
      if (key in state.simResultsGroup){
        simResultsGroup = {...state.simResultsGroup, [key]: {...state.simResultsGroup[key], simResults: {...state.simResultsGroup[key].simResults, ...val.simResults}} };
      }
      else{
        simResultsGroup = {...state.simResultsGroup, ...payload.simResultsGroup};
      }

      return {...state, simResultsGroup, modelData: payload.modelData, modelInfo: payload.modelInfo};
    }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

const RealEstateMySimView = () => {
  const navigate = useNavigate();
  const lc = useLocation();
  const userSelectedSimId = lc?.state?.sim_id || null;
  const reState = useReStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const {user} = useGlobalStateContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [state, dispatch] = React.useReducer(reducer, initialValue);

  

  React.useEffect(() => {
    const loadUserSimulation = async () => {
      if(userSelectedSimId !== null){
        try{
  
          const simulation = await getRealEstateSimulationByIdApi(userSelectedSimId, user);

          let modelData = null;
          let modelInfo = null;
          if(simulation.model_id in reState.model.byId && simulation.loc_code in reState.model.byId[simulation.model_id]?.data.byId){
            modelData = reState.model.byId[simulation.model_id]?.data.byId[simulation.loc_code];
            modelInfo = reState.model.byId[simulation.model_id]?.info
          }
          else{
            const [mInfo, modelVariables, modelBaseData] = await Promise.all([
              getRealEstateModelByIdApi(simulation.model_id, user),
              getRealEstateModelVariablesObj(simulation.model_id, simulation.loc_code, user),
              getRealEstateModelBaseDataObj(simulation.model_id, simulation.loc_code, user)
            ]);
        
            modelInfo = mInfo;
            modelData = {
              locationCode: simulation.loc_code,
              variable: modelVariables,
              baseData: modelBaseData,
              subscript: getRealEstateSubscript(modelVariables)
            }

          }

          const currentAddress = simulation.address_info;
          const simResultGroupKey = currentAddress.address.innb+currentAddress.dong+currentAddress.ho;
          const simOutput = await getRealEstateDataOutWithVarinfo(simulation.id, user);
          const outputData = Object.fromEntries(simOutput.map(e => [e.variable.name_en.split("[")[0], e.value]));
          const konasimResult = getKonaSimResults(modelData.variable, modelData.baseData, simulation.address_info);
          const simResultsGroup = {
            [simResultGroupKey]: {
              konasimResult: konasimResult, 
              address: currentAddress, 
              simResults: {
                [simulation.id]: {outputData: outputData, simInfo: simulation }
              }
            }};
          
          dispatch({type: ACTION.ADD_SIM_RESULT, payload: {simResultsGroup, modelData, modelInfo}})
          
        } catch (err) {
          if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
            const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
            globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
          }
          else{
            console.error(err)
          }
        }
      }
    
    }

    loadUserSimulation();
    //clear browserhistory state so that simulation will not persists when users clicks reload button 
    // navigate(lc.pathname, { replace: true });
  }, [userSelectedSimId]);


  const downloadReport = async () => {
    setIsOpen(true)
    //get list of simulation result ids
    

    try{
      const simIds = Object.entries(state.simResultsGroup).map(([key, {simResults}]) => Object.entries(simResults).map(([key, {simInfo}]) => simInfo.id)).flat();

      await downloadRealEstateMySimReportApi(simIds, user)
    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }finally{
      setIsOpen(false)
    }

  }

  
  
  const dateArr = React.useMemo(() => {
    if(!isEmpty(state.modelInfo)){

      var dateArr = Array.from(moment.rangeFromInterval('month', state.modelInfo.end_time, state.modelInfo.data_in_start).by("months"));
      dateArr = dateArr.map(d => d.format('YYYY-MM')); //TODO: revise format letter to have leading zeroes for month
      return dateArr;
    }

		return null;
  }, [state.modelInfo]); 


  const [target, setTarget] = React.useState(null);
  const [isIntersect, setIntersect] = React.useState(true);
  const onIntersect = async ([entry], observer) => {
    setIntersect(entry.isIntersecting);
  };

  React.useEffect(() => {
    const observer = new IntersectionObserver(onIntersect, {
      root: null,
      rootMargin: "-110px 0px 0px 0px",
      threshold: 1
    });
    if (target) observer.observe(target);

    return () => observer && observer.disconnect();
  }, [target]);




  return (
    <MainContentWrapper className="bg-konabg flex flex-col">
        <LoadingModalWithText isOpen={isOpen}>
          <DocumentTextIcon  className="animate-bounce h-14 text-white" />
          <div><span className="text-white text-xl font-semibold">보고서를 생성중입니다...</span></div>
        </LoadingModalWithText>
        {(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds) || isEmpty(state.simResultsGroup))
          ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
          : <div>
              <div className="py-10 px-28">
                <div className="relative">
                  <div className="rounded-xl overflow-hidden shadow-md">
                    <SearchkakaoMap location={Object.values(state.simResultsGroup)[0].address} height="300px" zoomLvl={3} />
                  </div>
                </div>
              </div>
              

              <div className="px-28 p-5 relative">
                
                <div className="flex items-center" ref={setTarget}>
                  <div className="flex-1 text-3xl font-bold">Parameter Information</div>
                  <div className={!isIntersect ? "fixed top-[110px] right-5 z-10" : ""}>
                    <button onClick={()=> navigate("/realestate/mysim")} className="min-w-[180px] p-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary inline-flex items-center space-x-2">
                      <span>Start New Simulation</span>
                    </button>
                  </div>
                </div>
                <div>
                  <table className="tbl-simparam table-fixed mt-10 text-xl bg-white shadow">
                    <thead>
                      <tr>
                        <th></th>
                        <th>코나아이 예측 시뮬레이션</th>
                        <th>시뮬레이션</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(SIM_PARAMETERS).map(([key, val]) => {
                        
                        let baseParamVal = state.modelData.variable[key].data_in[0].value;
                        baseParamVal = Object.fromEntries(baseParamVal);  //need to convert to object to merge similar years
                        const simParamObj = Object.values(Object.values(state.simResultsGroup)[0].simResults)[0].simInfo.parameter;
                        let simParamVal = Object.keys(simParamObj).filter(simKey => simKey.includes(key)).map(_ => simParamObj[_]);
                        simParamVal = simParamVal[0] && Object.fromEntries(simParamVal[0]);
                      return (
                        <tr key={key}>
                          <th scope="row" className="pl-10 text-left">{val.title}</th>
                          <td>
                            <div className="flex pl-10">
                              <div className="flex flex-col">
                                <div>KONA예측 Default  </div>
                                {/* {Object.entries(baseParamVal).map(([_k, _v]) => <div className="flex space-x-2" key={_k}><div>{_k}:</div><div>{customRound(_v, 2)}</div></div>)} */}
                              </div>
                            </div>
                          </td>
                          
                          <td>
                            <div className="flex pl-10">
                              <div className="flex flex-col">
                                {!simParamVal
                                ? <div>KONA예측 Default  </div>
                                : (Object.entries(simParamVal).map(([_k, _v]) => <div className="flex space-x-2" key={_k}><div>{_k}:</div><div>{customRound(_v, 2)}</div></div> ))
                                // 
                                }
                              </div>
                            </div>
                          </td>

                        </tr>
                        
                      )})}


                      {Object.entries(SIM_EVENT_PARAMETERS).map(([key, val]) => {
                        
                        const simParamObj = Object.values(Object.values(state.simResultsGroup)[0].simResults)[0].simInfo.parameter;
                        const eKeys = Object.keys(simParamObj).filter(simKey => simKey.includes(val.dateVar)).map(_ => _.match(/\[([^\]]+)/)[1]);

                        const elist = eKeys.map(_k => {
                          let dateVal = Object.keys(simParamObj).filter(simKey => simKey.includes(`${val.dateVar}[${_k}`)).map(_ => simParamObj[_])[0];
                          let paramVal = Object.keys(simParamObj).filter(simKey => simKey.includes(`${val.paramVar}[${_k}`)).map(_ => simParamObj[_])[0];
                          let enameVal = Object.keys(simParamObj).filter(simKey => simKey.includes(`event_name[${_k}`)).map(_ => simParamObj[_])[0];
                          
                          return {dateVal: dateArr[dateVal], paramVal, enameVal};
                        })
                        
                      return (
                        <tr key={key}>
                          <th scope="row" className="pl-10 text-left">{val.title}</th>
                          <td className="pl-10">없음</td>
                          <td>
                            <div className="flex flex-col pl-10 space-y-2">
                                {isEmpty(elist)
                                  ? <div>없음</div>
                                  : (elist.map((item, index) => 
                                      <div className="flex flex-col" key={index}>
                                        <div className="flex">Event Name: {item.enameVal}</div>
                                        <div className="flex space-x-2"><div>{item.dateVal}:</div><div>{customRound(item.paramVal, 2)}</div></div>
                                      </div>
                                    ))
                                }
                              </div>
                          </td>
                        </tr>
                        
                      )})}
                      
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="px-28 pt-10">
                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20">
                    <div className=" px-[32px] py-9">
                      <div className="text-3xl leading-none">부동산 가격 예측 결과</div>
                      <div className="pt-10 flex flex-col space-y-10">
                        {Object.entries(state.simResultsGroup).map(([key,val],index) => (
                          <div className="flex flex-col scroll-mt-[200px]" key={key}>
                            <div className="flex mb-5">
                              <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                            </div>
                            <div className="h-[400px]">
                              <Echarts options={getChartOptionsMySim(val)}  />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end of chart area */}


              <div className="px-28 pb-20">
                {/* result table */}
                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0 mt-10">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20 ">
                    <div className="px-[32px] py-9">
                      <div className="text-3xl leading-none">예측 결과 요약</div>
                        <div className="pt-10 flex flex-col space-y-10">
                          {Object.entries(state.simResultsGroup).map(([key,val],index) => (
                            <div className="flex flex-col" key={index}>
                              <div className="flex">
                                <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                              </div>
                              <TableMySim usersimResult={val} />
                            </div>
                          ))}
                        </div>
                    </div>
                  </div>
                </div>
                {/* end of result table */}

                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0  mt-10">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20 ">
                    <div className="px-[32px] py-9">
                      <div className="text-3xl leading-none">KONA 분석 평가</div>
                        <div className="pt-10 flex flex-col space-y-10">
                          {Object.entries(state.simResultsGroup).map(([key,val],index) => (
                            <div className="flex flex-col" key={index}>
                              <div className="flex mb-5">
                                <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                              </div>
                              <EvaluationMySim usersimResult={val}/>
                            </div>
                          ))}
                        </div>
                    </div>
                  </div>
                </div>

                <div className="pt-4">* 실제 가격은 해당 물건(주택)의 실거래 가격이 아닌, 지역별 매매가격지수를 기반으로 월 단위로 산정된 가격으로 실거래가격과 차이가 발생할 수 있음</div>
                
                {(!isEmpty(state.simResultsGroup)) &&
                  <div className="flex justify-center pt-20">
                      <button onClick={downloadReport} className="min-w-[180px] p-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary inline-flex items-center space-x-2">
                        <DocumentTextIcon className="h-10" />
                        <span>Download Report</span>
                      </button>
                  </div>
                }
              </div>


            </div>
        }
      </MainContentWrapper>
  )
}

export default RealEstateMySimView