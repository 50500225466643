import React from 'react'
import { getNoticeListApi, getTotalNoticeItemsApi } from "../../utils/api/referenceApi";
import ReferenceHeader from "./ReferenceHeader"
import dayjs from "dayjs";
import { MagnifyingGlassIcon, InboxIcon, PlusIcon} from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon} from "@heroicons/react/24/outline";
import Loader3 from "../../components/loaders/Loader3";
import { useDebounce } from "@toss/react";
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "../../components/context/GlobalContext";
import { ACCOUNT_ROLE } from "../../utils/constants";
import { TablePagination } from "./ReferencePagination";
const PAGE = 1;
const PAGE_SIZE = 10;

export const ACTION = {
	SET_NOTICE_LIST: "set-notice-list",
	SET_LOADING: "set-loading",
  SET_PAGE_NUM: "set-page-num"
}

const initialState = {
	noticeList: [],
  pageNum: 1,
	isLoading: true,
  totalNotice: 0,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_NOTICE_LIST:
			return {
				...state,
        noticeList: payload.noticeList,
        totalNotice: payload.totalNotice,
        pageNum: payload.pageNum,
				isLoading: false
			}
    case ACTION.SET_PAGE_NUM:
      return {...state, pageNum: payload};
    case ACTION.SET_LOADING:
      return {...state, isLoading: payload}
    
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

const Notice = () => {
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const refSearchField = React.useRef();
  const { user } = useGlobalStateContext();

  const onSearchChange = useDebounce(async () => {
    dispatch({type: ACTION.SET_LOADING, payload: true});
    const searchText = refSearchField.current.value;

    const [noticeList, totalNotice] = await Promise.all([
      getNoticeListApi(PAGE, PAGE_SIZE, searchText),
      getTotalNoticeItemsApi(searchText),
    ]);


    dispatch({
      type: ACTION.SET_NOTICE_LIST, 
      payload: {
        noticeList: noticeList, 
        totalNotice: totalNotice,
        pageNum: 1
      }
    });

  }, 500);

	const loadDefault = React.useCallback(async () => {

    const [noticeList, totalNotice] = await Promise.all([
      getNoticeListApi(PAGE, PAGE_SIZE, null),
      getTotalNoticeItemsApi(null),
    ]);

    dispatch({
      type: ACTION.SET_NOTICE_LIST, 
      payload: {
        noticeList: noticeList, 
        totalNotice: totalNotice,
        pageNum: state.pageNum
      }
    });

	}, [])


  React.useEffect(() => {
		loadDefault();
	},[]);

  function gotoPostDetails(id) {
    navigate("/reference/notice/" + id);
  }

  async function handlePageNumClick(pageNum) {
    dispatch({type: ACTION.SET_PAGE_NUM, payload: pageNum});    
    dispatch({type: ACTION.SET_LOADING, payload: true});
    const searchText = refSearchField.current.value;
    const [noticeList] = await Promise.all([
      getNoticeListApi(pageNum, PAGE_SIZE, searchText),
    ]);

    dispatch({
      type: ACTION.SET_NOTICE_LIST, 
      payload: {
        noticeList: noticeList, 
        totalNotice: state.totalNotice,
        pageNum: pageNum
      }
    });
  } 

  function NoticeTable({isLoading, noticeList}) {
    return (
      <table className="w-full text-left text-gray-500 table-fixed text-base pb-5">
        <thead className="text-sm uppercase bg-gray-100">
          <tr className="[&>*]:p-4 [&>*]:font-semibold text-center ">
            <th className="w-[10%]">번호</th>
            <th className="">제목</th>
            <th className="w-[15%]">글쓴이</th>
            <th className="w-[15%]">날짜</th>
            <th className="w-[10%]">조회수</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <ResultLoading colSpan={5} />}
          {(!isLoading && state.noticeList.length <= 0 && refSearchField.current.value !== "") && 
            <tr>
              <td colSpan={5}>
                <div className="flex flex-col space-y-4 items-center justify-center py-10 text-konagray/50">
                  <MagnifyingGlassIcon  className="h-10"/>
                  <span className="text-xl">검색결과가 없습니다</span>
                </div>
              </td>
            </tr>
          }
          {(!isLoading && state.noticeList.length <= 0 && refSearchField.current.value === "") && 
            <tr>
              <td colSpan={5}>
                <div className="flex flex-col space-y-4 items-center justify-center py-10 text-konagray/50">
                  <InboxIcon  className="h-10"/>
                  <span className="text-xl">게시물이 없습니다.</span>
                </div>
              </td>
            </tr>
          }
          {(!isLoading && state.noticeList.length > 0) && 
            state.noticeList.map(item => {
              const today = dayjs();
              const targetDate = dayjs(item.created_at);
              const fiveDaysAgo = today.subtract(5, 'day'); 

              return (
                <tr key={item.id} onClick={() => gotoPostDetails(item.id)} className="[&>*]:p-4 text-sm text-center align-middle border-b font-normal hover:bg-gray-50/50 cursor-pointer">
                  <td className="">{item.id}</td>
                  <td className="text-left font-semibold flex space-x-2 items-center">
                    <span>{item.title}</span>
                    {targetDate.isAfter(fiveDaysAgo) && <span className="animate-pulse bg-konared text-white text-[10px] leading-none font-thin py-0.5 px-1.5 rounded-full ">NEW</span>}
                    {item.comments > 0 && <div className="flex space-x-1 text-konagray/40"><ChatBubbleOvalLeftEllipsisIcon className="h-4" /><span className="text-xs font-light">{item.comments}</span></div>}
                  </td>
                  <td className="">{item.user?.name}</td>
                  <td className="">{targetDate.format("YYYY-MM-DD")}</td>
                  <td className="">{item.views}</td>
                </tr>
              )
            })
          }

        </tbody>
      </table>
    )
  }

  function ResultLoading({colSpan}) {
    return (
      <tr>
        <td colSpan={colSpan} className="border-b py-20"><Loader3 /></td>
      </tr>
    )
  }

  function AdminPanel () {
    return (
      <div className="mt-10 flex justify-center">
        <NavLink to="/reference/notice/create" className="flex space-x-1 items-center rounded-lg p-3 min-w-[200px] justify-center ripple-primary text-white"><PlusIcon className="h-6" /><span>ADD NEW ITEM</span></NavLink>
      </div>
    )
  }

  

  return (
    <div className="flex-1 flex flex-col pb-10">
      <ReferenceHeader>공지사항</ReferenceHeader>
      <div className="flex justify-center">
        <div className="flex-1 max-w-[1280px] p-10">
          <div className="flex pb-10">
            <div className="flex-1 flex justify-center">
              <div className="relative w-full max-w-lg">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input 
                  ref={refSearchField} 
                  type="text" 
                  className="block p-2 pl-10 w-full text-lg text-konagray/80 font-light rounded-full border outline-none focus:duration-300 focus:ring-2 shadow-sm focus:ring-konadanger focus:ring-opacity-50 focus:placeholder-red-400" 
                  onChange={() => onSearchChange()} 
                  
                />
              </div>
            </div>
          </div>
          <NoticeTable isLoading={state.isLoading} noticeList={state.noticeList} />
          <div className="flex justify-center p-5"><TablePagination totalItems={state.totalNotice} pageSize={PAGE_SIZE} pageNum={state.pageNum} clickCallback={handlePageNumClick} /></div>

          {/* for admin */}
          {(user && user?.user_info?.role === ACCOUNT_ROLE.ADMIN) && <AdminPanel />}
        </div>
      </div>
    </div>
    
  )
}

export default Notice