import React, { useReducer, useRef, useEffect} from 'react'
import {BiSearch} from 'react-icons/bi'
import debounce from "lodash/debounce";
import SearchListBox from "./SearchListBox";
import SearchAddressErrorModal from "../modals/SearchAddressErrorModal";
import { API_BASE_URL, SEARCH_HISTORY_TYPE, SOLIDEO_API_URL } from "../../utils/constants";
import { ApiError, AppError, bearerAuth, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { ArrowUpRightIcon, StarIcon as StarIconOutline } from '@heroicons/react/24/outline'
import { StarIcon, XMarkIcon, ArrowSmallRightIcon } from '@heroicons/react/24/solid'
import LoadingModalWithText from "../modals/LoadingModalWithText";
import { getJaduAptPrice, getRealEstateModelBaseDataApi, getRealEstateModelVariablesApi } from "../../utils/api/realestateApi";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../context/GlobalContext";
import { getRealEstateModelBaseDataObj, getRealEstateModelVariablesObj, getRealEstateSubscript, RE_ACTION, useReDispatchContext, useReStateContext } from "../context/RealEstateContext";

const getCatBtnClass = (sCategory, sCategoryPrev=0, e) =>{
  const baseClass = "w-[120px] py-[15px]";
  const delay = (e===sCategory && Math.abs(sCategoryPrev-sCategory) > 1) ? "delay-300" : "delay-150";
  // const delay = 'delay-300';
  return `${baseClass} ${(e===sCategory) ? "text-white":"text-black"} ${delay}`;
}

const getCatBgClass = (sCategory) => {
  const baseClass = "w-[120px] h-full rounded-2xl bg-konared absolute left-0 top-0 duration-500 transition ease -z-10"
  //this code structure is intentional so that tailwindcss can purge properly related classes
  //if other purging solution can be solve, translate size can be calculated dynamically
  switch(sCategory) {
    case 0:
      return `${baseClass} translate-x-[0]`;
    case 1:
      return `${baseClass} translate-x-[120px]`;
    case 2:
      return `${baseClass} translate-x-[240px]`;
    default:
  }
}


// use number for cateogry identifier to calculation direction of category button
const searchCategory = {
  apt: {code: 0, name:"아파트"},    
  multi: {code: 1, name:"연립다세대"},
  house: {code: 2, name:"단독주택"}
}


//address code : search_category
const addressType = {
  apt: {searchCategoryCode: searchCategory.apt.code, name:"아파트"},      
  ofctl: {searchCategoryCode: searchCategory.apt.code, name:"오피스텔"}, 
  rwhsMlpxhou: {searchCategoryCode: searchCategory.multi.code, name: "연립/다세대"},
  slhMltdwl: {searchCategoryCode: searchCategory.house.code, name:"단독/다가구"},
  lad: {searchCategoryCode: null, name: "토지"},  
  cmrc: {searchCategoryCode: null, name: "상업용"}
}

const ACTION = {
  SET_CATEGORY : 'set-category',
  SEARCH_START: 'search-query-start',
  SEARCH_DONE: 'search-query-done',
  SET_ADDRESS: 'set-address',
  SET_ADDRESS_WITH_OPT: 'set-address-with-options',
  SET_DONG: 'set-dong',
  SET_HO: 'set-ho',
  SET_DONG_LIST: 'set-dong-list',
  SET_HO_LIST: 'set-ho-list',
  SET_LCODE_INFO: 'set-location-code-info',
  SET_CHKLIST: 'set-chklist',
  SUBMIT_SEARCH: 'submit-search',
  SUBMIT_SEARCH_SUCCESS: 'submit-search-success',
  SUBMIT_RECENT_FAV_SUCCESS: 'submit-recent-fav-success',
  SET_ERROR: 'set-error',
  CLEAR_ERROR: 'clear-error',
  CONFIRM_ERROR_MODAL: 'confirm-error-modal',
  CLEAR_ERROR_AND_SEARCH: 'clear-error-and-search',
  SET_DONGHO_LOADING: "set-dongho-loading",
  SET_RECENT: "set-recent",
  SET_FAVORITES: "set-favorites",
  SET_RECENT_FAV: "set-recent-fav"
}

const initialValue = {
  error: {},
  isError: false,
  sCategory : searchCategory.apt.code,
  sCategoryPrev : searchCategory.apt.code,
  sQuery: '',
  sQueryResult: [],
  sLoading: false,
  address: {},
  dong: '', //must be empty string to avoid error when using as API query string
  ho: '', //must be empty string to avoid error when using as API query string
  dongList: [],
  hoList: [],
  lcodeInfo: {},
  chklist: null,
  addressInfo: null,
  submitSearchLoading: false,
  dongHoListLoading: false,
  recent: null,
  favorites: null,
  searchHistoryLoading: true,
}

const SEARCH_ERROR_CODE = {
  "000" : "Invalid Category. Please contact us to resolve this issue.",
  "001" : "Unsupported Category. Please contact us to resolve this issue.",
  "002" : "아파트로 조회하시겠습니까?",  //incorrect selection, go to apt
  "003" : "연립다세대로 조회하시겠습니까?",  //incorrect selection, go to multi
  "004" : "단독주택로 조회하시겠습니까?",  //incorrect selection, go to house
  "005" : "Please select a valid address.",
  "006" : "동을 선택해주세요",
  "007" : "호를 선택해주세요",
  "008" : "Cannot determine location_code. Please contact us to resolve this issue.",
  "009" : "An error occured during data retrieval. Please try again or search manually using the address bar."

}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_CATEGORY:
      return {...state, sCategory: payload, sCategoryPrev: state.sCategory};
    case ACTION.SEARCH_START:
      return {...state, sQuery: payload, sLoading: true};
    case ACTION.SEARCH_DONE:
      return {...state, sQueryResult: payload, sLoading: false};
    case ACTION.SET_ADDRESS:
      return {...state, address: payload.address, chklist: payload.chklist, dongList: payload.dongList, hoList: payload.hoList , dong:'', ho:'', sCategory: payload.sCategory, sCategoryPrev: state.sCategory, dongHoListLoading: false};
    case ACTION.SET_ADDRESS_WITH_OPT:   
      return {...state, ...payload, dong:'', ho:''};
    case ACTION.SET_DONG:
      return {...state, dong:payload.dong, hoList:payload.hoList, ho:'', dongHoListLoading: false};
    case ACTION.SET_HO:
      return {...state, ho:payload};
    case ACTION.SET_DONG_LIST:
      return {...state, dongList: payload};
    case ACTION.SET_HO_LIST:
      return {...state, holist: payload};
    case ACTION.SET_LCODE_INFO:
      return {...state, lcodeInfo: payload};
    case ACTION.SET_CHKLIST:
      return {...state, chklist: payload};
    case ACTION.SUBMIT_SEARCH:
      return {...state, submitSearchLoading: true};
    case ACTION.SUBMIT_SEARCH_SUCCESS:
      return {...state, addressInfo: payload.addressInfo, lcodeInfo: payload.lcodeInfo, submitSearchLoading: false};
    case ACTION.SUBMIT_RECENT_FAV_SUCCESS:
      return {...state, addressInfo: payload.addressInfo, lcodeInfo: payload.lcodeInfo, ho: payload.ho, dong: payload.dong, submitSearchLoading: false};
    case ACTION.SET_ERROR:
      return {...state, submitSearchLoading: false, isError: true, error: payload};
    case ACTION.CLEAR_ERROR:
      return {...state, isError: false};  //TODO: clear state.error here but with consideration of Modal content. In the current structure, clearing state.error here will cause modal to clear the content before it finishes closing. 
    case ACTION.CLEAR_ERROR_AND_SEARCH:
      return {...state, isError: false, ...initialValue, error: state.error, sCategory: state.sCategory, sCategoryPrev: state.sCategoryPrev, recent: state.recent, favorites: state.favorites, searchHistoryLoading: false}  //reset all but preserve category selection
    case ACTION.CONFIRM_ERROR_MODAL:
      return {...state, isError: false, sCategory: payload.sCategory, sCategoryPrev: state.sCategory};
    case ACTION.SET_DONGHO_LOADING:
      return {...state, dongHoListLoading: payload};
    case ACTION.SET_RECENT:
      return {...state, recent: payload}
    case ACTION.SET_FAVORITES:
      return {...state, favorites: payload}
    case ACTION.SET_RECENT_FAV:
      return {...state, recent: payload.recent, favorites:payload.favorites, searchHistoryLoading: false}
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

const SearchBar = (props) => {
  const {user} = useGlobalStateContext();
  const reState = useReStateContext();
  const reDispatch = useReDispatchContext();
  const globalDispatch = useGlobalDispatchContext();

  
  const refSearchField = useRef();
  const refSearchResult = useRef();
  const refSearchResultScroll = useRef();
  const [state, dispatch] = useReducer(reducer, initialValue);
  


  const getAuthHeader = {
    method: 'GET',
    headers: {"Authorization" : `Bearer ${user.access_token}`} 
  };


  
  const handleSearchQuery = async () => {
    var searchQString = refSearchField.current.children.searchInput.value.trim();
    var searchResult = [];

    if(searchQString !== state.address.jibunAddr){
      dispatch({type: ACTION.CLEAR_ERROR_AND_SEARCH});
    }

    dispatch({type: ACTION.SEARCH_START, payload: searchQString})
    if (searchQString) {
      let query = `?keyword=${encodeURI(searchQString)}&rows=50&page=1`
      let response = await fetch(`${SOLIDEO_API_URL}/api/solideo/getsearchpagelist${query}`,{
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
      
      const parseRes = await response.json();
      if (parseRes.data.addrResult.data.totalElements > 0) searchResult = parseRes.data.addrResult.data.content;
      // await new Promise(r => seTimeout(r, 2000)); //fake sleep for testing loader
    }
    dispatch({type: ACTION.SEARCH_DONE, payload: searchResult});
  }

  const handleSearchQueryDebounce = debounce(async () => { 
    await handleSearchQuery(); 
  }, 300);



  const getChklist = async (address, user) => {
    //get chklist 
    const pnu = address.innb;
    const rncode = address.rnMgtSn;
    const buldMnnmNo = address.buldMnnm;
    const buldSlnoNo = address.buldSlno;
    
    
   
    try {
    
      const query = `?pnu=${pnu}&rncode=${rncode}&buldMnnmNo=${buldMnnmNo}&buldSlnoNo=${buldSlnoNo}`
      const response = await fetch(`${SOLIDEO_API_URL}/api/solideo/getprposchklist${query}`,{
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
    
      const parseRes = await response.json();

      if(response.ok && parseRes?.code == 200){
        return parseRes.data;  
      }

      throw new Error(`Error retrieving getprposchklist: ${parseRes?.message}`);
      
    
    } catch (err) {
      console.error(err.message)
    }


  }


  const getHoList = async (pnuCnvr, rnAddrYn, dong, roadPnu) => {
    //for pnuCnvr => use state.chklist.pnuCnvr
    //for rnAddrYn => use state.chklist.rnAddrYn
    //for dong => use state.dong. this is optional

    //get Ho List
    const query = `?pnu=${pnuCnvr}&rnAddrYn=${rnAddrYn}` + (dong? `&dongNm=${dong}` : "")+(roadPnu? `&roadPnu=${roadPnu}` : "")
    const response = await fetch(`${SOLIDEO_API_URL}/api/solideo/getbildregstragbldgholist${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });
    
    const parseRes = await response.json();
    const hoList = parseRes.data.map(ho => ho.hoNm);  //will return empty array if parseRes.data is empty

    return hoList;  //return hoList in array format
  }

  const getDongList = async (pnuCnvr, rnAddrYn, smrizeAt, roadPnu) => {
    //for pnuCnvr => use state.chklist.pnuCnvr
    //for rnAddrYn => use state.chklist.rnAddrYn

    //get dong list
    const query = `?pnu=${pnuCnvr}&rnAddrYn=${rnAddrYn}&smrizeAt=${smrizeAt}`+(roadPnu? `&roadPnu=${roadPnu}` : "")      
    const response = await fetch(`${SOLIDEO_API_URL}/api/solideo/getbildregstragbldgdonglist${query}`,{
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    });

    const parseRes = await response.json();
    const dongList = parseRes.data.map(dong => dong.dongNm);  //will return empty array if parseRes.data is empty
    
    return dongList; //return dongList in array format
  }


  const closeModalReset = (param) => {
    refSearchField.current.children.searchInput.value = "";
    dispatch({type: ACTION.CLEAR_ERROR_AND_SEARCH})
  }

  const closeModal = (param) => {
    dispatch({type: ACTION.CLEAR_ERROR})
  }

  const cancelModal = () => {
    refSearchField.current.children.searchInput.value = "";
    dispatch({type: ACTION.CLEAR_ERROR_AND_SEARCH});
  }

  const confirmModal = async ({chklist, address}) => {
    const categoryCode = chklist.prposChkList[0].prposChkNm;
    dispatch({type: ACTION.CONFIRM_ERROR_MODAL, payload: {sCategory: addressType[categoryCode].searchCategoryCode}});
    await setHoDongLists(chklist, address);
  }

  const errorCheck = (chklist, address) => {
    
    // check if address category is correct or supported
    const categoryCode = chklist.prposChkList[0].prposChkNm;

    if (Object.keys(addressType).includes(categoryCode)){
      //category is valid

      // if category code not apartment, multi or house
      if(addressType[categoryCode].searchCategoryCode === null){
        return {code: "001", msg: SEARCH_ERROR_CODE["001"], confirmFn: closeModalReset, param: null};
      }

      //if category code is not the same as currently selected category
      if(addressType[categoryCode].searchCategoryCode !== state.sCategory){

        switch(addressType[categoryCode].searchCategoryCode) {
          case searchCategory.apt.code:
            return {code: "002", msg: SEARCH_ERROR_CODE["002"], confirmFn: confirmModal, param: {chklist,address}, cancelFn: cancelModal}
          case searchCategory.multi.code:
            return {code: "003", msg: SEARCH_ERROR_CODE["003"], confirmFn: confirmModal, param: {chklist,address}, cancelFn: cancelModal}
          case searchCategory.house.code:
            return {code: "004", msg: SEARCH_ERROR_CODE["004"], confirmFn: confirmModal, param: {chklist,address}, cancelFn: cancelModal}
          default:
            throw new Error(`Uncaught Search Address error. cateogryCode=${categoryCode}`);

        }
      }
      
    }
    else
    {
      //category code is invalid (unknown code)
      return {code: "000", msg: SEARCH_ERROR_CODE["000"], confirmFn: closeModalReset, param: null};
    }

    return false;

  }

  const setHoDongLists = async (chklist, address) => {
    

    //get dong list
    var dongList = await getDongList(chklist.pnuCnvr, chklist.rnAddrYn, chklist.smrizeAt, chklist?.roadPnu);
    var hoList = [];  //init empty hoList array which will be used if dongList is empty

    //if dong list is empty | try to get holist
    //this means apt has no dong but only ho
    if(dongList.length <= 0){
      //get ho list
      hoList = await getHoList(chklist.pnuCnvr, chklist.rnAddrYn, null, chklist?.roadPnu);

      if(hoList.length <= 0){
        // throw new Error(`CRITICAL error: dongList & hoList are both empty`);

        //reset search
      }
      
    }

    //set state.sCategory if needed
    const categoryCode = chklist.prposChkList[0].prposChkNm;
    

    //SUGGEST: load all dong and ho combination using selected address
    dispatch({type: ACTION.SET_ADDRESS, payload: {chklist: chklist, address:address, dongList, hoList, sCategory: addressType[categoryCode].searchCategoryCode}})
  }

  const handleSearchClick = async (e, key, val) => {
    //NOTE: use onMouseDown and prevent default so that it will not conflict with onBlur event of search field
    animateSlideResult(false)
    dispatch({type: ACTION.SET_DONGHO_LOADING, payload: true});
    const address = val;
    refSearchField.current.children.searchInput.value = address.jibunAddr;

    
    
    const chklist = await getChklist(address, user);
    // dispatch({type: ACTION.SET_CHKLIST, payload: chklist});

    const error = errorCheck(chklist, address);

    if (error) {
      dispatch({type: ACTION.SET_ERROR, payload: error});
    }
    else{
    
      await setHoDongLists(chklist, address);
      
    }
    //error check



    
  }

  const handleClickOutside = e => {
    if (!refSearchResult?.current.contains(e.target) && !refSearchField?.current.contains(e.target)) {
      animateSlideResult(false);
    }
    else if(refSearchField?.current.contains(e.target)){
      animateSlideResult(true);
    }

  };
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  


  useEffect(() => {
    const handleSearchClickClearBtn = event => {
      dispatch({type: ACTION.CLEAR_ERROR_AND_SEARCH});
    };

    document.addEventListener('search', handleSearchClickClearBtn);
    return () => document.removeEventListener('search', handleSearchClickClearBtn);
  });
  




  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Escape') {
        event.preventDefault();
        
        //remove focus on input field
        animateSlideResult(false)
        refSearchField.current.children.searchInput.blur();
        //TODO: remove focus on div if focused
      }
    };

    document.addEventListener('keydown', keyDownHandler);
    return () => document.removeEventListener('keydown', keyDownHandler);
  });
  

  const handleDongChange = async (dong) => {
    dispatch({type: ACTION.SET_DONGHO_LOADING, payload: true});
    if(dong){
      
      //get ho list
      const hoList = await getHoList(state.chklist.pnuCnvr, state.chklist.rnAddrYn, dong, state.chklist?.roadPnu);

      if(hoList.length <= 0){
        throw new Error(`Unhandled error: hoList are both empty when dong was seleted`);
      }

      // addressJsonData.current = {...addressJsonData.current, holist: parseRes.data}
      // setAddress(prevState => ({...prevState, dong: e.target.value, ho: ""}))
      dispatch({type: ACTION.SET_DONG, payload: {dong, hoList}})
    }
    else{
      //do something when there is no val
      //future error
      
    }
  }

  const handleHoChange = async (ho) => {
    if(ho){
      dispatch({type: ACTION.SET_HO, payload: ho})
    }
    else{
      //do something when there is no val
      //future error
    }
  }

  const checkSubscriptLcodeMapping = async (locationCode, modelId) => {
   

    try {
      const query = `?page_size=1&model_id=${modelId}&loc_code__in=${locationCode}`
      const response = await fetch(`${API_BASE_URL}/api/variables${query}`,getAuthHeader);
      const parseRes = await response.json();

      if ([400,403].includes(response.status)) throw new ResponseError(parseRes.detail);
      if (response.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")

      return parseRes.total_count > 0 ? locationCode : undefined;
      
    } catch (err) {
        alert(err.message)
    }

  }

  const getLcodeInfo = async (locationName, modelId) => {
    //get location_code
    const query = `?location_name__in=${locationName}`
    const response = await fetch(`${API_BASE_URL}/api/lcode${query}`,getAuthHeader);
    const parseRes = await response.json();

    const locationInfo = parseRes.total_count > 0 ? parseRes.data[0] : undefined; 

    return (locationInfo && await checkSubscriptLcodeMapping(locationInfo.location_code, modelId)) ? locationInfo : undefined;
  }

  const onSearchSubmit = async (e) => {


    //things to check 
    // state.address must have value
    // ho must have value, dong is optional
    dispatch({type: ACTION.SUBMIT_SEARCH});

    //check submit error 
    var error = false;
    if (Object.keys(state.address).length <= 0 || state.chklist === null){
      error = {code: "005", msg: SEARCH_ERROR_CODE["005"], confirmFn: closeModalReset, param: null}
    }
    else if(state.dongList.length > 0 && state.dong.trim().length === 0){
      error = {code: "006", msg: SEARCH_ERROR_CODE["006"], confirmFn: closeModal, param: null}
    }
    else if(state.hoList.length > 0 && state.ho.trim().length === 0){
      error = {code: "007", msg: SEARCH_ERROR_CODE["007"], confirmFn: closeModal, param: null}
    }


    //if there are no errors check if location code is valid
    
    if(!error){
      var locationName = `${state.address.siNm} ${state.address.sggNm}`;
      var lcodeInfo = await getLcodeInfo(locationName, reState.currentModelId);
      
      if(!lcodeInfo || lcodeInfo < 1){
        //try using upper level location code
        locationName= `${state.address.siNm}`;
        lcodeInfo = await getLcodeInfo(locationName, reState.currentModelId);

        if(!lcodeInfo || lcodeInfo < 1){
          //if still undefined throw a location_code error
          error = {code: "008", msg: SEARCH_ERROR_CODE["008"], confirmFn: closeModalReset, param: null}
        }
      }
      
    }
   


    if (error) {
      dispatch({type: ACTION.SET_ERROR, payload: error});
    }
    else {

      try{

        const addressId = state.address.innb+state.dong+state.ho;

        const addressInState = reState.address.byId[addressId];
        const jaduPriceInfo = addressInState?.info || await getJaduAptPrice(state.chklist, state.dong, state.ho, user);

        
        dispatch({type: ACTION.SUBMIT_SEARCH_SUCCESS, payload: {addressInfo:jaduPriceInfo, lcodeInfo}})
        
        const location = {
          chklist: state.chklist,
          address: state.address,
          dong: state.dong, 
          ho:state.ho, 
          info: jaduPriceInfo,
          locInfo:lcodeInfo
        }


        //save recent to DB | do not await
        saveRecent(addressId, location);

        const modelDataInState = reState.model.byId[reState.currentModelId]?.data.byId[location.locInfo.location_code];
        const [modelVariables, modelBaseData] = (modelDataInState !== undefined) 
          ? [modelDataInState.variable, modelDataInState.baseData]
          : await Promise.all([
              getRealEstateModelVariablesObj(reState.currentModelId, location.locInfo.location_code, user),
              getRealEstateModelBaseDataObj(reState.currentModelId, location.locInfo.location_code, user),
            ])

        const modelData = {
          locationCode: location.locInfo.location_code,
          variable: modelVariables,
          baseData: modelBaseData,
          subscript: getRealEstateSubscript(modelVariables)
        }


        reDispatch({
          type: RE_ACTION.SET_CURRENT, 
          payload: {modelInfo: reState.model.byId[reState.currentModelId]?.info, modelData: modelData, addressInfo: location}
        })

      } catch (err) {
        if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
          const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
          globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
        }
        else{
          console.error(err)
        }
      }

      


    }
  }

  

  const saveRecent = async (key, val) => {
    
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        search_key: key,
        parameters: val,
      })
    };

    const response = await fetch(`${API_BASE_URL}/api/addrsh/recent`, requestOptions);
    const parseRes = await response.json();

    var newRecent = {...state.recent};

    if(!(key in state.favorites)){
      if ("deleted" in parseRes) delete newRecent[parseRes.deleted];
      if (key in newRecent) delete newRecent[key];
      newRecent = {[key]:val, ...newRecent};
    }

    dispatch({type: ACTION.SET_RECENT, payload: newRecent});
  }


  const saveFavorite = async (key) => {
    
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        search_key: key,
      })
    };

    const response = await fetch(`${API_BASE_URL}/api/addrsh/favorite`, requestOptions);
    const parseRes = await response.json();

    var newFavorite = {...state.favorites};
    var newRecent = {...state.recent};
    if ("deleted" in parseRes) delete newFavorite[parseRes.deleted];
    if (key in newRecent) {
      newFavorite = {[key]:newRecent[key], ...newFavorite};
      delete newRecent[key];
    }

    return {recent: newRecent, favorites: newFavorite};
  }

  

  const deleteSearchHistory = async (key, type) => {
    try {
      const query = `?search_key=${key}&type=${type}`
      const response = await fetch(`${API_BASE_URL}/api/addrsh${query}`, {
        method: "DELETE",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });

      const parseRes = await response.json();

      if (response.status === 403) throw new ResponseError(parseRes.detail);
      if (response.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")

      
    } catch (err) {
        alert(err.message)
    }
  }

  const updateSearchHistoryParameter = async (key, type, parameters) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/addrsh/parameters`, {
        method: "PATCH",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
        body: JSON.stringify({
          search_key: key,
          type: type,
          parameters: parameters
        })
      });

      const parseRes = await response.json();

      if (response.status === 403) throw new ResponseError(parseRes.detail);
      if (response.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")

      
    } catch (err) {
        alert(err.message)
    }
  }


  const handleDeleteRecent = (e, dataRowId, key) => {
    e.stopPropagation();
    deleteSearchHistory(key, SEARCH_HISTORY_TYPE.RECENT);
    const parentDiv = document.querySelector(`[data-row=${dataRowId}]`);
    parentDiv.style.transition = "opacity 0.4s";
    parentDiv.style.opacity = 0;
    setTimeout(function() {
      const currentRecent = {...state.recent};
      delete currentRecent[key];
      dispatch({type: ACTION.SET_RECENT, payload: currentRecent});
    }, 400);
    
  }



  const handleSaveFavorite = async (e, dataRowId, key, val) => {
    e.stopPropagation();

    //add fadeout animation
    const parentDiv = document.querySelector(`[data-row=${dataRowId}]`);
    parentDiv.style.transition = "opacity 0.4s";
    parentDiv.style.opacity = 0;

    const [payload] = await Promise.all([saveFavorite(key), delay(400)]);
    if(payload){
      dispatch({type: ACTION.SET_RECENT_FAV, payload});
    }

  }

  const handleDeleteFavorite = (e, dataRowId, key) => {
    e.stopPropagation();
    deleteSearchHistory(key, SEARCH_HISTORY_TYPE.FAVORITE);
    const parentDiv = document.querySelector(`[data-row=${dataRowId}]`);
    parentDiv.style.transition = "opacity 0.4s";
    parentDiv.style.opacity = 0;
    setTimeout(function() {
      const currentFavorite = {...state.favorites};
      delete currentFavorite[key];
      dispatch({type: ACTION.SET_FAVORITES, payload: currentFavorite});
    }, 400);

  }

  const handleClickRecentFavorite = async (key, val, type) => {


    dispatch({type: ACTION.SUBMIT_SEARCH});
    animateSlideResult(false)
    //check submit error 
    try {
      const {chklist, address} = val;
      const addressId = address.innb+val.dong+val.ho;

      refSearchField.current.children.searchInput.value = address.jibunAddr;

      var locationName = `${address.siNm} ${address.sggNm}`;
      var [dongList, hoList, lcodeInfo] = await Promise.all([
        getDongList(chklist.pnuCnvr, chklist.rnAddrYn, chklist.smrizeAt, chklist?.roadPnu),
        getHoList(chklist.pnuCnvr, chklist.rnAddrYn, val.dong, chklist?.roadPnu),
        getLcodeInfo(locationName, reState.currentModelId)
      ]);



      if((dongList.length > 0 && val.dong.trim().length === 0) || (hoList.length > 0 && val.ho.trim().length === 0)){
        // error = {code: "009", msg: SEARCH_ERROR_CODE["009"], confirmFn: closeModalReset, param: null}
        throw new Error(null);
      }


      //check if location code is valid
      if(!lcodeInfo || lcodeInfo < 1){
        //try using upper level location code
        locationName= `${address.siNm}`;
        lcodeInfo = await getLcodeInfo(locationName, reState.currentModelId);

        if(!lcodeInfo || lcodeInfo < 1){
          //if still undefined throw a location_code error
          dispatch({type: ACTION.SET_ERROR, payload: {code: "008", msg: SEARCH_ERROR_CODE["008"], confirmFn: closeModalReset, param: null}});
        }
      }


      //set Ho and Dong dropdown boxes
      //set state.sCategory 
      const categoryCode = chklist.prposChkList[0].prposChkNm;
      dispatch({type: ACTION.SET_ADDRESS, payload: {chklist, address, dongList, hoList, sCategory: addressType[categoryCode].searchCategoryCode}})

      const addressInState = reState.address.byId[addressId];
      const jaduPriceInfo = addressInState?.info || await getJaduAptPrice(chklist, val.dong, val.ho, user);

      const location = {
        chklist: chklist,
        address: address,
        dong: val.dong, 
        ho: val.ho, 
        info: jaduPriceInfo,
        locInfo:lcodeInfo,
      }

      //update last visited
      await updateSearchHistoryParameter(key, type, location);


      const modelDataInState = reState.model.byId[reState.currentModelId]?.data.byId[location.locInfo.location_code];
      const [modelVariables, modelBaseData] = (modelDataInState !== undefined) 
        ? [modelDataInState.variable, modelDataInState.baseData]
        : await Promise.all([
          getRealEstateModelVariablesObj(reState.currentModelId, location.locInfo.location_code, user),
          getRealEstateModelBaseDataObj(reState.currentModelId, location.locInfo.location_code, user),
          ])

      const modelData = {
        locationCode: location.locInfo.location_code,
        variable: modelVariables,
        baseData: modelBaseData,
        subscript: getRealEstateSubscript(modelVariables)
      }


      reDispatch({
        type: RE_ACTION.SET_CURRENT, 
        payload: {modelInfo: reState.model.byId[reState.currentModelId]?.info, modelData: modelData, addressInfo: location}
      })

      dispatch({type: ACTION.SUBMIT_RECENT_FAV_SUCCESS, payload: {addressInfo: jaduPriceInfo, lcodeInfo, ho: val.ho, dong: val.dong}})

    } catch (err) {
      console.error(err)
      dispatch({type: ACTION.SET_ERROR, payload: {code: "009", msg: SEARCH_ERROR_CODE["009"], confirmFn: closeModalReset, param: null}});
    }



    // }
    
  }

  const animateSlideResult = (isOpen, e) => {
    if (!isOpen) {
      refSearchResult.current.style.height = 0;
      refSearchField.current.style.borderBottomRightRadius = "16px";
      refSearchField.current.style.borderBottomLeftRadius = "16px";
    } else {
      const minH = 25 + 400 + 40;
      const h = Math.min(minH, refSearchResult.current.scrollHeight)
      // refSearchResult.current.style.height = minHeight + 'px'; 
      refSearchResult.current.style.height = minH + 'px'; 
      refSearchField.current.style.borderBottomRightRadius = 0;
      refSearchField.current.style.borderBottomLeftRadius = 0;


      if(refSearchResultScroll?.current){
        refSearchResultScroll.current.scroll({
          top: 0,
        });
      }

    }
  }


  const getSearchHistory = React.useCallback(async () => {
    try {
      const query = `?page_size=all&sort=-created_at&type__in=${SEARCH_HISTORY_TYPE.RECENT},${SEARCH_HISTORY_TYPE.FAVORITE}`;
      const res = await fetch(`${API_BASE_URL}/api/addrsh${query}`, {
        method: "GET",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      });
      const parseRes = await res.json();

      if (res.status === 403) throw new ResponseError(parseRes.detail);
      if (res.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!res.ok) throw new Error("Unknown error occured. Please contact us for assistance.")

      return parseRes.data;

      
    } catch (err) {
        alert(err.message)
    }
  }, [])


  const setSearchHistory = React.useCallback(async () => {
    const searchHistory = await getSearchHistory();
    const recent = Object.fromEntries(searchHistory.filter(val => val.type === SEARCH_HISTORY_TYPE.RECENT).map(val => [val.search_key, val.parameters]));
    const favorites = Object.fromEntries(searchHistory.filter(val => val.type === SEARCH_HISTORY_TYPE.FAVORITE).map(val => [val.search_key, val.parameters]));
    dispatch({type: ACTION.SET_RECENT_FAV, payload: {recent, favorites}});
  }, [])


  useEffect(() => {
    setSearchHistory();
  }, [setSearchHistory])
  
  return (
    <div className="bg-gray-200 p-5 w-full rounded-xl shadow-lg shadow-gray-500/50">
      <SearchAddressErrorModal isOpen={state.isError} setClose={closeModal} errorObj={state.error} />
      {/* <LoadingModal isOpen={state.submitSearchLoading} /> */}
      <LoadingModalWithText isOpen={state.submitSearchLoading}>
        <div className="flex items-center justify-center space-x-2">
          <img className="h-5 mr-4" src={require("../../assets/images/JADU_BI_W.svg").default} alt="" />
          <ArrowSmallRightIcon  className="custom-animate-bounce-x h-8 text-white" />
          <img className="h-4" src={require("../../assets/images/konai_logo_darkbg.svg").default} alt="" />
        </div>
        <div><span className="text-white text-xl font-semibold">관련 정보를 조회 중 입니다. 잠시만 기다려주십시오.</span></div>
      </LoadingModalWithText>
      
      <div className="flex text-xl leading-none space-x-6">

        {/* category selection */}
        <div className="font-medium bg-white rounded-2xl relative z-0">
          <button className={getCatBtnClass(state.sCategory, state.sCategoryPrev, searchCategory.apt.code)} onClick={() => dispatch({type: ACTION.SET_CATEGORY, payload: searchCategory.apt.code})}>아파트</button>
          <button className={getCatBtnClass(state.sCategory, state.sCategoryPrev, searchCategory.multi.code)} onClick={() => dispatch({type: ACTION.SET_CATEGORY, payload: searchCategory.multi.code})}>연립다세대</button>
          <button className={getCatBtnClass(state.sCategory, state.sCategoryPrev, searchCategory.house.code)} onClick={() => dispatch({type: ACTION.SET_CATEGORY, payload: searchCategory.house.code})}>단독주택</button>
          <div className={getCatBgClass(state.sCategory)}></div>
        </div>

        {/* search input */}
        <div className="flex-1 bg-white rounded-2xl relative z-0 group">
          <div ref={refSearchField} className="h-full w-full rounded-2xl overflow-hidden flex box-border border border-gray-300">
            <input
              autoComplete="off"
              name="searchInput"
              type="search" 
              placeholder="관심있는 부동산의 주소를 입력해보세요" 
              className="re-search-input w-full outline-none pl-6 pr-2" 
              onChange={() => handleSearchQueryDebounce()}
              onFocus={(e) => animateSlideResult(true, e)}
              // onBlur={() => animateSlideResult(false)}
              // onBlur={(e)=> animateSlideResult("searchResults", false)}
            />
            
          </div>
        
          {/* start of search results */}
          <div id="searchResults" ref={refSearchResult} className="flex flex-col justify-end bg-white w-[calc(100%-2px)] absolute left-[1px] top-[25px] rounded-b-2xl shadow-lg shadow-gray-500/50 slideDown -z-10">
            
            {
              (() => {
                if (state.sLoading) {
                  return (
                    <div className="flex-1 pt-[25px] flex justify-center items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-konared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </div>
                  )
                }
                else
                {
                  if(state.sQueryResult && state.sQueryResult.length > 0) {
                    //if user inputted a query and results exists
                    return (
                      <div className="flex-1 pt-[25px]">
                        <div ref={refSearchResultScroll} className="flex flex-col overflow-y-auto max-h-[400px]" >
                          {state.sQueryResult.map((el, i) => {
                            let searchTxt = refSearchField.current.children.searchInput.value.trim();
                            let addressTxt = el.jibunAddr;
                            let addressTxt2 = `${el.rn} ${el.roadAddrPart2}`;
                            let idx = addressTxt.toLowerCase().indexOf(searchTxt);
                            if (idx >= 0) { 
                              //for main address
                              addressTxt = [addressTxt.substring(0, idx), <span key={`s${i}`} className="text-konared">{addressTxt.substring(idx, idx + searchTxt.length)}</span>, addressTxt.substring(idx + searchTxt.length)]
                            }
                            else{
                              //for sub address
                              let idx2 = addressTxt2.toLowerCase().indexOf(searchTxt);
                              if (idx2 > 0) { 
                                addressTxt2 = [addressTxt2.substring(0, idx2), <span key={`s${i}`} className="text-konared/50">{addressTxt2.substring(idx2, idx2 + searchTxt.length)}</span>, addressTxt2.substring(idx2 + searchTxt.length)]
                              }
                            }
    
                            return(
                              <div  key={`res-${i}`} className="flex items-center py-4 px-6 hover:bg-gray-100 [&>*:nth-child(2)]:hover:text-konared cursor-pointer" onMouseDown={(e) => handleSearchClick(e, i, el)}>
                                <div className="flex-1 flex flex-col">
                                  <div className="text-xl">{addressTxt}</div>
                                  <div className="text-base text-gray-500 font-light">{addressTxt2}</div>
                                </div>
                                
                                <ArrowUpRightIcon className="h-4" />
                              </div>
                            )}
                          )}
                        </div>
                      </div>
                    )
                  } else if (state.sQuery && state.sQueryResult.length < 1) {
                    // if user inputted a query but there are no results
                    return (
                      <div className="flex-1 pt-[25px] flex flex-col justify-center items-center">
                        <p className="text-base text-gray-400 p-kr">조회된 주소가 없습니다.</p>
                        <p className="text-base text-gray-400 p-kr">도로명 또는 지번 주소를 다시 확인해주세요.</p>
                      </div>
                    )
                  }
                  else{
                    return (
                      <div className="flex-1 pt-[25px] flex flex-col">
                        <div className="flex-1 flex flex-col  overflow-y-auto max-h-[400px]">
                          {(state.recent && Object.keys(state.recent).length > 0) &&
                            <>
                              <span className="flex items-center pt-4 pb-2 px-6 font-semibold text-md">Recent</span> 
                              {state.recent && Object.entries(state.recent).map(([key,val],index) => {
                                let addressTxt = val.address.jibunAddr;
                                let addressTxt2 = `${val.address.rn} ${val.address.roadAddrPart2}`;
                                let hoTxt = val.ho.length > 0 && val.ho.indexOf("호") < 0 ? `${val.ho}호` : val.ho ;
                                let dongTxt = val.dong.length > 0 && val.dong.indexOf("동") < 0 ? `${val.dong}동` : val.dong ;
                                const dataRowId = "rc-" + Math.random().toString(36).substring(2);

                                return (
                                  <div key={dataRowId} data-row={dataRowId} className="flex space-x-2 items-center py-2 px-6 hover:bg-gray-100 cursor-pointer" onClick={() => handleClickRecentFavorite(key, val, SEARCH_HISTORY_TYPE.RECENT)}>
                                    <div className="flex-1 flex flex-col">
                                      <div className="p-kr text-base">{addressTxt2}</div>
                                      <div className="p-kr text-sm text-gray-400 font-light">[지번] {addressTxt} {dongTxt} {hoTxt}</div>
                                    </div>
                                    <div className="flex h-6">
                                      <StarIconOutline className="text-gray-400 cursor-pointer hover:text-konayellow" onClick={(e) => handleSaveFavorite(e, dataRowId, key, val)} />
                                      <span className="inline-flex border-l mx-2"></span>
                                      <XMarkIcon className="text-gray-400 cursor-pointer hover:text-black" onClick={(e) => handleDeleteRecent(e, dataRowId, key)} />
                                    </div>
                                    
                                  </div>
                                )
                              })}
                            </>
                          }

                          {(state.favorites && Object.keys(state.favorites).length > 0) &&
                            <>
                              <span className="flex items-center pt-4 pb-2 px-6 font-semibold text-md">Favorite</span> 
                              {state.favorites && Object.entries(state.favorites).map(([key,val],index) => {
                                let addressTxt = val.address.jibunAddr;
                                let addressTxt2 = `${val.address.rn} ${val.address.roadAddrPart2}`;
                                let hoTxt = val.ho.length > 0 && val.ho.indexOf("호") < 0 ? `${val.ho}호` : val.ho ;
                                let dongTxt = val.dong.length > 0 && val.dong.indexOf("동") < 0 ? `${val.dong}동` : val.dong ;
                                const dataRowId = "rc-" + Math.random().toString(36).substring(2);

                                return (
                                  <div key={dataRowId} data-row={dataRowId} className="flex space-x-2 items-center py-2 px-6 hover:bg-gray-100 cursor-pointer" onClick={() => handleClickRecentFavorite(key, val, SEARCH_HISTORY_TYPE.FAVORITE)}>
                                    <div className="flex-1 flex flex-col">
                                      <div className="p-kr text-base">{addressTxt2}</div>
                                      <div className="p-kr text-sm text-gray-400 font-light">[지번] {addressTxt} {dongTxt} {hoTxt}</div>
                                    </div>
                                    <div className="flex h-6">
                                      <StarIcon className="text-konayellow cursor-pointer" />
                                      <span className="inline-flex border-l mx-2"></span>
                                      <XMarkIcon className="text-gray-400 cursor-pointer hover:text-black" onClick={(e) => handleDeleteFavorite(e, dataRowId, key)} />
                                    </div>
                                    
                                  </div>
                                )
                              })}
                            </>
                          }
                          {state.searchHistoryLoading 
                            ? <div className="flex-1 pt-[25px] flex justify-center items-center h-full">
                                <svg className="animate-spin -ml-1 mr-3 h-7 w-7 text-konared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                              </div>
                            : (!state.recent || Object.keys(state.recent).length < 1) && (!state.favorites || Object.keys(state.favorites).length < 1) &&
                              <div className="flex-1 pt-[25px] flex justify-center items-center h-full">
                                <div className="text-base text-gray-400 p-kr">No Recent Searches</div>
                              </div>
                          }

                        </div>
                        
                       
                      </div>
                    )
                  } 
                }
              })()
            }

            <div className="h-[40px] flex items-center justify-end px-4 text-xs font-light space-x-2 text-gray-500 border-t">
              <span>Results By</span>
              <a target="_blank" rel="noopener noreferrer" href="https://www.jadurang.kr/jadu/main"><img className="h-3" src={require("../../assets/images/JADU_BI.svg").default} alt="" /></a>
            </div>
          </div>
          {/* end of search results */}
        </div>

        {/* ho, dong selection */}
        <div className="flex space-x-4">
          {state.dongHoListLoading
          ?
          <div className="w-[316px] flex items-center space-x-4">
            <svg className="animate-spin h-6 w-6 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            <span className="text-base">동/호 정보를 로딩 중입니다...</span>
          </div>
          :
          <>
            <div className="w-[150px]">
              <SearchListBox 
                datalist={state.dongList} 
                selectedVal={state.dong} 
                onChangeVal={handleDongChange} 
                placeholder={state.address && state.dongList.length > 0 ? "동 선택": "동정보 없음"} 
                disabled={state.address && state.dongList.length > 0 ? false : true}
              />
            </div>
            <div className="w-[150px]">
              <SearchListBox 
                datalist={state.hoList} 
                selectedVal={state.ho} 
                onChangeVal={handleHoChange} 
                placeholder={state.address && state.hoList.length > 0 ? "호 선택" : "호정보 없음"}  
                disabled={state.address && state.hoList.length > 0 ? false : true}
              />
            </div>
          </>
          }

         
          
        </div>

        {/* search button */}
        <div>
          {state.submitSearchLoading
          ?
          <div className="p-[10px] rounded-2xl bg-konared">
            <svg className="animate-spin h-[30px] w-[30px] text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          </div>
          :
          <button onClick={onSearchSubmit} className="p-[10px] rounded-2xl bg-konared text-white hover:scale-110 duration-150"><BiSearch size={30} /></button>
          }

  
        </div>

      </div>
    </div>
  )
}

export default SearchBar