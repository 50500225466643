import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import {HiX} from 'react-icons/hi'
import Echarts from "../charts/Echarts"
import ParamDescData from '../../assets/param-description.json'
import { getChartOptionParamInfoModal } from "../charts/EchartOptions"



const ParamInfoModal = ({ isOpen, setIsOpen, ...rest }) => {
  const {paramInfo, inputVarInfo} = rest;
  const paramDesc = ParamDescData[paramInfo.paramName];
  
  

  const renderInfo = () => {
    // var [lastDate, lastDataVal] = vData.data.value.at(-1)
    var [lastDate, lastDataVal] = inputVarInfo.data_in[0].value.at(-1)
    var lastDateKr = `${lastDate.split("-")[0]}년 ${lastDate.split("-")[1]}월` 

    return (
      <div className="flex flex-col space-y-3 mt-3 overflow-y-auto max-h-[900px]">
        <div className="text-xl font-bold">{paramDesc.title}</div>
        <div className="p-kr text-justify text-base">{paramDesc.description}</div>
        <div className="pt-3">
          <div className="flex flex-col space-y-1">
            <span className="text-xs text-gray-600 font-semibold">{paramDesc.title} Raw Data</span>
            <span className="text-xs text-gray-600">현재 ({lastDateKr}): <b className="text-konared">{lastDataVal.toLocaleString()}</b> </span>
            <span className="text-xs text-gray-600">단위: </span>
          </div>
          <div className="h-[180px]"><Echarts options={getChartOptionParamInfoModal(inputVarInfo.data_in[0].value)} /></div>
        </div>
        
      </div>
    );

  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-md  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex items-center"
                >
                  <span className="flex-1 text-md font-medium leading-6 text-gray-900">Parameter Information</span>
                  <button onClick={() => setIsOpen(false)} className="hover:text-orange-400"><HiX size={20} /></button>
                </Dialog.Title>
                
                <div className="flex flex-col space-y-3 w-full">
                  
                  {/* content of info */}
                  {renderInfo()}
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ParamInfoModal