import React from 'react'
import { NavLink, useParams } from "react-router-dom";
import dayjs from "dayjs";
import {maskName} from "@toss/utils"
import { addCommentApi, addNoticeUserReactionsApi, deleteCommentApi, deleteNoticeUserReactionsApi, getCommentsApi, getUserCommentReactionsApi } from "../../utils/api/referenceApi";
import ReferenceHeader from "./ReferenceHeader";
import { 
  UserIcon, 
  EyeIcon,
  HandThumbUpIcon,
  ChatBubbleOvalLeftEllipsisIcon,
  TrashIcon,
  ClockIcon  } from '@heroicons/react/24/outline'
import { UserCircleIcon, HomeIcon, ChevronRightIcon, HandThumbUpIcon as HandThumbUpIconSolid } from "@heroicons/react/24/solid";
import Loader3 from "../../components/loaders/Loader3";
import MiniLoader from "../../components/loaders/MiniLoader";
import { ApiError, AppError, delay, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import DeleteCommentModal from "../../components/modals/DeleteCommentModal";
import ReferenceLoginRequireModal from "../../components/modals/ReferenceLoginRequireModal";
import { ACCOUNT_ROLE } from "../../utils/constants";

const PAGE = 1;
const PAGE_SIZE = 5; 

export const ACTION = {
	LOAD_COMMENT_REACTION: "load-comment-reaction",
  APPEND_COMMENT: "append-comment",
  DELETE_COMMENT: "delete-comment",
  SET_DELETE_MODAL: "set-delete-modal",
  SET_LOGIN_MODAL: "set-login-modal",
  SET_LOAD_MORE_LOADING: "set-load-comment-loading",
  APPEND_LOAD_MORE: "append-load-more",
}

const initialState = {
	isLoaded: false,
  comments: [],
  reactedCommentIds: [],
  isOpenDeleteModal: false,
  isOpenLoginModal: false,
  delCommentInfo: null,
  pageNum: 1,
  loadMoreLoading: false,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.LOAD_COMMENT_REACTION:
			return {...state, comments: payload.comments, reactedCommentIds: payload.reactedCommentIds, isLoaded: true};
    case ACTION.APPEND_COMMENT:
      return {...state, comments: [payload, ...state.comments]};
    case ACTION.DELETE_COMMENT:
      return {
        ...state, 
        comments: state.comments.filter(c => c.id !== payload),
      };
    case ACTION.SET_DELETE_MODAL:
      return {...state, isOpenDeleteModal: payload.isOpen, delCommentInfo: payload.info};
    case ACTION.SET_LOGIN_MODAL:
      return {...state, isOpenLoginModal: payload};
    case ACTION.SET_LOAD_MORE_LOADING:
      return {...state, loadMoreLoading: payload};
    case ACTION.APPEND_LOAD_MORE:
      return {...state, comments: [...state.comments, ...payload], pageNum: state.pageNum + 1};
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}



export default function PostComments ({postType, user, postId, totalComments, callbackFn: {setCommentCount, submitCommentLike}, callbackUrl}) {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const globalDispatch = useGlobalDispatchContext();

  const loadCommentsAndReactions = React.useCallback(async () => {
    
    let [commentList, reactionList] = await Promise.all([
      getCommentsApi(postType, PAGE, PAGE_SIZE, postId),
      getUserCommentReactionsApi(postType, postId, user),
    ]);

    const reactedCommentIds = reactionList ? reactionList.map(i => i.comment_id) : []

    dispatch({type: ACTION.LOAD_COMMENT_REACTION, payload: {comments: commentList, reactedCommentIds: reactedCommentIds}});
  }, [])


	React.useEffect(() => {
		loadCommentsAndReactions();
	},[]);


  const deleteComment = async (commentInfo) => {
    const parentDiv = document.querySelector(`[data-row=${commentInfo.blockId}]`);
    parentDiv.style.transition = "opacity 0.4s";
    parentDiv.style.opacity = 0;

    try {
      await deleteCommentApi(postType, postId, commentInfo.commentId, user);

      
      setTimeout(function() {
        parentDiv.style.display = "none";
        dispatch({type: ACTION.DELETE_COMMENT, payload: commentInfo.commentId});
        setCommentCount(totalComments - 1);
      }, 400);
      
      //separate a dispatch call for closing modal, this must be called first wihtout triggering comments rerendering
      dispatch({type: ACTION.SET_DELETE_MODAL, payload: {isOpen: false, info: null}});

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
  }


  const submitComment = async (value) => {
    try {
      const comment = await addCommentApi(postType, postId, value, user);
      //append user_info when appending comment because the api only returns comment info without user
      dispatch({type: ACTION.APPEND_COMMENT, payload: {...comment, user: user?.user_info}});
      setCommentCount(totalComments + 1);
    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
  }

  function closeLoginModal () {
    dispatch({type: ACTION.SET_LOGIN_MODAL, payload: false});
  }

  function closeDeleteModal () {
    dispatch({type: ACTION.SET_DELETE_MODAL, payload: {isOpen: false, info: null}});
  }

  function openLoginModal () {
    dispatch({type: ACTION.SET_LOGIN_MODAL, payload: true})
  }

  function openDeleteModal (commentInfo) {
    dispatch({type: ACTION.SET_DELETE_MODAL, payload: {isOpen: true, info: commentInfo}});
  }

  async function loadMore () {
    dispatch({type: ACTION.SET_LOAD_MORE_LOADING, payload: true});
    try {
      const comments = await getCommentsApi(postType, state.pageNum + 1, PAGE_SIZE, postId);

      dispatch({type: ACTION.SET_LOAD_MORE_LOADING, payload: false});
      dispatch({type: ACTION.APPEND_LOAD_MORE, payload: comments});
      
    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }

  }

  if (!state.isLoaded) {
    return <div className="flex items-center text-xs space-x-2 text-konared"><MiniLoader className="h-4" /><span className="text-konagray/50">Loading Comments</span></div>
  }
  else {
    return (
      <div className="">
        <ReferenceLoginRequireModal isOpen={state.isOpenLoginModal} closeFn={closeLoginModal} gotoUrl={callbackUrl} />
        <DeleteCommentModal isOpen={state.isOpenDeleteModal} info={state.delCommentInfo} closeFn={closeDeleteModal} confirmFn={deleteComment}  />
        <div className="mb-2"><span className=" text-konared font-semibold">{totalComments}</span>개의 댓글</div>
        <CommentForm user={user} submitCommentCallback={submitComment} gotoUrl={callbackUrl} />
        {/* comments */}
        <div className="bg-gray-50 mt-2 flex flex-col">
          {state.comments.length > 0 ? 
            state.comments.map(item => <PostCommentsDetails key={item.id} postId={postId} comment={item} user={user} reaction={state.reactedCommentIds.includes(item.id)}  callbackFn={{openDeleteModal, openLoginModal, submitCommentLike}} />)
          : (
            <div className="px-2 py-4 text-gray-500 text-center">댓글이 없습니다.</div>
          )}
        </div>
        <div className="flex justify-center py-2">
          {state.comments.length < totalComments && <button className="text-konared hover:underline" onClick={loadMore}>{state.loadMoreLoading ? <MiniLoader className="text-konared h-4" /> : <span>Load More...</span>}</button>}
        </div>
      </div>
    )
  }
}


function PostCommentsDetails({postId, comment, user, reaction, callbackFn: {openDeleteModal, openLoginModal, submitCommentLike}}){
  const [isLike, setIsLike] = React.useState(reaction);
  const [countLike, setCountLike] = React.useState(comment.likes);

  const blockId = `cm-${comment.id}`;
  const commentInfo = {
    content: comment.content,
    blockId: blockId,
    commentId: comment.id
  }


  const submitLike = async () => {
    
    await submitCommentLike(isLike, comment.id);

    if(isLike) {
      // const reaction = await deleteNoticeUserReactionsApi(postId, comment.id, user);
      setIsLike(false);
      setCountLike(countLike - 1);
    } else {
      // const reaction = await addNoticeUserReactionsApi(postId, comment.id, user);
      setIsLike(true);
      setCountLike(countLike + 1);
    }
  }

  return (
    <div className="border-b p-4" key={blockId} data-row={blockId}>
      <div className="flex items-start space-x-2">
        <UserCircleIcon className="h-10 text-konagray/60" />
        <div className="flex-1 flex flex-col">
          <div className="w-full flex space-x-2 items-end">
            <span className="font-semibold leading-none ">{comment?.user?.name && maskName(comment?.user?.name)}</span>
            <span className="text-xs text-konagray/60  leading-none">{dayjs(comment.created_at).format("YYYY-MM-DD HH:MM")}</span>
            <div className="flex-1 flex space-x-1 items-center justify-end text-konagray/80">
              {(user?.user_info?.id === comment.user_id || user?.user_info?.role === ACCOUNT_ROLE.ADMIN) && <button className="hover:text-konared cursor-pointer" onClick={() => openDeleteModal(commentInfo)}><TrashIcon className="h-4" /></button>}
              <button className="hover:text-konared cursor-pointer" onClick={() => (user) ? submitLike() : openLoginModal()}>
                {isLike ? <HandThumbUpIconSolid className="h-4 text-konared"  /> : <HandThumbUpIcon className="h-4"  />}
              </button>
              <span>{countLike}</span>
            </div>
          </div>
          <div className="mt-2">{comment.content}</div>
        </div>
      </div>
      
    </div>
  )
}



function CommentForm ({user, submitCommentCallback, gotoUrl}) {
  const [charCount, setCharCount] = React.useState(0);
  const [isFormLoading, setFormLoading] = React.useState(false);
  const refCommentField = React.useRef();

  async function submitForm () {
    const value = refCommentField.current.value;
    if(value.length > 0) {
      setFormLoading(true);
      await submitCommentCallback(value);
      refCommentField.current.value = "";
      setCharCount(0);
      setFormLoading(false);
    }
  }

  if (!user) {
    return (
      <div className="flex flex-col border rounded-lg">
        <NavLink to="/login" state={{gotoUrl: gotoUrl}} className="p-4 text-konagray/60 justify-center text-center cursor-pointer">댓글을 작성하려면 로그인 해주세요</NavLink>
      </div>
    )
  }
  else {
    return (
      <div className="flex flex-col border rounded-lg">
        <div className="p-2"> 
          <div className="pb-2 flex items-center space-x-1 text-konagray/60"><UserCircleIcon className="h-4" /><span>{user?.user_info?.name}</span></div>
          <textarea ref={refCommentField} className="w-full flex-1 border p-2 rounded-lg" placeholder="댓글 입력해주세요" maxLength="300" onChange={e => setCharCount(e.target.value.length)}/>
        </div>
        <div className="p-2 border-t flex items-center justify-between">
          <span className="text-xs">{charCount} / 300</span>
          <button className="ripple-primary text-white rounded-md px-4 py-2 text-sm font-light" onClick={submitForm}>
            {isFormLoading ? (
              <MiniLoader className="h-4 text-white" />
            ) : (
              <span>등록</span>
            )}
          </button>
        </div>
      </div>
    )
  }
}