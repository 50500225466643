import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import React from "react"
import DotLoader from "../loaders/DotLoader"

const BankSelectModal = ({isOpen, setIsOpen, bankList, setSelectedBank}) => {
  const initialFocusRef = React.useRef(null)
  const [category, setCategory] = React.useState(0);

  const handleCategoryClick = (bankCode) => {
    setSelectedBank(bankCode);
  }

  return (
    <Transition appear show={isOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-[465px] w-[465px]  transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="flex items-center"
                >
                  <span className="flex-1 text-xl font-bold text-gray-900">은행선택</span>
                  <button onClick={() => setIsOpen(false)} className="hover:text-orange-400"><XMarkIcon className="h-4 w-4" /></button>
                </Dialog.Title>
                
                <div className="flex flex-col space-y-3 w-full py-4 focus:outline-none" ref={initialFocusRef} tabIndex={-1}>
                  <div className="flex flex-col">
                    <div className="border-b flex">
                      <div className="flex-1 flex justify-center">
                        <div onClick={() => setCategory(0)} className={`cursor-pointer py-2 px-6  translate-y-[1px] ${category===0 && "border-b-2 border-konared"}`}>은행</div>
                      </div>
                      <div className="flex-1 flex justify-center">
                        <div onClick={() => setCategory(1)} className={`cursor-pointer py-2 px-6  translate-y-[1px] ${category===1 && "border-b-2 border-konared"}`}>증권사</div>
                      </div>
                    </div>

                    {/* <div className="overflow-y-auto max-h-[500px] h-[500px] grid grid-cols-3 gap-2 bg-red-100"> */}
                    <div className="overflow-y-auto max-h-[500px] h-[500px] ">
                      {(() => {
                        
                        if(bankList === null || bankList <= 0){
                          return (
                            <div className="flex h-full"><DotLoader /></div>
                          )
                        }
                        if(category === 0){

                          const listOfBanks = Object.entries(bankList)
                          .filter(([key, val]) => key[0]==="0")
                          .map(([key, val]) => ({bankCode: key, bankName: val}));

                          return (
                            
                            <div className="flex flex-wrap p-2">
                              {listOfBanks.map(items => 
                                <div className="p-2" key={items.bankCode}>
                                  <div onClick={() => handleCategoryClick(items.bankCode)} className="h-28 w-28 border rounded-xl flex flex-col items-center justify-center space-y-2 hover:bg-konabg cursor-pointer">
                                    <div><img className="w-10" src={require(`../../assets/images/bank_logo/img_logo_${items.bankCode}.png`)} alt="" /></div>
                                    <div>{items.bankName}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        }
                        else{
                          const listOfSecurities = Object.entries(bankList)
                          .filter(([key, val]) => key[0]!=="0")
                          .map(([key, val]) => ({bankCode: key, bankName: val}));

                          return (
                            <div className="flex flex-wrap p-2">
                              {listOfSecurities.map(items => 
                                <div className="p-2" key={items.bankCode}>
                                  <div onClick={() => handleCategoryClick(items.bankCode)} className="h-28 w-28 border rounded-xl flex flex-col items-center justify-center space-y-2 hover:bg-konabg cursor-pointer">
                                    <div><img className="w-10" src={require(`../../assets/images/bank_logo/img_logo_${items.bankCode}.png`)} alt="" /></div>
                                    <div>{items.bankName}</div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )
                        }
                      })()}
                    </div>

                  </div>
                </div>

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default BankSelectModal