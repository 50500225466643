import React from 'react'
import BillingBankRegistration from "./BillingBankRegistration"
import BillingHistory from "./BillingHistory"
import BillingSubscriptions from "./BillingSubscriptions"
import { PlusIcon } from '@heroicons/react/24/solid'
import BillingContext, { ACTION as BILLING_ACTION, BILLING_PAGES } from "../../context/BillingProvider";
import BillingKonaOApiTerms from "./BillingKonaOApiTerms"
import BillingKonaOApiRegister from "./BillingKonaOApiRegister"
import { ACCOUNT_TYPE } from "../../../utils/constants"

const BillingContent = () => {
  const {billingState, billingDispatch} = React.useContext(BillingContext);
  
  const ConnectedAccount = () => {
    if (billingState.userInfo?.account_type === ACCOUNT_TYPE.CORPORATE){
      return (
        <div className="flex flex-col">
          <div>It is currently not possible to register a bank account for corporate accounts.</div>
          <div>견적서 요청 및 이용요금의 결제는 당사 이메일(<span className="font-bold text-konared">ikyjeong@naver.com</span>)로 문의 바랍니다.</div>
        </div> 
      )

    }
    else if ((billingState.userInfo?.account_type === ACCOUNT_TYPE.PERSONAL && billingState.userInfo?.oapiInfo && billingState.userInfo.oapiInfo?.bank_account !== null)) 
    {
      return (
        <div className="flex flex-col w-56 h-[120px] border-2 p-2 rounded-md bg-gray-50">
          <div className="flex-1 flex">
            <div className="flex-1"><img className="h-8" src={require(`../../../assets/images/bank_logo/img_logo_${billingState.userInfo.oapiInfo.bank_code}.png`)} alt="" /></div>
            <div className=""><button onClick={() => billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.BANK_REGISTRATION})} className="text-xs bg-konagray/5  border border-konagray/30 hover:bg-konagray/20 rounded-xl py-1 px-2">계좌변경</button></div>
          </div>
          <div className="text-xs text-konasub/60">계좌번호</div>
          <div className="text-sm">{billingState.userInfo.oapiInfo.bank_account}</div>
        </div>
      )
    }
    else {
      return (
        <div className="flex items-center space-x-2">
          <div>There is currently no connected payment account.</div>
          <button onClick={() => billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.BANK_REGISTRATION})} className="bg-konared text-white inline-flex items-center text-sm justify-center rounded p-2 ripple-primary"><PlusIcon className="h-3 w-3" /><span>계좌 등록하기</span></button>
        </div> 
      )
    }
  }

  return (
    <>
      <div className="pt-10 pb-5 border-b-[1px] border-gray-200">
        <h1 className="text-2xl font-bold text-konared">Billing</h1>
      </div>

      {(() => {
        if(false){
          //TODO: loading, 
        }
        else{
          switch(billingState.frameView) {
            case BILLING_PAGES.BILLIN_MAIN:
              return (
                <>
                  <div className="py-4 border-b">
                    <div className="text-lg font-semibold pb-2">Connected Payment Account</div>
                    <ConnectedAccount />
                  </div>
                  
                  <BillingSubscriptions />
                  <BillingHistory />
                </>
              );
            case BILLING_PAGES.BANK_REGISTRATION:
              return (
                <div className="mt-10 max-w-[600px] mx-auto">
                  <BillingBankRegistration />
                </div>
              );
            case BILLING_PAGES.OAPI_TERMS:
              return (
                <div className="mt-10 max-w-[600px] mx-auto">
                  <BillingKonaOApiTerms />
                </div>
              );
            case BILLING_PAGES.OAPI_REGISTER:
              return (
                <div className="mt-10 max-w-[600px] mx-auto">
                  <BillingKonaOApiRegister />
                </div>
              );
            default:
              console.error("ERROR: cannot determine frameView");
              
          }
        }
      })()}
    </>
  )
}

export default BillingContent