import React, { Fragment, useReducer, useEffect, useState } from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import Echarts from '../../components/charts/Echarts'
import FilterBar from '../../components/population/FilterBar'
import { CardWrapper, CardHeader, CardContent } from '../../components/population/CardWrapper'
import { getChartOptionHouseholdBar, getChartOptionHouseholdLine } from '../../components/charts/PopulationEchartOptions'
import { round } from 'lodash'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal'
import LoadingModal from '../../components/modals/LoadingModal'
import { handleError } from '../../utils/pop-handle-response'
import { checkSubscribed } from '../../utils/pop-subscription-checker'
import { NavLink, useNavigate } from 'react-router-dom'
import { useGlobalStateContext } from "../../components/context/GlobalContext"


const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
	SET_SIDO_DATA: 'set-current-sido',
	SET_SIDO_LIST: 'set-sido-list',

}

const initialState = {
	currentSD: '전국',
	sidoList: null,
	mainData: null,
	housedata: null,
	singleData: null,
	personHousehold: null
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_SIDO_DATA:
			return {
				...state,
				currentSD: payload.currentSD,
				housedata: payload.housedata,
				singleData: payload.singleData,
				personHousehold: payload.personHousehold
			};
		case ACTION.SET_SIDO_LIST:
			return { ...state, sidoList: payload };
		case ACTION.SET_MAIN_DATA:
			return {
				...state,
				mainData: payload.mdata,
				housedata: payload.housedata,
				singleData: payload.singleData,
				personHousehold: payload.personHousehold,
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

function PopHousehold() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isSimRunning, setSimRunning] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useGlobalStateContext();
	const [isSubscribed, setSubscribed] = useState(false)
	let navigate = useNavigate()
	
	useEffect(() => {

		const loadDefault = async () => {
					
			let isUserSubscribed = false

			try {
				isUserSubscribed = await checkSubscribed(user)
				
				setSimRunning(true)

				if(isUserSubscribed){
					
					setSubscribed(true)
					
					let query = `?page_size=all&loc_type=sd`
					let response = await fetch(`${API_BASE_URL}/api/population/location-map${query}`,{
						method: "GET",
						headers: {"Content-Type" : "application/json"}
					});

				
					if (!response.ok) throw new Error(handleError(response.status))

					let parseRes = await response.json()

					if(!parseRes.data || parseRes.data.length<=0) throw new Error("Unable to fetch location")

					dispatch({ type: ACTION.SET_SIDO_LIST, payload: parseRes.data })

					query = `?page_size=all&name_en__sw=household`
					response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
						method: "GET",
						headers: { "Content-Type": "application/json" },
					});

					if (!response.ok) throw new Error(handleError(response.status))

					let parseRes2 = await response.json()

					if(!parseRes2.data || parseRes2.data.length<=0) throw new Error(" No house data available")

					let mainData = processData(parseRes2.data)
				
					dispatch({
						type: ACTION.SET_MAIN_DATA,
						payload: {
							mdata: mainData,
							housedata: getChartOptionHouseholdBar({
								name: mainData['총 가구 수'][state.currentSD]['name'],
								type: "bar",
								showSymbol: true,
								data: Object.entries(mainData['총 가구 수'][state.currentSD]['data']).map(([key, value]) => { return [key, round(value, 0)] }),
								markPoint: {
								  data: [
									{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
									{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
								  ]
								},
							}),
							singleData: getChartOptionHouseholdLine({
								name: '1인 가구 비율 -' + mainData['일인 가구 비율'][state.currentSD]['name'].split('-')[1],
								type: "line",
								showSymbol: true,
								data: Object.entries(mainData['일인 가구 비율'][state.currentSD]['data']).map(([key, value]) => { return [key, round(value, 4)] }),
								markPoint: {
								  data: [
									{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
									{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
								  ]
								},
							}),
							personHousehold: getChartOptionHouseholdLine({
								name: mainData['세대 당 가구원 수'][state.currentSD]['name'],
								type: "line",
								showSymbol: true,
								data: Object.entries(mainData['세대 당 가구원 수'][state.currentSD]['data']).map(([key, value]) => { return [key, round(value, 2)] }),
								markPoint: {
								  data: [
									{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
									{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
								  ]
								},
							})
						}
					})

				}
				
			}  catch (error) {
				setIsOpen(true)
				console.error(error.message)
				
			}finally{
				await setSimRunning(false)

				if(!isUserSubscribed)
					navigate("/pop-sub-redirect")	
			}

		}

		loadDefault();

	}, [])

	const processData = (dataSource) => {
		let data = {}

		let names = ['총 가구 수', '일인 가구 비율', '세대 당 가구원 수']
		names.forEach(name => {
			let hdata = dataSource.filter(item => item.name_kr.includes(name))
			let vals = {}

			if(hdata.length>0){
				hdata.forEach(item => {
					let content = {}
					content['loc_code'] = item.loc_code
					content['name'] = item.name_kr

					let val_detail = {}

					item.value.forEach(pvals => {
						val_detail[pvals[0]] = pvals[1]
					})
					content['data'] = val_detail
					vals[item.name_kr.split('-')[1]] = content

				});
				data[name] = vals
			}
		})

		return data
	}
	const handleSIDOChange = (e) => {
	
		dispatch({
			type: ACTION.SET_SIDO_DATA,
			payload: {
				currentSD: e,
				housedata: getChartOptionHouseholdBar({
					name: state.mainData['총 가구 수'][e]['name'],
					type: "bar",
					showSymbol: true,
					data: Object.entries(state.mainData['총 가구 수'][e]['data']).map(([key, value]) => { return [key, round(value, 0)] }),
					markPoint: {
					  data: [
						{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
						{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
					  ]
					},
				}),
				singleData: getChartOptionHouseholdLine({
					name: '1인 가구 비율 -' + state.mainData['일인 가구 비율'][e]['name'].split('-')[1],
					type: "line",
					showSymbol: true,
					data: Object.entries(state.mainData['일인 가구 비율'][e]['data']).map(([key, value]) => { return [key, round(value, 4)] }),
					markPoint: {
					  data: [
						{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
						{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
					  ]
					},
				}),
				personHousehold: getChartOptionHouseholdLine({
					name: state.mainData['세대 당 가구원 수'][e]['name'],
					type: "line",
					showSymbol: true,
					data: Object.entries(state.mainData['세대 당 가구원 수'][e]['data']).map(([key, value]) => { return [key, round(value, 2)] }),
					markPoint: {
					  data: [
						{ type: 'max', name: 'Max', label: { formatter: "H", color: 'white'}, symbolSize: 30, itemStyle: {color: '#4caf50'} },
						{ type: 'min', name: 'Min', label: { formatter: "L", color: 'white'}, symbolSize: 30, itemStyle: {color: '#FF3C42'}  }
					  ]
					},
				})
			}
		})

	}


	return (
		<MainContentWrapper className="bg-konabg relative">
		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
		<LoadingModal isOpen={isSimRunning} />
			<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6">
		
				<FilterBar title={'데이터 선택'} className="col-span-2" >
					<div className='px-5 text-base'>
						<Listbox value={state.currentSD} onChange={handleSIDOChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-10 min-w-[150px]">
										<Listbox.Button className="relative w-full cursor-default border-2 border-gray-50 rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:border-gray-200 sm:text-sm">
											<span className="block truncate">{(state.sidoList !== null && state.currentSD !== '전국') ? state.sidoList.filter(item => item.name === state.currentSD)[0].name : '전국'}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto -z-10"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												<Listbox.Option
													className={({ active }) =>
														`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
														}`
													}
													value="전국">
													전국
												</Listbox.Option>
												{
													(state.sidoList) ? state.sidoList.map(({ location_code, name }, index) => (

														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={name}
														>
															{name}
														</Listbox.Option>

													))
														:
														<Listbox.Option value="none">정보 없음</Listbox.Option>
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >


					</div>
				</FilterBar>

				<CardWrapper className="col-span-2 h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								가구 수 예측
							</div>
							<div className='flex text-right text-lg'>
								단위: 가구
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5 " >
						{
							state.housedata ?
								<Echarts options={state.housedata} />
								:
								<div className="h-full flex justify-center items-center"> </div>
						}
					</CardContent>
				</CardWrapper>

				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								세대당 가구원 수
							</div>
							<div className='flex text-right text-lg'>
								단위: 명/세대
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5" >
						{
							state.personHousehold ?
								<Echarts options={state.personHousehold} />
								:
								<div className="h-full flex justify-center items-center"></div>
						}
					</CardContent>
				</CardWrapper>

				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								1인 가구 비율
							</div>
							<div className='flex text-right text-lg'>
								단위: %
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5" >
						{
							state.singleData ?
								<Echarts options={state.singleData} />
								:
								<div className="h-full flex justify-center items-center"></div>
						}
					</CardContent>
				</CardWrapper>
			</div>
		</MainContentWrapper>
	)
}

export default PopHousehold