import React from 'react'
import { useForm, useController} from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import RegistrationContext, {ACTION as REG_ACTION} from "../context/RegistrationProvider";
import { CheckIcon } from '@heroicons/react/24/outline'
import { ACCOUNT_TYPE } from "../../utils/constants";
import RegSteps from "./RegSteps";
import Loader3 from "../loaders/Loader3";


const PersonalRegTerms = () => {
  const navigate = useNavigate();
  const {registrationState, registrationDispatch} = React.useContext(RegistrationContext);

  const form = useForm({mode:"onChange"});


  React.useEffect(() => {

    if(registrationState.regType !== ACCOUNT_TYPE.PERSONAL){
      navigate("/register");
    }

  },[]);


  const onSubmit = async data => {
    //if submit is triggered, it is assumed all required TOS was checked
    delete data["tAll"];  //remove tall value
    delete data["tAge"];  //remove age value
    
    //only save TOS which is tracked in DB
    const terms_agreed = Object.entries(data)
      .filter(([key, val]) => val === true)
      .map(([key, val]) => parseInt(key.split("_")[1]));

    //set TOS
    registrationDispatch({type: REG_ACTION.SET_TOS, payload: terms_agreed});
    navigate("/register/preg-info");
  } 


  const handleAllSelect = (e) => {
    const tAllVal = e.target.checked;

    //age agreement
    form.setValue(`tAge`, tAllVal, { shouldValidate: true });
    //TOS
    Object.values(registrationState.regPersonalTerms).forEach(terms => {
      form.setValue(`t_${terms.id}`, tAllVal, { shouldValidate: true });
    });
    // form.setValue("t1", tAllVal, { shouldValidate: true });
    // form.setValue("m1", tAllVal, { shouldValidate: true });
  }

  const handleTermsInputChange = (e) => {
    const tVals = form.getValues();
    delete tVals["tAll"];
    
    Object.values(tVals).forEach(val => {
      if (val === false) form.setValue("tAll", false);
    });
  }

  const ErrorMessage = errors => {
    const reqMsg = "필수약관에 모두 동의해주셔야 회원가입을 진행할 수 있습니다.";
    const message = Object.keys(errors).length > 0 ? Object.values(errors)[0].type==="required" ? reqMsg : "unknown error" : null;
    return (
      <div>{message}</div>
    )
  }



  const TermsInput = ({ control, name, termInfo}) => {
    const {field} = useController({name, control, rules: { required : termInfo.is_mandatory }, defaultValue: false});
    return (
      <div className="flex items-center px-3 py-5 border-b border-gray-300">
        <div className="flex-1">
          <input className="hidden" id={name} type="checkbox" {...form.register(name, {onChange: (e) => handleTermsInputChange(e)})} />
          <label className="flex items-center space-x-2 cursor-pointer" htmlFor={name}>
            <div className={`inline-flex items-center justify-center h-8 w-8 rounded-full p-1 ${form.watch(name) ? "bg-konared":"border border-gray-300"}`}><CheckIcon className={`${form.watch(name) ? "text-white":"text-gray-300"}`} /></div>
            <span className="text-xl text-konagray">{`[${termInfo.is_mandatory ? '필수' : '선택'}] ${termInfo.title}`}</span> 
          </label>
        </div>
        <a className="border border-gray-300 rounded-md p-2 w-20 text-center text-konagray hover:bg-gray-100" target="_blank" rel="noopener noreferrer" href={termInfo.url}>보기</a>
      </div>
     
    );
  }

  return (
    <div className="py-20 px-48">
      <div className="flex flex-col items-center space-y-8 pb-10">
        <h1 className="text-4xl leading-none font-bold">개인회원 약관동의</h1>
        <RegSteps prevStep={1} nextStep={1} />
      </div>

      <div className="w-full max-w-4xl mx-auto">
        <form onSubmit={form.handleSubmit(onSubmit)}>
          
            {(() => {
              if(registrationState.regPersonalTerms.length > 0){
                return(
                  <div className="flex flex-col">
                    {/* tAll */}
                    <div className="px-3 py-5 border-y border-konagray">
                      <input className="hidden" id="tAll" type="checkbox" {...form.register("tAll")} onClick={(e) => handleAllSelect(e)} />
                      <label className="flex items-center space-x-2 cursor-pointer" htmlFor="tAll" >
                        <div className={`inline-flex items-center justify-center h-8 w-8 rounded-full p-1 ${form.watch(`tAll`) ? "bg-konared":"border border-gray-300"}`}><CheckIcon className={`${form.watch(`tAll`) ? "text-white":"text-gray-300"}`} /></div>
                        <span className="text-xl text-konagray">이용약관 전체 동의</span> 
                      </label>
                    </div>


                    <div className="px-3 py-5 border-b border-gray-300">
                      <input className="hidden" id="tAge" type="checkbox" {...form.register("tAge", { required: true})} />
                      <label className="flex items-center space-x-2 cursor-pointer" htmlFor="tAge" >
                        <div className={`inline-flex items-center justify-center h-8 w-8 rounded-full p-1 ${form.watch(`tAge`) ? "bg-konared":"border border-gray-300"}`}><CheckIcon className={`${form.watch(`tAge`) ? "text-white":"text-gray-300"}`} /></div>
                        <div className="text-xl text-konagray">본인은 만 14세 이상입니다. <span className="text-base">(만 14세 미만일 경우 회원가입이 불가합니다.)</span></div> 
                      </label>
                    </div>

                    {registrationState.regPersonalTerms.map((item, index) => {
                        return (
                          <TermsInput name={`t_${item.id}`} control={form.control} termInfo={item} key={index} />
                        );
                      })
                    }
                    <div className="pt-10">
                      <div className="flex justify-center -translate-y-5 text-red-500 text-lg"><ErrorMessage {...form.formState.errors} /></div>
                      <div className="flex justify-center space-x-5">
                        <NavLink 
                          onMouseDown={e => e.preventDefault()} 
                          to={registrationState.locationState ? registrationState.locationState?.prevPath : "/" }
                          state={registrationState.locationState} 
                          className="w-[180px] py-4 rounded-xl text-xl text-center font-semibold ripple-gray-outline"
                        >가입 취소
                        </NavLink>
                        <button type="submit" className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary" disabled={!(registrationState.regPersonalTerms.length > 0)}>다음 단계</button>
                      </div>
                      <div className="flex justify-center mt-5">
                        <div className="text-base">이미 가입된 회원입니까? <NavLink to="/login" className="font-bold hover:text-konared hover:underline underline-offset-4">Log in</NavLink></div>
                      </div>
                    </div>
                  </div>
                  
                )

              
              }
              else{
                //TODO add loader or error if TOS was not loaded successfully
                return (
                  <div className="mt-10 flex flex-col items-center space-y-2">
                    <Loader3 />
                    <span>약관 내용을 조회 중입니다</span>
                  </div>
                )
              }
            })()}

        </form>
      </div>


      
    </div>
  )
}

export default PersonalRegTerms