import React from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import { NavLink } from "react-router-dom"

function SubscribeRedirect() {

	return (
		<MainContentWrapper className=" text-center">
			<div className="flex flex-col items-center space-y-8 py-20">
					<div className="flex justify-center"><img className="h-60" src={require(`../../assets/images/upgrade.png`)} alt="" /></div>
					<div className="flex flex-col items-center space-y-3 text-2xl">
						<p>이 서비스는 유료입니다. 서비스 이용을 위해서 결제가 필요합니다.</p>
					</div>
					<div className="inline-flex items-center space-x-5" >
						<NavLink to="/population" state={{ scrollTo: "features" }} className="w-[180px] py-4 rounded-xl text-xl text-center font-semibold ripple-gray-outline">자세히보기</NavLink>
						<NavLink to="/population" state={{ scrollTo: "payment" }} className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">결제 하기</NavLink>
					</div>
			</div>
		</MainContentWrapper >
	)
}

export default SubscribeRedirect