import React from 'react'
import UserInfoContext, {ACTION as USER_INFO_ACTION, USER_INFO_PAGES} from "../../context/UserInfoProvider";
import Moment from 'moment';


const UserInfoDetails = () => {
  const {userInfoState, userInfoDispatch} = React.useContext(UserInfoContext);
  return (
    <div>
      <div className="mt-[60px] border-t border-t-black border-b border-b-konagray/10 divide-y divide-konagray/10">
        {("phone" in userInfoState.userInfo || "social_no" in userInfoState.userInfo) &&
          <>
            <div className="flex p-4 text-lg">
              <div className="flex-initial w-60 font-semibold">이름</div>
              <div className="flex-1 font-light">{userInfoState.userInfo.name}</div>
            </div>
            <div className="flex p-4 text-lg">
              <div className="flex-initial w-60 font-semibold">성별</div>
              <div className="flex-1 font-light">{userInfoState.userInfo.sex}</div>
            </div>
            <div className="flex p-4 text-lg">
              <div className="flex-initial w-60 font-semibold">생년월일</div>
              <div className="flex-1 font-light">{userInfoState.userInfo.social_no}</div>
            </div>
            <div className="flex p-4 text-lg">
              <div className="flex-initial w-60 font-semibold">휴대폰번호</div>
              <div className="flex-1 font-light">{userInfoState.userInfo.phone}</div>
            </div>
          </>
        }
        
        <div className="flex p-4 text-lg">
          <div className="flex-initial w-60 font-semibold">이메일(ID)</div>
          <div className="flex-1 font-light">{userInfoState.userInfo.email}</div>
          {/* <button className="border rounded px-4 text-sm hover:bg-konared hover:text-white hover:border-0" onClick={() => userInfoDispatch({type: USER_INFO_ACTION.SET_FRAME_VIEW, payload: USER_INFO_PAGES.UPDATE_EMAIL})} >변경</button> */}
        </div>
        <div className="flex p-4 text-lg">
          <div className="flex-initial w-60 font-semibold">비밀번호</div>
          <div className="flex-1 font-light">최종변경일({Moment(userInfoState.userInfo.pw_lastupdate).format("YYYY/MM/DD")})</div>
          <button className="border rounded px-4 text-sm hover:bg-konared hover:text-white hover:border-0" onClick={() => userInfoDispatch({type: USER_INFO_ACTION.SET_FRAME_VIEW, payload: USER_INFO_PAGES.UPDATE_PASSWORD})} >변경</button>
        </div>
        <div className="flex p-4 text-lg">
          <div className="flex-initial w-60 font-semibold">회원 탈퇴</div>
          <button className="border rounded px-4 text-sm hover:bg-konared hover:text-white hover:border-0" onClick={() => userInfoDispatch({type: USER_INFO_ACTION.SET_FRAME_VIEW, payload: USER_INFO_PAGES.UNSUBSCRIBE})}>회원 탈퇴</button>
        </div>
      </div>
    </div>
  )
}

export default UserInfoDetails