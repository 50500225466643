import { bearerAuth } from './helpers'
import { API_BASE_URL, SERVICES } from "./constants"
import { handleError } from './pop-handle-response';

export const checkSubscribed = async (user) => {

    try {
        const requestOptions = {
            method: 'GET',
            headers: { "Authorization": bearerAuth(user) }
        };
        const query = `?service_id=${SERVICES.population.id}`;
        const response = await fetch(`${API_BASE_URL}/api/subscription/verify${query}`, requestOptions);

        if (response.ok) {
            return true;
        }
        
        if (response.status === 403) throw new Error(handleError(response.status))
        
        throw new Error(`Failed to validate user subscription`);


    } catch (err) {
        console.error(err.message)
    }
}
