import React from 'react'
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="w-[15%] bg-gray-50 border-konagray border-opacity-20">
      <div className="pt-10 pb-5 border-b px-5 text-2xl text-gray">마이페이지</div>
      <nav aria-label="secondary" className="py-5 px-3 flex flex-col space-y-10">
        <ul className="space-y-2">
          <li><NavLink to="/mypage/simulation" className={({ isActive }) => "flex rounded-xl text-base px-5 py-2 cursor-pointer  " + (isActive ? " transition duration-200 bg-konared text-white bg-opacity-100" : "")}>시뮬레이션</NavLink></li>
          <li><NavLink to="/mypage/report" className={({ isActive }) => "flex rounded-xl text-base px-5 py-2 cursor-pointer " + (isActive ? " transition duration-200 bg-konared text-white bg-opacity-100" : "")}>Reports</NavLink></li>
          <li><NavLink to="/mypage/userinfo" className={({ isActive }) => "flex rounded-xl text-base px-5 py-2 cursor-pointer " + (isActive ? " transition duration-200 bg-konared text-white bg-opacity-100" : "")}>개인정보수정</NavLink></li>
          <li><NavLink to="/mypage/billing" className={({ isActive }) => "flex rounded-xl text-base px-5 py-2 cursor-pointer " + (isActive ? " transition duration-200 bg-konared text-white bg-opacity-100" : "")}>Billing</NavLink></li>
          <li><NavLink to="/mypage/terms" className={({ isActive }) => "flex rounded-xl text-base px-5 py-2 cursor-pointer " + (isActive ? " transition duration-200 bg-konared text-white bg-opacity-100" : "")}>이용약관</NavLink></li>
        </ul>
      </nav>
    </div>
  )
}

export default Sidebar