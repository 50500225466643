import React from 'react'
import { ACCOUNT_TYPE, API_BASE_URL, TOS_POSITION } from "../../utils/constants";
import { useGlobalStateContext } from "../context/GlobalContext";
import { mergeTerms } from "../context/PaymentProvider";


const loadKONASDTerms = async () => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {"Content-Type" : "application/json"} 
    };
    const query = `?page_size=all&is_active=true`
    const response = await fetch(`${API_BASE_URL}/api/terms${query}`,requestOptions);
    const terms = await response.json();

    let termsList = [];
    if(response.ok && terms.data.length > 0){
      termsList = terms.data.sort((a, b) => a.order - b.order);
    }

    return termsList;

  } catch (err) {
    console.error(err.message)
  }
}

const loadOpenApiTerms = async () => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {"Content-Type" : "application/json"} 
    };
    const response = await fetch(`${API_BASE_URL}/api/payment/oapi-terms-all`,requestOptions);
    const terms = await response.json();

    let termsList = [];
    if(response.ok && terms.length > 0){
      termsList = terms.sort((a, b) => a.order - b.order);
    }

    return termsList;

  } catch (err) {
    console.error(err.message)
  }
}


const MyPageTerms = () => {
  const {user} = useGlobalStateContext();
  const [termsLoading, setTermsLoading] = React.useState(true);
  const [termsList, setTermsList] = React.useState([]);

  React.useEffect(() => {

    
    const loadTerms = async () => {
      
      let [konasdTerms, openapiTerms] = await Promise.all([
        loadKONASDTerms(),
        loadOpenApiTerms(),
      ]);

      //remove regPersonalTerms or regCorporateTerms depending on user account_type
      const removeTermType = user.user_info.account_type === ACCOUNT_TYPE.PERSONAL ? TOS_POSITION.REG_CORPORATE : TOS_POSITION.REG_PERSONAL;
      konasdTerms = konasdTerms.filter(val => val.position_type !== removeTermType);

      let termsList = [];
      const konasdPositionType = [...new Set( konasdTerms.map(term => term.position_type))];
      konasdPositionType.forEach(pos => {
        termsList = mergeTerms(termsList, konasdTerms.filter(val => val.position_type === pos), "KONASD");
      });

      termsList = mergeTerms(termsList, openapiTerms, "OPENAPI");
      setTermsList(termsList);
      setTermsLoading(false);
      
    }

    //load terms and condition
    loadTerms();

  },[]);


  return (
    <div>
      <div className="pt-10 pb-5 border-b border-konagray/10">
        <h1 className="text-2xl font-bold text-konared">이용약관</h1>
      </div>
      <div>
        {!termsLoading
        ?
          <div className="mt-[60px] border-t border-t-black border-b border-b-konagray/10 divide-y divide-konagray/10">
            {termsList.map((terms, i) => {
                return(
                  <div className="flex p-4 text-lg items-center" key={i}>
                    <div className="flex-1 font-light">{terms.title}</div>
                    <a className="border rounded px-4 py-1 text-sm hover:bg-konared hover:text-white hover:border-konared" target="_blank" rel="noopener noreferrer" href={terms.url}>보기</a>
                  </div>
                )
              })
            }
          </div>
        :
          <div className="mt-[60px] border-t border-t-black border-b border-b-konagray/10  divide-y flex flex-col animate-pulse">
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[60%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[40%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[50%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[55%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[58%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
            <div className="flex justify-between space-x-10 py-5 min-h-[62px]">
              <div className="h-5 w-[48%] bg-slate-200 rounded"></div>
              <div className="h-5 w-16 bg-slate-200 rounded"></div>
            </div>
          </div>
        }
        
      </div>
    </div>
  )
}

export default MyPageTerms