import React from 'react'
import { Outlet } from "react-router-dom"
import MainContentWrapper from "../../components/MainContentWrapper"

const Reference = () => {
  return (
    <MainContentWrapper className="flex">
      <Outlet />
    </MainContentWrapper>
  )
}

export default Reference