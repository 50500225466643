import React from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import MainContentWrapper from "../components/MainContentWrapper";
import { ACCOUNT_TYPE } from "../utils/constants";
import { BuildingOffice2Icon, UserCircleIcon, PresentationChartLineIcon, DocumentTextIcon,ChartBarIcon, ChatBubbleLeftRightIcon } from '@heroicons/react/24/outline'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import MiniLoader from "../components/loaders/MiniLoader";
import { useGlobalStateContext } from "../components/context/GlobalContext";





const validationSchema = Yup.object().shape({
  email: Yup.string()
      .required('이메일 주소를 입력해주세요'),
  password: Yup.string()
      .required('비밀번호를 입력해주세요'),
});

const cssLoginHeadActive = "border-black border-b-0";
const cssLoginHeadNotActive = "border-gray-200 border-b-black text-gray-400 bg-gray-50";




const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {user, handleLogin} = useGlobalStateContext();
  const [isSending, setSending] = React.useState(false);
  const [loginType, setLoginType] = React.useState(location?.state?.accountType || ACCOUNT_TYPE.PERSONAL);

  const form = useForm(
    {
      defaultValues: {
        email: "",
        password: "",
        persistLogin: false
      },
      mode:"onSubmit", 
      resolver: yupResolver(validationSchema)
    });

  React.useEffect(() => {
    if (user) { 
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[user]);


  const onSubmit = async data => {
    setSending(true);
    try {
      
      //set token
      //set userSubject
      const loggedUser = await handleLogin(data.email, data.password, loginType, data.persistLogin);
      if (loggedUser) {

        if(location?.state?.gotoUrl){
          navigate(location?.state?.gotoUrl, {state: {...location?.state?.payload, prevPath: location?.state?.prevPath}});
        }
        else
        {
          let { prevPath } = location.state || { prevPath: { pathname: "/" } };
          prevPath = typeof prevPath === "string" && prevPath.includes("/register") ? "/" : prevPath;
          navigate(prevPath);
        }

        //redirect always to main
        // navigate("/");

      }

    
    } catch (err) {

      console.error(err.message);
      setSending(false);
      form.setError('email', { type: 'custom', message: err.message }, { shouldFocus: true });
      //catch error for incorrect email or password
    }
    setSending(false);

  } 


  const ErrorMessage = errors => {
    const message = Object.keys(errors).length > 0 ? errors.email ? errors.email.message : errors.password.message : null;
    // const message = errors ? "true" : "false";
    return (
      <div>{message}</div>
    )
  }

  return (
    <MainContentWrapper>
      <div className="px-28 pb-20">
      
        <div className="w-full max-w-5xl mx-auto flex flex-col">
          <div className="py-8">
            <h1 className="font-bold text-2xl">Login</h1>
            <p className="text-base text-konagray">로그인 후 다양한 서비스를 이용해 보세요.</p>
          </div>
          <div className="flex divide-x">
            <div className="flex-1 pr-8">
              <div className="flex text-lg font-bold">
                <div className={`flex-1 flex justify-center items-center space-x-2 py-4 text-center cursor-pointer border ${loginType === ACCOUNT_TYPE.PERSONAL? cssLoginHeadActive : cssLoginHeadNotActive}`} onClick={() => setLoginType(ACCOUNT_TYPE.PERSONAL)}><UserCircleIcon className="w-6" /><span>개인회원</span></div>
                <div className={`flex-1 flex justify-center items-center space-x-2 py-4 text-center cursor-pointer border ${loginType === ACCOUNT_TYPE.CORPORATE? cssLoginHeadActive : cssLoginHeadNotActive}`} onClick={() => setLoginType(ACCOUNT_TYPE.CORPORATE)}><BuildingOffice2Icon className="w-6" /><span>기업회원</span></div>
              </div>
              <form className="flex flex-col py-8" onSubmit={form.handleSubmit(onSubmit)}>
                <div className="flex justify-center pb-4 -mt-4 text-konared">{isSending ? <MiniLoader className={`h-5 w-5 ${loginType === ACCOUNT_TYPE.PERSONAL ? "text-konared": "text-indigo-600"}`} /> : <ErrorMessage {...form.formState.errors} />}</div>
                <div className="flex space-x-3">
                  <div className="flex-1 flex flex-col space-y-3">
                    <Controller
                      render={({field}) => {
                        return(
                          <input 
                            {...field}
                            tabIndex={1}
                            placeholder="이메일주소"
                            className={`w-full py-3 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                          />
                        )
                      }}
                      name="email"
                      control={form.control}
                    />
                    <Controller
                      render={({ field}) => {

                        return(
                          <input 
                            {...field} 
                            tabIndex={2}
                            type="password"
                            placeholder="비밀번호"
                            className={`w-full py-3 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                          />
                        )
                      }}
                      name="password"
                      control={form.control}
                    />
                  </div>                
                  <button tabIndex={3} type="submit" className={`w-32  text-white text-2xl font-semibold text-center rounded-xl ${loginType===ACCOUNT_TYPE.PERSONAL? "ripple-primary": "ripple-blue"}`} disabled={isSending}>로그인</button>
                </div>
                <div className="pt-4 flex">
                  <div className="flex-1 flex items-center">
                    <input className="inp-cbx hidden" id="persistLogin" type="checkbox" {...form.register("persistLogin")}/>
                    <label className="cbx flex items-center space-x-2" htmlFor="persistLogin">
                      <span className="h-5 w-5 flex justify-center items-center"><svg className="h-2/3 w-2/3" viewBox="0 0 12 9"><polyline points="1 5 4 8 11 1"></polyline></svg></span>
                      <span className="">로그인 상태 유지</span> 
                    </label>
                    <div className="group relative flex ml-1">
                      <button type="button" className="w-5 h-5"><QuestionMarkCircleIcon className="text-konagray/30 cursor-pointer" /></button>

                      <div className="invisible absolute left-0 mt-5 -ml-[78px] flex flex-col group-focus-within:visible group-active:visible">
                        <div className="ml-20 -mb-[1px] inline-block overflow-hidden"> 
                          <div className="h-3 w-3 origin-bottom-left rotate-45 transform border bg-white"></div>
                        </div>

                        <div className="flex w-[430px] flex-col rounded-md border bg-white shadow-md p-4">
                          <div className="font-bold">로그인 상태 유지 안내</div>
                          <ul className="list-disc list-outside ml-4 mt-2 flex flex-col space-y-2">
                            <li>아이디와 비밀번호를 입력하지 않고, 편리하게 로그인 정보를 유지할 수 있는 기능 입니다.</li>
                            <li>로그인 상태 유지를 선택하고, 로그인하시면, 브라우저의 쿠키를 삭제하거나, 직접 로그아웃을 선택하기 전까지 로그인 상태가 유지됩니다.</li>
                            <li>다만, 7일동안 해당 PC에서 알바천국을 방문하지 않는 경우, 자동으로 해제 됩니다.</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <NavLink to="/findpass" className={`hover:underline underline-offset-[6px] font-semibold text-base ${loginType === ACCOUNT_TYPE.PERSONAL ? "text-konared": "text-indigo-600"}`}>비밀번호 찾기</NavLink>

                </div>
              </form>
              
              {/* <div className="flex divide-x justify-center">
                
                <NavLink to="/findemail" className="px-4 hover:underline underline-offset-[6px] font-semibold text-base text-konagray">아이디 찾기</NavLink>
                
              </div> */}

              <div className="flex flex-col items-center">
                <span>계정이 아직 없으신가요?</span>
                <span><NavLink to="/register" className={`font-semibold hover:underline underline-offset-[4px] ${loginType === ACCOUNT_TYPE.PERSONAL ? "text-konared": "text-indigo-600"}`}>회원가입</NavLink> 을 통해 간편하게 계정을 생성하세요.</span>
              </div>
            </div>
            <div className="flex-1 flex flex-col space-y-8 pl-8">
              {loginType===ACCOUNT_TYPE.PERSONAL 
              ?
              <>
                <div className="text-center font-bold text-3xl">개인회원 주요 서비스</div>
                <div className="flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-konared rounded-full flex items-center justify-center"><PresentationChartLineIcon className="text-konared" /></div>
                  <div className="flex-1 flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">부동산 가격 예측</p>
                    <p className="p-kr">모델에 활용된 데이터를 매월 업데이트하여 전국</p>
                    <p className="p-kr">공동주택(아파트, 연립, 다세대) 및 단독주택 가격을 예측하여 제공합니다.</p>
                  </div>
                </div>
                <div className="flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-konared rounded-full flex items-center justify-center"><DocumentTextIcon className="text-konared" /></div>
                  <div className="flex-1 flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">부동산 시뮬레이션 보고서 제공</p>
                    <p className="p-kr">사용자가 선택한 부동산의 시뮬레이션 결과 보고서를 프린트(저장) 기능이 제공됩니다.</p>
                  </div>
                </div>
                <div className="flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-konared rounded-full flex items-center justify-center"><ChartBarIcon className="text-konared" /></div>
                  <div className="flex-1 flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">부동산 데이터 제공</p>
                    <p className="p-kr">모델에 사용된 경제 및 부동산 관련 변수의 시계열 자료를 조회할 수 있습니다.</p>
                  </div>
                </div>
              </>
              :
              <>
                <div className="text-center font-bold text-3xl">기업회원 주요 서비스</div>
                <div className="flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-indigo-600 rounded-full flex items-center justify-center"><PresentationChartLineIcon className="text-indigo-600" /></div>
                  <div className="flex-1 flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">부동산 가격 및 인구 예측</p>
                    <p className="p-kr">당사 모델이 예측한 부동산 가격과 미래 인구를 조회할 수 있고, 사용자가 모델의 변수 값을 변경하여 시뮬레이션 할 수 있습니다.</p>
                    <p className="p-kr text-xs text-indigo-600">* 부동산 가격 예측 서비스와 인구 예측 서비스는 각각 이용요금이 부과 됩니다.</p>
                  </div>
                </div>
                <div className="flex-1 flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-indigo-600 rounded-full flex items-center justify-center"><DocumentTextIcon className="text-indigo-600" /></div>
                  <div className="flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">시뮬레이션 보고서 제공</p>
                    <p className="p-kr">사용자가 설정한 부동산 및 인구 시뮬레이션 보고서 기능이 제공됩니다.</p>
                  </div>
                </div>
                <div className="flex-1 flex items-center space-x-8">
                  <div className="w-16 h-16 p-4 border-2 border-indigo-600 rounded-full flex items-center justify-center"><ChatBubbleLeftRightIcon className="text-indigo-600" /></div>
                  <div className="flex flex-col">
                    <p className="text-base font-bold mb-2 p-kr">컨설팅</p>
                    <p className="p-kr">부동산 및 인구 예측서비스</p>
                  </div>
                </div>
              </>
              }
              
            </div>
            
          </div>
        </div>
      </div>
    </MainContentWrapper>
  )
}

export default Login