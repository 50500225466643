import React from 'react'
import { useForm} from "react-hook-form";
import SimParamSlider from "./SimParamSlider";
import {BiReset} from 'react-icons/bi'
import DotLoader from "../loaders/DotLoader";
import SimParamEventSlider from "./SimParamEventSlider";
import {delay} from "../../utils/helpers"


import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { bearerAuth } from "../../utils/helpers";
import ResetParamModal from "../modals/ResetParamModal";
import { API_BASE_URL } from "../../utils/constants";
import RealEstateNotSubModal from "../modals/RealEstateNotSubModal";
import LoadingModalWithText from "../modals/LoadingModalWithText";
import Loader2WithSuccess from "../loaders/Loader2WithSuccess";
import { useGlobalStateContext } from "../context/GlobalContext";
import { useReStateContext } from "../context/RealEstateContext";
import { REMYSIM_ACTION, SIM_EVENT_PARAMETERS, SIM_PARAMETERS, useReMysimDispatchContext, useReMysimStateContext, USEREVENT_PARAM_DEFAULT_VAL } from "../context/ReMysimContext";
import { getRealEstateDataOutWithVarinfo } from "../../utils/api/realestateApi";
const moment = extendMoment(Moment);

const SimForm = ({chartRef, konasimResult}) => {
  const {user, state: {isSubscribedRealEstate}} = useGlobalStateContext();
  const reState = useReStateContext();
  const reMysimState = useReMysimStateContext();
  const reMysimDispatch = useReMysimDispatchContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenSimLoading, setIsOpenSimLoading] = React.useState(false);
  const [isOpenIsSub, setIsOpenIsSub] = React.useState(false);

  const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];
  const modelInfo = reState.model.byId[reState.currentModelId]?.info;

  
  const formDefaults = React.useMemo(() => {
    
    const paramFormDefaults = Object.fromEntries(Object.entries(SIM_PARAMETERS).map(([key, val]) => {
      return [key, [{value:modelData.variable[key]?.data_in[0].value[0][1]}]] 
    } ));
    const paramCheckboxDefaults = Object.fromEntries(Object.entries(SIM_PARAMETERS).map(([key, val]) => {return [`${key}-check`, val.checkboxDefault ]} ));
    
    //add event defaults
    //set initial value to 0.1 temporarily, TODO: must recheck
    const eventFormDefaults = Object.fromEntries(Object.entries(SIM_EVENT_PARAMETERS).map(([key, val]) => {return [key,[{value: USEREVENT_PARAM_DEFAULT_VAL}] ]} ));

    //add form defaults for event checkboxes
    const eventCheckboxDefaults = Object.fromEntries(Object.entries(SIM_EVENT_PARAMETERS).map(([key, val]) => {return [`${key}-check`,false ]} ));
    const results = {...paramFormDefaults, ...paramCheckboxDefaults, ...eventFormDefaults, ...eventCheckboxDefaults, simName: ""}

    return results;
  }, [reState.currentLocCode]); 


  //setting up form with initial form defaults
  const form = useForm({defaultValues: formDefaults});


  React.useEffect(() => {
    //reset form defaults everytime default changes (when location_code chagnes)
    form.reset(formDefaults);

  }, [formDefaults]);



  const onSubmit = async (data) => {

    if(isSubscribedRealEstate){
      if (data.simName.trim() === ""){
        //required simname
        form.setError('simName', { type: 'custom', message: "시뮬레이션명을 필요합니다." }, { shouldFocus: true });
      }
      else{
        setIsOpenSimLoading(true);

        var dateArr = Array.from(moment.rangeFromInterval('month', modelInfo.end_time, modelInfo.data_in_start).by("months"));
        dateArr = dateArr.map(d => d.format('YYYY-M')); //TODO: revise format letter to have leading zeroes for month


        const parameter = Object.fromEntries(Object.entries(SIM_PARAMETERS)
          .filter(([key, val]) => data[`${key}-check`] === true)
          .map(([key, val]) => {
            const dataval = data[key].map(v => [parseInt(v.date), parseFloat(v.value)]);
            return [modelData.variable[key]?.name_en, dataval.length > 1 ? dataval : [dataval[0],dataval[0]]]
          }))


        const event = Object.fromEntries(Object.entries(SIM_EVENT_PARAMETERS)
          .filter(([key, val]) => data[`${key}-check`] === true)
          .map(([key, val]) => {
            const dataval = data[key].map((v,index) => [
              [`event_name[${key}${index+1}]`, v.ename],
              [`${SIM_EVENT_PARAMETERS[key].paramVar}[${key}${index+1},${modelData.subscript}]`, v.value],
              [`${SIM_EVENT_PARAMETERS[key].dateVar}[${key}${index+1}]`, dateArr.indexOf(`${v.year}-${v.month}`)]
            ]);

            return dataval
          }).flat(2))



        // create result json obj for query
        const addSimJson = {
          "name": data.simName || "Untitled",
          "parameter": {...parameter, ...event}, 
          "model_id": modelInfo.id,
          "loc_code": reState.currentLocCode,
          "type": "USER",
          "user_id": user.user_info.id ,
          "address_info": reState.address.byId[reState.currentAddressId]
        }



        // RUN Sim 
        // TODO: create single endpoint and process all steps in the backend
        try {
          // add new sim
          const response = await fetch(`${API_BASE_URL}/api/simulations`,{
            method: "POST",
            headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
            body: JSON.stringify(addSimJson),
          });


          const newSimulation = await response.json();

          // run simulation
          const response2 = await fetch(`${API_BASE_URL}/api/simulations/${newSimulation.id}/run`,{
            method: "POST",
            headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
          });
          
          const currentAddress = reState.address.byId[reState.currentAddressId];
          const simResultGroupKey = currentAddress.address.innb+currentAddress.dong+currentAddress.ho;

          if(response2.ok){
            const response3 = await fetch(`${API_BASE_URL}/api/data-out?simulation_id=${newSimulation.id}&expand=variable`,{
              method: "GET",
              headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
            });
          
            const simOutput = await getRealEstateDataOutWithVarinfo(newSimulation.id, user);
            const outputData = Object.fromEntries(simOutput.map(e => [e.variable.name_en.split("[")[0], e.value]));
            const simResultsGroup = {
              [simResultGroupKey]: {
                konasimResult: konasimResult, 
                address: currentAddress, 
                simResults: {
                  [newSimulation.id]: {outputData: outputData, simInfo: newSimulation }
                }
              }};

            reMysimDispatch({type: REMYSIM_ACTION.ADD_SIM_RESULT, payload: {simResultsGroup}})
            setIsOpenSimLoading(false);
          }

          //scroll to results charts
          await delay(100);  //add delay to time overlay closing and moving the chart section, will also add fake delay to wait for chartref to be rendered first before scrollIntoView
          chartRef.current[simResultGroupKey].scrollIntoView()    
                  
          //reset simName field when after running simulation
          form.setValue("simName", "", { shouldTouch: false, shouldDirty: false  });

        } catch (err) {
          console.error(err.message)
        }
      }
      
    
    }
    else {
      setIsOpenIsSub(true);
    }    
    
  };

  
  const resetResults = () => {
    form.reset(formDefaults);
    reMysimDispatch({type: REMYSIM_ACTION.RESET_MYSIM});
    setIsOpen(false);

  }

  const handleReset = () => {

    if(Object.keys(reMysimState.simResultsGroup).length > 0){
      setIsOpen(true)
    }
    else{
      form.reset(formDefaults);
    }
  }


  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <LoadingModalWithText isOpen={isOpenSimLoading} delayLeaveCls="delay-1000">
        <div className="flex flex-col w-full h-full relative">
          {isOpenSimLoading
          ? 
          <>
            <Loader2WithSuccess success={false} />
            <div className="mt-4 text-white text-xl font-light">시뮬레이션 실행 중...</div>
          </>
          : 
          <>
            <Loader2WithSuccess success={true} />
            <div className="mt-4 text-white text-xl font-light">시뮬레이션 완료</div>
          </>
          }
          

        </div>
      </LoadingModalWithText>

      <ResetParamModal isOpen={isOpen} setIsOpen={setIsOpen} resetResults={resetResults} />
      <RealEstateNotSubModal isOpen={isOpenIsSub} setIsOpen={setIsOpenIsSub} />
      <div className="flex">
        <div className="flex-1 text-3xl font-bold text-konared">지수 설정하기</div>
        <button onClick={handleReset} type="button" className="inline-flex items-center space-x-2 border border-konared px-4 rounded-full text-konared ripple-primary-outline text-sm font-semibold"><BiReset size={20} /><span>데이터 초기화</span></button>
      </div>
      
      {(() => {
        if((reState.model.allIds.includes(reState.currentModelId) && reState.model.byId[reState.currentModelId]?.data.allIds.includes(reState.currentLocCode))) {
          
            var formPlusStyle = "focus:ring-blue-500 focus:ring-opacity-40 placeholder-gray-400"
            

            if(form.formState.errors?.simName){
              formPlusStyle = "focus:ring-konadanger focus:ring-opacity-50 focus:placeholder-red-400"
              form.formState.errors?.simName.ref.scrollIntoView({ behavior: 'smooth' })
            }
        
            return (
              <div className="grid grid-cols-3 gap-10 mt-5">
                {/* column 1 */}
                <div className="bg-white flex flex-col shadow-md">
                  <div className="h-1 bg-gray-400"></div>
                  <div className="p-4 flex flex-col">
                    <SimParamSlider param="e L CLI" form={form} />
                    <SimParamSlider param="e L CPI" form={form} />
                    <SimParamSlider param="e L MIR" form={form} />
                    <SimParamSlider param="e L KOSPI" form={form} />
                  </div>
                </div>
                {/* end column 1 */}

                 {/* column 2 */}
                 <div className="bg-white flex flex-col shadow-md">
                  <div className="h-1 bg-gray-400"></div>
                  <div className="p-4 flex flex-col">
                    <SimParamSlider param="e L M2" form={form} />
                    <SimParamSlider param="e L REM" form={form} />
                    <SimParamSlider param="e L HSR x" form={form} />
                    <SimParamSlider param="e L TTI x" form={form} />
                  </div>
                </div>
                {/* end column 2 */}

                {/* column 3 */}
                <div className="bg-white flex flex-col shadow-md">
                  <div className="h-1 bg-gray-400"></div>
                  <div className="p-4 flex flex-col h-full">
                    <div className="text-center text-base p-kr py-4">시뮬레이션에 부동산 가격 상승과 하락과 관련된 정책을 예상하여 반영할 경우, 선택하여 조건을 입력해주세요</div>
                    <SimParamEventSlider param="EA" form={form} />
                    <SimParamEventSlider param="EB" form={form} />
                    {/* <SimParamEventSlider param="EC" form={form} /> */}
                    <div className="flex-1 flex flex-col justify-end space-y-4">
                      <div className="flex flex-col space-y-1">
                        <input 
                          {...form.register("simName")} 
                          placeholder="시뮬레이션명 입력해주세요" 
                          type="text" 
                          className={`flex-1 text-xl text-gray-800 py-3 rounded-2xl border outline-none focus:duration-300  focus:ring-2 shadow-sm px-4 scroll-mt-[400px] ${formPlusStyle}`}
                          autoComplete="off" 
                        />
                      </div>
                      <button type="submit" className="bg-konared py-[15px] rounded-2xl text-xl text-white font-semibold ripple-primary inline-flex justify-center items-center">
                        {reMysimState.isSimRunning && 
                          <svg className="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        }
                        <span>시뮬레이션 실행</span>
                      </button>
                    </div>

                  </div>
                </div>
                {/* end column 3 */}

               
              </div>
            )
        } else {
            return (
              <div><DotLoader /></div>
            )
        }
      })()}
    </form>
  )
}

export default SimForm