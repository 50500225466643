import React from 'react'
import { NavLink } from 'react-router-dom';

const PageNotFound = () => {

  return (
    <div className="flex w-full items-center justify-center">
      <div className="flex">
        <div className="px-8 text-5xl font-bold text-konared">404</div>
        <div className="flex flex-col space-y-10">
          <div className="px-8 border-l">
            <p className="text-5xl font-bold">Page not found</p>
            <p className="text-lg text-gray-500 mt-2">Please check the URL in the address bar and try again.</p>
          </div>
          <div className="px-8">
            <NavLink to="/" className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">Go back home</NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound