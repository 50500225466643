import React, { useReducer, useEffect, useState } from 'react'
import Echarts from '../../components/charts/Echarts';
import { getChartOptionMarriage, getTimelineOptionForIntMarriage } from '../../components/charts/PopulationEchartOptions';
import MainContentWrapper from '../../components/MainContentWrapper'
import { CardWrapper, CardHeader, CardFooter, CardContent } from '../../components/population/CardWrapper'
import DotLoader from '../../components/loaders/DotLoader'
import { API_BASE_URL } from "../../utils/constants";
import APIUnavailableModal from '../../components/modals/APIUnavailableModal';
import LoadingModal from '../../components/modals/LoadingModal'
import { handleError } from '../../utils/pop-handle-response'
import { checkSubscribed } from '../../utils/pop-subscription-checker';
import { NavLink, useNavigate } from 'react-router-dom';
import { useGlobalStateContext } from "../../components/context/GlobalContext";



const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
	SET_CHANGE_TIME: 'set-change-time',
}

const initialState = {
	main_data: null,
	total_marriage: null,
	marriage_age: null,
	curYear: 2020,
	yearlist: null
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_MAIN_DATA:
			return {
				...state,
				main_data: payload.main_data,
				yearlist: payload.yearlist,
				curYear: payload.curYear,
				marriage_age: payload.marriage_age,
				total_marriage: payload.total_marriage
			};
		case ACTION.SET_CHANGE_TIME:
			return {
				...state,
				curYear: payload.curYear,
				marriage_age: payload.marriage_age
			};
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}
function PopImmigrationMarriage() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isSimRunning, setSimRunning] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useGlobalStateContext();
	const [isSubscribed, setSubscribed] = useState(false)
	let navigate = useNavigate()


	useEffect(() => {
		const loadDefault = async () => {
					
			let isUserSubscribed = false

			try{
				isUserSubscribed = await checkSubscribed(user)
				
				setSimRunning(true)
				
				if(isUserSubscribed){
					
					setSubscribed(true)
					
					let query = `?page_size=all&name_en=marriage immigration`
					let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
						method: "GET",
						headers: { "Content-Type": "application/json" },
					})

					if (!response.ok) throw new Error(handleError(response.status))

					let parseRes = await response.json()

					if(!parseRes.data || parseRes.data.length<=0) throw Error("No available marriage data")

					let yearlist = parseRes.data[0].value.map(x => { return x[0] })
					
					let marriageBar =[]
					let male_marriages = []
					let female_marriages = [] 
					yearlist.forEach(y => {
						marriageBar.push({
							title: { text: y + '년도' },
							series: [
								{
									data: parseRes.data.filter(v => v.name_kr.includes('남자')).map(item => {
										return item.value.filter(p => p[0] === y)[0][1]
									})
								},
								{
									data: parseRes.data.filter(v => v.name_kr.includes('여자')).map(item => {
										return item.value.filter(p => p[0] === y)[0][1]
									})
								}
							]
						}) 
						female_marriages.push([y,  parseRes.data.filter(v => v.name_kr.includes('여자')).map(item=>{ return item.value.filter(p => p[0] === y)[0][1]}).reduce((accumulator, value) => { return accumulator + value;}, 0)])
						male_marriages.push([y,  parseRes.data.filter(v => v.name_kr.includes('남자')).map(item=>{ return item.value.filter(p => p[0] === y)[0][1]}).reduce((accumulator, value) => { return accumulator + value;}, 0)])
					})
		
					let ageSeries =[
						{ name: '남성', type: 'bar', stack: 'male' },
						{ name: '여성', type: 'bar', stack: 'female' },
					]
					dispatch({
						type: ACTION.SET_MAIN_DATA,
						payload: {
							main_data: parseRes.data,
							yearlist: yearlist,
							curYear: yearlist[yearlist.length - 1],
							marriage_age: getTimelineOptionForIntMarriage(yearlist,marriageBar, ageSeries),
							total_marriage: getChartOptionMarriage([
								{
									name: '남성',
									type: "line",
									showSymbol: true,
									data: male_marriages
								},
								{
									name: '여성',
									type: "line",
									showSymbol: true,
									data: female_marriages
								}
							])
						}
					})

				}
			}catch(error){
				setIsOpen(true)
				console.error(error.message)
			} finally {
				setSimRunning(false)

				if(!isUserSubscribed)
					navigate("/pop-sub-redirect")	
			}
		}
		loadDefault();
	}, [])
	
	return (
		<MainContentWrapper className="bg-konabg relative">
		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
		<LoadingModal isOpen={isSimRunning} />

			<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6">

				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								결혼 이민의 연도별 추세
							</div>
							<div className='flex text-right text-lg'>
								단위: 명
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5 flex flex-col" >
						<div className='flex-1 py-2  '>
							{
								state.total_marriage ?
									<Echarts options={state.total_marriage} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</div>

					</CardContent>
					<CardFooter>
						<div>데이터 출처: 국가통계포털</div>
					</CardFooter>
				</CardWrapper>

				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								결혼 이민의 연령별 분포
							</div>
							<div className='flex text-right text-lg'>
								단위: 명
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5 flex flex-col" >
						<div className='flex-1 py-2 '>

							{
								state.marriage_age ?
									<Echarts options={state.marriage_age} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</div>

					</CardContent>
					<CardFooter>
						<div>데이터 출처: 국가통계포털</div>
					</CardFooter>
				</CardWrapper>

			</div>
		</MainContentWrapper>
	)
}

export default PopImmigrationMarriage