import { Listbox, Transition } from '@headlessui/react'


const SearchListBox = ({datalist, selectedVal, onChangeVal, disabled=false, placeholder}) => {

  return (
      <Listbox
        as="div"
        value={selectedVal}
        onChange={onChangeVal}
        className="z-0 h-full"
        disabled={disabled}
        
      >
        {({ open }) => (
          <>
            <div className={`relative z-20 h-full flex ${disabled && 'opacity-50'}`}>
                <Listbox.Button className="cursor-default relative w-full h-full bg-white rounded-xl  border border-gray-300 pl-3 pr-10 py-2 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                  <span className="block truncate">{selectedVal ? selectedVal : placeholder}</span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20"  fill="none"  stroke="currentColor">
                      <path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </span>
                </Listbox.Button>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute top-[50%] w-full rounded-md bg-white shadow-lg overflow-hidden -z-10"
              >
                <Listbox.Options
                  static
                  className="max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 "
                >
                  {datalist.map((data,i) => (
                    <Listbox.Option key={i} value={data}>
                      {({ selected, active }) => (
                        <div className={`${active? "text-black bg-konahover": "text-black"} cursor-default select-none relative py-2 pl-8 pr-4`}>
                          <span className={`${selected ? "font-semibold" : "font-normal"} block truncate`}>{data}</span>
                          {selected && (
                            <span className={`${active ? "text-konared" : "text-konared"} absolute inset-y-0 left-0 flex items-center pl-1.5`}>
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
                              </svg>
                            </span>
                          )}
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>



              </Transition>
            </div>
          </>
        )}
      </Listbox>
  )
}

export default SearchListBox