
import React from 'react'
import { NavLink, useLocation, useNavigate} from 'react-router-dom';
import {animateSlideDown} from '../utils/effects'
import konaLogo from '../assets/images/konai_logo.svg'
import TosUpdateModal from "./modals/TosUpdateModal";
import { useGlobalStateContext } from "./context/GlobalContext";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {user, handleLogout} = useGlobalStateContext();
  const [isOpen, setIsOpen] = React.useState(false); 

  
  React.useEffect(() => {
    if(user && user?.userUpdatedTos.length > 0) setIsOpen(true);
  },[user]);


  return (
    <header className="sticky top-0 w-full h-[100px] z-[9999] flex items-center border-b border-[#E5E8EB] px-28 bg-white">
      <TosUpdateModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="inline-flex items-center space-x-5 hover:cursor-pointer" onClick={() => navigate('/')}><img src={konaLogo} className="h-[30px]" alt="" /></div>
      <div className="flex-1 flex h-full justify-center text-xl space-x-20">
        <div data-target="nav-sim0" onMouseEnter={e => animateSlideDown(e, true)} onMouseLeave={e => animateSlideDown(e, false)} onClick={e => animateSlideDown(e, false)} >
          <div className="inline-flex items-center h-full relative">
            <NavLink to="/realestate" className="hover:font-bold">부동산 가격 예측 서비스</NavLink>
          </div>
          <div className="absolute left-0 top-[100px] w-full shadow-[0_5px_5px_-5px_rgba(0,0,0,0.2)] slideDown bg-white"  id="nav-sim0">
            <div className="flex justify-center space-x-16 h-20 text-lg">
                <NavLink to="/realestate/main" className="flex items-center group relative">
                  <span className="">부동산 예측 서비스란?</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
                <NavLink to="/realestate/about" className="flex items-center group relative">
                  <span className="">서비스 제공 범위</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
                <NavLink to="/realestate/konasim" className="flex items-center group relative">
                  <span className="">KONA 예측</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
                <NavLink to="/realestate/mysim" className="flex items-center group relative">
                  <span className="">나의 예측</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
                <NavLink to="/realestate/datalibrary" className="flex items-center group relative">
                  <span className="">데이터 살펴보기</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
            </div>
          </div>
        </div>

        <div data-target="nav-sim1" onMouseEnter={e => animateSlideDown(e, true)} onMouseLeave={e => animateSlideDown(e, false)} onClick={e => animateSlideDown(e, false)} >
          <div className="inline-flex items-center h-full relative">
            <NavLink to="/population" className="hover:font-bold">인구 예측 서비스</NavLink>
          </div>

          <div className="absolute left-0 top-[100px] w-full shadow-[0_5px_5px_-5px_rgba(0,0,0,0.2)] slideDown bg-white"  id="nav-sim1">
            <div className="flex justify-center min-h-[200px] divide-x">
              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <NavLink to="/population" className="flex items-center text-lg font-semibold">인구 예측 서비스란?</NavLink>
              </div>
              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <NavLink to="/pop-dashboard" className="flex items-center text-lg font-semibold">Dashboard</NavLink>
              </div>
              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <span className="flex items-center text-lg font-semibold mb-3">인구 예측</span>
                {/* 3rd depth */}
                <NavLink to="/pop-national" className="flex items-center text-base hover:underline underline-offset-4">전국 인구 예측</NavLink>
                <NavLink to="/pop-local" className="flex items-center text-base hover:underline underline-offset-4">지역별 인구 예측</NavLink>
              </div>

              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <span className="flex items-center text-lg font-semibold mb-3">가구수와 소멸위험</span>
                {/* 3rd depth */}
                <NavLink to="/pop-household" className="flex items-center text-base hover:underline underline-offset-4">가구수 예측</NavLink>
                <NavLink to="/pop-extinction" className="flex items-center text-base hover:underline underline-offset-4">소멸위험지수 예측</NavLink>
              </div>

              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <span className="flex items-center text-lg font-semibold mb-3">정책 시뮬레이션</span>
                {/* 3rd depth */}
                <NavLink to="/pop-simulation-national" className="flex items-center text-base hover:underline underline-offset-4">전국 인구 시뮬레이션</NavLink>
                <NavLink to="/pop-simulation-sido" className="flex items-center text-base hover:underline underline-offset-4">시도 인구 시뮬레이션</NavLink>
                <NavLink to="/pop-simulation-workforce" className="flex items-center text-base hover:underline underline-offset-4">산업 인력 시뮬레이션</NavLink>
              </div>

              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <span className="flex items-center text-lg font-semibold mb-3">인구 동향&지표</span>
                {/* 3rd depth */}
                <NavLink to="/pop-datalocal-birth" className="flex items-center text-base hover:underline underline-offset-4">출산율</NavLink>
                <NavLink to="/pop-datalocal-mov" className="flex items-center text-base hover:underline underline-offset-4">전입전출</NavLink>
                <NavLink to="/pop-datalocal-marriage" className="flex items-center text-base hover:underline underline-offset-4">초혼율</NavLink>
                <NavLink to="/pop-immigration" className="flex items-center text-base hover:underline underline-offset-4">결혼 이민</NavLink>
                <NavLink to="/pop-international" className="flex items-center text-base hover:underline underline-offset-4">국제인구지표</NavLink>
              </div>
              <div className="flex flex-col justify-start group relative min-w-[120px] p-5 border-l">
                {/* 2nd depth */}
                <span className="flex items-center text-lg font-semibold mb-3">도움말</span>
                {/* 3rd depth */}
                <NavLink to="/pop-intro" className="flex items-center text-base hover:underline underline-offset-4">시스템 소개</NavLink>
              </div>
               
            </div>
          </div>

        </div>

        <div data-target="nav-sim2" onMouseEnter={e => animateSlideDown(e, true)} onMouseLeave={e => animateSlideDown(e, false)} onClick={e => animateSlideDown(e, false)} >
          <div className="inline-flex items-center h-full relative">
            <span className="hover:font-bold cursor-default">자료실</span>
          </div>
          <div className="absolute left-0 top-[100px] w-full shadow-[0_5px_5px_-5px_rgba(0,0,0,0.2)] slideDown bg-white"  id="nav-sim2">
            <div className="flex justify-center space-x-16 h-20 text-lg">
                <NavLink to="/reference/notice" className="flex items-center group relative">
                  <span className="">공지사항</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
                <NavLink to="/reference/population-news" className="flex items-center group relative">
                  <span className="">인구뉴스</span>
                  <div className=" bg-konared absolute bottom-0 left-0 right-0 mx-auto h-[2px] w-0 transition-[width] duration-500 group-hover:w-full"></div>
                </NavLink>
            </div>
          </div>
        </div>

{/* 
        <div data-target="nav-sim2" onMouseEnter={e => animateSlideDown(e, true)} onMouseLeave={e => animateSlideDown(e, false)} onClick={e => animateSlideDown(e, false)} >
          <div className="inline-flex items-center h-full relative">
            <NavLink to="/" className="hover:font-bold">결과 보고서 작성</NavLink>
          </div>
          <div className="absolute left-0 top-[100px] w-full shadow slideDown"  id="nav-sim2">
          </div>
        </div> */}


      </div>
      <div className="text-lg flex space-x-5">
        {user
        ?
          <>
            <span className="">{user.user_info.name && `${user.user_info.name} 님, 안녕하세요!`}</span>
            <NavLink to="/mypage" className="hover:font-bold">마이페이지</NavLink>
            <button className="hover:font-bold" onClick={handleLogout}>로그아웃</button>
          </>
        :
        <>
          <NavLink to="/login" state={{prevPath: location.pathname}} className="inline-flex items-center hover:font-bold">로그인</NavLink>
          {/* <NavLink to="/register" onClick={() => changeLocation('/register')} className="bg-konared text-white leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">무료로 시작하기</NavLink> */}
          <NavLink to="/register" reloadDocument className="bg-konared text-white leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">회원가입</NavLink>
        </>

        }
        
      </div>
    </header>



  )
}

export default Header