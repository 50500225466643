import React from 'react'
import { CheckIcon } from '@heroicons/react/24/outline'

const RegSteps = ({prevStep, nextStep}) => {
  const _step = prevStep !== nextStep ? nextStep-1 : nextStep;
  const [delayStep, setDelayStep] = React.useState(_step);
  
  React.useEffect(() => {
    setDelayStep(nextStep);
  },[nextStep]);


  return (
    <div className="flex">
      <div className="flex flex-col justify-center items-center">
        <div className="w-[200px] h-10 relative">
          <div className="bg-gray-200 w-[200px] h-[2px] absolute left-0 top-0 bottom-0 my-auto translate-x-1/2 overflow-hidden">
            <div className={`transform w-full h-full  transition duration-300 bg-konared ${delayStep>1?'-translate-x-0':'-translate-x-full'}`}></div>
          </div>
          
          <div className="inline-flex items-center justify-center h-10 w-10 bg-konared rounded-full absolute mx-auto left-0 right-0 p-1"><CheckIcon className="text-white" /></div>
        </div>
        <div className="py-2 font-bold">약관동의</div>
      </div>
      
      <div className="flex flex-col justify-center items-center">
        <div className="w-[200px] h-10 relative">
          <div className="bg-gray-200 w-[200px] h-[2px] absolute left-0 top-0 bottom-0 my-auto translate-x-1/2 overflow-hidden">
            <div className={`transform w-full h-full  transition duration-300 bg-konared ${delayStep>2?'-translate-x-0':'-translate-x-full'}`}></div>
          </div>
          
          <div className={`transition delay-200 inline-flex items-center justify-center h-10 w-10 border-2 p-1 ${delayStep>1?'bg-konared  border-konared':'bg-white  border-gray-200'} rounded-full absolute mx-auto left-0 right-0`}><CheckIcon className={`transition delay-200 ${delayStep>1?'text-white':'text-gray-200'}`} /></div>
        </div>
        <div className="py-2 font-bold">회원정보 입력</div>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="w-[200px] h-10 relative">
        <div className={`transition delay-200 inline-flex items-center justify-center h-10 w-10 border-2 p-1 ${delayStep>2?'bg-konared  border-konared':'bg-white  border-gray-200'} rounded-full absolute mx-auto left-0 right-0`}><CheckIcon className={`transition delay-200 ${delayStep>2?'text-white':'text-gray-200'}`} /></div>
        </div>
        <div className="py-2 font-bold">가입 완료</div>
      </div>

    </div>
  )
}

export default RegSteps