import React from 'react'
import { customRound } from "../../utils/helpers";
import Moment from 'moment';
import { getAddressTxt } from "../context/RealEstateContext";

const EvaluationKonaSim = ({konasimResult, address}) => {
  const currMonth = Moment().format("YYYY-MM");

  const lastInputDate = konasimResult.inputDataPriceVal.at(-1)[0];
  // const lastInputDateStr = `${lastInputDate.split("-")[0]}년 ${lastInputDate.split("-")[1]}월`;
  const lastInputDateStr = `${currMonth.split("-")[0]}년 ${currMonth.split("-")[1]}월`;

  
  const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
  let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);
  //table data array for base data (sequence: current, 3mo, 6mo, 9mo, 1yr, 2yr)
  const tblBaseIndex = [baseDataPriceVal[0][1], baseDataPriceVal[6][1], baseDataPriceVal[12][1], baseDataPriceVal[24][1]]
  const tblBaseRate = tblBaseIndex.map((val, idx, arr) => customRound((val-arr[0])/arr[0]*100, 2));

  //customRound((bData[0][1]-bData[0][1])/bData[0][1]*100, 2)
  //set values needed for description
  const indexAfter6mo = tblBaseIndex[1].toLocaleString() + "원";
  const indexAfter1yr = tblBaseIndex[2].toLocaleString() + "원";
  const indexAfter2yr = tblBaseIndex[3].toLocaleString() + "원";
  const rateAfter1yr = tblBaseRate[2];
  const rateAfter2yr = tblBaseRate[3];


  return (
    <div>
      <div className="pt-10 text-xl p-kr flex flex-col space-y-4">
        <p>{getAddressTxt(address)} 검색 물건의 <span className="font-extrabold">{lastInputDateStr}</span> 기준 매매 가격은 <span className="font-extrabold">{tblBaseIndex[0].toLocaleString()}원</span> 이며,</p>
        <p>KONA의 분석에 의한 예측 적정 가격은 6개월 후 <span className="font-extrabold">{indexAfter6mo}</span>, 1년 후 <span className="font-extrabold">{indexAfter1yr}</span>, 2년 후 <span className="font-extrabold">{indexAfter2yr}</span>으로 예측됩니다.</p>
        <p>최종적으로 분석한 결과, <span className="text-konared font-bold">현재로부터 1년 후 예측 가격은 현 시점보다 {rateAfter1yr >= 0 ? `${Math.abs(rateAfter1yr)}% 상승}` : `${Math.abs(rateAfter1yr)}% 하락`}이 예상되고, 2년 후 {rateAfter1yr >= 0 ? `${Math.abs(rateAfter2yr)}% 상승` : `${Math.abs(rateAfter2yr)}% 하락`} 할 것으로 예측</span>됩니다</p>
      </div>
    </div>
  )
}

export default EvaluationKonaSim