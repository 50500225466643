import React from 'react'
import MainContentWrapper from "../components/MainContentWrapper"
import { RegistrationProvider } from "../components/context/RegistrationProvider"
import { Outlet } from "react-router-dom"

const Register = () => {

  return (
    <MainContentWrapper>
      <RegistrationProvider>
        <Outlet />
      </RegistrationProvider>
    </MainContentWrapper>
  )
}

export default Register