import React from 'react'
import { NavLink } from 'react-router-dom';
import FrontPopup from "../components/modals/FrontPopup";
import Moment from 'moment';
import MainContentWrapper from "../components/MainContentWrapper";



const Main = () => {

  // Disable Pop up in main page
  // const [isOpen, setIsOpen] = React.useState(false);
  
  // React.useEffect(() => {

  //   const popUpToken = localStorage.popUpDate;
  //   const pTokenDate = Moment(popUpToken);
  //   const currTimeStamp = Moment();
  //   const numDays = Moment.duration(currTimeStamp.diff(pTokenDate)).asDays()
  //   if(!popUpToken || numDays > 7) {
  //     setIsOpen(true);
  //   } 
  // },[]);

  

  return (
    <MainContentWrapper>
      {/* <FrontPopup isOpen={isOpen} setIsOpen={setIsOpen} /> */}
      <div className="h-[400px] flex flex-col justify-center space-y-5 px-14 text-white" style={{ backgroundImage: `url(${require("../assets/images/ribbon_main.jpg")})` }}>
        <h1 className="text-[45px] leading-none font-bold">시장을 예측하는 <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#9BA2EF] to-[#EE8EA5]">새로운 패러다임</span></h1>
        <h1 className="text-[70px] leading-none font-bold">System Dynamics</h1>
      </div>

      <div className="grid grid-cols-1 gap-20 py-20 place-items-center">

        {/* realestate */}
        <div className="h-[400px] w-[1060px] relative bg-gray-300 bg-cover bg-[center_bottom_-100px]"  style={{ backgroundImage: `url(${require("../assets/images/main_re_link_1020.jpg")})` }}>
          <div className="flex h-[300px] w-[513px] space-x-6 p-7 bg-white -translate-x-1/2 absolute bottom-0">
            <div className="h-[5px] w-[70px] bg-black mt-[20px]"></div>
            <div className="flex-1 flex flex-col space-y-5">
              <h1 className="text-[45px] leading-none font-bold">부동산 예측 서비스</h1>
              <p className="text-xl leading-9 p-kr">미국 MIT의 System Dynamics 분석방법론과 자체 개발한 부동산 매매가격예측지수를 토대로 부동산의 미래 가격을 예측해보세요.</p>
              <div>
                < NavLink to="/realestate" className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">서비스 시작하기</NavLink>
              </div>

            </div>
          </div>
        </div>


        {/* population */}
        <div className="h-[400px] w-[1060px] relative bg-gray-300 bg-cover" style={{ backgroundImage: `url(${require("../assets/images/main_pop_link_1822.jpg")})` }}>
          <div className="flex h-[350px] w-[513px] space-x-6 p-7 bg-white translate-x-1/2 absolute bottom-0 right-0">
            <div className="h-[5px] w-[70px] bg-black mt-[20px]"></div>
            <div className="flex-1 flex flex-col space-y-5">
              <h1 className="text-[45px] leading-none font-bold">인구 예측 서비스</h1>
              <p className="text-xl leading-9 p-kr">미국 MIT의 System Dynamics 분석방법론을 적용하여 2060년까지 행정동별 인구를 예측하였습니다. 출산율과 인구이동지표 등 인구 정보를 활용해서 미래 인구 변화를 예측할 수 있습니다</p>
              <div>
                < NavLink to="/population" className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">서비스 시작하기</NavLink>
              </div>

            </div>
          </div>
        </div>


        {/* service 3 */}
        {/* <div className="h-[400px] w-[1060px] relative bg-gray-300">
          <div className="flex h-[300px] w-[513px] space-x-6 p-7 bg-white -translate-x-1/2 absolute bottom-0">
            <div className="h-[5px] w-[70px] bg-black mt-[20px]"></div>
            <div className="flex-1 flex flex-col space-y-5">
              <h1 className="text-[45px] leading-none font-bold">보고서 작성 서비스</h1>
              <p className="text-xl leading-9 p-kr">미국 MIT의 System Dynamics의 분석 방법론 과자체 개발한 부동산 매매가격 예측지수를 토대로 부동산의 미래 가격을 예측해보세요. </p>
              <div>
                < NavLink to="/" className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">서비스 시작하기</NavLink>
              </div>
              
            </div>
          </div>
        </div> */}



      </div>



    </MainContentWrapper>
  )
}

export default Main