import React, { Fragment, useReducer, useEffect, useState } from 'react'
import Echarts from '../../components/charts/Echarts'
import { getChartOptionMovement, getTimelineOptionForMovement } from '../../components/charts/PopulationEchartOptions'
import MainContentWrapper from '../../components/MainContentWrapper'
import FilterBar from '../../components/population/FilterBar'
import { CardWrapper, CardHeader, CardFooter, CardContent } from '../../components/population/CardWrapper'
import DotLoader from '../../components/loaders/DotLoader'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal';
import LoadingModal from '../../components/modals/LoadingModal';
import { handleError } from '../../utils/pop-handle-response'
import { NavLink, useNavigate } from 'react-router-dom'
import { checkSubscribed } from '../../utils/pop-subscription-checker'
import { useGlobalStateContext } from "../../components/context/GlobalContext"

const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
	SET_SIDO_DATA: 'set-sido-data',
	SET_SIDO_LIST: 'set-sido-list',
	SET_SIDO_YEARLY: 'set-sido-yearly',
	SET_YEAR: 'set-current-year',
	SET_SIDO: 'set-current-sido'
}


const initialState = {
	currentSD: '1100000000',
	sidoList: null,
	mainData: null,
	cumulativeData: null,
	currentYear: 2021,
	yearlyData: null
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_SIDO:
			return { ...state, currentSD: payload };
		case ACTION.SET_SIDO_LIST:
			return { ...state, sidoList: payload };
		case ACTION.SET_MAIN_DATA:
			return { 
				...state,
				mainData: payload.mainData
			};
		case ACTION.SET_SIDO_DATA:
			return { ...state, cumulativeData: payload };
		case ACTION.SET_SIDO_YEARLY:
			return { ...state, yearlyData: payload };
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

function PopLocalMovement() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isSimRunning, setSimRunning] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const { user } = useGlobalStateContext();
	const [isSubscribed, setSubscribed] = useState(false)
	let navigate = useNavigate()

	useEffect(() => {

		const loadDefault = async () => {
					
			let isUserSubscribed = false
			
			try{
				isUserSubscribed = await checkSubscribed(user)
				
				setSimRunning(true)

				if(isUserSubscribed){
					
					setSubscribed(true)
						
					let query = `?page_size=all&loc_type=sd`
					let response = await fetch(`${API_BASE_URL}/api/population/location-map${query}`, {
						method: "GET",
						headers: { "Content-Type": "application/json" }
					})

					if (!response.ok) throw new Error(handleError(response.status))

					let parseRes = await response.json()

					if(!parseRes.data || parseRes.data.length<=0) throw new Error("Unable to fetch location")

					dispatch({ type: ACTION.SET_SIDO_LIST, payload: parseRes.data })

					query = `?page_size=all&name_en__sw=Net Movement xx&loc_code=${state.currentSD}`
					response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
						method: "GET",
						headers: { "Content-Type": "application/json" },
					});

					if (!response.ok) throw new Error(handleError(response.status))
					
					let parseRes2 = await response.json()

					if(parseRes2.data && parseRes2.data.length>0){
						processMainData(parseRes2.data)
					}else{
						alert('No data available for this location.')
					}

				}
			}catch (error){
				setIsOpen(true)
				console.error(error.message)
			}finally{
				await setSimRunning(false)

				if(!isUserSubscribed)
					navigate("/pop-sub-redirect")	
			}
		}

		loadDefault();

	}, [])

	const processMainData = (data) => {

		let movSido = data.map(item => {
			return [parseInt(item.name_en.split(',')[1].trim().split(']')[0].replace('A', '')), parseInt(item.value.reduce((prev, current) => prev + current[1], 0) / 11)]

		})

		let year_list = data[0].value.map(item => { return item[0] })

		let sd_full = year_list.map(y => {
			return {
				title: { text: y + '년도' },
				series: [
					{
						data: data.map(val => {
							return {
								name: parseInt(val.name_en.split(',')[1].trim().split(']')[0].replace('A', '')),
								value: val.value.filter(i => i[0] === y)[0][1]
							}
						})
					}
				]
			}
		})
		
		dispatch({ 
            type: ACTION.SET_MAIN_DATA, 
			payload: {
				mainData: data
			}})

		let dataOptions = getChartOptionMovement(movSido)
		let yObject = getTimelineOptionForMovement(year_list, sd_full, [{ name: '', type: 'bar', stack: 'base' }])

		dispatch({ type: ACTION.SET_SIDO_DATA, payload: dataOptions })
		dispatch({ type: ACTION.SET_SIDO_YEARLY, payload: yObject })
	}

	const handleOnSelectChange = async (e) => {
		try{
			await setSimRunning(true)

			let cursido = e
			dispatch({ type: ACTION.SET_SIDO, payload: cursido })
	
			let query = `?page_size=all&name_en__sw=Net Movement xx&loc_code=${cursido}`
			let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
				method: "GET",
				headers: { "Content-Type": "application/json" },
			});
			
			if (!response.ok) throw new Error(handleError(response.status))

			const parseRes2 = await response.json()			
			if(parseRes2.data && parseRes2.data.length>0){
				processMainData(parseRes2.data)
			}else{
				alert('No data available for this location.')
			}
		}catch(error){
			setIsOpen(true)
			console.error(error.message)
		} finally {
			await setSimRunning(false)
		}

	}
	
	return (
		<MainContentWrapper className="bg-konabg">
			<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
			<LoadingModal isOpen={isSimRunning} />

			<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6">
				
				<FilterBar title={'데이터 선택'} className="col-span-2" >
					<div className='px-5 text-base'>
						<Listbox value={state.currentSD} onChange={handleOnSelectChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-20 min-w-[150px]">
										<Listbox.Button className="relative w-full cursor-default border-2 border-gray-50 rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:border-gray-200 sm:text-sm">
											<span className="block truncate">{(state.sidoList !== null) ? state.sidoList.filter(item => item.location_code === state.currentSD)[0].name : '정보 없음'}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto -z-10"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												{
													(state.sidoList) ? state.sidoList.map(({ location_code, name }, index) => (

														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={location_code}
														>
															{name}
														</Listbox.Option>

													)) : ''
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >

					</div>
				</FilterBar>
				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								순이동 (2020~2021 연간 평균)
							</div>
							<div className='flex text-right text-lg'>
								단위: 명
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5 flex flex-col" >
						<div className=' h-9 inline-flex items-center space-x-4'>
							<div className="flex relative pb-2 min-w-[200px]">

							</div>
						</div>
						<div className='flex-1 py-2 '>
							{
								state.cumulativeData ?
									<Echarts options={state.cumulativeData} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</div>
					</CardContent>
					<CardFooter>
						<div>데이터 출처: 국가통계포털</div>
					</CardFooter>
				</CardWrapper>
				<CardWrapper className="h-[500px]">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="flex-1">
								연도별 순이동
							</div>
							<div className='flex text-right text-lg'>
								단위: 명
							</div>
						</div>
					</CardHeader>
					<CardContent className="p-5" >
						{
							state.yearlyData ?
								<Echarts options={state.yearlyData} />
								:
								<div className="h-full flex justify-center items-center"></div>
						}
					</CardContent>
					<CardFooter>
						<div>데이터 출처: 국가통계포털</div>
					</CardFooter>
				</CardWrapper>
			</div>
		</MainContentWrapper>
	)
}

export default PopLocalMovement