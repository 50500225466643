import {range} from "@toss/utils"


export function TablePagination ({totalItems, pageSize, pageNum, clickCallback}) {
  const totalPages = Math.ceil(totalItems / pageSize);
  const pagesArray = range(1, totalPages + 1);
  
  
  return (
    <div className="flex justify-center space-x-2">
      {pagesArray.map(num => {
        if ( num === pageNum) {
          return <button key={num} className="flex items-center justify-center text-xs h-8 w-8 border-konared bg-konared text-white rounded-lg" disabled>{num}</button>
        }
        else{
          return <button key={num} onClick={() => clickCallback(num)} className="flex items-center justify-center text-xs h-8 w-8 border rounded-lg hover:bg-konared/20 ">{num}</button>
        }
      })

      }
    </div>
  )
}