import {round as lround} from 'lodash';
import {BiCaretDown, BiCaretUp} from 'react-icons/bi'
import Moment from 'moment';
import { varNameAptPriceIndexOut } from "../context/RealEstateContext";
import { customRound } from "../../utils/helpers";

const TableMySim = ({usersimResult}) => {
  const currMonth = Moment().format("YYYY-MM");
  const {konasimResult} = usersimResult;
  const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
  let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);

  //table data array for base data (sequence: current, 3mo, 6mo, 9mo, 1yr, 2yr)
  let tblBaseIndex = [baseDataPriceVal[0][1], baseDataPriceVal[6][1], baseDataPriceVal[12][1], baseDataPriceVal[24][1]]
  let tblBaseRate = tblBaseIndex.map((val, idx, arr) => customRound((val-arr[0])/arr[0]*100, 2));


  const {currentIndex} = konasimResult;
  const lastInputDate = konasimResult.inputDataPriceVal.at(-1)[0];
  
  const simDataArr =  Object.entries(usersimResult.simResults).map(([key,val]) => {
    // let indexOfLastInput = val.outputData[varNameAptPriceIndexOut].findIndex(d => d[0] == lastInputDate); 
    let indexOfLastInput = val.outputData[varNameAptPriceIndexOut].findIndex(d => d[0] == currMonth); 
    let simData = val.outputData[varNameAptPriceIndexOut].slice(indexOfLastInput);
    simData = simData.map(([x,y]) => [x, parseInt(usersimResult.address.info.resultAmt*(1+(lround(y,4)-currentIndex)/currentIndex))])  // round 2 decimal places

    var tblSimIndex = [simData[0][1], simData[6][1], simData[12][1], simData[24][1]]
    var tblSimRate = tblSimIndex.map((val, idx, arr) => lround((val-arr[0])/arr[0]*100, 2));

    return{
      simInfo: val.simInfo,
      tblSimIndex,
      tblSimRate
    }
    
  })


  return (
    <div className="">
      <table className="tbl-simresult table-fixed mt-5 text-xl">
        <thead>
          <tr>
            <th></th>
            <th className="text-konadownblue">현재 가격 ({`${Moment().format('M')}월`})</th>
            <th className="text-konared">6개월 후 예측 가격</th>
            <th className="text-konared">1년 후 예측 가격</th>
            <th className="text-konared">2년 후 예측 가격</th>
          </tr>
        </thead>
        <tbody>

          <tr>
            <th scope="row" className="">KONA 예측 가격</th>
            {
              tblBaseIndex.map((val, idx, arr) =>  
                idx === 0 
                ? (<td key={idx}><span>{val.toLocaleString()}원</span></td>) 
                : (<td key={idx}><div className="flex flex-col py-2"><div>{val.toLocaleString()}원 </div> <div className={`text-base font-normal inline-flex justify-center items-center ${val < arr[0] ? 'text-konadownblue' : 'text-konaupred'}`}>{val < arr[0] ? <BiCaretDown size={25} className="text-blue-600" /> : <BiCaretUp size={25} className="text-red-600" />  }{tblBaseRate[idx]}%</div></div></td>)
              )
            }
          </tr>
          
          {simDataArr.map((eachSim, index) => (
            <tr key={index}>
              <th scope="row" className="">{eachSim.simInfo.name}</th>
              {
                eachSim.tblSimIndex.map((val, idx, arr) =>  
                  idx === 0 
                  ? (<td key={idx}><span>{val.toLocaleString()}원</span></td>) 
                  : (<td key={idx}><div className="flex flex-col py-2"><div>{val.toLocaleString()}원 </div> <div className={`text-base font-normal inline-flex justify-center items-center ${val < arr[0] ? 'text-konadownblue' : 'text-konaupred'}`}>{val < arr[0] ? <BiCaretDown size={25} className="text-blue-600" /> : <BiCaretUp size={25} className="text-red-600" />  }{eachSim.tblSimRate[idx]}%</div></div></td>)
                )
              }
            </tr>
          ))

          }


          
          
          
        </tbody>
      </table>
    
    </div>
  )
}

export default TableMySim