import React from 'react'
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Moment from 'moment';
import {bearerAuth, InvalidRequestBody, ResponseError} from "../../../utils/helpers"
import UserInfoContext, {ACTION as USER_INFO_ACTION} from "../../context/UserInfoProvider";
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";

const validationSchema = Yup.object().shape({
  password: Yup.string()
      .required('비밀번호를 입력해주세요'),
});

const SetPassword = () => {
  const {user} = useGlobalStateContext();
  const {userInfoState, userInfoDispatch} = React.useContext(UserInfoContext);
  const form = useForm({mode:"onChange", resolver: yupResolver(validationSchema)});

  const onSubmit = async data => {
    userInfoDispatch({type: USER_INFO_ACTION.START_SUBMIT_FORM});

    const transactionID = Moment().format("YYYYMMDDHHmmss");
    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({transaction_id: transactionID, password: data.password})
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/myinfo-verify`, requestOptions);
      const formInfo = await response.json();

      if (response.status === 403) throw new ResponseError(formInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(formInfo.detail);
      
      
      if(formInfo.token &&  Object.keys(formInfo.user_info).length > 0 ){
        userInfoDispatch({
          type: USER_INFO_ACTION.MYINFO_VERIFY_SUCCESS,
          payload: {userInfo: formInfo.user_info, formToken: formInfo.token, transactionID}
        });
      }


    } catch (err) {
      if (err instanceof ResponseError) {
        form.setError('password', { type: 'custom', message: err.message }, { shouldFocus: true });
        userInfoDispatch({type: USER_INFO_ACTION.SET_LOADING, payload: false})
      }
      else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      }
      else {
        throw(err)
      }
    }

  } 

  return (
    <div>
      <div className="mt-[60px] pb-5 text-lg">본인 확인을 위해 비밀번호를 한번 더 입력해주세요.</div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="border-t border-t-black border-b border-b-konagray/10 divide-y divide-konagray/10">
          <div className="flex flex-col">
            <div className="flex p-4 text-lg items-center">
              <div className="flex-initial w-60 font-semibold">비밀번호</div>
              <div className="flex-1">
                <Controller
                  render={({ field, fieldState: {isDirty, error}}) => {

                    let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                    if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                    if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                    return(
                      <>
                        <input 
                          {...field} 
                          autoFocus
                          type="password"
                          placeholder="비밀번호 입력"
                          className={`w-full border px-8 py-4  outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
                        />
                      </>
                    )
                  }}
                  defaultValue=""
                  name="password"
                  control={form.control}
                />
              </div>
            </div>
            {form.formState.errors?.password && <div className="pl-64"><span className="text-red-500 inline-flex pb-4">{form.formState.errors?.password?.message}</span></div>}
          </div>
        </div>

        <div className="flex justify-center pt-10">
          <button type="submit" className="bg-konared text-white text-lg px-8 py-4 ripple-primary min-w-[140px] inline-flex justify-center" disabled={userInfoState.formLoading} >
            {userInfoState.formLoading && 
              <svg className="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            <span>다음</span>
          </button>

        </div>
      </form>
    </div>  
  )
}

export default SetPassword