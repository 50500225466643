import React, {useState, useEffect, useRef} from 'react'
import PropTypes from 'prop-types'
import * as echarts from 'echarts'
import debounce from 'lodash/debounce';


const Echarts = props => {
  const {options, location} = props

  const chartRef = useRef(null)
  const [chartEl, setChartEl] = useState(chartRef)
  const [width, setWidth] = useState(0)

  //add event listener which is used to detect window resize
  useEffect(() => {
    window.addEventListener('resize', debounce(() => setWidth(window.innerWidth),10), { passive: true })
    return () => {
      window.removeEventListener('resize', debounce(() => setWidth(window.innerWidth),10), { passive: true })
    }
  }, [])

  useEffect(() => {
    if(!chartEl.current){
      // chartEl.clear() //trigger animation when option is changed | modify: do not trigger
      chartEl.resize()    
      chartEl.setOption(options)
    }
    else{
      setChartEl(echarts.init(chartRef.current))
    }

  }, [chartEl, options])


  //for window resize | this part is separeted from the above use effect to trigger animation when option change but not when window is resized
  useEffect(() => {
    if(!chartEl.current){
      chartEl.resize()    
      chartEl.setOption(options)
    }

  }, [width])

  useEffect(() => {
    if(!chartEl.current){
      chartEl.clear()    
    }

  }, [location])

  return (
    <div ref={chartRef} style={{height: '100%', width: '100%'}}></div>
  )
}

Echarts.propTypes = {
  options: PropTypes.any,
}

export default Echarts
