import React, { Fragment, useReducer, useEffect, useState } from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import FilterBar from '../../components/population/FilterBar'
import { CardWrapper, CardHeader, CardFooter, CardContent, CardHeaderFilter } from '../../components/population/CardWrapper'
import { getChartOptionBirth, getChartOptionInternationalData, getChartOptionMarriage } from '../../components/charts/PopulationEchartOptions';
import Echarts from '../../components/charts/Echarts';
import PopulationEcharts from '../../components/charts/PopulationEcharts';
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal'
import LoadingModal from '../../components/modals/LoadingModal'
import { handleError } from '../../utils/pop-handle-response'
import { checkSubscribed } from '../../utils/pop-subscription-checker';
import { NavLink, useNavigate } from 'react-router-dom';
import { useGlobalStateContext } from "../../components/context/GlobalContext";

const DEFAULT_COUNTRIES = ['한국', '일본', '필리핀', '미국', '독일', '영국', '이스라엘']

const UNITS = {
	'부양비' : '%',
	'장래인구' : '1000 명',
	'기대수명' : '년',
	'영아사망률' : '1000 명당 명',
	'합계출산율' : '명'
}

const ACTION = {
	SET_SELECTED_DATA: 'set-data',
	SET_SELECTED_COUNTRIES: 'set-countries',
	SET_MAIN_DATA: 'set-main-data',
	RESET: 'reset-choices'
}

const initialState = {
	curData: 'No Data Selected',
	datalist: null,
	countrylist: null,
	selectedCountries: null,
	mainData: null,
	graphData: null
}

//부양비 그래프

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_MAIN_DATA:
			return {
				...state,
				mainData: payload.mainData,
				curData: payload.curData,
				datalist: payload.datalist,
				countrylist: payload.countrylist,
				selectedCountries: payload.selectedCountries,
			};
		case ACTION.SET_SELECTED_COUNTRIES:
			return {
				...state,
				selectedCountries: payload.selectedCountries,
			};
		case ACTION.SET_SELECTED_DATA:
			return {
				...state,
				curData: payload.curData,
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

const Icon_colored_printer =(props)=> {
	return (
	  	<svg {...props} viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="5.5" width="28" height="10" rx="1.5" fill="white" stroke="black"/>
		<path d="M10 0.5H19C19.8284 0.5 20.5 1.17157 20.5 2V5.5H8.5V2C8.5 1.17157 9.17157 0.5 10 0.5Z" fill="white" stroke="black"/>
		<rect x="5.5" y="10.5" width="18" height="9" fill="#D9D9D9" stroke="black"/>
		<rect x="6" y="11" width="6" height="8" fill="#4EB9FF"/>
		<rect x="6" y="11" width="6" height="8" fill="#4EB9FF"/>
		<rect x="18" y="11" width="5" height="8" fill="#FDCB44"/>
		<rect x="12" y="11" width="6" height="8" fill="#FD4080"/>
		<circle cx="26" cy="8" r="1" fill="black"/>
		</svg>
	)
}

const Icon_black_printer =(props)=> {
	return (
	  	// <svg width="29" height="20" viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<svg {...props} viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="5.5" width="28" height="10" rx="1.5" fill="white" stroke="black"/>
		<path d="M10 0.5H19C19.8284 0.5 20.5 1.17157 20.5 2V5.5H8.5V2C8.5 1.17157 9.17157 0.5 10 0.5Z" fill="white" stroke="black"/>
		<rect x="5.5" y="10.5" width="18" height="9" fill="#D9D9D9" stroke="black"/>
		<rect x="6" y="11" width="9" height="8" fill="#3B3A35"/>
		<circle cx="26" cy="8" r="1" fill="black"/>
		<path d="M8.70455 18V12.1818H10.7386C11.1439 12.1818 11.4782 12.2519 11.7415 12.392C12.0047 12.5303 12.2008 12.7169 12.3295 12.9517C12.4583 13.1847 12.5227 13.4432 12.5227 13.7273C12.5227 13.9773 12.4782 14.1837 12.3892 14.3466C12.3021 14.5095 12.1866 14.6383 12.0426 14.733C11.9006 14.8277 11.7462 14.8977 11.5795 14.9432V15C11.7576 15.0114 11.9366 15.0739 12.1165 15.1875C12.2964 15.3011 12.447 15.464 12.5682 15.6761C12.6894 15.8883 12.75 16.1477 12.75 16.4545C12.75 16.7462 12.6837 17.0085 12.5511 17.2415C12.4186 17.4744 12.2093 17.6591 11.9233 17.7955C11.6373 17.9318 11.2652 18 10.8068 18H8.70455ZM9.40909 17.375H10.8068C11.267 17.375 11.5938 17.286 11.7869 17.108C11.982 16.928 12.0795 16.7102 12.0795 16.4545C12.0795 16.2576 12.0294 16.0758 11.929 15.9091C11.8286 15.7405 11.6856 15.6061 11.5 15.5057C11.3144 15.4034 11.0947 15.3523 10.8409 15.3523H9.40909V17.375ZM9.40909 14.7386H10.7159C10.928 14.7386 11.1193 14.697 11.2898 14.6136C11.4621 14.5303 11.5985 14.4129 11.6989 14.2614C11.8011 14.1098 11.8523 13.9318 11.8523 13.7273C11.8523 13.4716 11.7633 13.2547 11.5852 13.0767C11.4072 12.8968 11.125 12.8068 10.7386 12.8068H9.40909V14.7386Z" fill="#FFFEFE"/>
		<path d="M8.70455 18V12.1818H10.7386C11.1439 12.1818 11.4782 12.2519 11.7415 12.392C12.0047 12.5303 12.2008 12.7169 12.3295 12.9517C12.4583 13.1847 12.5227 13.4432 12.5227 13.7273C12.5227 13.9773 12.4782 14.1837 12.3892 14.3466C12.3021 14.5095 12.1866 14.6383 12.0426 14.733C11.9006 14.8277 11.7462 14.8977 11.5795 14.9432V15C11.7576 15.0114 11.9366 15.0739 12.1165 15.1875C12.2964 15.3011 12.447 15.464 12.5682 15.6761C12.6894 15.8883 12.75 16.1477 12.75 16.4545C12.75 16.7462 12.6837 17.0085 12.5511 17.2415C12.4186 17.4744 12.2093 17.6591 11.9233 17.7955C11.6373 17.9318 11.2652 18 10.8068 18H8.70455ZM9.40909 17.375H10.8068C11.267 17.375 11.5938 17.286 11.7869 17.108C11.982 16.928 12.0795 16.7102 12.0795 16.4545C12.0795 16.2576 12.0294 16.0758 11.929 15.9091C11.8286 15.7405 11.6856 15.6061 11.5 15.5057C11.3144 15.4034 11.0947 15.3523 10.8409 15.3523H9.40909V17.375ZM9.40909 14.7386H10.7159C10.928 14.7386 11.1193 14.697 11.2898 14.6136C11.4621 14.5303 11.5985 14.4129 11.6989 14.2614C11.8011 14.1098 11.8523 13.9318 11.8523 13.7273C11.8523 13.4716 11.7633 13.2547 11.5852 13.0767C11.4072 12.8968 11.125 12.8068 10.7386 12.8068H9.40909V14.7386Z" fill="#FFFEFE"/>
		<path d="M16.7614 18L15.1705 12.1818H15.8864L17.1023 16.9205H17.1591L18.3977 12.1818H19.1932L20.4318 16.9205H20.4886L21.7045 12.1818H22.4205L20.8295 18H20.1023L18.8182 13.3636H18.7727L17.4886 18H16.7614Z" fill="#2E2A2A"/>
		</svg>
	)
}
function PopInternational() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isSimRunning, setSimRunning] = useState(false)
	const { user } = useGlobalStateContext();
	const [isOpen, setIsOpen] = useState(false)
	const [graphOptions, setGraphOptions] = useState(null)
	const [isBW, setIsBW] = useState(false)

	let navigate = useNavigate()


	useEffect(() => {

		const loadDefault = async () => {
			let selectedCountries = DEFAULT_COUNTRIES
			try{
				setSimRunning(true)

				let query = `?page_size=all&name_en__sw=country comparison`
				let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
				method: "GET",
				headers: { "Content-Type": "application/json" },
				});
				
				if (!response.ok) throw new Error(handleError(response.status))

				let parseRes = await response.json()

				if(!parseRes.data || parseRes.data.length<=0) throw Error("No country data available")
	
				let set_name = parseRes.data.map(item=>{return item.name_kr.split('-')[0].trim()})
				let country_name = parseRes.data.map(item=>{return item.loc_code})
	
				set_name = [...new Set(set_name)]
				country_name = [...new Set(country_name)]
	
				let dataSet = {}
			
				if (set_name.length > 0) {
					set_name.forEach((name) => {
						let pair = {}
						parseRes.data.forEach((item) => {
							if (item.name_kr.includes(name)) {
								pair[item.loc_code] = item.value
							}
						})
						dataSet[name] = pair
					})
				}
	
				setGraphOptions(getChartOptionInternationalData(dataSet[set_name[0]], selectedCountries, isBW))
				
				dispatch({
					type: ACTION.SET_MAIN_DATA,
					payload: {
						mainData: dataSet,
						curData: set_name[0],
						datalist: set_name,
						countrylist: country_name,
						selectedCountries: selectedCountries
					}
				})
			}catch(error){
				setIsOpen(true)
				console.error(error.message)
			} finally {
				setSimRunning(false)
			}
		}

		loadDefault();
	}, [])

	const handleFilterChange = (name) => {
		let selectedCountries = JSON.parse(JSON.stringify(state.selectedCountries))

		if(selectedCountries.includes(name)){
			selectedCountries = selectedCountries.filter(country=> !country.includes(name))
		}else{
			selectedCountries.push(name)
		}

		setGraphOptions(getChartOptionInternationalData(state.mainData[state.curData], selectedCountries, isBW))
		
		dispatch({
			type: ACTION.SET_SELECTED_COUNTRIES,
			payload: {
				selectedCountries: selectedCountries,
			}
		})
	}

	const handleOnSelectChange = (e) => {
		let data = e
		
		setGraphOptions(getChartOptionInternationalData(state.mainData[data], state.selectedCountries, isBW))

		dispatch({
			type: ACTION.SET_SELECTED_DATA,
			payload: {
				curData: data,
			}
		})
	}

	const handleReset = () => {
		setGraphOptions(getChartOptionInternationalData(state.mainData[state.curData], DEFAULT_COUNTRIES, isBW))
		dispatch({
			type: ACTION.SET_SELECTED_COUNTRIES,
			payload: {
				selectedCountries: DEFAULT_COUNTRIES,
			}
		})
	}

	const setPrintrColor =()=>{
		let new_printr_state = !isBW
		setIsBW(new_printr_state)
		setGraphOptions(getChartOptionInternationalData(state.mainData[state.curData], state.selectedCountries, new_printr_state))
	}
	return (
		<MainContentWrapper className="bg-konabg relative">
		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
		<LoadingModal isOpen={isSimRunning} />

			<div className="pt-10 pb-20 px-28 flex flex-col space-y-4">
				
				<FilterBar title={'데이터 선택'} className="col-span-2" >
					<div className='px-5 text-base'>
						<Listbox value={state.curData} onChange={handleOnSelectChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-10 min-w-[150px]">
										<Listbox.Button className="relative w-full cursor-default border-2 border-gray-50 rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md hover:border-gray-200 sm:text-sm">
											<span className="block truncate">{(state.datalist !== null) ? state.curData : '정보 없음'}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto -z-10"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												{
													(state.datalist) ? state.datalist.map((name, index) => (

														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={name}
														>
															{name}
														</Listbox.Option>

													)) : ''
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >
					</div>
				</FilterBar>
				<div className="flex flex-row space-x-4 h-[500px]">

					<CardWrapper className="flex-1 h-full">
					<CardHeader >
						<div className='w-full flex flex-row items-center'>
							<div className="">
								{state.curData}
							</div>
							<div className="flex-1 px-2">
								<button type="button" className="flex-initial py-2 px-2 text-white hover:bg-red-600 rounded-lg " onClick ={setPrintrColor}> 
								{
									isBW ?
									(<Icon_colored_printer
										className="h-5 hover:shadow-lg hover:text-gray-500"
										aria-hidden="true"
									/>)
									:
									(<Icon_black_printer
										className="h-5 hover:shadow-lg hover:text-gray-500"
										aria-hidden="true"
									/>)
								}
									
								</button>
							</div>
							<div className='flex text-right text-lg'>
								단위: {UNITS[state.curData]}
							</div>
						</div>
					</CardHeader>
						<CardContent className=" p-5 " >
							{
								graphOptions ?
									<PopulationEcharts options={graphOptions} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: UN(국제연합)</div>
						</CardFooter>
					</CardWrapper>


					<CardWrapper className="flex-none w-[300px] h-full">
						<CardHeaderFilter className='flex-row'>
							<div className="flex-1">
								국가 선택
							</div>
							<div className='flex text-right'>
								<button className='inline-flex items-center space-x-2 border border-white px-4 rounded-full text-white text-base font-semibold' onClick={handleReset}>
									초기화
								</button>
							</div>
						</CardHeaderFilter>

						<CardContent className="flex flex-col px-5 pt-2 py-5 overflow-y-auto" >
							{(state.countrylist) ? state.countrylist.map((name, index) => {
								return (
									<div className="py-1" key={index} >
										<input
											type="checkbox"
											id={`custom-checkbox-${index}`}
											name={name}
											value={name}
											checked={state.selectedCountries.includes(name)}
											onChange={() => handleFilterChange(name)}
										/>
										<label className=" pl-3 text-base" htmlFor={`custom-checkbox-${index}`}>{name}</label>
									</div>
								);
							})
								:
								<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
					</CardWrapper>

				</div>
			</div>
		</MainContentWrapper>
	)
}

export default PopInternational