import React from 'react'
import { PaymentProvider } from "../components/context/PaymentProvider"
import MainContentWrapper from "../components/MainContentWrapper"
import PaymentContent from "../components/payment/PaymentContent"

const Payment = () => {
  return (
    <PaymentProvider>
      <MainContentWrapper className="flex justify-center">
          <PaymentContent />
      </MainContentWrapper>
    </PaymentProvider>
  )
}

export default Payment