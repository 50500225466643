import React from 'react'
import { UserInfoProvider } from "../../context/UserInfoProvider"
import UserInfoContent from "./UserInfoContent"

const UserInfo = () => {
  

  return (
    <UserInfoProvider>
      <div className="pt-10 pb-5 border-b border-konagray/10">
        <h1 className="text-2xl font-bold text-konared">개인정보수정</h1>
      </div>
      <UserInfoContent />
    </UserInfoProvider>
  )
}

export default UserInfo