import React from 'react'

const FilterBar = ({title, className, children}) => {
  return (
    <div className={`flex flex-row rounded-xl shadow-md h-[64px] bg-white  ${className}`}>
    {/* <div className={`flex flex-row rounded-xl overflow-hidden shadow-md h-[500px] bg-white  ${className}`}> */}
      <div className={`flex items-center rounded-xl text-xl font-bold bg-konagray w-44 text-white justify-center`}>
        {title}
      </div>
      <div className={`flex-1 flex items-center`}>{children}</div>
    </div>
  )
}

export default FilterBar
