import React from 'react'
import "../../assets/css/loaders.css"

const Loader2 = () => {
  return (
    <div className="flex-1 flex items-center justify-center">
      <span className="loader2 w-20 h-20"></span>
    </div>
  )
}

export default Loader2