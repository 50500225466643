import React from 'react'
import { factoryUseContext } from "./GlobalContext";

export const SIM_PARAMETERS = {
  "e L CLI": {inputVarName: "D Composite Leading Indicator", title:"경기선행지수", checkboxDefault:false},
  "e L CPI": {inputVarName: "D Consumer Price Index", title:"소비자물가지수", checkboxDefault:false},
  "e L MIR": {inputVarName: "D Mortgage Interest Rate", title:"주택담보대출금리", checkboxDefault:true},
  "e L KOSPI": {inputVarName: "D KOSPI Stock Index", title:"종합주가지수 (KOSPI)", checkboxDefault:false},
  "e L M2": {inputVarName: "D Money Stock", title:"통화량(M2, 평잔)", checkboxDefault:false},
  "e L REM": {inputVarName: "D Real Estate Money", title:"부동산자금 관련변수", checkboxDefault:false},
  "e L HSR x": {inputVarName: "D House Supply Ratio", title:"주택 보급율", checkboxDefault:false},
  "e L TTI x": {inputVarName: "D Trade Trend Index x", title:"매매수급동향지수", checkboxDefault:false},
}


export const SIM_EVENT_PARAMETERS = {
  "EA" : {dateVar: "e User Event Up start time x", paramVar: "p User Apartment Event Up coeff xx", title: "부동산 상승 이벤트"},
  "EB" : {dateVar: "e User Event Down start time x", paramVar: "p User Apartment Event Down coeff xx", title: "부동산 하락 이벤트"},
  // "EC" : {dateVar: "e User Event UpDown start time x", paramVar: "p User Apartment Event UpDown coeff xx", title: "사건 사고"}
}

export const USEREVENT_PARAM_DEFAULT_VAL = 0.1;


export const ReMysimStateContext = React.createContext(null);
export const ReMysimDispatchContext = React.createContext(null);

export const REMYSIM_ACTION = {
  ADD_SIM_RESULT: 'add-sim-result',
  REMOVE_SIM_RESULT_GROUP: 'remove-sim-result-group',
  RESET_MYSIM: 'reset-mysim'
}
const initialValue = {
  simResultsGroup: {},
}


function reducer(state, {type, payload}){
  switch(type){
    case REMYSIM_ACTION.ADD_SIM_RESULT: {
      const [key, val] = Object.entries(payload.simResultsGroup)[0];
      var simResultsGroup;
      if (key in state.simResultsGroup){
        simResultsGroup = {...state.simResultsGroup, [key]: {...state.simResultsGroup[key], simResults: {...state.simResultsGroup[key].simResults, ...val.simResults}} };
      }
      else{
        simResultsGroup = {...state.simResultsGroup, ...payload.simResultsGroup};
      }

      return {...state, simResultsGroup};
    }
    case REMYSIM_ACTION.RESET_MYSIM:
      return {...state, ...initialValue}
    case REMYSIM_ACTION.REMOVE_SIM_RESULT_GROUP:{

      const simResultsGroup = {...state.simResultsGroup};
      delete simResultsGroup[payload];

      return {...state, simResultsGroup: {...simResultsGroup}}
    }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}

export default function ReMysimContextProvider({children}) {
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const values = React.useMemo(() => state, [state]);

  return (
    <ReMysimStateContext.Provider value={values}>
      <ReMysimDispatchContext.Provider value={dispatch} >
        {children}
      </ReMysimDispatchContext.Provider>
    </ReMysimStateContext.Provider>
  )
}


export const useReMysimStateContext = factoryUseContext("ReMysimStateContext", ReMysimStateContext);
export const useReMysimDispatchContext = factoryUseContext("ReMysimDispatchContext",ReMysimDispatchContext);