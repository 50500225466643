import React, { useReducer, useEffect, useState } from 'react'
import Echarts from '../../components/charts/Echarts';
import { getChartOptionFemaleMarriage, getChartOptionFemaleMarriageRate, getChartOptionFemalePopulation, getChartOptionMarriage, getTimelineOptionFemaleMarriageByAge, getTimelineOptionFemaleMarriageRateByAge, getTimelineOptionFemalePopulationByAge, getTimelineOptionForLocMarriage } from '../../components/charts/PopulationEchartOptions';
import MainContentWrapper from '../../components/MainContentWrapper'
import { CardHeader, CardFooter, CardWrapper, CardContent } from '../../components/population/CardWrapper'
import APIUnavailableModal from '../../components/modals/APIUnavailableModal';
import Loader3 from "../../components/loaders/Loader3";
import { ApiError, AppError, bearerAuth, customRound, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { getFirstMarriageDataApi } from "../../utils/api/populationApi";
import { roundToUnit } from "@toss/utils";


const ACTION = {
	LOAD_DATA: 'load-data',
}

const initialState = {
	yearList: null,
	marriageData: null,
	populationData: null,
	isLoaded: false,
}


const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.LOAD_DATA:
			return { 
			  	...state, 
					yearList: payload.yearList,
					marriageData: payload.marriageData,
					populationData: payload.populationData,
					isLoaded: true,
			 };
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

function PopMarriage() {
	const [state, dispatch] = useReducer(reducer, initialState);
	const [isOpen, setIsOpen] = useState(false);
	const globalDispatch = useGlobalDispatchContext();
	const { user } = useGlobalStateContext();

	useEffect(() => {

		const loadDefault = async () => {

			try {

				let firstMarriageData = await getFirstMarriageDataApi(user);

				// add yearKeyObj in the dataset, this will be uses durring summation instead of filtering within a loop
				firstMarriageData = firstMarriageData.map(data =>( {...data, yearKey: Object.fromEntries(data.value)}));

				// create year list base on first index (it is assumed that whole dataset has the same yearlist)
				const yearList = firstMarriageData[0].value.map(([year,_]) => year);

				// get total marriage rate of female by year
				const totalFemaleMarriageRateYear = yearList.map(year => {
					let value = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 초혼 비율"))
						.map(data => data.yearKey[year])
						.reduce((prev, current) => prev + current * 100, 0)	//convert to percentage
					return [year, value];
				})

				
				// get total marriage rate of female by age and group by year (will be used for timeline chart)
				const totalFemaleMarriageRateAge = yearList.map(year => {
					let seriesData = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 초혼 비율"))
						.sort((a, b) => a.name_kr.split("-")[1] - b.name_kr.split("-")[1])
						.map(data => ({name: `${data.name_kr.split("-")[1]}세` , value: data.yearKey[year] * 100})) //convert to percentage
					return ({
						title: {text:  `${year}년도`},
						series: [{data: seriesData}]
					})
				});


				// get max value marriage rate of female by age
				const maxFemaleMarriageRateAge = Math.max(
					...firstMarriageData
					.filter(data => data.name_kr.includes("연령별 여성 초혼 비율"))
					.map(data => Math.max(...data.value.map(ix => ix[1])))
				)
				
				// get total marriage of female by year (persons)
				const totalFemaleMarriageYear = yearList.map(year => {
					let value = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 초혼수"))
						.map(data => data.yearKey[year])
						.reduce((prev, current) => prev + current, 0)
					return [year, value];
				})


				// get total marriage of female by age and group by year (persons) (will be used for timeline chart)
				const totalFemaleMarriageAge = yearList.map(year => {
					let seriesData = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 초혼수"))
						.sort((a, b) => a.name_kr.split("-")[1] - b.name_kr.split("-")[1])
						.map(data => ({name: `${data.name_kr.split("-")[1]}세` , value: data.yearKey[year]})) //convert to percentage
					return ({
						title: {text:  `${year}년도`},
						series: [{data: seriesData}]
					})
				});


				// get max value marriage of female by age
				const maxFemaleMarriageAge = Math.max(
					...firstMarriageData
					.filter(data => data.name_kr.includes("연령별 여성 초혼수"))
					.map(data => Math.max(...data.value.map(ix => ix[1])))
				)

				const marriageData = {
					totalFemaleMarriageRateYear: totalFemaleMarriageRateYear,
					totalFemaleMarriageRateAge: totalFemaleMarriageRateAge,
					maxFemaleMarriageRateAge: roundToUnit(maxFemaleMarriageRateAge * 100, 1),	//convert to percentage
					totalFemaleMarriageYear: totalFemaleMarriageYear,
					totalFemaleMarriageAge: totalFemaleMarriageAge,
					maxFemaleMarriageAge: maxFemaleMarriageAge,
				}





				// get total population by gender
				const totalFemalePopulationYear = yearList.map(year => {
					let value = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 인구"))
						.map(data => data.yearKey[year])
						.reduce((prev, current) => prev + current, 0)	//convert to percentage
					return [year, value];
				})

				
				// get total marriage rate of female by age and group by year (will be used for timeline chart)
				const totalFemalePopulationAge = yearList.map(year => {
					let seriesData = firstMarriageData
						.filter(data => data.name_kr.includes("연령별 여성 인구"))
						.sort((a, b) => a.name_kr.split("-")[1] - b.name_kr.split("-")[1])
						.map(data => ({name: `${data.name_kr.split("-")[1]}세` , value: data.yearKey[year]})) //convert to percentage
					return ({
						title: {text:  `${year}년도`},
						series: [{data: seriesData}]
					})
				});


				// get max value marriage rate of female by age
				const maxFemalePopulationAge = Math.max(
					...firstMarriageData
					.filter(data => data.name_kr.includes("연령별 여성 인구"))
					.map(data => Math.max(...data.value.map(ix => ix[1])))
				)

				const populationData = {
					totalFemalePopulationYear: totalFemalePopulationYear,
					totalFemalePopulationAge: totalFemalePopulationAge,
					maxFemalePopulationAge: maxFemalePopulationAge,
				}

				



				dispatch({
					type: ACTION.LOAD_DATA, 
					payload: {
						yearList: yearList,
						marriageData: marriageData,
						populationData: populationData,
					},
				})
		
			} catch (err) {
				if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
					const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
					globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
				}
				else{
					console.error(err)
				}
			}

		}
		loadDefault();
	}, [])

	return (
		<MainContentWrapper className="bg-konabg relative flex flex-col">
			<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />

			{!state.isLoaded ? (
				<div className="flex-1 flex justify-center items-center"><Loader3 /></div>
			) : (

				<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6">

					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1 text-left ">합계 초혼율</div>
								<div className='flex text-right text-lg'>단위: %</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 ">
							{state?.marriageData?.totalFemaleMarriageRateYear && <Echarts options={getChartOptionFemaleMarriageRate(state?.marriageData?.totalFemaleMarriageRateYear)} />}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>


					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row text-lg'>
								<div className="flex-1  text-left">연령별 연간 초혼 비율</div>
								<div className='flex text-right text-lg'>단위: %</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 flex flex-col" >
							{state?.marriageData?.totalFemaleMarriageRateAge && <Echarts options={getTimelineOptionFemaleMarriageRateByAge(state?.yearList, state?.marriageData?.totalFemaleMarriageRateAge, state?.marriageData?.maxFemaleMarriageRateAge)} />}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1 text-left">초혼수</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5">
							{state?.marriageData?.totalFemaleMarriageYear && <Echarts options={getChartOptionFemaleMarriage(state?.marriageData?.totalFemaleMarriageYear)} />}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>


					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row  items-center'>
								<div className="flex-1">연령별 초혼수</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5  flex flex-col" >
							<div className='flex-1 py-2'>
								{state?.marriageData?.totalFemaleMarriageAge && <Echarts options={getTimelineOptionFemaleMarriageByAge(state?.yearList, state?.marriageData?.totalFemaleMarriageAge, state?.marriageData?.maxFemaleMarriageAge)} />}
							</div>

						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">남.여 인구</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5">
							{state?.populationData?.totalFemalePopulationYear && <Echarts options={getChartOptionFemalePopulation(state?.populationData?.totalFemalePopulationYear)} />}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>


					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">연령별 인구</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 flex flex-col" >
							<div className='flex-1 py-2'>
								{state?.populationData?.totalFemalePopulationAge && <Echarts options={getTimelineOptionFemalePopulationByAge(state?.yearList, state?.populationData?.totalFemalePopulationAge, state?.populationData?.maxFemalePopulationAge)} /> }
							</div>
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

				</div>
			)

			}

		</MainContentWrapper>
	)
}

export default PopMarriage