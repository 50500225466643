
import {graphic, time} from 'echarts'
import {round as lround} from 'lodash';
import { toMBT } from "../../utils/formatter"
import Moment from 'moment';
import { varNameAptPriceIndexOut } from "../context/RealEstateContext";

export const DefaultToolbox = {
  right: 10,
  feature: {
      dataZoom: {
          yAxisIndex: 'none'
      },
    //   restore: {},
      saveAsImage: {}
  }
}




export const getChartOptionsKonaSim = (konasimResult) => {
    const currMonth = Moment().format("YYYY-MM");
    //start date from 2017-01
    const startIndex = konasimResult.inputDataPriceVal.findIndex(d => d[0] == "2017-01")
    let inputDataPriceVal = konasimResult.inputDataPriceVal.slice(startIndex,);

    const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
    // let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);
    let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(startIndex,);


    const lastInputDate = inputDataPriceVal.at(-1)[0];
    const colorPalette = ['#075985','#FF3C42',  '#fac858', '#5ECD43', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc'];

    return {
        toolbox: {
          right: "-5px",
          feature: {
              dataZoom: {
                  yAxisIndex: 'none'
              },
            //   restore: {},
            //  saveAsImage: {}
          }
        },
        tooltip: {
            trigger: 'axis',
            confine:true,
            renderMode: 'richText',
            // axisPointer: {
            //   type: 'cross'
            // }
        },
        animation: true,
        legend: {
          icon: "roundRect",
            top: 0,
            left: 'center',
            textStyle: {
              fontSize: '14',
            },
            },
        grid: {
            show: true,
            left: '0.2%',
            right: '0.2%',
            bottom: '0%',
            top: '10%',
            containLabel: true
        },
        color: colorPalette,
        xAxis: {
          type:"category",
          // axisLabel: {
          //   fontSize: 12
          // },

          axisLabel: {
            formatter: (function(value){
                // return Moment(value).format("YYYY년 MM월")
                return time.format(value, "{yyyy}년 {MM}월");
            }),
            interval: 5 ,
            rotate: 30,
            fontSize: 11
        },
        
          boundaryGap: false,
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          },
           
          
        },
        yAxis: {
          type: 'value',
          // min: function(item){
          //     return (item.min <= 55 ? 40 : 60)
          // },
          // max: function(item){
          //   return (item.max >= 135 ? 160 : 140)
          // },

          boundaryGap: false,
          scale: true,
          axisLabel: {
            fontSize: 12
          }
        },
        series: [
          {
            name: "실제가격",
            type: "line",
            showSymbol: false,
            data: inputDataPriceVal,
            lineStyle: {
              width: 3
            },
            markLine: {
              data: [ { name: 'currDate', xAxis: currMonth } ],
              symbol: ["none"],
              label : {
                position: "start", distance: -30, 
                formatter: "현재: {c}",
                fontSize: 12,
              },
              lineStyle: {
                color: '#9ca3af',
              },
           },
           
           emphasis: {
            focus: 'series',
          }
          },
          {
            name: "KONA 예측 가격",
            type: "line",
            showSymbol: false,
            data: baseDataPriceVal,
            lineStyle: {
              type: [5, 5],
              width: 1.5,
            },
            emphasis: {
              focus: 'series'
            },
          },
        ]
      }
}




export const getChartOptionsMySim = (usersimResult) => {
  

  const currMonth = Moment().format("YYYY-MM");


  //start date from "2017-01"
  const {konasimResult} = usersimResult;
  const startIndex = konasimResult.inputDataPriceVal.findIndex(d => d[0] == "2017-01")
  let inputDataPriceVal = konasimResult.inputDataPriceVal.slice(startIndex,);
  const baseDataCurrMonthIndex = konasimResult.baseDataPriceVal.findIndex(d => d[0] === currMonth);
  // let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(baseDataCurrMonthIndex);
  let baseDataPriceVal = konasimResult.baseDataPriceVal.slice(startIndex,);

  const {origLastInputDate, currentIndex} = konasimResult;
  

  const simResultOption = Object.entries(usersimResult.simResults).map(([key,val]) => {
    let indexOfLastInput = val.outputData[varNameAptPriceIndexOut].findIndex(d => d[0] == origLastInputDate);
    let simData = val.outputData[varNameAptPriceIndexOut].slice(startIndex,);
    simData = simData.map(([x,y]) => [x, parseInt(usersimResult.address.info.resultAmt*(1+(lround(y,4)-currentIndex)/currentIndex))])  // round 2 decimal places
    return{
      name: val.simInfo.name,
      type: "line",
      showSymbol: false,
      data: simData,
      lineStyle: {
        type: [5, 5],
        width: 1.5,
      },
      emphasis: {
        focus: 'series'
      },
    }
  })

  

  const colorPalette = ['#075985','#FF3C42',  '#e6b600', '#5ECD43', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc'];

  return {
      toolbox: {
        right: "-5px",
        feature: {
            dataZoom: {
                yAxisIndex: 'none'
            },
          //   restore: {},
          //  saveAsImage: {}
        }
      },
      tooltip: {
          trigger: 'axis',
          confine:true,
          renderMode: 'richText',
          // axisPointer: {
          //   type: 'cross'
          // }
      },
      animation: true,
      legend: {
        icon: "roundRect",
          top: 0,
          left: 'center',
          textStyle: {
            fontSize: '14',
          },
          },
      grid: {
          show: true,
          left: '0.2%',
          right: '0.2%',
          bottom: '0%',
          top: '10%',
          containLabel: true
      },
      color: colorPalette,
      xAxis: {
        type:"category",
        axisLabel: {
          formatter: (function(value){
              // return Moment(value).format("YYYY년 MM월")
              return time.format(value, "{yyyy}년 {MM}월");
          }),
          interval: 5 ,
          rotate: 30,
          fontSize: 11
        },
        splitLine: {
          lineStyle: {
            type: 'dashed'
          }
        },
        boundaryGap: false,
        
      },
      yAxis: {
        type: 'value',
        // min: function(item){
        //     return (item.min <= 55 ? 40 : 60)
        // },
        // max: function(item){
        //   return (item.max >= 135 ? 160 : 140)
        // },
        scale: true,
        axisLabel: {
          fontSize: 12
        },
        boundaryGap: false,
      },
      series: [
        {
          name: "과거 가격",
          type: "line",
          showSymbol: false,
          data: inputDataPriceVal,
          lineStyle: {
            width: 3
          },
          markLine: {
            data: [ { name: 'currDate', xAxis: currMonth } ],
            symbol: ["none"],
            label : {
              position: "start", distance: -30, 
              formatter: "현재: {c}",
              fontSize: 12,
            },
            lineStyle: {
              color: '#9ca3af',
            },
         },
         
         emphasis: {
          focus: 'series',
        }
        },
        {
          name: "KONA 예측 가격",
          type: "line",
          showSymbol: false,
          data: baseDataPriceVal,
          lineStyle: {
            type: [5, 5],
            width: 1.5,
          },
          emphasis: {
            focus: 'series'
          },
        },
        ...simResultOption
      ]
    }
}



export const getChartOptionParamInfoModal = (data) => {
  // get input data 
  const rawData = data.map(([x,y]) => [x, lround(y,2)])  // round 2 decimal places


  //TODO: move to EchartOptions
  const contrastColor = '#475569';
  const contrastColor2 = '#d1d5db';
  const colorPalette = ['#FF3C42', '#91cc75', '#ee6666', '#fac858', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc'];
  const option = {
    animation: false,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        var date = params[0].value[0].split("-");
        var dateStr = `${date[0]}년 ${date[1]}월` 
        return `${dateStr}:   ${params[0].value[1]}`;
      },
      padding: 3,
      backgroundColor: "#4b5563",
      borderWidth: 0,
      textStyle: {
        color: "#eee",
        fontSize: 10,
      }
      // axisPointer: {
      //   type: 'cross'
      // }
  },
    grid: {
        show: false,
        left: '1%',
        right: '1%',
        bottom: '1%',
        top: '10%',
        containLabel: true,
        borderColor: contrastColor2
    },
    color: colorPalette,
    textStyle: {
      color: contrastColor
    },
    xAxis: {
      type:"category",
      axisLine: {
        lineStyle: {
          color: contrastColor
        }
      },
      axisLabel: {
          fontSize: 9,
        //  rotate: 90,
        // interval: parseInt(rawData.length/10)
      },
      scale: true,
      
      
    },
    yAxis: {
        type: 'value',
        axisLine: {
          lineStyle: {
            color: contrastColor
          }
        },
        scale: true,
        splitNumber: 2,
       //min: function(item){
       //  var diff = item.max-item.min;
       //  if(diff == 0) {diff = 1}
       // //  return (item.min - ((diff) * 0.2)).toFixed(2);
       // return item.min;
       //},
       // max: function(item){
       //     var diff = item.max-item.min;
       //     if(diff == 0) {diff = 1}
       //     return (item.max + ((diff) * 0.2)).toFixed(2);
       // },
        
        axisLabel: {
          fontSize: 9,
          formatter: function (value, index) {
            return toMBT(value);
          }
        },
        splitLine : {
          show: true,
          lineStyle: {
            color: contrastColor2
          }
        }
    },
    series: [
      {
        name: "data",
        type: "line",
        showSymbol: false,
        data: rawData,
        lineStyle: {
          width: 2.5
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 60, 66, 0.6)'
            },
            {
              offset: 1,
              color: 'rgba(255, 60, 66, 0)',
              
            }]),
        },
        silent: true
      },
    
    ]
  }

  return option;
}





export const getChartOptionDataLibCard = (data) => {
  // get input data 
  const rawData = data.map(([x,y]) => [x, lround(y,2)])  // round 2 decimal places


  //TODO: move to EchartOptions
  const contrastColor = '#475569';
  const contrastColor2 = '#d1d5db';
  const colorPalette = ['#FF3C42', '#91cc75', '#ee6666', '#fac858', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc'];
  const option = {
    animation: false,
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        var date = params[0].value[0].split("-");
        var dateStr = `${date[0]}년 ${date[1]}월` 
        return `${dateStr}:   ${params[0].value[1]}`;
      },
      padding: 3,
      backgroundColor: "#4b5563",
      borderWidth: 0,
      textStyle: {
        color: "#eee",
        fontSize: 10,
      }
      // axisPointer: {
      //   type: 'cross'
      // }
  },
  animation: true,

    grid: {
        show: false,
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '0%',
        containLabel: false,
    },
    color: colorPalette,
    textStyle: {
      color: contrastColor
    },
    xAxis: {
      show: false,
      type:"category",
      boundaryGap: false,
      scale: true,
      
      
    },
    yAxis: {
        show: false,
        type: 'value',
        axisLine: {
          lineStyle: {
            color: contrastColor
          }
        },
        scale: true,
        splitNumber: 3,
 
    },
    series: [
      {
        name: "data",
        type: "line",
        showSymbol: false,
        data: rawData,
        lineStyle: {
          width: 2.5
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 60, 66, 0.6)'
            },
            {
              offset: 1,
              color: 'rgba(255, 60, 66, 0)',
              
            }]),
        },
        silent: true
      },
    
    ]
  }

  return option;
}


export const getChartOptionDataLibDetails = (data) => {
  // get input data 
  const rawData = data.map(([x,y]) => [x, lround(y,2)])  // round 2 decimal places


  //TODO: move to EchartOptions
  const contrastColor = '#475569';
  const contrastColor2 = '#d1d5db';
  const colorPalette = ['#FF3C42', '#91cc75', '#ee6666', '#fac858', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc'];
  const option = {
    toolbox: {
      right: "-5px",
      feature: {
          dataZoom: {
              yAxisIndex: 'none'
          },
        //   restore: {},
        //  saveAsImage: {}
      }
    },
    tooltip: {
        trigger: 'axis',
        confine:true,
        renderMode: 'richText',
    },
    animation: true,

    grid: {
        show: true,
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '10%',
        containLabel: true
    },
    color: colorPalette,
    xAxis: {
      type:"category",
      boundaryGap: false,
      axisLabel: {
        fontSize: 12
      },
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      }
      
    },
    yAxis: {
      type: 'value',
      scale: true,
      axisLabel: {
        fontSize: 12
      }
    },
    series: [
      {
        type: "line",
        showSymbol: false,
        data: rawData,
        lineStyle: {
          width: 2.5
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: 'rgba(255, 60, 66, 0.6)'
            },
            {
              offset: 1,
              color: 'rgba(255, 60, 66, 0)',
              
            }]),
        },
        silent: true
      },
    
    ]
  }

  return option;
}




export const getChartOptionUpEventInfo = (data) => {
  // get input data 
  const rawData = data.map(([x,y]) => [x, lround(y,2)]);  // round 2 decimal places

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    animation: false,

    grid: {
        show: false,
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '10%',
        containLabel: true
    },
    xAxis: {
      type:"category",
      boundaryGap: false,
      axisLabel: {
        fontSize: 11
      },
      
    },
    yAxis: {
      type: 'value',
      scale: true,
      axisLabel: {
        fontSize: 11
      },
      splitNumber: 3,
    },
    visualMap: {
      show: false,
      dimension: 0,
      pieces: [
        {
          lte: 89,
          color: 'rgba(15, 23, 42, 0.3)'
        },
        {
          gt: 89,
          lte: 107,
          color: 'red'
        },
        {
          gt: 107,
          color: 'rgba(15, 23, 42, 0.3)'
        }
      ]
    },
    series: [
      {
        type: "line",
        showSymbol: false,
        data: rawData,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0.4)'
          },
          data: [
            [
              {
                name: '상승',
                xAxis: '2020-06'
              },
              {
                xAxis: '2021-12'
              }
            ]
          ]
        }
       
      },
    
    ]
  }

  return option;
}


export const getChartOptionDownEventInfo = (data) => {
  // get input data 
  const rawData = data.map(([x,y]) => [x, lround(y,2)])  // round 2 decimal places

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    animation: false,

    grid: {
        show: false,
        left: '0%',
        right: '0%',
        bottom: '0%',
        top: '10%',
        containLabel: true
    },
    xAxis: {
      type:"category",
      boundaryGap: false,
      axisLabel: {
        fontSize: 11
      },
      
    },
    yAxis: {
      type: 'value',
      scale: true,
      axisLabel: {
        fontSize: 11
      },
      splitNumber: 3,
    },
    visualMap: {
      show: false,
      dimension: 0,
      pieces: [
        {
          lte: 69,
          color: 'rgba(15, 23, 42, 0.3)'
        },
        {
          gt: 69,
          lte: 78,
          color: 'red'
        },
        {
          gt: 78,
          color: 'rgba(15, 23, 42, 0.3)'
        }
      ]
    },
    series: [
      {
        type: "line",
        showSymbol: false,
        data: rawData,
        markArea: {
          itemStyle: {
            color: 'rgba(255, 173, 177, 0.4)'
          },
          data: [
            [
              {
                name: '하락',
                xAxis: '2018-10'
              },
              {
                xAxis: '2019-07'
              }
            ],

          ]
        }
       
      },
    
    ]
  }

  return option;
}