import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../context/GlobalContext";

const ApiErrorModal = () => {
  const globalDispatch = useGlobalDispatchContext();
  const {state: globalState} = useGlobalStateContext();
  const initialFocusRef = React.useRef(null);
  const errorMessage = React.useRef();
  
  if(globalState.isApiError) errorMessage.current = globalState.apiErrorMsg;

  const closeModal = () => {
    globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {isApiError:false}});
  }

  return (
    <Transition show={globalState.isApiError} as={React.Fragment} >
      <Dialog as="div" className="relative z-[9000]" onClose={()=> {}} initialFocus={initialFocusRef}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm">
                <div className="bg-white p-6 flex flex-col space-y-4 items-center">
                    <div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                      <ExclamationTriangleIcon className="h-8 w-8 text-red-600" aria-hidden="true" />
                    </div>
                    <Dialog.Title as="h3" className="text-xl font-medium text-gray-900 focus:outline-none"  ref={initialFocusRef} tabIndex={-1}>{errorMessage.current?.title}</Dialog.Title>
                    <p className="pb-4 text-sm font-light text-gray-500 text-center">{errorMessage.current?.message}</p>
                    <button
                    type="button"
                    className="mt-4 inline-flex w-full justify-center rounded-md focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 px-4 py-2 text-white text-base ripple-primary"
                    onClick={() => closeModal()}
                    >
                      확인
                    </button>
                </div>

              

                  
              </Dialog.Panel>


            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default ApiErrorModal