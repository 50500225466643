import React from 'react'
import UserInfoContext, {ACTION as USER_INFO_ACTION} from "../../context/UserInfoProvider";
import {bearerAuth, InvalidRequestBody, ResponseError} from "../../../utils/helpers"
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";

const Unsubscribe = () => {
  const {user, handleLogout} = useGlobalStateContext();
  const {userInfoState, userInfoDispatch} = React.useContext(UserInfoContext);

  const handleUnsubscribe = async () => {
    userInfoDispatch({type: USER_INFO_ACTION.START_SUBMIT_FORM});

    const requestOptions = {
      method: 'PATCH',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        transaction_id: userInfoState.transactionID, 
        form_token: userInfoState.formToken,
      })
    };

    try {

      const response = await fetch(`${API_BASE_URL}/api/users/unsubscribe`, requestOptions);
      const userInfo = await response.json();

      if (response.status === 403) throw new ResponseError(userInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(userInfo.detail);
      
      if(response.ok){

        userInfoDispatch({type: USER_INFO_ACTION.UNSUBSCRIBE_SUCCESS});
        handleLogout();
      }
      else{
        throw new Error("Error in fetch request")
      }

    } catch (err) {
      if (err instanceof ResponseError) {
        console.error("ResponseError", err.message, err.__detail);
      } else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      } else {
        throw(err)
      }
    }
  }

  return (
    <div className="flex flex-col justify-center text-lg w-[500px] mx-auto">
      <div className="pt-20 pb-10 flex flex-col items-center border-b border-black">
        <div className="border-2 border-konared rounded-full w-16 h-16 inline-flex justify-center items-center text-5xl font-light text-konared">!</div>
        <p className="font-bold pt-4 text-xl">정말 탈퇴하시겠습니까?</p>
        <p>더 좋은 서비스를 제공하고자 노력하고 있습니다.</p>
      </div>
      <div className="pt-10 px-4">
        <ul className="list-disc pl-4 space-y-4">
          <li>등록된 카드는 회원탈퇴 후 즉시 폐기됩니다.</li>
          <li>회원탈퇴로 인해 폐기된 카드는 결제취소 및 사용이 불가능합니다.</li>
          <li>포인트, 카드캐시(정책발행금 등)가 남아 있으면 즉시 탈퇴가 불가능하오니 고객센터(ikyjeong@naver.com)로 문의 부탁드립니다.</li>
        </ul>
      </div>
      <div className="flex justify-center py-10">
        <button className="bg-konared text-white text-lg px-8 py-4 ripple-primary min-w-[140px] inline-flex justify-center" disabled={userInfoState.formLoading} onClick={handleUnsubscribe}>
          {userInfoState.formLoading && 
            <svg className="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          }
          <span>탈퇴하기</span>
        </button>
      </div>
    </div>
  )
}

export default Unsubscribe