import React from 'react'
import { Navigate, useLocation } from "react-router";
import { useGlobalStateContext } from "./context/GlobalContext";
import Loader3 from "./loaders/Loader3";
import MainContentWrapper from "./MainContentWrapper";


const SubscriptionPopulation = ({Component}) => {
  const  {user, state: {isSubscribedPopulation}} = useGlobalStateContext();

  if(isSubscribedPopulation === true){
    return <Component />;
  }
  else if(isSubscribedPopulation === false){
    return <Navigate to="/pop-sub-redirect" />;  
  }
  else{
    return (
      <MainContentWrapper className="flex flex-col">
        <Loader3 />
      </MainContentWrapper>
    )
  }
}

const SubscriptionRoutePopulation = ({Component}) => {
  const location = useLocation();
  const {user} = useGlobalStateContext();
  
  return user ? <SubscriptionPopulation Component={Component} /> : <Navigate to="/login" state={{prevPath: location.pathname}} />
}

export default SubscriptionRoutePopulation