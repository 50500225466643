
export const handleError =(status)=>{
	
    let errorMessage =''

    switch (status) {
        case 400:
            errorMessage ='Bad request.'
            break
        case 404:
            errorMessage ='Not Found.'
            break
        case 406:
            errorMessage ='Not acceptable.'
            break
        case 422:
            errorMessage ='Error in passed values.'
            break
        case 403:
            errorMessage ='Account does not have access right to this page.'
            break
        case 500:
            errorMessage ='Internal server error.'
            break
        default:
            errorMessage ='There was an error in connecting with the API'
    }

    return errorMessage
}