import React from 'react'
import { FAQAccordion } from "../Accordion"

const FAQsRealEstate = () => {
  return (
    <div className="px-28 py-20">
      <h1 className="text-4xl font-bold">자주 묻는 질문</h1>
      <div className="mt-20 border-t-2 border-black flex flex-col divide-y text-xl">
        <FAQAccordion question="이용권을 구매해야 서비스를 이용할 수 있나요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <p className="p-kr">일반 사용자는 KONA 예측가격과 데이터 등을 확인할 수 있습니다. 나의 예측을 이용하기 위해서는 이용권을 결제해야 합니다.</p>
          </div>
        </FAQAccordion>
        <FAQAccordion question="KONA 예측과 나의 예측은 어떻게 다르나요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <p className="p-kr">KONA 예측은 자체 개발한 자동예측 모듈을 사용하여 예측한 가격입니다. 나의 예측은 모델이 설정한 변수 값을 사용하지 않고, 사용자의 의도대로 부동산 가격에 영향을 주는 다양한 경제 변수 미래 값을 변경하여 부동산 가격을 예측합니다. </p>
          </div>
        </FAQAccordion>
        <FAQAccordion question="예측에 사용된 기법은 무엇인가요?">
          <div className="text-konagray flex space-x-2">
            <span>A.</span>
            <p className="p-kr">본 서비스는 한국부동산원에서 제공하는 매매가격지수와 이에 영향을 주는 경제변수와의 관계를 분석하여 부동산 가격을 예측하는 서비스로서, 각종 사회경제 분야에서 미래예측 방법으로 활용되고 있는 System Dynamics 분석방법론을 적용하여 개발되었습니다.</p>
          </div>
        </FAQAccordion> 
      </div>
    </div>


  )
}

export default FAQsRealEstate