import React from 'react'

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { NavLink } from "react-router-dom";
import MainContentWrapper from "../components/MainContentWrapper";
import { API_BASE_URL } from "../utils/constants";
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import MiniLoader from "../components/loaders/MiniLoader";
import { InvalidRequestBody, ResponseError } from "../utils/helpers";



const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('적합한 이메일 형식이 아닙니다')
    .required('이메일 주소를 입력해주세요'),
});

const FindPassword = () => {
  const [isSending, setSending] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);

  const form = useForm(
  {
    defaultValues: {
      email: "",
    },
    mode:"onSubmit", 
    resolver: yupResolver(validationSchema)
  });

  const onSubmit = async data => {
    setSending(true);
    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json"},
      body: JSON.stringify({email: data.email, source_url: document.location.origin})
    };

    try {
      const response = await fetch(`${API_BASE_URL}/api/auth/find-pass`, requestOptions);
      const resJson = await response.json();

      if ([400,403].includes(response.status)) throw new ResponseError(resJson.detail);
      if (response.status === 422) throw new InvalidRequestBody(resJson.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")

      if(response.ok){
        setSubmitSuccess(true);
      }
      

    } catch (err) {
      if (err instanceof ResponseError) {
        
        form.setError('email', { type: 'custom', message: err.message }, { shouldFocus: true });
      }
      else{
        alert(err.message)
      }
    }
    setSending(false);
  } 



  const ErrorMessage = errors => {
    const message = Object.keys(errors).length > 0 ? errors.email ? errors.email.message : errors.password.message : null;
    // const message = errors ? "true" : "false";
    return (
      <div>{message}</div>
    )
  }

  return (
    <MainContentWrapper> 
      <div className="min-h-[calc(100vh-200px)] py-20 px-48">
        <div className="border flex flex-col items-center w-full max-w-lg mx-auto p-8 rounded-3xl">
          <img className="h-24" src={require(`../assets/images/shield_red.svg`).default} alt="" />
          <h2 className="text-3xl font-semibold py-4">비밀번호를 분실하셨나요?</h2>
          <p className="text-base">가입했던 이메일 주소를 입력하여, 비밀번호 초기화 메일을 발송하세요.</p>
          {!submitSuccess
            ? <div className="flex flex-col pt-8 pb-4 w-full min-h-[200px]">
                <div className="flex justify-center pb-4 -mt-4 text-konared">{isSending ? <MiniLoader className="h-5 w-5" /> : <ErrorMessage {...form.formState.errors} />}</div>
                <form className="flex flex-col space-y-8" onSubmit={form.handleSubmit(onSubmit)}>
                  <Controller
                      render={({field}) => {
                        return(
                          <input 
                            {...field}
                            placeholder="이메일주소"
                            className={`w-full py-4 px-8 text-xl border rounded-xl outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500`}
                          />
                        )
                      }}
                      name="email"
                      control={form.control}
                    />
                  <button type="submit" className="w-full py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary" disabled={isSending}>비밀번호 초기화 메일 발송</button>
                </form>
              </div>

            : <div className="flex flex-col items-center justify-center py-4 w-full min-h-[200px] text-lg text-konasuccess">
                <CheckCircleIcon className="h-8" />
                <p>이메일을 보냈습니다.</p>
                <p>비밀번호를 재설정하려면 받은편지함을 확인하세요.</p>
              </div>
          }
          <div className="w-full text-center"><NavLink onMouseDown={e => e.preventDefault()} to="/login" className="hover:underline underline-offset-[6px] font-semibold text-base">돌아가기</NavLink></div>
        </div>

        
      </div>
    </MainContentWrapper>
  )
}

export default FindPassword