import React from 'react'
import { NavLink } from "react-router-dom";
import PaymentContext, {PAYMENT_PAGES} from "../context/PaymentProvider";
import DotLoader from "../loaders/DotLoader";
import BankRegistration from "./BankRegistration";
import KonaOApiRegister from "./KonaOApiRegister";
import KonaOApiTerms from "./KonaOApiTerms";
import PaymentDetails from "./PaymentDetails";
import ProductSelect from "./ProductSelect";

const PaymentContent = () => {
  const {paymentState} = React.useContext(PaymentContext);

  return (
    <div className="w-full max-w-[800px] min-h-[calc(100vh-200px)]">
        {(() => {
          if(paymentState.userInfo === null){
            return (
              <div className="flex h-full"><DotLoader /></div>
            )
          }
          else if(paymentState.userInfo !== null && paymentState.paymentSuccess && paymentState.paymentInfo !== null){
            const paidProduct = paymentState.productList[paymentState.paymentInfo.product_id];
            const transactionId = paymentState.paymentInfo.subscription_history[0].transaction_id || null;

            return (
              <div className="flex flex-col items-center pb-20 pt-10">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                  <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                      <stop offset="0%" stopColor="#4ade80" stopOpacity="100%" />
                      <stop offset="100%" stopColor="#3b82f6" stopOpacity="100%" />
                  </linearGradient>
                  <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                </svg>



                <div className="font-bold text-2xl">Payment Successful</div>
                <div className="mt-4 text-base">You have an active subscription for the selected service.</div>
                <div className="text-base">Please cancel your current subscription to purchase a different subsciption type.</div>
                <div className="bg-konagray/10 w-2/3 max-w-[500px] flex flex-col mt-12 py-4 px-8 rounded-xl space-y-2">
                  <div className="flex text-base items-center"><div className="flex-1">Transaction ID</div><div className="text-konasub/80 text-xs font-semibold">{transactionId}</div></div>
                  <div className="flex text-base items-center"><div className="flex-1">Subscription</div><div className="font-bold">{paidProduct.name}</div></div>
                  <div className="flex text-base items-center"><div className="flex-1">Price</div><div className="font-bold">{paidProduct.price} {paidProduct.isRecurring && "/ " + paidProduct.term[0]}</div></div>
                  <div className="flex text-base items-center"><div className="flex-1">Next Billing Date</div><div className="font-bold">{paymentState.paymentInfo.next_billing_date}</div></div>
                </div>
                <NavLink to="/" state={{scrollTo: "payment"}}  className="mt-10 bg-konared text-white inline-flex leading-none rounded px-10 py-4 ripple-primary">확인</NavLink>
              </div>
            )
          }
          else{

            const rightSide = () => {
              switch(paymentState.frameView) {
                case PAYMENT_PAGES.PAYMENT_DETAILS:
                  return <PaymentDetails />;
                case PAYMENT_PAGES.BANK_REGISTRATION:
                  return <BankRegistration />;
                case PAYMENT_PAGES.OAPI_TERMS:
                  return <KonaOApiTerms />;
                case PAYMENT_PAGES.OAPI_REGISTER:
                  return <KonaOApiRegister />;
                default:
                  console.error("ERROR: cannot determine frameView");
              }
            }

            return (
              <div className="py-10">
                <div className="col-span-3 text-2xl font-bold text-konared px-4 border-b pb-4">Payment</div>
                {/* <ProductSelect />     */}
                <div className="col-span-2 py-4 text-lg">{rightSide()}</div>
              </div>
            )

            
            
          }
        })()}
    </div>
  )
}

export default PaymentContent