import React from 'react'
import SearchkakaoMap from "../../components/realestate/SearchKakaoMap";
import MainContentWrapper from "../../components/MainContentWrapper";
import Loader3 from "../../components/loaders/Loader3";
import SearchBar from "../../components/realestate/SearchBar";
import { isEmpty } from "lodash";
import RealEstateMySimContent from "./RealEstateMySimContent";
import { useReStateContext } from "../../components/context/RealEstateContext";
import ReMysimContextProvider from "../../components/context/ReMysimContext";


const RealEstateMySimBase = () => {
  const reState = useReStateContext();
  
  return (
    <ReMysimContextProvider>
      <MainContentWrapper className="bg-konabg flex flex-col">
        {(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds) || !(reState.model.allIds.includes(reState.currentModelId) && reState.model.byId[reState.currentModelId]?.data.allIds.includes(reState.currentLocCode)))
          ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
          : <div>
              <div className="py-10 px-28">
                <div className="relative">
                  <div className="w-full absolute top-0 left-0 z-10 px-7 py-3">
                    <SearchBar />
                  </div>
                  <div className="rounded-xl overflow-hidden shadow-md">
                    <SearchkakaoMap location={reState.address.byId[reState.currentAddressId]} height="300px" zoomLvl={3} />
                  </div>
                </div>
              </div>
              <RealEstateMySimContent />
            </div>
        }
      </MainContentWrapper>
    </ReMysimContextProvider>
  )
}

export default RealEstateMySimBase