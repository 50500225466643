import { round } from 'lodash';
import React, { useReducer, useEffect, useState } from 'react'
import Echarts from '../../components/charts/Echarts';
import { getChartOptionMarriage, getTimelineOptionForLocMarriage } from '../../components/charts/PopulationEchartOptions';
import MainContentWrapper from '../../components/MainContentWrapper'
import { CardHeader, CardFooter, CardWrapper, CardContent } from '../../components/population/CardWrapper'
import { API_BASE_URL } from "../../utils/constants";
import APIUnavailableModal from '../../components/modals/APIUnavailableModal';
import LoadingModal from '../../components/modals/LoadingModal'
import { handleError } from '../../utils/pop-handle-response'
import Loader3 from "../../components/loaders/Loader3";

const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
	SET_INITIAL_TOTAL_MARRIAGE: 'set-initial-total',
	SET_TOTAL_MARRIAGE_RATE: 'set-total-marriage-rate',
	SET_TOTAL_MARRIAGE_RATE_MAIN: 'set-total-marriage-rate-age',
	SET_TOTAL_MARRIAGE_RATE_CURYEAR: 'set-total-marriage-rate-year',
	SET_POPULATION_CURYEAR: 'set-population-year',
	SET_MARRIAGE_CURYEAR: 'set-marriage-year',
	SET_INITIAL_MARRIAGE: 'set-initial-marriage',
	SET_INITIAL_POPULATION: 'set-initial-population',
}

const initialState = {
	main_data: null,
	total_marriage_rate_year: null,
	total_marriage_rate_ageyear: null,
	total_marriage_year: null,
	total_marriage_age: null,
	total_population_year: null,
	total_population_age: null,
	total_marriage_rate_curyear: 2021,
	total_marriage_curyear: 2021,
	total_population_curyear: 2021,
	yearlist: null,
	isLoaded: false,
}


const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_MAIN_DATA:
			return { 
			  	...state, 
			  	main_data: payload.main_data,
			 };
		case ACTION.SET_INITIAL_TOTAL_MARRIAGE:
			return {
				...state,
				total_marriage_rate_year: payload.total_marriage_rate_year,
				total_marriage_rate_ageyear: payload.total_marriage_rate_ageyear,
				yearlist: payload.yearlist
			};
		case ACTION.SET_INITIAL_MARRIAGE:
			return {
				...state,
				total_marriage_year: payload.total_marriage_year,
				total_marriage_age: payload.total_marriage_age,
			}
		case ACTION.SET_INITIAL_POPULATION:
			return {
				...state,
				total_population_year: payload.total_population_year,
				total_population_age: payload.total_population_age,
				isLoaded: true
			}
		case ACTION.SET_TOTAL_MARRIAGE_RATE_CURYEAR:
			return {
				...state,
				total_marriage_rate_ageyear: payload.total_marriage_rate_ageyear,
				total_marriage_rate_curyear: payload.total_marriage_rate_curyear,
			};
		case ACTION.SET_MARRIAGE_CURYEAR:
			return {
				...state,
				total_marriage_age: payload.total_marriage_age,
				total_marriage_curyear: payload.total_marriage_curyear,
			};
		case ACTION.SET_POPULATION_CURYEAR: {
			return {
				...state,
				total_population_age: payload.total_population_age,
				total_population_curyear: payload.total_population_curyear,
			};
		}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

function PopMarriage2() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isOpen, setIsOpen] = useState(false)

	useEffect(() => {

		const loadDefault = async () => {
					

			try {
				
				let query = `?page_size=all&name_en__sw=first marriage`
				let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
				method: "GET",
				headers: { "Content-Type": "application/json" },
				});
				
				if (!response.ok) throw new Error(handleError(response.status))

				let parseRes = await response.json()

				if(!parseRes.data || parseRes.data.length<=0) throw new Error("No available marriage data.")
	
				//prep data for easy retrieval
				let set_name = []
				for (let item of parseRes.data) {
					set_name = [...set_name, item.name_kr.split('-')[0].trim()]
				}
				set_name = [...new Set(set_name)]
	
				//holder of main data sets
				let dataSet = {}
				if (set_name.length > 0) {
					for (let name of set_name) {
						let val_pair = []
						for (let item of parseRes.data) {
							if (item.name_kr.includes(name)) {
								val_pair.push([((item.name_kr.split('-')).slice(1)).toString().trim().replace(",", '-'), item.value])
							}
						}
						dataSet[name] = val_pair
					}
				}
	
				dispatch({
					type: ACTION.SET_MAIN_DATA,
					payload: {
						main_data: dataSet
					}})
				
	
				let total_ds = extractData('연령별 남성 초혼 비율', '연령별 여성 초혼 비율', dataSet)
				dispatch({
					type: ACTION.SET_INITIAL_TOTAL_MARRIAGE,
					payload: {
						total_marriage_rate_year: getChartOptionMarriage(total_ds.dataSeriesRateByYear),
						// total_marriage_rate_ageyear: getChartOptionMarriageBar(ds.year),
						total_marriage_rate_ageyear: getTimelineOptionForLocMarriage(total_ds.year_list, total_ds.bar, total_ds.ageSeries, total_ds.age_list),
						yearlist: total_ds.year_list
					}
				})
				let marriage = extractData('연령별 남성 초혼수', '연령별 여성 초혼수', dataSet)
				dispatch({
					type: ACTION.SET_INITIAL_MARRIAGE,
					payload: {
						total_marriage_year: getChartOptionMarriage(marriage.dataSeriesRateByYear),
						total_marriage_age: getTimelineOptionForLocMarriage(marriage.year_list, marriage.bar, marriage.ageSeries, marriage.age_list),
					}
				})
	
				let pop = extractData('연령별 남성 인구', '연령별 여성 인구', dataSet)
				
				dispatch({
					type: ACTION.SET_INITIAL_POPULATION,
					payload: {
						total_population_year: getChartOptionMarriage(pop.dataSeriesRateByYear),
						total_population_age: getTimelineOptionForLocMarriage(pop.year_list, pop.bar, pop.ageSeries, pop.age_list.sort((a, b) => (a > b) ? 1 : -1)),
					}
				})
				
			} catch (error) {
				setIsOpen(true)
				console.error(error.message)
			}
		}
		loadDefault();
	}, [])

	const extractData = ( male, female, dataSet) =>{

		let year_list = dataSet[male][0][1].map(p=>{ return p[0]})
		let age_list = dataSet[male].map(x => x[0] == 0 ? 0 : (parseInt(x[0]) || x))

		let marriageBar =[]
		let male_marriages = []
		let female_marriages = []

		year_list.forEach( y =>{ 

			male_marriages.push([
				y, 
				male.includes('비율')? round(dataSet[male].map(item=>{ return item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1] }).reduce((prev, current) => prev + current, 0)*100,2) : round(dataSet[male].map(item=>{ return item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1] }).reduce((prev, current) => prev + current, 0),2)
			])
			female_marriages.push([
				y,
				female.includes('비율')? round(dataSet[female].map(item=>{ return item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1] }).reduce((prev, current) => prev + current, 0)*100,2): round(dataSet[female].map(item=>{ return item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1] }).reduce((prev, current) => prev + current, 0),2)
			])
			
		})

		
		year_list.forEach( y => {

			marriageBar.push({
				title: { text: y + '년도' },
				series: [
					{
						data: dataSet[male].filter(p => isNaN(p[0]) === false ).map(item => {
							return {
								name:  parseInt(item[0]) || 0,
								value: male.includes('비율')? round(item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1]*100,2) : round(item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1],2)
								
							}
						}).sort((a,b) => a.name - b.name)
					},
					{
						data: dataSet[female].filter(p => isNaN(p[0]) === false ).map(item => {
							return {
								name:  parseInt(item[0]) || 0,
								value: female.includes('비율')? round(item[1].filter(i => parseInt(i[0]) === parseInt(y))[0][1]*100,2) : round(item[1].filter(i =>parseInt(i[0]) === parseInt(y))[0][1],2)
							}
						}).sort((a,b) => a.name - b.name)
					}
				]
			}) 
		})
		
	
		let dataSeriesRateByYear = [{
				name: male.split(" ")[1],
				type: "line",
				showSymbol: true,
				data: male_marriages
			},{
				name: female.split(" ")[1],
				type: "line",
				showSymbol: true,
				data: female_marriages
			}

		]
		let ageSeries =[
			{ name: '남성', type: 'bar', stack: 'male' },
			{ name: '여성', type: 'bar', stack: 'female' },
		]
		return {
			bar : marriageBar,
			ageSeries: ageSeries,
			year_list : year_list,
			age_list: age_list,
			dataSeriesRateByYear: dataSeriesRateByYear
		}
	}

	return (
		<MainContentWrapper className="bg-konabg relative flex flex-col">
			<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />

			{!state.isLoaded ? (
				<div className="flex-1 flex justify-center items-center"><Loader3 /></div>
			) : (

				<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6">

					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1 text-left ">합계 초혼율</div>
								<div className='flex text-right text-lg'>단위: %</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 ">
							{
								state.total_marriage_rate_year ?
									<Echarts options={state.total_marriage_rate_year} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row text-lg'>
								<div className="flex-1  text-left">연령별 연간 초혼 비율</div>
								<div className='flex text-right text-lg'>단위: %</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 flex flex-col" >
							{
								state.total_marriage_rate_ageyear ?
									<Echarts options={state.total_marriage_rate_ageyear} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>
					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1 text-left">초혼수</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5">
							{
								state.total_marriage_year ?
									<Echarts options={state.total_marriage_year} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row  items-center'>
								<div className="flex-1">연령별 초혼수</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5  flex flex-col" >
							<div className='flex-1 py-2'>
								{
									state.total_marriage_age ?
										<Echarts options={state.total_marriage_age} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}
							</div>

						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>
					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">남.여 인구</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5">
							{
								state.total_population_year ?
									<Echarts options={state.total_population_year} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>

					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">연령별 인구</div>
								<div className='flex text-right text-lg'>단위: 명</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 flex flex-col" >
							<div className='flex-1 py-2'>
								{
									state.total_population_age ?
										<Echarts options={state.total_population_age} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}
							</div>
						</CardContent>
						<CardFooter>
							<div>데이터 출처: 국가통계포털</div>
						</CardFooter>
					</CardWrapper>
				</div>
			)

			}

		</MainContentWrapper>
	)
}

export default PopMarriage2