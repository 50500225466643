//Contains helper functions


export const bearerAuth = (user) => {
  return `Bearer ${user.access_token}`
}

export const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);


export class AppError extends Error {
  constructor(message) {
      super(message); // (1)
      this.name = "AppError"; // (2)
  }
}

export class ApiError extends Error {
  constructor(message) {
      super(message); // (1)
      this.name = "ApiError"; // (2)
  }
}

export class ResponseError extends Error {
  constructor(message) {
      super(message); // (1)
      this.name = "ResponseError"; // (2)
  }
}

export class InvalidRequestBody extends Error {
  constructor(detail) {
      super("Invalid Request Body"); // (1)
      this.name = "InvalidRequestBody"; // (2)
      this.__detail = detail;
  }
}


export const isEmpty = (value) => {
  if (value instanceof Map || value instanceof Set) return value.size === 0;

  return  value === undefined ||
          value === null ||
          (typeof value === "object" && Object.keys(value).length === 0) ||
          (typeof value === "string" && value.trim().length === 0)
}


function createRound(methodName) {
  const func = Math[methodName]
  return (number, precision) => {
    precision = precision == null ? 0 : (precision >= 0 ? Math.min(precision, 292) : Math.max(precision, -292))
    if (precision) {
      // Shift with exponential notation to avoid floating-point issues.
      // See [MDN](https://mdn.io/round#Examples) for more details.
      let pair = `${number}e`.split('e')
      const value = func(`${pair[0]}e${+pair[1] + precision}`)

      pair = `${value}e`.split('e')
      return +`${pair[0]}e${+pair[1] - precision}`
    }
    return func(number)
  }
}

export const customRound = createRound("round");

export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      return ({ children }) => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        )
      }
    },
    ({ children }) => <>{children}</>
  )
}




/**
 * Helpers for charting 
 * 
 */

/**
 * Calculate and update values for tick spacing and nice
 * minimum and maximum data points on the axis.
 */
export function calculateTicks(maxTicks, minPoint, maxPoint) {
  let range = niceNum(maxPoint - minPoint, false);
  let tickSpacing = niceNum(range / (maxTicks - 1), true);
  let niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
  let niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
  let tickCount = range / tickSpacing;
  return [tickSpacing, niceMin, niceMax];
}

/**
* Returns a "nice" number approximately equal to range Rounds
* the number if round = true Takes the ceiling if round = false.
*
* @param range the data range
* @param round whether to round the result
* @return a "nice" number to be used for the data range
*/
function niceNum(range, round) {
  let exponent;
  /** exponent of range */
  let fraction;
  /** fractional part of range */
  let niceFraction;
  /** nice, rounded fraction */

  exponent = Math.floor(Math.log10(range));
  fraction = range / Math.pow(10, exponent);

  if (round) {
      if (fraction < 1.5)
          niceFraction = 1;
      else if (fraction < 3)
          niceFraction = 2;
      else if (fraction < 7)
          niceFraction = 5;
      else
          niceFraction = 10;
  } else {
      if (fraction <= 1)
          niceFraction = 1;
      else if (fraction <= 2)
          niceFraction = 2;
      else if (fraction <= 5)
          niceFraction = 5;
      else
          niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}