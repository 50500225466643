import React from 'react'
import { NavLink, useLocation } from "react-router-dom";
import MainContentWrapper from "../../components/MainContentWrapper";
import FAQsRealEstate from "../../components/realestate/FAQsRealEstate";
import { ACCOUNT_ROLE, ACCOUNT_TYPE, API_BASE_URL, SERVICES } from "../../utils/constants";
import { BuildingOffice2Icon, UserCircleIcon} from '@heroicons/react/24/outline'
import { useGlobalStateContext } from "../../components/context/GlobalContext";

const RealEstateAbout = () => {
  const paymentDivRef = React.useRef(null);
  const {user} = useGlobalStateContext();
  const location = useLocation();
  const [productList, setProductList] = React.useState();

  React.useEffect(() => {
    if(location?.state?.scrollTo === "payment") {
      paymentDivRef.current.scrollIntoView()    
    }
  },[location]);


  React.useEffect(() => {

    const loadProduct = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {"Content-Type" : "application/json"} 
        };
        const query = `?page_size=all&is_active=true&service_id=${SERVICES.realestate.id}`
        const response = await fetch(`${API_BASE_URL}/api/product${query}`,requestOptions);
        const products = await response.json();
        
        if(products.data.length > 0){
          const productList = Object.fromEntries(products.data.map(item => [item.id, item]));
          setProductList(productList);
        }
        
  
      } catch (err) {
        console.error(err.message)
      }
    }

    //load products
    loadProduct();

  },[]);
  return (
    <MainContentWrapper>

      <div className="grid grid-cols-3 py-20 px-28">
        <div className="flex flex-col items-center justify-center px-5">
          <div className="text-5xl font-bold">
            <p>빅데이터를 활용한</p>
            <p className="mt-5"><span className="underline-2">최적의 결과</span> 도출</p>
          </div>
        </div>
        <div className="col-span-2 grid grid-cols-1 gap-10 px-5">
          <div className="text-2xl font-bold bg-konabg rounded-xl p-6 text-center shadow-lg">전국 공동주택(아파트, 연립, 다세대 등) 및 단독주택 <span className="text-konared">가격을 예측</span>합니다</div>
          <div className="text-2xl font-bold bg-konabg rounded-xl p-6 text-center shadow-lg">지역별 매매가격예측지수를 토대로 <span className="text-konared">개별 주택에 대한 정보</span>를 제공합니다</div>
          <div className="text-2xl font-bold bg-konabg rounded-xl p-6 text-center shadow-lg">시스템다이내믹스 모델에 활용되는 데이터는 <span className="text-konared">매월 중순 업데이트</span> 됩니다</div>
          <div className="text-2xl font-bold bg-konabg rounded-xl p-6 text-center shadow-lg">예측정보는 서비스 이용일(분석 기준일)로부터 <span className="text-konared">2년치 결과</span>를 제공합니다</div>
        </div>

      </div>
 
      <div className="px-28 py-20 scroll-mt-[100px]" ref={paymentDivRef}>
        <div className="flex flex-col justify-center items-center space-y-4">
          <span className="font-bold text-4xl">부동산 예측 서비스 이용 요금 안내</span>
          {(user && user.user_info.account_type === ACCOUNT_TYPE.PERSONAL) &&
            <div className="flex flex-col items-center text-base">
              <p className="p-kr">전문가용 시나리오 분석을 원하는 경우 아래의 이용요금을 결제한 후 이용이 가능합니다.</p>
              <p className="p-kr mt-4">결제 또는 이용요금 관련 문의(세금계산서 발행 등)는 다음 메일로 연락주시기 바랍니다</p>
              <p>(문의처 : 코나아이㈜ 시스템다이내믹스팀 <span className="font-semibold">ikyjeong@naver.com</span>)</p>
            </div>
          }
        </div>

        <div className="mt-11 flex flex-col space-y-8 mx-auto items-center">
          {
            (() => {
              if(productList && user?.user_info.role === ACCOUNT_ROLE.REGULAR) {
                const prodIdPersonalMonthly = 1;
                const prodIdPersonalYearly = 2;
                const prodIdCorporateMonthly = 3;
                const prodIdCorporateYearly = 4;
                return (
                  <React.Fragment>
                    <div className="flex w-full space-x-8 justify-center">
                      {(!user || (user && user.user_info.account_type === ACCOUNT_TYPE.PERSONAL)) &&
                      <>
                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-red-50/50 border border-konared">
                          <UserCircleIcon className="h-14 text-konared" />
                          <div className="mt-4 text-2xl font-semibold">개인 회원 월별 결제</div>
                          <div className="mt-10 text-3xl font-bold text-konared">₩{productList[prodIdPersonalMonthly].price.toLocaleString()}</div>
                          <div>/month</div>
                          {user
                            ? <NavLink to="/payment" state={{pid: prodIdPersonalMonthly, prevPath: "/re-about", scrollTo:"payment"}} className="mt-8 ripple-primary text-white text-lg text-center font-semibold rounded-lg w-full py-3">결제하기</NavLink>
                            : <NavLink to="/register" state={{gotoUrl: "/payment", prevPath: "/re-about", scrollTo:"payment", payload: {pid: prodIdPersonalMonthly}, accountType: ACCOUNT_TYPE.PERSONAL}} className="mt-8 ripple-primary text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>
                          }
                        </div>

                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-red-50/50 border border-konared">
                          <UserCircleIcon className="h-14 text-konared" />
                          <div className="mt-4 text-2xl font-semibold">개인 회원 연간 결제</div>
                          <div className="mt-10 text-3xl font-bold text-konared">₩{productList[prodIdPersonalYearly].price.toLocaleString()}</div>
                          <div>/year</div>
                          {user
                            ? <NavLink to="/payment" state={{pid: prodIdPersonalYearly, prevPath: "/re-about", scrollTo:"payment"}} className="mt-8 ripple-primary text-white text-lg text-center font-semibold rounded-lg w-full py-3">결제하기</NavLink>
                            : <NavLink to="/register" state={{gotoUrl: "/payment", prevPath: "/re-about", scrollTo:"payment", payload: {pid: prodIdPersonalYearly}, accountType: ACCOUNT_TYPE.PERSONAL}} className="mt-8 ripple-primary text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>
                          }
                        </div>
                      </>
                      }
                      {(!user || (user && user.user_info.account_type === ACCOUNT_TYPE.CORPORATE)) &&
                      <>
                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-indigo-50/50 border border-indigo-600">
                          <BuildingOffice2Icon className="h-14 text-indigo-600" />
                          <div className="mt-4 text-2xl font-semibold">기업 회원 월별 결제</div>
                          <div className="mt-10 text-3xl font-bold text-indigo-600">₩{productList[prodIdCorporateMonthly].price.toLocaleString()}</div>
                          <div>/month</div>
                          {!user && <NavLink to="/register" state={{gotoUrl: "/payment", prevPath: "/re-about", scrollTo:"payment", payload: {pid: prodIdCorporateMonthly}, accountType: ACCOUNT_TYPE.CORPORATE}} className="mt-8 ripple-blue text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>}
                        </div>

                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-indigo-50/50 border border-indigo-600">
                          <BuildingOffice2Icon className="h-14 text-indigo-600" />
                          <div className="mt-4 text-2xl font-semibold">기업 회원 연간 결제</div>
                          <div className="mt-10 text-3xl font-bold text-indigo-600">₩{productList[prodIdCorporateYearly].price.toLocaleString()}</div>
                          <div>/year</div>
                          {!user && <NavLink to="/register" state={{gotoUrl: "/payment", prevPath: "/re-about", scrollTo:"payment", payload: {pid: prodIdCorporateYearly}, accountType: ACCOUNT_TYPE.CORPORATE}} className="mt-8 ripple-blue text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>}
                        </div>
                      </>
                      }
                    </div>
                    {(user && user.user_info.account_type === ACCOUNT_TYPE.CORPORATE) &&
                      <div className="flex-1 flex flex-col items-center text-lg">
                        <p>기업회원의 서비스 계약기간은 1년입니다.</p>
                        <p>견적서 요청 및 이용요금의 결제는 당사 이메일(<span className="font-bold text-indigo-600">ikyjeong@naver.com</span>)로 문의 바랍니다.</p>
                      </div>
                    }
                  </React.Fragment>
                )
              }
              else {
                return (
                  <>
                    <span className="text-xl text-konared mt-4">오류가 발생했습니다. 관리자에게 문의해주세요: ikyjeong@naver.com</span>
                  </>
                )
              }
            })()
          }

          

         

        </div>

      </div>


      {/* faq */}
      <FAQsRealEstate />

      

    </MainContentWrapper>
  )
}

export default RealEstateAbout