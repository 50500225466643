import React from 'react'
import SimForm from "../../components/realestate/SimForm";
import { useLocation, useNavigate } from "react-router-dom";
import Echarts from "../../components/charts/Echarts";
import { getChartOptionsKonaSim, getChartOptionsMySim } from "../../components/charts/EchartOptions";
import TableMySim from "../../components/realestate/TableMySim";
import {HiOutlineTrash} from 'react-icons/hi'
import EvaluationMySim from "../../components/realestate/EvaluationMySim";
import { MapPinIcon } from '@heroicons/react/24/solid'
import EvaluationKonaSim from "../../components/realestate/EvaluationKonaSim";
import { ApiError, AppError, bearerAuth, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { API_BASE_URL } from "../../utils/constants";
import { DocumentTextIcon} from '@heroicons/react/24/solid'
import LoadingModalWithText from "../../components/modals/LoadingModalWithText";
import TableKonaSim from "../../components/realestate/TableKonaSim";
import { downloadRealEstateMySimReportApi } from "../../utils/api/realestateApi";
import { isEmpty } from "lodash";
import { getAddressTxt, getKonaSimResults, useReStateContext } from "../../components/context/RealEstateContext";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { REMYSIM_ACTION, useReMysimDispatchContext, useReMysimStateContext } from "../../components/context/ReMysimContext";



const getSimulationInfo = async (simId, user) => {
  try {
    
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const response = await fetch(`${API_BASE_URL}/api/simulations/${simId}`,requestOptions);
    const simInfo = await response.json();
    
    if(!response.ok){
      throw new Error(`Failed to fetch simulation`);
    }
    return simInfo;
  
  } catch (err) {
    console.error(err.message)
  }
}

const getSimulationData = async (simId, user) => {
  try {
    
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const response = await fetch(`${API_BASE_URL}/api/data-out?simulation_id=${simId}`,requestOptions);
    const simData = await response.json();
    
    if(!response.ok){
      throw new Error(`Failed to fetch simulation data`);
    }
    return simData;
  
  } catch (err) {
    console.error(err.message)
  }
}



const RealEstateMySimContent = () => {
  const {user} = useGlobalStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const reState = useReStateContext();
  const reMysimState = useReMysimStateContext();
  const reMysimDispatch = useReMysimDispatchContext();
  const chartRef= React.useRef({}); // will be used for scrollintoview after simulation
  const [isOpen, setIsOpen] = React.useState(false);
  const lc = useLocation();
  const userSelectedSimId = lc?.state?.sim_id || null;

  const navigate = useNavigate();


  const konasimResult = React.useMemo(() => {
    let results = null;
    if(reState.currentAddressId){
      const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];
      results = getKonaSimResults(modelData.variable, modelData.baseData, reState.address.byId[reState.currentAddressId]);
    }

    return results;
  }, [reState.currentAddressId]);



  const downloadReport = async () => {
    setIsOpen(true)
    //get list of simulation result ids
    

    try{
      const simIds = Object.entries(reMysimState.simResultsGroup).map(([key, {simResults}]) => Object.entries(simResults).map(([key, {simInfo}]) => simInfo.id)).flat();

      await downloadRealEstateMySimReportApi(simIds, user)
    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }finally{
      setIsOpen(false)
    }

  }
  return (
    <div>
      <LoadingModalWithText isOpen={isOpen}>
        <DocumentTextIcon  className="animate-bounce h-14 text-white" />
        <div><span className="text-white text-xl font-semibold">보고서를 생성중입니다...</span></div>
      </LoadingModalWithText>
      
      <div className="bg-gray-400 bg-opacity-20  px-28 p-5 relative">
        <SimForm chartRef={chartRef} konasimResult={konasimResult}  />
      </div>


      {/* chart area */}
      <div className="px-28 pt-10">
        <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0">
          <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
          <div className="bg-white relative z-20">
            <div className=" px-[32px] py-9">
              <div className="text-3xl leading-none">부동산 가격 예측 결과</div>
              <div className="">
                {(!isEmpty(reMysimState.simResultsGroup))
                  ? <div className="pt-10 flex flex-col space-y-10">
                      {Object.entries(reMysimState.simResultsGroup).map(([key,val],index) => (
                        <div className="flex flex-col scroll-mt-[200px]" key={key} ref={(el) => (chartRef.current[key] = el)}>
                          <div className="flex mb-5">
                            <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                            <button 
                              onClick={() => reMysimDispatch({type: REMYSIM_ACTION.REMOVE_SIM_RESULT_GROUP, payload: key})} 
                              className="text-konagray p-2 border rounded-md hover:text-konared hover:border-konared">
                                <HiOutlineTrash size={20} />
                            </button>
                          </div>
                          <div className="h-[400px]">
                            <Echarts options={getChartOptionsMySim(val)}  />
                          </div>
                        </div>
                      ))}
                    </div>
                  : <div className="h-[400px]">
                      <Echarts options={getChartOptionsKonaSim(konasimResult)}  />
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end of chart area */}

      <div className="px-28 pb-20">
        {/* result table */}
        <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0 mt-10">
          <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
          <div className="bg-white relative z-20 ">
            <div className="px-[32px] py-9">
              <div className="text-3xl leading-none">예측 결과 요약</div>
              {(!isEmpty(reMysimState.simResultsGroup))
                ? <div className="pt-10 flex flex-col space-y-10">
                    {Object.entries(reMysimState.simResultsGroup).map(([key,val],index) => (
                      <div className="flex flex-col" key={index}>
                        <div className="flex">
                          <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                          <button 
                            onClick={() => reMysimDispatch({type: REMYSIM_ACTION.REMOVE_SIM_RESULT_GROUP, payload: key})} 
                            className="text-konagray p-2 border rounded-md hover:text-konared hover:border-konared">
                              <HiOutlineTrash size={20} />
                          </button>
                        </div>
                        <TableMySim usersimResult={val} />
                      </div>
                    ))}
                  </div>
                : (!isEmpty(konasimResult)) && <TableKonaSim konasimResult={konasimResult} /> 
              }
            </div>
          </div>
        </div>
        {/* end of result table */}

        <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0  mt-10">
          <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
          <div className="bg-white relative z-20 ">
            <div className="px-[32px] py-9">
              <div className="text-3xl leading-none">KONA 분석 평가</div>
              
              <div className="">
                {(!isEmpty(reMysimState.simResultsGroup))
                  ?  <div className="pt-10 flex flex-col space-y-10">
                      {Object.entries(reMysimState.simResultsGroup).map(([key,val],index) => (
                        <div className="flex flex-col" key={index}>
                          <div className="flex mb-5">
                            <div className="flex-1 flex items-center space-x-2 font-bold text-xl text-gray-500"><MapPinIcon className="h-6 w-6" /><span>{getAddressTxt(val.address)}</span></div>
                            <button 
                              onClick={() => reMysimDispatch({type: REMYSIM_ACTION.REMOVE_SIM_RESULT_GROUP, payload: key})} 
                              className="text-konagray p-2 border rounded-md hover:text-konared hover:border-konared">
                                <HiOutlineTrash size={20} />
                            </button>
                          </div>
                          <EvaluationMySim usersimResult={val}/>
                        </div>
                      ))}
                    </div>
                  : (!isEmpty(konasimResult)) && <EvaluationKonaSim konasimResult={konasimResult} address={reState.address.byId[reState.currentAddressId]} />
                }
              </div>
              
            </div>
          </div>
        </div>

        <div className="pt-4">* 실제 가격은 해당 물건(주택)의 실거래 가격이 아닌, 지역별 매매가격지수를 기반으로 월 단위로 산정된 가격으로 실거래가격과 차이가 발생할 수 있음</div>
        
        {(!isEmpty(reMysimState.simResultsGroup)) &&
          <div className="flex justify-center pt-20">
              <button onClick={downloadReport} className="min-w-[180px] p-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary inline-flex items-center space-x-2">
                <DocumentTextIcon className="h-10" />
                <span>Download Report</span>
              </button>
          </div>
        }
      </div>
      {/* end of results area */}
    </div>
  )
}

export default RealEstateMySimContent