import React, { useRef } from 'react'
import { NavLink } from "react-router-dom"
import { HiHome, HiChevronRight } from 'react-icons/hi'
import MainContentWrapper from "../../components/MainContentWrapper";

function PopIntroduction() {
  return (
    <MainContentWrapper className="flex flex-col bg-konabg">
      <div className="pt-10 pb-20 px-28  ">
        {/* breadcrumbs */}
        <nav className="flex pb-4" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
            <li className="inline-flex items-center">
              <NavLink to="/population" className="inline-flex items-center text-sm font-medium hover:text-konared">
                <span className="mr-2"><HiHome size={16} /></span>
                Home
              </NavLink>
            </li>
            <li>
              <div className="flex items-center">
                <span className=""><HiChevronRight size={24} /></span>
                <span className="ml-2">도움말</span>
              </div>
            </li>
            <li aria-current="page">
              <div className="flex items-center">
                <span className=""><HiChevronRight size={24} /></span>
                <span className="ml-2">시스템 소개</span>
              </div>
            </li>
          </ol>
        </nav>
        {/* end of breadcrumbs */}

        <div className="grid grid-cols-2 gap-8">
          <div className="col-span-2 rounded-xl overflow-hidden shadow-md bg-white">
            <div className="flex items-center h-12 px-7 text-2xl bg-konared text-white">
              시스템 소개
            </div>
            <div className='flex p-8 space-x-8 min-h-[400px]'>
              <div className="flex-1 flex flex-col text-justify space-y-5 text-xl p-kr">
                <p>
                  인구 예측 모델은 다음 그림과 같이 5 종류의 결정 변수와 3 가지 목적 변수로 구성되며, 그 외에 주요 변수로는 성별 연령별 인구를 중심으로 출산율, 가임여성 수, 사망률, 국제 결혼, 외국인 유학생, 체류자 등이 모델에 내생 변수로 포함되어 있습니다.
                </p>
                <p>
                  현재 모델은 정부 정책과 결정 변수 사이의 관계를 모델화하지 않고 있는데, 정책의 종류가 많고 그 계량적 관계 설정에 추가의 연구가 필요하기 때문입니다.
                </p>
                <p>
                  사회 문화적 변화는 일반 시스템다이내믹스 모델에서는 환경 변수로 취급하는 것이 보통입니다. 우리 연구팀은 인구 모델의 해결에 사회문화적 변화가 그 중심에 있다고 판단하여, 앞으로 적극적으로 연구를 진행하여 모델을 확장할 계획입니다.
                </p>
                <p>
                  또한 예상되는 부작용까지 모델에 반영하여 우리나라의 모든 사람들이 올바른 사고를 할 수 있도록 도움을 줄 계획입니다.
                </p>
              </div>
              <div className="flex-1">
                <img className="object-contain w-full h-full" src={require("../../assets/images/model.png")} alt="" />
              </div>
            </div>
          </div>
          <div className="rounded-xl overflow-hidden shadow-md bg-white ">
            <div className="flex items-center h-12 px-7 text-2xl bg-konared text-white">
              인과지도
            </div>
            <div className="flex p-8 space-x-8 min-h-[400px]">
              <img className="object-contain w-full h-full" src={require("../../assets/images/cld.png")} alt="" />
            </div>
          </div>
          <div className="rounded-xl overflow-hidden shadow-md bg-white ">
            <div className="flex items-center h-12 px-7 text-2xl bg-konared text-white">
              저량 유량도
            </div>
            <div className="flex p-8 space-x-8 min-h-[400px]">
              <img className="object-contain w-full h-full" src={require("../../assets/images/stockflow.png")} alt="" />
            </div>
          </div>
          <div className="col-span-2 shadow-md rounded-xl bg-white text-xl p-8 text-center">
            <p>
              본 페이지에 소개된 도표는 본 시스템의 이해를 돕기 위한 개념도의 일환으로 세부 모델은 추가 데이터를 반영합니다.
            </p>
            <p>
              <span className='font-semibold'> System Dynamics의 </span> 모델에 대한 더 자세한 정보는 코나아이 시스템다이내믹스팀에 문의해주세요.
            </p>
          </div>
        </div>
      </div>
    </MainContentWrapper>
  )
}

export default PopIntroduction