import React from 'react'
import { useNavigate } from 'react-router-dom'
import Moment from 'moment';
import { 
	CheckCircleIcon,  
	XCircleIcon,
	XMarkIcon,
	CheckIcon,
	ClockIcon,
	ChevronRightIcon,
	ChevronLeftIcon,
	HomeIcon,
	BuildingOffice2Icon,
	UsersIcon } from '@heroicons/react/24/solid'
import PageNumListBox from "./PageNumListBox";
import SimTableOptionsButton from "./SimTableOptionsButton";
import { SIMULATION_STATUS } from "../../../utils/constants";
import DeleteSimulationModal from "../../modals/DeleteSimulationModal";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../context/GlobalContext";
import { ApiError, AppError, bearerAuth, delay, InvalidRequestBody, ResponseError } from "../../../utils/helpers"
import { deleteRealEstateSimulation, updateRealEstateSimulation } from "../../../utils/api/realestateApi"
import { deletePopulationSimulation, updatePopulationSimulation } from "../../../utils/api/populationApi"
import { useOuterClick } from "../../../utils/useOuterClick";
import "../../../assets/css/withcheck-loader.css"
import { deleteIndustrySimulation, updateIndustrySimulation } from "../../../utils/api/industryApi";
import MySimViewNotReadyModal from "../../modals/MySimViewNotReadyModal";

const PAGE_SIZE = 5;
export const SIM_GROUP = {
	realestate: {name: "realestate", simCode: "RE"},
	population: {name: "population", simCode: "POP"},
	industry: {name: "industry", simCode: "IND"},
}

const SIDO_URL = "/pop-simulation-sido"

export const MODEL_CATEGORY_MAPPER = {
	realestate: {
		apartment: {name_en: "Apartment", name_kr: "아파트", url: "/realestate/viewsim"}
	},
	population: {
		national: {name_en: "National", name_kr: "전국", url: "/pop-simulation-national"},
		sd1: {name_en: "Seoul", name_kr: "서울특별시", url: SIDO_URL},
		sd2: {name_en: "Busan", name_kr: "부산광역시", url: SIDO_URL},
		sd3: {name_en: "Daegu", name_kr: "대구광역시", url: SIDO_URL},
		sd4: {name_en: "Incheon", name_kr: "인천광역시", url: SIDO_URL},
		sd5: {name_en: "Gwangju", name_kr: "광주광역시", url: SIDO_URL},
		sd6: {name_en: "Daejeon", name_kr: "대전광역시", url: SIDO_URL},
		sd7: {name_en: "Ulsan", name_kr: "울산광역시", url: SIDO_URL},
		sd8: {name_en: "Sejeong", name_kr: "세종특별자치시", url: SIDO_URL},
		sd9: {name_en: "Gyeonggi-do", name_kr: "경기도", url: SIDO_URL},
		sd10: {name_en: "Gangwon-do", name_kr: "강원도", url: SIDO_URL},
		sd11: {name_en: "Chungcheongbuk-do", name_kr: "충청북도", url: SIDO_URL},
		sd12: {name_en: "Chungcheongnam-do", name_kr: "충청남도", url: SIDO_URL},
		sd13: {name_en: "Jeollabuk-do", name_kr: "전라북도", url: SIDO_URL},
		sd14: {name_en: "Jeollanam-do", name_kr: "전라남도", url: SIDO_URL},
		sd15: {name_en: "Gyeongsangbuk-do", name_kr: "경상북도", url: SIDO_URL},
		sd16: {name_en: "Gyeongsangnam-do", name_kr: "경상남도", url: SIDO_URL},
		sd17: {name_en: "Jeju", name_kr: "제주특별자치도", url: SIDO_URL},
	},
	industry: {
		all_industry: {name_en: "All Industry", name_kr: "전산업", url: null},
		manufacturing: {name_en: "Manufacturing", name_kr: "제조업", url: null},
	}
}



export const ACTION = {
	SET_DELETE_SIM_MODAL: "set-delete-sim-modal",
	DELETE_SIM_SUCCESS: "delete-sim-success",
	SET_OPT_ACTIONS_STATUS: "set-opt-actions-status",
	SET_EDIT_SIM_MODAL: "set-edit-sim-modal",
	EDIT_SIM_SUCCESS: "edit-sim-success"
}

const initialState = {
	simList : [],
	isDeleteSimModalOpen: false,
	isEditSimModalOpen: false,
	optionSelectedSimInfo: null,
	optionActionStatus: {loading: false, success: false},
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_SIMULATION_LIST:
			return {...state, simList: payload}
		case ACTION.SET_DELETE_SIM_MODAL:
			return {...state, isDeleteSimModalOpen: payload.isOpen, optionSelectedSimInfo: payload.simInfo, optionActionStatus: {...initialState.optionActionStatus}}
		case ACTION.DELETE_SIM_SUCCESS:
			return {
				...state, 
				simList: [...state.simList.filter(item=>item.id !== payload.simId)],
				isDeleteSimModalOpen: false, 
				optionSelectedSimInfo: null, 
				optionActionStatus: {...state.optionActionStatus, loading: false}
			}
		case ACTION.SET_OPT_ACTIONS_STATUS:
			return {...state, optionActionStatus: payload};
		case ACTION.SET_EDIT_SIM_MODAL:
			return {...state, isEditSimModalOpen: payload.isOpen, optionSelectedSimInfo: payload.simInfo, optionActionStatus: {...initialState.optionActionStatus}}
		case ACTION.EDIT_SIM_SUCCESS:

			return {
				...state, 
				simList: [...state.simList.map(item=> item.id === payload.simId ? {...item, name: payload.newSimName} : {...item})],
				isEditSimModalOpen: false, 
				optionSelectedSimInfo: null, 
				optionActionStatus: {...state.optionActionStatus, loading: false}
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}



const SimulationTable = ({tableTitle, simGroup, simList, viewLink}) => {
	const navigate = useNavigate();
	const globalDispatch = useGlobalDispatchContext();
	const { user } = useGlobalStateContext();
	const [state, dispatch] = React.useReducer(reducer, initialState)
  const [currPageNum, setCurrPageNum] = React.useState(1);
	const editSimRef = React.useRef();
	const [errorIsOpen, setErrorIsOpen] = React.useState(false);

	React.useEffect(() => {
		dispatch({type: ACTION.SET_SIMULATION_LIST, payload: simList});
	}, []);

	const handleDeleteSim = (simInfo, simGroupName) => {
		dispatch({type: ACTION.SET_DELETE_SIM_MODAL, payload: {isOpen: true, simInfo: {...simInfo, simGroupName}}})
	}

	const deleteSimConfirm = async (simId, simGroupName) => {
		dispatch({type: ACTION.SET_OPT_ACTIONS_STATUS, payload: {loading: true, success: false}})
		try {

			switch (simGroupName) {
				case SIM_GROUP.realestate.name:
					await deleteRealEstateSimulation(simId, user);
					break;
				case SIM_GROUP.population.name:
					await deletePopulationSimulation(simId, user);
					break;
				case SIM_GROUP.industry.name:
					await deleteIndustrySimulation(simId, user);
					break;
				default:
					console.error(`Invalid simGroup ${simGroupName}.`);
			}

			dispatch({type: ACTION.SET_OPT_ACTIONS_STATUS, payload: {loading: false, success: true}})
			await delay(600);

			dispatch({type: ACTION.DELETE_SIM_SUCCESS, payload: {simId, simGroupName}});
	
		} catch (err) {
			if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
				const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
				globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
			}
			else{
				console.error(err)
			}
		}
	}

	const closeDeleteSimModal = () => {
		dispatch({type: ACTION.SET_DELETE_SIM_MODAL, payload: {isOpen: false, simInfo: null}})
	}



	const handleEditSim = (simInfo, simGroupName) => {
		dispatch({type: ACTION.SET_EDIT_SIM_MODAL, payload: {isOpen: true, simInfo: {...simInfo, simGroupName}}})
	}

	const cancelEdit = async (e) => {
		e.preventDefault();
		dispatch({type: ACTION.SET_EDIT_SIM_MODAL, payload: {isOpen: false, simInfo: null}})
	}

	const editSimConfirm = async (e, simInfo, simGroupName) => {
		e.preventDefault();
		const newSimName = editSimRef.current?.value;
		dispatch({type: ACTION.SET_OPT_ACTIONS_STATUS, payload: {loading: true, success: false}})
		try {
			let body = {name: newSimName}
			switch (simGroupName) {
				case SIM_GROUP.realestate.name:
					await updateRealEstateSimulation(body, simInfo.id, user);
					break;
				case SIM_GROUP.population.name:
					await updatePopulationSimulation(body, simInfo.id, user);
					break;
				case SIM_GROUP.industry.name:
					await updateIndustrySimulation(body, simInfo.id, user);
					break;
				default:
					console.error(`Invalid simGroup ${simGroupName}.`);
			}

			dispatch({type: ACTION.SET_OPT_ACTIONS_STATUS, payload: {loading: false, success: true}})
			await delay(600);

			dispatch({type: ACTION.EDIT_SIM_SUCCESS, payload: {simId: simInfo.id, newSimName, simGroupName}});
	
		} catch (err) {
			if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
				const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
				globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
			}
			else{
				console.error(err)
			}
		}
	}

	const InputEditSimulation = ({simInfo, simGroupName}) => {
		return (
			<input 
				ref={editSimRef}
				autoFocus
				type="text" 
				defaultValue={editSimRef.current?.value || simInfo.name}  
				className="w-4/5 py-1 px-2 border rounded-md outline-none focus:ring-opacity-30 focus:ring-4 focus:ring-konainfo focus:border-blue-500"
				onClick={(e) => e.stopPropagation()}
				onBlur={(e) => (e.target.value === simInfo.name) ? cancelEdit(e) : editSimConfirm(e, simInfo, simGroupName)}
				onKeyUp={e => {
					if (e.key === 'Enter') {
						if (e.target.value === simInfo.name) { 
							cancelEdit(e);
						} else {
							editSimConfirm(e, simInfo, simGroupName);
						} 
					}
					else if(e.key === 'Escape') {
						cancelEdit(e);
					}
				}}
				
			/>
				
		)
	}


	const getSimStatusTag = (status) => {
		switch (status) {
			case SIMULATION_STATUS.DONE:
				return <div className="flex space-x-2 items-center text-sm text-konasuccess"><span><CheckCircleIcon className="h-6" /></span><span>{SIMULATION_STATUS.DONE}</span></div>;
			case SIMULATION_STATUS.ERROR:
				return <div className="flex space-x-2 items-center text-sm text-konared"><span><XCircleIcon className="h-6" /></span><span>{SIMULATION_STATUS.ERROR}</span></div>;
			case SIMULATION_STATUS.NOT_STARTED:
				return <div className="flex space-x-2 items-center text-sm text-gray-400"><span><ClockIcon className="h-6" /></span><span>{SIMULATION_STATUS.NOT_STARTED}</span></div>;
				default:
				return null;
		}
	}

	const getSimIcon = (simGroupName) => {
		switch (simGroupName) {
			case SIM_GROUP.realestate.name:
				return <HomeIcon className="text-white" />;
			case SIM_GROUP.population.name:
				return <UsersIcon className="text-white" />;
			case SIM_GROUP.industry.name:
				return <BuildingOffice2Icon className="text-white" />;
			default:
				return null;
		}
	}

	return (
		<div>
			<DeleteSimulationModal isOpen={state.isDeleteSimModalOpen} simInfo={state.optionSelectedSimInfo} closeModal={closeDeleteSimModal} confirmFn={deleteSimConfirm} optionActionStatus={state.optionActionStatus}  />
			<MySimViewNotReadyModal isOpen={errorIsOpen} setIsOpen={setErrorIsOpen} />
			<div className="py-5 flex flex-row  items-center">
				<div className='flex-1'>
					<h1 className="text-2xl font-semibold ">{tableTitle}</h1>
				</div>
				
				
			</div >

			<table className="w-full text-left text-gray-500 table-fixed text-base pb-5">
				<thead className="text-sm uppercase bg-gray-50">
					<tr className="[&>*]:p-4 [&>*]:font-semibold text-center ">
						<th className="w-16 px-4"></th>
						<th className="text-left">Simulation</th>
						<th className="w-40">Category</th>
						<th className="w-40 text-left"><span className="ml-8">Status</span></th>
						<th className="w-40">Simulation Date</th>
						<th className="w-24">Options</th>
					</tr>
				</thead>
				<tbody>
					{
						(state.simList.length > 0) ?
							state.simList.slice((currPageNum - 1) * PAGE_SIZE, (PAGE_SIZE * currPageNum)).map((item, index) => {

								const simCategory = item?.category ? MODEL_CATEGORY_MAPPER[simGroup.name][item.category].name_kr : undefined;
								const simViewUrl = item?.category ? MODEL_CATEGORY_MAPPER[simGroup.name][item.category].url : viewLink;
								

								return (
									<tr 
										key={index+Date.now()} 
										className={`[&>*]:p-4 text-center align-middle border-b  ${(!state.isEditSimModalOpen || state.optionSelectedSimInfo?.id !== item.id ) && "cursor-pointer hover:bg-gray-50"}`}
										onClick={(state.isEditSimModalOpen && state.optionSelectedSimInfo?.id === item.id ) ? undefined : simViewUrl !== null ? () => navigate(simViewUrl, {state: { sim_id: item.id }}) : () => setErrorIsOpen(true) }
									>
										<td className=""><div className="bg-gray-200 h-10 w-10 p-2 rounded-full">{getSimIcon(simGroup.name)}</div></td>
										<td className="text-left break-all flex flex-col space-y-1 h-full justify-center">
											{ (state.isEditSimModalOpen && state.optionSelectedSimInfo?.id === item.id ) ? (
													<div className="flex space-x-3 items-center">
														<InputEditSimulation simInfo={item} simGroupName={simGroup.name} /> 
														{(!state.optionActionStatus.loading && !state.optionActionStatus.success) && 
															<>
																<button className="rounded-md p-1 ripple-primary text-white" onMouseDown={(e) => (editSimRef.current?.value === item.name) ? cancelEdit(e) : editSimConfirm(e, item, simGroup.name)}><CheckIcon className="h-5" /></button> 
																<button className="rounded-md p-1 ripple-gray text-white" onMouseDown={(e) => cancelEdit(e)}><XMarkIcon className="h-5" /></button>
															</>
														}
														{(!state.optionActionStatus.loading && state.optionActionStatus.success) && <svg className="checkmarkSmall h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmarkSmall__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmarkSmall__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>}
														{(state.optionActionStatus.loading && !state.optionActionStatus.success) && 
															<svg className="animate-spin  h-5 w-5 text-konared" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
																<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
																<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
															</svg>
														}

														
													</div>
												) : (
													<>
														<div>{item.name}</div>
														<div className="flex space-x-2">
															<div className="bg-red-100 text-red-400 border border-red-200 text-xs rounded-full px-2">{`${simGroup.simCode}-${item.id}`}</div>
															<div className="bg-gray-200 text-gray-500 border border-gray-300 text-xs rounded-full px-2">{`v-${item.version}`}</div>
															
														</div>
													</>
											)}
											
										</td>
										<td>{simCategory && simCategory}</td>
										<td>{getSimStatusTag(item.status)}</td>
										<td>{Moment(item.created_at).format("YYYY-MM-DD")}</td>
										<td className="">
											<SimTableOptionsButton item={item} key={index} simGroup={simGroup} viewLink={simViewUrl} optionsFn={{handleDeleteSim, handleEditSim, setErrorIsOpen}} />
										</td>
								</tr>
							)
						})
						:
						<tr>
							<td colSpan={6} className="text-center p-2">정보 없음</td>
						</tr>
					}
					<tr>
						<td colSpan={6} className="">
							<div className="flex justify-end items-center space-x-2 p-2  bg-gray-50">
								<button className="border border-gray-300 text-gray-500 p-1 rounded-lg bg-white hover:bg-konagray/10" onClick={() => setCurrPageNum(Math.max(currPageNum-1, 1))}><ChevronLeftIcon className="h-4" /></button>
								<button className="border border-gray-300 text-gray-500 p-1 rounded-lg bg-white hover:bg-konagray/10" onClick={() => setCurrPageNum(Math.min(currPageNum+1, Math.ceil(state.simList.length / PAGE_SIZE)))}><ChevronRightIcon className="h-4" /></button>
								<PageNumListBox
									dataList={Array.from({ length: Math.ceil(state.simList.length / PAGE_SIZE) }, (_, i) => i + 1)}
									selectedVal={currPageNum}
									onChangeVal={setCurrPageNum}
								/>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	)
}

export default SimulationTable