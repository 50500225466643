import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './layouts/App'
import reportWebVitals from './reportWebVitals';
import { isMobile } from 'react-device-detect';
import konaLogo from "./assets/images/konai_logo.svg"

const root = ReactDOM.createRoot(document.getElementById('root'));


const renderContent = () => {
  if (isMobile) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-xl">
        <div className="">코나아이(주) SD 예측 시스템은</div>
        <div className="">현재 <span className="font-bold text-konared">PC</span>에서만 사용 가능합니다.</div>
        <div className="mt-4">모바일서비스는 준비중입니다.</div>
        <div className="text-sm pt-10">문의사항은 <a href="mailto:ikyjeong@naver.com" className="font-bold underline underline-offset-2">ikyjeong@naver.com</a>으로 연락주세요</div>
        <div className="mt-10"><img src={konaLogo} className="h-[25px]" alt="" /></div>
      </div>
    )
  }
  return <App />
}


root.render(
  // remove strict mode so that useEffect will only execute once
  renderContent()
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
