import React from 'react'
import "../../assets/css/loaders.css"

const Loader3 = () => {
  return (
    <div className="flex-1 flex items-center justify-center">
      <span className="loader3 w-12 h-12"></span>
    </div>
  )
}

export default Loader3