import React from 'react'
import {BsFillQuestionCircleFill} from 'react-icons/bs'
import { useFieldArray, useController} from "react-hook-form";
import ParamInfoModal from "../modals/ParamInfoModal";
import { useReStateContext } from "../context/RealEstateContext";


const IndustryParamSlider = ({param, form, hasSubTitle = true, showUnit=false, switchId}) => {
  const SliderWithInput = ({ control, name, min, max, step, title, unit }) => {
    const {field} = useController({name: name, control});
    
    return (
      <div className="inline-flex items-center space-x-4 w-full">
      <div className="flex-1 relative py-2">
        {hasSubTitle && <span className="absolute top-1 left-0 text-xs font-bold">{title}</span>}
        <input 
          {...field} 
          type="range"
          min={min}
          max={max}
          step={step}
          className="w-full sim-input-slider" 
        />
        <span className="absolute bottom-1 left-0 text-[10px] text-gray-400 font-medium">{min}{unit}</span>
        <span className="absolute bottom-1 right-0 text-[10px] text-gray-400 font-medium">{max}{unit}</span>
      </div>
      <div className="w-[80px] flex items-center space-x-1">
        <input 
          {...field} 
          type="text" 
          className="block text-xs py-2 rounded-lg w-full border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm" 
          autoComplete="off"
        />
        <span>{unit}</span>
      </div>
    </div>
    );
  }

  return (
    <div className="py-2 bg-white flex flex-col">
      {/* <ParamInfoModal isOpen={isOpen} setIsOpen={setIsOpen} paramInfo={paramInfo} inputVarInfo={inputVarInfo} /> */}
      
      <div className="flex items-center">
        <label htmlFor={`switch-id-${switchId}`} className="inline-flex">
          <input
              {...form.register("switchVar")}
              type="radio"
              value={param.switchVarValue}
              id={`switch-id-${switchId}`}
              className="mr-3"
              // onChange={e => handleOnChangeSwitchSim(e.target.value)}
          />
          <span className="text-xl font-semibold  cursor-pointer">{param.title}</span> 
        </label>
        {/* <span className="text-xl font-semibold">{param.title}</span>  */}
      </div>

      
      <div className={`overflow-hidden  ${form.watch(`switchVar`) === param.switchVarValue ? "h-full" : "h-0"}`}>
        <div className={`flex flex-col mt-4 px-4 py-2  border rounded-xl relative transition-opacity duration-300 opacity-0 ${form.watch(`switchVar`) === param.switchVarValue ? "opacity-100" : "opacity-0"}`}>
          {param.paramVar.map((val, index) => {
            
            const paramFormatOverride = (param.overrideFormatterMultipler !== null) ? param.overrideFormatterMultipler : 1;
            const paramUnitOverride = (param.overrideUnit !== null) ? param.overrideUnit : "";
            return (
              <SliderWithInput 
                key={index}
                name={`${val.name_en.replace(/[\[\]]/g, "")}`} 
                control={form.control} 
                min={val.e_min * paramFormatOverride}
                max={val.e_max * paramFormatOverride}
                step={val.e_inc * paramFormatOverride}
                title={val.name_kr}
                unit={paramUnitOverride}
              />
            )
          })}
        </div>
      </div>
      

    </div>
  )
}

export default IndustryParamSlider