import React, { Fragment, useReducer, useEffect, useState } from 'react'
import { CardHeader, CardWrapper, CardContent, CardHeaderFilter } from '../../components/population/CardWrapper'
import MainContentWrapper from '../../components/MainContentWrapper'
import FilterBar from '../../components/population/FilterBar'
import filter_list from '../../assets/filter_list.json'
import paLabels from '../../assets/pa-labels.json'
import pafilter from '../../assets/pa-filter.json'
import PopulationEcharts from '../../components/charts/PopulationEcharts'
import { getChartOptionPopLocalForecast } from '../../components/charts/PopulationEchartOptions'
import { NavLink, useNavigate } from "react-router-dom"
import { Listbox, Transition } from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { API_BASE_URL} from '../../utils/constants'
import { bearerAuth } from '../../utils/helpers'
import LoadingModal from "../../components/modals/LoadingModal"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal'
import { handleError } from '../../utils/pop-handle-response'
import { checkSubscribed } from '../../utils/pop-subscription-checker'
import { useGlobalStateContext } from "../../components/context/GlobalContext"

const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
	SET_FILTER_CHANGE: 'set-filter-change',
	SET_SIDO_CHANGE: 'set-sido-change',
	SET_SGG_CHANGE: 'set-sgg-change',
	SET_EMD_CHANGE: 'set-emd-change',
	SET_DATA: 'set-data',
	SET_AGE_START: 'set-start-age',
	SET_AGE_END: 'set-end-age',
	ADD_CUSTOM: 'add-custom-age'
}

const Icon_colored_printer =(props)=> {
	return (
	  	<svg {...props} viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="5.5" width="28" height="10" rx="1.5" fill="white" stroke="black"/>
		<path d="M10 0.5H19C19.8284 0.5 20.5 1.17157 20.5 2V5.5H8.5V2C8.5 1.17157 9.17157 0.5 10 0.5Z" fill="white" stroke="black"/>
		<rect x="5.5" y="10.5" width="18" height="9" fill="#D9D9D9" stroke="black"/>
		<rect x="6" y="11" width="6" height="8" fill="#4EB9FF"/>
		<rect x="6" y="11" width="6" height="8" fill="#4EB9FF"/>
		<rect x="18" y="11" width="5" height="8" fill="#FDCB44"/>
		<rect x="12" y="11" width="6" height="8" fill="#FD4080"/>
		<circle cx="26" cy="8" r="1" fill="black"/>
		</svg>
	)
}

const Icon_black_printer =(props)=> {
	return (
		<svg {...props} viewBox="0 0 29 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.5" y="5.5" width="28" height="10" rx="1.5" fill="white" stroke="black"/>
		<path d="M10 0.5H19C19.8284 0.5 20.5 1.17157 20.5 2V5.5H8.5V2C8.5 1.17157 9.17157 0.5 10 0.5Z" fill="white" stroke="black"/>
		<rect x="5.5" y="10.5" width="18" height="9" fill="#D9D9D9" stroke="black"/>
		<rect x="6" y="11" width="9" height="8" fill="#3B3A35"/>
		<circle cx="26" cy="8" r="1" fill="black"/>
		<path d="M8.70455 18V12.1818H10.7386C11.1439 12.1818 11.4782 12.2519 11.7415 12.392C12.0047 12.5303 12.2008 12.7169 12.3295 12.9517C12.4583 13.1847 12.5227 13.4432 12.5227 13.7273C12.5227 13.9773 12.4782 14.1837 12.3892 14.3466C12.3021 14.5095 12.1866 14.6383 12.0426 14.733C11.9006 14.8277 11.7462 14.8977 11.5795 14.9432V15C11.7576 15.0114 11.9366 15.0739 12.1165 15.1875C12.2964 15.3011 12.447 15.464 12.5682 15.6761C12.6894 15.8883 12.75 16.1477 12.75 16.4545C12.75 16.7462 12.6837 17.0085 12.5511 17.2415C12.4186 17.4744 12.2093 17.6591 11.9233 17.7955C11.6373 17.9318 11.2652 18 10.8068 18H8.70455ZM9.40909 17.375H10.8068C11.267 17.375 11.5938 17.286 11.7869 17.108C11.982 16.928 12.0795 16.7102 12.0795 16.4545C12.0795 16.2576 12.0294 16.0758 11.929 15.9091C11.8286 15.7405 11.6856 15.6061 11.5 15.5057C11.3144 15.4034 11.0947 15.3523 10.8409 15.3523H9.40909V17.375ZM9.40909 14.7386H10.7159C10.928 14.7386 11.1193 14.697 11.2898 14.6136C11.4621 14.5303 11.5985 14.4129 11.6989 14.2614C11.8011 14.1098 11.8523 13.9318 11.8523 13.7273C11.8523 13.4716 11.7633 13.2547 11.5852 13.0767C11.4072 12.8968 11.125 12.8068 10.7386 12.8068H9.40909V14.7386Z" fill="#FFFEFE"/>
		<path d="M8.70455 18V12.1818H10.7386C11.1439 12.1818 11.4782 12.2519 11.7415 12.392C12.0047 12.5303 12.2008 12.7169 12.3295 12.9517C12.4583 13.1847 12.5227 13.4432 12.5227 13.7273C12.5227 13.9773 12.4782 14.1837 12.3892 14.3466C12.3021 14.5095 12.1866 14.6383 12.0426 14.733C11.9006 14.8277 11.7462 14.8977 11.5795 14.9432V15C11.7576 15.0114 11.9366 15.0739 12.1165 15.1875C12.2964 15.3011 12.447 15.464 12.5682 15.6761C12.6894 15.8883 12.75 16.1477 12.75 16.4545C12.75 16.7462 12.6837 17.0085 12.5511 17.2415C12.4186 17.4744 12.2093 17.6591 11.9233 17.7955C11.6373 17.9318 11.2652 18 10.8068 18H8.70455ZM9.40909 17.375H10.8068C11.267 17.375 11.5938 17.286 11.7869 17.108C11.982 16.928 12.0795 16.7102 12.0795 16.4545C12.0795 16.2576 12.0294 16.0758 11.929 15.9091C11.8286 15.7405 11.6856 15.6061 11.5 15.5057C11.3144 15.4034 11.0947 15.3523 10.8409 15.3523H9.40909V17.375ZM9.40909 14.7386H10.7159C10.928 14.7386 11.1193 14.697 11.2898 14.6136C11.4621 14.5303 11.5985 14.4129 11.6989 14.2614C11.8011 14.1098 11.8523 13.9318 11.8523 13.7273C11.8523 13.4716 11.7633 13.2547 11.5852 13.0767C11.4072 12.8968 11.125 12.8068 10.7386 12.8068H9.40909V14.7386Z" fill="#FFFEFE"/>
		<path d="M16.7614 18L15.1705 12.1818H15.8864L17.1023 16.9205H17.1591L18.3977 12.1818H19.1932L20.4318 16.9205H20.4886L21.7045 12.1818H22.4205L20.8295 18H20.1023L18.8182 13.3636H18.7727L17.4886 18H16.7614Z" fill="#2E2A2A"/>
		</svg>
	)
}
const initialState = {
	currentDataSet: [],
	locationList: null,
	sidoList: null,
	sggList: null,
	emdList: null,
	curSD: 'none',
	curSGG: 'none',
	curEMD: 'none',
	popData: null,
	selectedFilter: ['all'],
	customFilter: [],
	checkedState: [true, false, false, false, false, false, false, false, false],
	selectedData: null,
	ageEnd: 100,
	ageStart: 0,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_MAIN_DATA:
			return {
				...state,
				locationList: payload.locationList,
				sidoList: payload.sidoList,
				curSGG: payload.curSGG,
				curSD: payload.curSD,
				sggList: payload.sggList,
				selectedData: payload.selectedData,
				currentDataSet: payload.currentDataSet,
				popData: payload.popData,
				selectedFilter: payload.selectedFilter,
				checkedState: payload.checkedState
			}
		case ACTION.SET_FILTER_CHANGE:
			return {
				...state,
				selectedFilter: payload.selectedFilter,
				checkedState: payload.checkedState,
				popData: payload.popData
			}
		case ACTION.SET_SIDO_CHANGE:
			return {
				...state,
				curSD: payload.curSD,
				sggList: payload.sggList,
				curSGG: payload.curSGG,
				curEMD: payload.curEMD,
				emdList: payload.emdList,
			}
		case ACTION.SET_SGG_CHANGE:
			return {
				...state,
				curSGG: payload.curSGG,
				curEMD: payload.curEMD,
				emdList: payload.emdList
			}
		case ACTION.SET_EMD_CHANGE:
			return {
				...state,
				curEMD: payload.curEMD,
			}
		case ACTION.SET_DATA:
			return {
				...state,
				currentDataSet: payload.currentDataSet,
				selectedData: payload.selectedData,
				popData: payload.popData,
			}
		case ACTION.SET_AGE_START:
			return {
				...state,
				ageStart: parseInt(payload.ageStart),
			}
		case ACTION.SET_AGE_END:
			return {
				...state,
				ageEnd: parseInt(payload.ageEnd),
			}
		case ACTION.ADD_CUSTOM:
			return {
				...state,
				ageEnd: payload.ageEnd,
				ageStart: payload.ageStart,
				customFilter: payload.customFilter,
				popData: payload.popData,
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}
function PopLocal() {

	const [state, dispatch] = useReducer(reducer, initialState)
	const { user } = useGlobalStateContext();
	const [isSimRunning, setSimRunning] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [popGraphData, setPopGraphData] = useState(null)
	const [isBW, setIsBW] = useState(false)

	let navigate = useNavigate()

	useEffect(() => {
		const loadDefault = async () => {

			try {
				
				setSimRunning(true)
				
				let query = `?page_size=all`
				let response = await fetch(`${API_BASE_URL}/api/population/location-map${query}`,{
					method: "GET",
					headers: {
						"Content-Type" : "application/json",
						"Authorization" : bearerAuth(user)
					} 
				});

				if (!response.ok) throw new Error(handleError(response.status))

				let locationList = await response.json()

				if(!locationList.data || locationList.data.length<=0) throw new Error("Error when fecthing location")

				let sidoList = locationList.data.filter(item => item.loc_type === "sd")
				let curSido = sidoList[0].location_code
				let sggList = locationList.data.filter(item => (item.loc_type === "sgg" && item.location_code.startsWith(curSido.substring(0, 2))))

				let currentDataSet = []
				let selectedData = []
				// let graphData = []
				let popData =[]

				query = `?page_size=all&name_en__sw=Population Model by age sex&loc_code=${sidoList[0].location_code}`
				response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
					method: "GET",
					headers: { "Content-Type": "application/json" },
				});

				if (!response.ok) throw new Error(handleError(response.status))

				let data = await response.json()
				let selectedFilter = ['all']
				
				if (data && data.data.length > 0) {

					let year_vals = data.data[0].value.map(pair => { return pair[0] })
					selectedFilter.forEach(filter => {
						let ages = pafilter[filter].split(",")
						let age_values = {}
						year_vals.forEach(y => { age_values[y] = 0 }) //initializing the values 

						data.data.forEach(item => {
							if (ages.includes(item.name_en.split(",")[1].replace("]", "")))
								item.value.forEach(i => { age_values[i[0]] += i[1] })
						})
						
						popData.push({
							name: sidoList[0].name + '-' + paLabels[filter],
							data: Object.entries(age_values).map(([key, val]) => { return [parseInt(key), val] }),
						})
					})
					
					selectedData.push([sidoList[0].name, sidoList[0].location_code])
					currentDataSet.push([sidoList[0].location_code, data])

					setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))
					
				} else {
					alert('No data available for this location')
				}
				
				dispatch({
					type: ACTION.SET_MAIN_DATA,
					payload: {
						locationList: locationList,
						sidoList: sidoList,
						curSGG: 'none',
						selectedData: selectedData,
						currentDataSet: currentDataSet,
						curSD: curSido,
						sggList: sggList,
						popData: popData,
						selectedFilter : selectedFilter,
						checkedState: [true, false, false, false, false, false, false, false, false]
					}
				})
			
			} catch (e) {
				console.error(e.message)
				setIsOpen(true)
			}finally{
				setSimRunning(false)
			}
		}
		
		loadDefault();

	}, [])


	const handleSidoChange = (e) => {
		
		let curSD = e
		let sggList = state.locationList.data.filter(item => (item.loc_type === "sgg" && item.location_code.startsWith(curSD.substring(0, 2))))

		dispatch({
			type: ACTION.SET_SIDO_CHANGE,
			payload: {
				curSD: curSD,
				sggList: sggList,
				emdList: null,
				curSGG: 'none',
				curEMD: 'none'
			}
		})
	}

	const handleSGGChange = (e) => {
		let curSGG = e
		let emdList = []

		if (curSGG === 'none')
			emdList = null
		else
			emdList = state.locationList.data.filter(item => (item.loc_type === "emd" && item.location_code.startsWith(curSGG.substring(0, 5))))

		dispatch({
			type: ACTION.SET_SGG_CHANGE,
			payload: {
				emdList: emdList,
				curSGG: curSGG,
				curEMD: 'none'
			}
		})
	}

	const handleEMDChange = (e) => {
		let curEMD = e

		dispatch({
			type: ACTION.SET_EMD_CHANGE,
			payload: {
				curEMD: curEMD
			}
		})
	}

	const handleOnSelectChange = (position, value) => {

		let x = state.selectedFilter.indexOf(value)

		let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))
		let popData = JSON.parse(JSON.stringify(state.popData))

		const updatedCheckedState = state.checkedState.map((item, index) =>
			index === position ? !item : item
		)
		
		if (x < 0) {
			if (state.selectedData)
				state.selectedData.forEach(location => {
					
					let data = state.currentDataSet.filter(item => item[0] === location[1])[0]
					if (data && data[1].data.length > 0) {
						let year_vals = data[1].data[0].value.map(pair => { return pair[0] })

						let ages = pafilter[value].split(",")
						let age_values = {}

						year_vals.forEach(y => { age_values[y] = 0 }) //initializing the values 

						data[1].data.forEach(item => {
							if (ages.includes(item.name_en.split(",")[1].replace("]", "")))
								item.value.forEach(i => { age_values[i[0]] += i[1] })
						})

						popData.push({
							name: location[0] + '-' + paLabels[value],
							data: Object.entries(age_values).map(([key, val]) => { return [parseInt(key), val] }),
						})
						
					}
				})

			selectedFilter.push(value)
		} else {
			selectedFilter = selectedFilter.filter(v => v !== value)
			popData = popData.filter(element => paLabels[value] !== element.name.split("-")[1])
		}
		
		setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))

		dispatch({
			type: ACTION.SET_FILTER_CHANGE,
			payload: {
				selectedFilter: selectedFilter,
				checkedState: updatedCheckedState,
				popData: popData
			}
		})
	}

	const handleAddData = async () => {

		let newData = ''

		let selectedData = JSON.parse(JSON.stringify(state.selectedData))
		let currentDataSet = JSON.parse(JSON.stringify(state.currentDataSet))
		let selectedFilter = JSON.parse(JSON.stringify(state.selectedFilter))

		if (state.curEMD.includes('none')) {
			if (state.curSGG.includes('none'))
				newData = state.sidoList.filter(item => item.location_code.includes(state.curSD))
			else
				newData = state.sggList.filter(item => item.location_code.includes(state.curSGG))
		} else {
			newData = state.emdList.filter(item => item.location_code.includes(state.curEMD))
		}

		if (selectedData && (selectedData.filter(item => item[0] === newData[0].name)).length > 0) {
			alert('Data already loaded in the chart.')
		} else {
			try {
				setSimRunning(true)

				let query = `?page_size=all&name_en__sw=Population Model by age sex&loc_code=${newData[0].location_code}`
				let response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
					method: "GET",
					headers: { "Content-Type": "application/json" },
				});
				
				//we are expecting an array of objects
				if (!response.ok) throw new Error(handleError(response.status))

				let loc_data = await response.json()
				let popData = JSON.parse(JSON.stringify(state.popData))

				if (loc_data && loc_data.data.length > 0) {

					let year_vals = loc_data.data[0].value.map(pair => { return pair[0] })


					state.selectedFilter.forEach(filter => {
						let ages = pafilter[filter].split(",")
						let age_values = {}
						year_vals.forEach(y => { age_values[y] = 0 }) //initializing the values 

						loc_data.data.forEach(item => {
							if (ages.includes(item.name_en.split(",")[1].replace("]", "")))
								item.value.forEach(i => { age_values[i[0]] += i[1] })
						})
						popData.push({
							name: newData[0].name + '-' + paLabels[filter],
							data: Object.entries(age_values).map(([key, val]) => { return [parseInt(key), val] }),
						})
					})

					// custom filter check
					if (state.customFilter.length > 0) {

						state.customFilter.forEach(itemPair => {

							let start = itemPair[0]
							let end = itemPair[1]

							let ages = Array(end-start+1).fill().map((_, idx)=> 'A' + (start + idx))

							let age_values = {}
							year_vals.forEach(y => { age_values[y] = 0 })

							loc_data.data.forEach(item => {
								if (ages.includes(item.name_en.split(",")[1].replace("]", "")))
									item.value.forEach(i => { age_values[i[0]] += i[1] })
							})
							popData.push({
								name: newData[0].name + '- 사용자 [' + start + '-' + end + ']',
								data: Object.entries(age_values).map(([key, val]) => { return [parseInt(key), val] }),
							})
						})
					}

					selectedData.push([newData[0].name, newData[0].location_code])
					currentDataSet.push([newData[0].location_code, loc_data])

					setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))

					dispatch({
						type: ACTION.SET_DATA,
						payload: {
							currentDataSet: currentDataSet,
							selectedData: selectedData,
							popData: popData,
						}
					})
				} else {
					alert('No data from selected location')
				}

			} catch (error) {
				console.error(error)
			} finally{
				setSimRunning(false)
			}
		}

	}

	const handleRemoveData = (location) => {

		let selectedData =  JSON.parse(JSON.stringify(state.selectedData.filter(element => element[0] !== location[0])))
		let currentDataSet =  JSON.parse(JSON.stringify(state.currentDataSet.filter(element => element[0] !== location[1])))
		let popData = JSON.parse(JSON.stringify(state.popData.filter(element =>location[0] !== element.name.split('-')[0])))
		
		setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))
		dispatch({
			type: ACTION.SET_DATA,
			payload: {
				selectedData: selectedData,
				currentDataSet: currentDataSet,
				popData: popData
			}
		})

	}

	const handleCustomClick = () => {

		let start = isNaN(parseInt(state.ageStart)) ? 0 : parseInt(state.ageStart)
		let end = isNaN(parseInt(state.ageEnd)) ? 100 : parseInt(state.ageEnd)
		let popData = JSON.parse(JSON.stringify(state.popData))

		if (end < start) {
			end = end + start
			start = end - start
			end = end - start
		}

		let pair = state.customFilter.filter(y => (y[0] === start && y[1] === end))

		if (pair.length === 0) {
			let ages = Array.from(Array((end - start) + 1)).map((e, i) => 'A' + (i + start))
			if (state.selectedData) {
				state.selectedData.forEach(location => {
					let data = state.currentDataSet.filter(item => item[0] === location[1])[0]
					if (data && data[1].data.length > 0) {
						let year_vals = data[1].data[0].value.map(pair => { return pair[0] })
						let age_values = {}
						year_vals.forEach(y => { age_values[y] = 0 })

						data[1].data.forEach(item => {
							if (ages.includes(item.name_en.split(",")[1].replace("]", "")))
								item.value.forEach(i => { age_values[i[0]] += i[1] })
						})
						popData.push({
							name: location[0] + '- 사용자 [' + start + '-' + end + ']',
							data: Object.entries(age_values).map(([key, val]) => { return [parseInt(key), val] }),
						})
					}
				})

				setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))
				dispatch({
					type: ACTION.ADD_CUSTOM,
					payload: {
						ageStart: start,
						ageEnd: end,
						customFilter: [...state.customFilter, [start, end]],
						popData: popData
					}
				})
			}
		} 

	}

	const handleClear = () => {

		let popData = JSON.parse(JSON.stringify(state.popData.filter(k => !k.name.includes('사용자'))))

		setPopGraphData(getChartOptionPopLocalForecast(popData, isBW))
		
		dispatch({
			type: ACTION.ADD_CUSTOM,
			payload: {
				ageStart: 0,
				ageEnd: 100,
				customFilter: [],
				popData: popData
			}
		})
	}

	const setPrintrColor =()=>{
		let new_printr_state = !isBW
		setIsBW(new_printr_state)
		setPopGraphData(getChartOptionPopLocalForecast(state.popData, new_printr_state))
	}
	return (
		<MainContentWrapper className="bg-konabg relative">
		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
		<LoadingModal isOpen={isSimRunning} />
			<div className="pt-10 pb-20 px-28 flex flex-col space-y-4 select-none">
				
				<FilterBar title={'데이터 선택'} className="flex flex-row space-x-4" >
					<div className='flex-1 inline-flex p-2 space-x-4'>
						<Listbox value={state.curSD} onChange={handleSidoChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-10 min-w-[150px]">
										<Listbox.Button className={`relative w-full cursor-default border-2 border-gray-50 rounded-lg ${((state.sidoList !== null)) ? 'bg-white hover:border-gray-200' : 'bg-gray-200 text-white'} py-2 pl-3 pr-10 text-left shadow-md sm:text-sm`}>
											<span className="block truncate">{(state.sidoList !== null && state.curSD !== 'none') ? state.sidoList.filter(item => item.location_code === state.curSD)[0].name : '정보 없음'}</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto z-5"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												{
													(state.sidoList) ? state.sidoList.map(({ location_code, name }, index) => (
														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={location_code}
														>
															{name}
														</Listbox.Option>

													))
														:
														<Listbox.Option value="none">정보 없음</Listbox.Option>
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >

						{/* sgg */}
						<Listbox value={state.curSGG} onChange={handleSGGChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-10 min-w-[150px]">
										<Listbox.Button className={`relative w-full cursor-default border-2 border-gray-50 rounded-lg ${((state.sggList !== null)) ? 'bg-white hover:border-gray-200' : 'bg-gray-200 text-white'} py-2 pl-3 pr-10 text-left shadow-md sm:text-sm`}>
											<span className="block truncate">
												{
													(state.sggList !== null && state.curSGG !== 'none') ?
														((state.sggList.filter(item => item.location_code === state.curSGG)[0].name).split(" ").length > 1) ? ((state.sggList.filter(item => item.location_code === state.curSGG)[0].name).split(" ").slice(1)).join(' '): (state.sggList.filter(item => item.location_code === state.curSGG)[0].name)
														:
														(state.sggList && state.curSGG === 'none') ? '전체' : '정보 없음'
												}
											</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto z-5"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												{(state.sggList) ?
													<Listbox.Option
														className={({ active }) =>
															`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
															}`
														}
														value="none">
														전체
													</Listbox.Option> : ''}
												{
													(state.sggList) ? state.sggList.map(({ location_code, name }, index) => (
														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={location_code}
														>
															{(name.split(" ").length > 1) ? (name.split(" ").slice(1)).join(" "): name}
													
														</Listbox.Option>

													)) : ''
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >

						{/* emd */}
						<Listbox value={state.curEMD} onChange={handleEMDChange} className="z-0">
							{({ open }) => (
								<>
									<div className="relative z-10 min-w-[150px]">
										<Listbox.Button className={`relative w-full cursor-default border-2 border-gray-50 rounded-lg ${((state.emdList !== null)) ? 'bg-white hover:border-gray-200' : 'bg-gray-200 text-white'} py-2 pl-3 pr-10 text-left shadow-md sm:text-sm`}>
											<span className="block truncate">
												{
													(state.emdList !== null && state.curEMD !== 'none') ?

														state.emdList.filter(item => item.location_code === state.curEMD)[0].name.split(" ")[state.emdList.filter(item => item.location_code === state.curEMD)[0].name.split(" ").length - 1]
														:
														(state.emdList && state.curEMD === 'none') ? '전체' : '정보 없음'
												}
											</span>
											<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
												<ChevronUpDownIcon
													className="h-5 w-5 text-gray-400"
													aria-hidden="true"
												/>
											</span>
										</Listbox.Button>
										<Transition
											as={Fragment}
											leave="transition ease-in duration-100"
											leaveFrom="opacity-100"
											leaveTo="opacity-0"
											className="absolute top-[50%] w-full mt-5 max-h-60 rounded-md bg-white shadow-lg overflow-y-auto -z-10"
										>
											<Listbox.Options className=" max-h-60 rounded-md pt-[25px] py-1 text-base leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5">
												{(state.emdList) ?
													<Listbox.Option
														className={({ active }) =>
															`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
															}`
														}
														value="none">
														전체
													</Listbox.Option> : ''}
												{
													(state.emdList) ? state.emdList.map(({ location_code, name }, index) => (
														<Listbox.Option
															key={index}
															className={({ active }) =>
																`relative cursor-default select-none py-2 pl-3 pr-4 ${active ? ' bg-konabg text-amber-900' : 'text-gray-900'
																}`
															}
															value={location_code}
														>
															{name.split(" ")[name.split(" ").length - 1]}
														</Listbox.Option>

													)) : ''
												}
											</Listbox.Options>
										</Transition>

									</div>
								</>
							)}
						</Listbox >

					</div>
					<div className='p-2 pr-5 overflow-hidden'>
						<button className=' bg-konayellow hover:shadow-md hover:shadow-gray-500 text-white font-bold p-2 rounded-lg' onClick={handleAddData}>
							데이터 추가하기
						</button>
					</div>
				</FilterBar>
				<div className='flex flex-row space-x-4 max-h-[600px]'>
					<div className='flex-1 '>
						<CardWrapper className="h-full">
							<CardHeader >
								<div className='w-full flex flex-row items-center'>
									<div >
										지역별 인구 예측
									</div>
									<div className="flex-1 px-2">
										<button type="button" className="flex-initial py-2 px-2 text-white hover:bg-red-600 rounded-lg " onClick ={setPrintrColor}> 
										{
											isBW ?
											(<Icon_colored_printer
												className="h-5 hover:shadow-lg hover:text-gray-500"
												aria-hidden="true"
											/>)
											:
											(<Icon_black_printer
												className="h-5 hover:shadow-lg hover:text-gray-500"
												aria-hidden="true"
											/>)
										}
											
										</button>
									</div>
									<div className='flex text-right text-lg'>
										단위: 명
									</div>
								</div>
							</CardHeader>
							<CardContent className="p-5 overflow-y-auto" >
								{
									state.popData ?
										<PopulationEcharts options={popGraphData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}
							</CardContent>
						</CardWrapper>
					</div>
					<div className='flex flex-col w-[300px] space-y-4'>
						<CardWrapper className=" h-40">
							<CardHeaderFilter title="추가된 데이터 목록" />
							<CardContent className="flex flex-col px-5 pt-2 pb-5 overflow-y-auto" >
								{
									state.selectedData ? (
										state.selectedData.map((item, index) => {
											return (
												<div className="p-1 flex flex-row items-center" key={index}>
													<div className="flex-1 justify-self-start overflow-x-hidden">
														{item[0]}
													</div>
													<div>
														<button className=' bg-konared text-white rounded p-1 text-xs hover:shadow-md hover:shadow-gray-700' onClick={() => handleRemoveData(item)}> 항목 제거 </button>
													</div>
												</div>
											)
										})
									) : <div className='h-full flex justify-center items-center'> 선택한 지역이 없습니다 </div>
								}

							</CardContent>
						</CardWrapper>
						<CardWrapper className="flex-1 h-80 min-h-[250px] max-h-[700px]">
							<CardHeaderFilter title="데이터 속성" />
							<CardContent className="flex flex-col px-5 pt-2 pb-5 overflow-y-auto" >
								{
									filter_list ? (
										Object.entries(filter_list).map(([name, value], index) => {
											return (
												<div className="py-1" key={index}>

													<input
														type="checkbox"
														id={`custom-checkbox-${index}`}
														name={value}
														value={name}
														checked={state.checkedState[index]}
														onChange={() => handleOnSelectChange(index, name)}
													/>
													<label className=" pl-2 text-base" htmlFor={`custom-checkbox-${index}`}>{value}</label>
												</div>
											)
										})
									) : <div> No Location Found </div>
								}

							</CardContent>
						</CardWrapper>
						<CardWrapper className=" h-fit" >
							<CardHeaderFilter title="연령 범위 설정" />
							<CardContent className="flex flex-col  p-5 pt-2 space-y-2 overflow-hidden" >
								<div className=' h-12 flex flex-row overflow-hidden  items-center'>
									<div className='flex-1 px-2'>
										<input
											className='w-full h-7 border-2 text-center'
											type="number"
											min="0"
											value={state.ageStart}
											onChange={(e) => dispatch({ type: ACTION.SET_AGE_START, payload: { ageStart: e.target.value } })}
											max="100"
										/>
									</div>
									<div> -</div>
									<div className='flex-1 p-2'>
										<input
											className='w-full h-7 border-2  text-center'
											type="number"
											min="0"
											value={state.ageEnd}
											onChange={(e) => dispatch({ type: ACTION.SET_AGE_END, payload: { ageEnd: e.target.value } })}
											max="100"
										/>
									</div>
									<div className=' p-2'>
										<button onClick={handleCustomClick} className=' bg-konared hover:shadow-md hover:shadow-gray-500 text-white font-bold p-2 w-14 rounded-lg'>
											필터
										</button>
									</div>
								</div>
								<div className='flex h-10 space-x-2 items-center p-2'>
									<button className=' bg-konasub hover:shadow-md hover:shadow-gray-500 text-white font-bold p-2 rounded w-full' onClick={handleClear}>
										사용자 선택 제거
									</button>
								</div>
							</CardContent>
						</CardWrapper>
					</div>
				</div>

			</div>

		</MainContentWrapper>
	)
}

export default PopLocal