import React from 'react'
import MainContentWrapper from "../../components/MainContentWrapper"
import CardDataLibItems from "../../components/cards/CardDataLibItems"
import ParamDescData from '../../assets/param-description.json'
import { useNavigate } from "react-router-dom"
import Loader3 from "../../components/loaders/Loader3";
import { isEmpty } from "../../utils/helpers"
import { useReStateContext } from "../../components/context/RealEstateContext"


const DataLibrary = () => {
  const navigate = useNavigate();
  const reState = useReStateContext();

  return (
    <MainContentWrapper className="bg-konabg flex flex-col">
      {(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds))
        ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
        : <div className="py-20 px-48">
            {/* start of datalib cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 xl:grid-cols-3 gap-14">
              {
                Object.entries(ParamDescData).map((param, index) => <div key={index} onClick={()=> navigate(`/realestate/datalibrary-details/${param[1].linkID}`)}><CardDataLibItems  paramJson={param} /></div>)
              }
            </div>
          </div> 
      }
    </MainContentWrapper>
  )
}

export default DataLibrary