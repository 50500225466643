import React from 'react'
import {Outlet} from 'react-router-dom'
import MainContentWrapper from "../components/MainContentWrapper"
import Sidebar from "../components/mypage/Sidebar";

const MyPage = () => {
  
  return (
    <MainContentWrapper className="flex px-48">
      <Sidebar />
      <div className="flex-1 px-10 pb-10 min-h-[800px]">
        <Outlet />
      </div>
    </MainContentWrapper>
  )
}

export default MyPage