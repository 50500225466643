import React from 'react'
import ReferenceHeader from "./ReferenceHeader"
import { HomeIcon, 
  ChevronRightIcon,  } from '@heroicons/react/24/solid'
import { ApiError, AppError, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { updateNoticeByIdApi, getNoticeByIdApi, NEWS_GROUP, getNewsByIdApi, updateNewsByIdApi } from "../../utils/api/referenceApi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import NotFound from "../NotFound";
import PageNotFound from "../PageNotFound";
import { NEWS_CATEGORY_KR_MAP } from "./PopulationNews";


const GROUP = NEWS_GROUP.POPULATION;


function BreadCrumbs() {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
        <li className="inline-flex items-center">
          <NavLink to="/" className="inline-flex items-center text-sm font-medium hover:text-konared">
            <span className="mr-2"><HomeIcon className="h-4" /></span>
            Home
          </NavLink>
        </li>
        <li>
          <div className="flex items-center">
            <span><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference" className="ml-2 hover:text-konared">자료실</NavLink>
          </div>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <span className=""><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference/population-news" className="ml-2 hover:text-konared">인구뉴스</NavLink>
          </div>
        </li>
      </ol>
    </nav>
  )
}



const UpdatePopulationNews = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const { user } = useGlobalStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const titleRef = React.useRef(null);
  const urlRef = React.useRef(null);
  const [isLoading, setIsloading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isNotFound, setIsNotFound] = React.useState(false);
  const [category, setCategory] = React.useState("COLUMN");


  const loadPost = React.useCallback(async () => {
    try {

      const post = await getNewsByIdApi(id);
      titleRef.current.value = post?.title;
      urlRef.current.value = post?.url;
      setCategory(post?.category);

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        if(err.message === "Cannot find news information."){
          setIsNotFound(true);
        }
        else {
          const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
          globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})

        }
      }
      else{
        console.error(err)
      }
    }

    
	}, [])


  React.useEffect(() => {
		loadPost();
	},[]);



  const submitForm = async () => {
    const title = titleRef.current.value;
    const url = urlRef.current.value;

    if (title === "" || title.length < 1) {
      alert("Title field must not be empty");
      return;
    }

    if (url === "" || url.length < 1) {
      alert("URL field must not be empty");
      return;
    }

    try {

      setIsloading(true);

      const post = await updateNewsByIdApi(title, url, GROUP, category, id, user);   

      setIsloading(false);
      setIsSuccess(true);
      await delay(600);
      navigate("/reference/population-news/" + post.id);

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
    
  }


  if(isNotFound) {
    return <PageNotFound />
  }
  else {

    return (
      <div className="flex-1 flex flex-col pb-10">
        <ReferenceHeader>인구뉴스</ReferenceHeader>
        <div className="flex justify-center">
          <div className="flex-1 max-w-[1280px] p-10">
            <div className="flex pb-10">
              <div className="flex-1 flex flex-col">
                <div className="flex items-center pb-5">
                  <div className="flex text-3xl font-bold text-konared">Edit 인구뉴스</div>
                  <div className="flex-1 flex justify-end"><BreadCrumbs /></div>
                </div>
                <div className="flex space-x-5">
                  <div className="flex-1">
                    <div className="text-konagray/80 font-semibold">TITLE</div>
                    <input ref={titleRef} type="text" className="border-2 border-[#eee] rounded-lg p-2 text-lg w-full" />
                  </div>
                  <div className="flex-1">
                    <div className="text-konagray/80 font-semibold">CATEGORY</div>
                    <select 
                      className="border-2 border-[#eee] rounded-lg p-2.5 text-lg w-full"
                      value={category} 
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {Object.entries(NEWS_CATEGORY_KR_MAP).map(([key, val]) => {
                        return (
                          <option key={key} value={key}>{val}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="mt-5">
                  <div className="text-konagray/80 font-semibold">URL</div>
                  <input ref={urlRef} type="text" className="border-2 border-[#eee] rounded-lg p-2 text-lg w-full" />
                </div>
                <div className="py-5 flex justify-center">
                  <button onClick={submitForm} className="ripple-primary text-white p-3 min-w-[200px] rounded-lg flex justify-center space-x-2" disabled={isLoading || isSuccess}>
                        {(isSuccess) && <svg className="checkmarkSmallWhite h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmarkSmallWhite__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>}
                        {(isLoading && !isSuccess) && 
                          <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        }
                        <span>UPDATE</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UpdatePopulationNews