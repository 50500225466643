import React from 'react'
import { useLocation } from "react-router-dom"
import { API_BASE_URL, TOS_POSITION } from "../../utils/constants"
import { getBillingFrequencyKorean } from "../../utils/formatter"
import { bearerAuth } from "../../utils/helpers"
import { useGlobalStateContext } from "./GlobalContext"


const PaymentContext = React.createContext()

export const PRODUCT_LIST_MAPPER = {
  1: {description: "부동산 가격 예측 시뮬레이션과 사용자가 경제 변수 데이터의 미래 값을 입력 후 시뮬레이션 하여 부동산 가격을 예측합니다."},
  2: {description: "부동산 가격 예측 시뮬레이션과 사용자가 경제 변수 데이터의 미래 값을 입력 후 시뮬레이션 하여 부동산 가격을 예측합니다."},
  3: {description: "부동산 가격 예측 시뮬레이션과 사용자가 경제 변수 데이터의 미래 값을 입력 후 시뮬레이션 하여 부동산 가격을 예측합니다."},
  4: {description: "부동산 가격 예측 시뮬레이션과 사용자가 경제 변수 데이터의 미래 값을 입력 후 시뮬레이션 하여 부동산 가격을 예측합니다."},
  5: {description: "부동산 가격 예측 서비스와 인구 예측 서비스가 제공하는 모든 정보의 열람과 시뮬레이션을 이용하여 미래 정보를 예측할 수 있습니다."},
  6: {description: "부동산 가격 예측 서비스와 인구 예측 서비스가 제공하는 모든 정보의 열람과 시뮬레이션을 이용하여 미래 정보를 예측할 수 있습니다."},
  7: {description: "부동산 가격 예측 서비스와 인구 예측 서비스가 제공하는 모든 정보의 열람과 시뮬레이션을 이용하여 미래 정보를 예측할 수 있습니다."},
  8: {description: "부동산 가격 예측 서비스와 인구 예측 서비스가 제공하는 모든 정보의 열람과 시뮬레이션을 이용하여 미래 정보를 예측할 수 있습니다."}
}


export const PAYMENT_PAGES = {
  PAYMENT_DETAILS: "payment-details",
  BANK_REGISTRATION: "bank-registration",
  OAPI_TERMS: "oapi-terms",
  OAPI_REGISTER: "oapi-register"
}

export const ACTION = {
  SET_FRAME_VIEW: "set-frame-view",
  SET_PAYMENT_INFO: "set-payment-info",
  SET_SELECTED_PRODUCT: "set-selected-product",
  SET_TERMS_LOADING: "set-terms-loading",
  SET_TERMS_AGREED: "set-terms-agreed",
  SET_BANK_ACCOUNT: "set-bank-account",
  SELECT_PRODUCT: "select-product",
  SET_PAYMENT_LOADING: "set-payment-loading",
  PAYMENT_SUCCESS: "payment-success",
  OAPI_REGISTER_SUCCESS: "oapi-register-success"
}

export const initialValue = {
  selectedProdId: null,
  prevPath: "/",
  userInfo: null,
  frameView: PAYMENT_PAGES.PAYMENT_DETAILS,
  termsFormLoading: false,
  termsList: [],
  termsAgreed: [],
  bankList: null,
  productList: null,
  userSubscribedServices: [],
  paymentLoading: false,
  paymentSuccess: false,
  paymentInfo: null
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_SELECTED_PRODUCT:
      return {...state, selectedProdId: payload};
    case ACTION.SET_FRAME_VIEW:
      return {...state, frameView: payload};
    case ACTION.SET_PAYMENT_INFO:
      //sets selected product and userInfo
      //sets bankList
      return {
        ...state, 
        userInfo: {...payload.userInfo},
        selectedProdId: payload.selectedProdId,
        prevPath: payload.prevPath,
        termsList: payload.termsList,
        bankList: payload.bankList,
        productList: payload.productList,
        userSubscribedServices: payload.subscribedServices
      }
    case ACTION.SET_TERMS_LOADING:
      return {...state, termsFormLoading: true};
    case ACTION.SET_TERMS_AGREED: {

      return {
        ...state,
        termsList: payload.termsList,
        termsAgreed: payload.termsAgreed,
        termsFormLoading: false,
        frameView: payload.frameView  //needed because one scenario when new TOS was added and redirection is needed to payment information page
      }
    }
    case ACTION.SET_BANK_ACCOUNT:{
      let userInfo = state.userInfo;
      userInfo.oapiInfo.bank_account = payload.bank_account;
      userInfo.oapiInfo.bank_code = payload.bank_code;
      return {...state, userInfo, arsLoading: false };
    }
    case ACTION.SELECT_PRODUCT:
      return {...state, selectedProdId: payload};
    case ACTION.SET_PAYMENT_LOADING:
      return {...state, paymentLoading: payload};
    case ACTION.PAYMENT_SUCCESS:
      return {
        ...initialValue, 
        userInfo: state.userInfo, 
        productList: state.productList,
        paymentSuccess: true, 
        paymentLoading: false,
        paymentInfo: payload
      };
    case ACTION.OAPI_REGISTER_SUCCESS:
      return {
        ...state,
        userInfo:{...state.user_info, oapiInfo: payload.oapiInfo},
        frameView: payload.frameView
      }
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}


export const loadTerms = async (user) => {
  try {
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const query = `?position_type=${TOS_POSITION.PAYMENT_OPENAPI}&is_active=True`
    const response = await fetch(`${API_BASE_URL}/api/users/terms${query}`,requestOptions);
    const terms = await response.json();

    let termsList = [];
    if(response.ok && terms.length > 0){
      termsList = terms.sort((a, b) => a.order - b.order);
    }

    return termsList;

  } catch (err) {
    console.error(err.message)
  }
}

export const getOApiInfo = async (user) => {
  try {
    
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const response = await fetch(`${API_BASE_URL}/api/payment/userinfo`,requestOptions);
    const oapiInfo = await response.json();
    
    if(!response.ok){
      throw new Error(`Failed to fetch oapiInfo`);
    }
    return oapiInfo;
  
  } catch (err) {
    console.error(err.message)
  }
}


export const getBankList = async (user) => {
  try {
    
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const query = `?page_size=all`;
    const response = await fetch(`${API_BASE_URL}/api/bankcode${query}`,requestOptions);
    const bankList = await response.json();
    
    if(response.ok && bankList.total_count > 0)
    {
      return bankList.data;
    }


    throw new Error(`Failed to fetch bankList`);
    
  
  } catch (err) {
    console.error(err.message)
  }
}


export const mergeTerms = (baseTerms, addTerms, source) => {

  /* terms format
  {
    tid: 1,
    is_agree: false,
    is_mandatory: true,
    order: 1,
    title: "OPEN BANKING",
    url: "http://konasd.com",
    source: "KONASD"
  }
  */

  const maxOrder = baseTerms.length > 0 ? baseTerms.map(terms => terms.order).reduce((a, b) => Math.max(a, b), -Infinity) : 0;

  var newTerms = [];
  if (source === "KONASD"){
    //sort incoming first
    addTerms = addTerms.sort((a, b) => a.order - b.order);

    newTerms = addTerms.map(terms => ({
      tid: terms.id,
      is_agree: terms.is_agree,
      is_mandatory: terms.is_mandatory,
      order: terms.order + maxOrder,
      title: terms.title,
      url: terms.url,
      source: source
    }))


  }
  else if(source === "OPENAPI"){
    //sort incoming first
    addTerms = addTerms.sort((a, b) => a.order - b.order);

    newTerms = addTerms.map(terms => ({
      tid: terms.tcId,
      is_agree: terms.isAgree,
      is_mandatory: terms.required === "REQUIRED" ? true : false,
      order: parseInt(terms.order) + maxOrder,
      title: terms.title,
      url: terms.url,
      source: source
    }))
  }


  return [...baseTerms, ...newTerms];


}

export const PaymentProvider = ({children}) => {
  const {user} = useGlobalStateContext();
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const location = useLocation();

  

  

  const getProductList = async () => {
    try {
      
      const requestOptions = {
        method: 'GET',
        headers: {"Authorization" : bearerAuth(user)} 
      };
      const query = `?page_size=all`;
      const response = await fetch(`${API_BASE_URL}/api/product${query}`,requestOptions);
      const productList = await response.json();
      
      if(response.ok && productList.total_count > 0)
      {
        return productList.data;
      }


      throw new Error(`Failed to fetch productList`);
      
    
    } catch (err) {
      console.error(err.message)
    }
  }

  const getUserActiveSubscriptions = async () => {
    try {
      
      const requestOptions = {
        method: 'GET',
        headers: {"Authorization" : bearerAuth(user)} 
      };
      const query = `?page_size=all&unsubscribe_date__isnull=true&expand=product`;
      const response = await fetch(`${API_BASE_URL}/api/subscription/user${query}`,requestOptions);
      const subscriptions = await response.json();
      
      if(response.ok)
      {
        return subscriptions.data;  //also return empty array if there are no subscription
      }


      throw new Error(`Failed to fetch user subscriptions`);
      
    
    } catch (err) {
      console.error(err.message)
    }
  }

  
  
  React.useEffect(() => {

    const loadUserInfo = async () => {
      const pid = location?.state?.pid || 1;
      const prevPath = location?.state?.prevPath || "/"
      try {

        let [oapiInfo, bankList, productList, subscriptions, termsListKonaSD] = await Promise.all([
          getOApiInfo(user),
          getBankList(user),
          getProductList(),
          getUserActiveSubscriptions(),
          loadTerms(user),
        ]);

        
        //TOOD: fix error when doing promise all with more than two functions
        // let oapiInfo = await getOApiInfo();
        // let bankList = await getBankList();
        // let productList = await getProductList();
        // let subscriptions = await getUserActiveSubscriptions();
        productList = Object.fromEntries(productList.map(item => 
          [
            item.id, 
            {
              ...item, 
              description: PRODUCT_LIST_MAPPER[item.id].description,
              isRecurring: item.term > 0,
              term: getBillingFrequencyKorean(item.term),
              price: `₩${item.price.toLocaleString()}`
            }
          ]
        ));
        bankList = Object.fromEntries(bankList.map(item => [item.bank_code, item.bank_name]));
        //get user subscribed services (only product with terms >= 1)
        const subscribedServices = Object.fromEntries(
          subscriptions
          .map(subs => [subs.product.service_id, productList[subs.product.id]])
        );


        //merge terms from KONASD and OPENAPI
        let termsList = mergeTerms([], termsListKonaSD, "KONASD");
        termsList = mergeTerms(termsList, oapiInfo.terms_list, "OPENAPI");

        dispatch({
          type: ACTION.SET_PAYMENT_INFO, 
          payload: {
            userInfo:{...user.user_info, oapiInfo}, 
            selectedProdId: pid, 
            prevPath: prevPath,
            termsList,
            bankList, 
            productList,
            subscribedServices
          }
        })



  
      } catch (err) {
        console.error(err.message)
      }
    }

    //load userInfo (bank_code, bank_account, oapi_terms)
    loadUserInfo();

  },[]);

  const value = {
    paymentState: state,
    paymentDispatch: dispatch,
  }
  
  return (
    <PaymentContext.Provider value={value}>
      {children}
    </PaymentContext.Provider>
  )
}

export default PaymentContext