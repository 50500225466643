import React from 'react'
import { getNewsListApi, getTotalNewsItemsApi, lNewsItemsApi, NEWS_GROUP } from "../../utils/api/referenceApi";
import ReferenceHeader from "./ReferenceHeader"
import dayjs from "dayjs";
import { MagnifyingGlassIcon, InboxIcon, PlusIcon, ChevronDownIcon, PlayIcon} from "@heroicons/react/24/solid";
import { ChatBubbleOvalLeftEllipsisIcon} from "@heroicons/react/24/outline";
import Loader3 from "../../components/loaders/Loader3";
import { useDebounce } from "@toss/react";
import { Menu, Transition } from '@headlessui/react'
import { NavLink, useNavigate } from "react-router-dom";
import { useGlobalStateContext } from "../../components/context/GlobalContext";
import { ACCOUNT_ROLE } from "../../utils/constants";
import { TablePagination } from "./ReferencePagination";
const PAGE = 1;
const PAGE_SIZE = 10;

export const ACTION = {
	SET_NEWS_LIST: "set-news-list",
	SET_LOADING: "set-loading",
  SET_PAGE_NUM: "set-page-num",
  SET_CATEGORY: "set-category"
}

const initialState = {
	newsList: [],
  pageNum: 1,
	isLoading: true,
  totalItems: 0,
  selectedCategory: null,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_NEWS_LIST:
			return {
				...state,
        newsList: payload.newsList,
        totalItems: payload.totalItems,
        pageNum: payload.pageNum,
				isLoading: false
			}
    case ACTION.SET_PAGE_NUM:
      return {...state, pageNum: payload};
    case ACTION.SET_LOADING:
      return {...state, isLoading: payload}
    case ACTION.SET_CATEGORY:
      return {...state, selectedCategory: payload}
    
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}

const GROUP = NEWS_GROUP.POPULATION;

export const NEWS_CATEGORY_KR_MAP = {
  COLUMN : "칼럼",
  INTERVIEW : "인터뷰",
  ARTICLE : "기사",
}

const PopulationNews = () => {
  const navigate = useNavigate();
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const refSearchField = React.useRef();
  const { user } = useGlobalStateContext();

  const onSearchChange = useDebounce(async () => {
    dispatch({type: ACTION.SET_LOADING, payload: true});
    const searchText = refSearchField.current.value;

    const [newsList, totalItems] = await Promise.all([
      getNewsListApi(GROUP, state.selectedCategory, PAGE, PAGE_SIZE, searchText),
      getTotalNewsItemsApi(GROUP, state.selectedCategory, searchText),
    ]);


    dispatch({
      type: ACTION.SET_NEWS_LIST, 
      payload: {
        newsList: newsList, 
        totalItems: totalItems,
        pageNum: 1
      }
    });

  }, 500);

	const loadDefault = React.useCallback(async () => {

    const [newsList, totalItems] = await Promise.all([
      getNewsListApi(GROUP, null, PAGE, PAGE_SIZE, null),
      getTotalNewsItemsApi(GROUP, null, null),
    ]);

    dispatch({
      type: ACTION.SET_NEWS_LIST, 
      payload: {
        newsList: newsList, 
        totalItems: totalItems,
        pageNum: state.pageNum
      }
    });

	}, [])


  React.useEffect(() => {
		loadDefault();
	},[]);

  function gotoPostDetails(id) {
    navigate("/reference/population-news/" + id);
  }

  async function handlePageNumClick(pageNum) {
    dispatch({type: ACTION.SET_PAGE_NUM, payload: pageNum});    
    dispatch({type: ACTION.SET_LOADING, payload: true});
    const searchText = refSearchField.current.value;
    const [newsList] = await Promise.all([
      getNewsListApi(GROUP, null, pageNum, PAGE_SIZE, searchText),
    ]);

    dispatch({
      type: ACTION.SET_NEWS_LIST, 
      payload: {
        newsList: newsList, 
        totalItems: state.totalItems,
        pageNum: pageNum
      }
    });
  } 

  async function handleCategorySelect(category) {
    dispatch({type: ACTION.SET_CATEGORY, payload: category});    
    dispatch({type: ACTION.SET_LOADING, payload: true});
    const searchText = refSearchField.current.value;
    const [newsList, totalItems] = await Promise.all([
      getNewsListApi(GROUP, category, PAGE, PAGE_SIZE, searchText),
      getTotalNewsItemsApi(GROUP, category, searchText),
    ]);

    dispatch({
      type: ACTION.SET_NEWS_LIST, 
      payload: {
        newsList: newsList, 
        totalItems: totalItems,
        pageNum: 1
      }
    });
  }

  function PopulationNewsTable({isLoading, newsList}) {
    return (
      <table className="w-full text-left text-gray-500 table-fixed text-base pb-5">
        <thead className="text-sm uppercase bg-gray-100">
          <tr className="[&>*]:p-4 [&>*]:font-semibold text-center ">
            <th className="w-[8%]">번호</th>
            <th className="w-[10%]"><CategoryMenu category={state.selectedCategory} clickCallback={handleCategorySelect} /></th>
            <th className="">제목</th>
            <th className="w-[15%]">글쓴이</th>
            <th className="w-[15%]">날짜</th>
            <th className="w-[10%]">조회수</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && <ResultLoading colSpan={6} />}
          {(!isLoading && newsList.length <= 0 && refSearchField.current.value !== "") && 
            <tr>
              <td colSpan={6}>
                <div className="flex flex-col space-y-4 items-center justify-center py-10 text-konagray/50">
                  <MagnifyingGlassIcon  className="h-10"/>
                  <span className="text-xl">검색결과가 없습니다</span>
                </div>
              </td>
            </tr>
          }
          {(!isLoading && newsList.length <= 0 && refSearchField.current.value === "") && 
            <tr>
              <td colSpan={6}>
                <div className="flex flex-col space-y-4 items-center justify-center py-10 text-konagray/50">
                  <InboxIcon  className="h-10"/>
                  <span className="text-xl">게시물이 없습니다.</span>
                </div>
              </td>
            </tr>
          }
          {(!isLoading && newsList.length > 0) && 
            newsList.map(item => {
              const today = dayjs();
              const targetDate = dayjs(item.created_at);
              const fiveDaysAgo = today.subtract(5, 'day'); 

              return (
                <tr key={item.id} onClick={() => gotoPostDetails(item.id)} className="[&>*]:p-4 text-sm text-center align-middle border-b font-normal hover:bg-gray-50/50 cursor-pointer">
                  <td className="">{item.id}</td>
                  <td className="">{NEWS_CATEGORY_KR_MAP[item.category]}</td>
                  <td className="text-left font-semibold flex space-x-2 items-center">
                    <span>{item.title}</span>
                    {targetDate.isAfter(fiveDaysAgo) && <span className="animate-pulse bg-konared text-white text-[10px] leading-none font-thin py-0.5 px-1.5 rounded-full ">NEW</span>}
                    {item.comments > 0 && <div className="flex space-x-1 text-konagray/40"><ChatBubbleOvalLeftEllipsisIcon className="h-4" /><span className="text-xs font-light">{item.comments}</span></div>}
                  </td>
                  <td className="">{item.user?.name}</td>
                  <td className="">{targetDate.format("YYYY-MM-DD")}</td>
                  <td className="">{item.views}</td>
                </tr>
              )
            })
          }

        </tbody>
      </table>
    )
  }

  function ResultLoading({colSpan}) {
    return (
      <tr>
        <td colSpan={colSpan} className="border-b py-20"><Loader3 /></td>
      </tr>
    )
  }

  function AdminPanel () {
    return (
      <div className="mt-10 flex justify-center">
        <NavLink to="/reference/population-news/create" className="flex space-x-1 items-center rounded-lg p-3 min-w-[200px] justify-center ripple-primary text-white"><PlusIcon className="h-6" /><span>ADD NEW ITEM</span></NavLink>
      </div>
    )
  }

  return (
    <div className="flex-1 flex flex-col pb-10">
      <ReferenceHeader>인구뉴스</ReferenceHeader>
      <div className="flex justify-center">
        <div className="flex-1 max-w-[1280px] p-10">
          <div className="flex pb-10">
            <div className="flex-1 flex justify-center">
              <div className="relative w-full max-w-lg">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
                <input 
                  ref={refSearchField} 
                  type="text" 
                  className="block p-2 pl-10 w-full text-lg text-konagray/80 font-light rounded-full border outline-none focus:duration-300 focus:ring-2 shadow-sm focus:ring-konadanger focus:ring-opacity-50 focus:placeholder-red-400" 
                  onChange={() => onSearchChange()} 
                  
                />
              </div>
            </div>
          </div>
          <PopulationNewsTable isLoading={state.isLoading} newsList={state.newsList} />
          <div className="flex justify-center p-5"><TablePagination totalItems={state.totalItems} pageSize={PAGE_SIZE} pageNum={state.pageNum} clickCallback={handlePageNumClick} /></div>

          {/* for admin */}
          {(user && user?.user_info?.role === ACCOUNT_ROLE.ADMIN) && <AdminPanel />}
        </div>
      </div>
    </div>
    
  )
}


function CategoryMenu ({category, clickCallback}) {
  const btnText = category === null ? "카테고리" : NEWS_CATEGORY_KR_MAP[category];
  return (
    <Menu as="div" className="relative inline-block">
      <Menu.Button className="group flex items-center space-x-1"><span>{btnText}</span><PlayIcon className="h-3 rotate-90" /></Menu.Button>
      <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
      >
      <Menu.Items className="px-1 py-1 absolute left-0 mt-2 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
          {Object.entries(NEWS_CATEGORY_KR_MAP).map(([key, val]) => {
            return (
              <Menu.Item key={key}>
                {({ active }) => (
                  <button 
                    className={`${active ? 'bg-konared text-white' : 'text-konagray'} group flex space-x-2 rounded-md cursor-pointer items-center w-full p-2 text-xs font-semibold`}
                    onClick ={() => clickCallback(key)}
                  >
                    <span>{val}</span>
                  </button>
                )}
              </Menu.Item>
            )
          })}
          
      </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default PopulationNews