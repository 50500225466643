import React from 'react'
import { NavLink } from "react-router-dom";
import PaymentContext, {ACTION as PAYMENT_ACTION} from "../context/PaymentProvider";
import BankInfo from "./BankInfo"
import ProductInfo from "./ProductInfo"
import { ArrowLongRightIcon } from '@heroicons/react/24/outline'
import { bearerAuth, InvalidRequestBody, ResponseError } from "../../utils/helpers";
import { API_BASE_URL } from "../../utils/constants";
import { useGlobalStateContext } from "../context/GlobalContext";

const PaymentDetails = () => {
  const {user} = useGlobalStateContext();
  const {paymentState, paymentDispatch} = React.useContext(PaymentContext);
  const [error, setError] = React.useState(null)
  const selectedProductSvcId = paymentState.productList[paymentState.selectedProdId].service_id;
  


  const handleConfirmPayment = async (productId) => {
    paymentDispatch({type: PAYMENT_ACTION.SET_PAYMENT_LOADING, payload: true});
    setError(null)
    try{
      
      if(paymentState.userInfo.oapiInfo.bank_account === null){
        throw new Error("Please register a bank account.")
      }   

      if(paymentState.selectedProdId === null ){
        throw new Error("Please select a subscription.")
      }
      
      const requestOptions = {
        method: 'POST',
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
        body: JSON.stringify({user_id: user.user_info.id, product_id: productId })
      };

      const response = await fetch(`${API_BASE_URL}/api/payment/checkout`,requestOptions);
      const paymentInfo = await response.json();

      if ([400,403,404].includes(response.status)) throw new ResponseError(paymentInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(paymentInfo.detail);
      if(!response.ok) throw new Error("Error in fetch request");



      //call dispatch when payment is successful
      paymentDispatch({type: PAYMENT_ACTION.PAYMENT_SUCCESS, payload: paymentInfo})

    } catch (err) {
      if (err instanceof ResponseError) {
        console.error(err)
        setError(err.message);
      } else if (err instanceof InvalidRequestBody) {
        console.error(err)
        setError("There was a problem sending your payment request.");
      } else {
        console.error(err)
        setError(err.message);
      }
    }
    paymentDispatch({type: PAYMENT_ACTION.SET_PAYMENT_LOADING, payload: false});

  }


  return (
    <div>
      {(() => {
        if(Object.entries(paymentState.userSubscribedServices).map(([key, value]) => value.service_id).includes(paymentState.productList[paymentState.selectedProdId].service_id)){
          const subscribedProduct = paymentState.userSubscribedServices[selectedProductSvcId];
          return(
            <div className="flex flex-col items-center">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#FA6161" stopOpacity="100%" />
                    <stop offset="100%" stopColor="#F7CD1C" stopOpacity="100%" />
                </linearGradient>
                <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
              </svg>

              <div className="font-bold text-2xl">Subscription Exists</div>
              <div className="mt-4 text-base">You have an active subscription for the selected service.</div>
              <div className="text-base">Please cancel your current subscription to purchase a different subsciption type.</div>
              <div className="bg-konagray/10 w-2/3 flex mt-12 items-center py-4 px-8 rounded-xl ">
                <div className="flex flex-col flex-1">
                  <span className="text-xs">Subscription</span>
                  <span className="text-xl font-bold">{subscribedProduct.name}</span>
                </div>
                <div className="text-base">{subscribedProduct.price} {subscribedProduct.isRecurring && "/ " + subscribedProduct.term[0]}</div>
              </div>
              <div className="mt-4 flex w-2/3 justify-end"><NavLink to="/mypage/billing"  className="text-xs hover:underline underline-offset-4 flex items-center">Go To Billing Page {<ArrowLongRightIcon className="ml-1 h-4"/>}</NavLink></div>
              <div><NavLink to={paymentState.prevPath} state={{scrollTo: "payment"}}  className="mt-10 inline-flex items-center justify-center px-8 leading-5 rounded py-3 text-base ripple-gray">Cancel</NavLink></div>
            </div>
          )
        }
        else{
          return (
            <>
              <BankInfo />
              <ProductInfo />
              <div className="flex flex-col space-y-2 py-10 items-center">
                {error && <p className="py-2 text-xs font-semibold text-konared">{error}</p>}


                <button  onClick={() => handleConfirmPayment(paymentState.selectedProdId)}  className="bg-konared text-white inline-flex items-center justify-center w-48 leading-5 rounded py-4 ripple-primary" disabled={paymentState.paymentLoading}>
                  {paymentState.paymentLoading && 
                    <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                  }
                  <span>결제하기</span>
                </button>

                {!paymentState.paymentLoading && <NavLink to={paymentState.prevPath} state={{scrollTo: "payment"}}  className="text-sm hover:underline underline-offset-4">Cancel</NavLink>}


                
              </div>
            </>
          )
        }
      })()}
        
      
    </div>
  )
}

export default PaymentDetails