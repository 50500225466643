import React from 'react'
import PaymentContext from "../context/PaymentProvider";

const ProductInfo = () => {
  const {paymentState} = React.useContext(PaymentContext);
  const {name, isRecurring, term, price, description} = paymentState.productList[paymentState.selectedProdId];

  return (
    <>
      <div className="flex flex-col p-4 border-b">
        <div className="flex items-center">
          <div className="flex-1 flex flex-col">
            <div className="text-lg font-bold">{name}</div>
            <div className="text-base text-konasub/70">{isRecurring && term[1]}</div>
          </div>
          <div className="text-lg font-bold text-konared">{price} / {term[0]}</div>
        </div>

      </div>

      <div className="p-4 text-sm text-konasub/50"><div dangerouslySetInnerHTML={{ __html: description }} /></div>
    </>
  )
}

export default ProductInfo