import React from 'react'
import "../../assets/css/withcheck-loader.css"
import "../../assets/css/loaders.css"

function Loader2WithSuccess({success = false}) {
  return (
    <div className="flex-1 flex items-center justify-center">
      {success
      ? <svg className="checkmark h-20 w-20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
      : <span className="loader2white w-20 h-20"></span>

      }
    </div>
  )
}

export default Loader2WithSuccess