import { ArrowLongRightIcon } from "@heroicons/react/24/outline"
import React from 'react'
import { NavLink } from "react-router-dom"
import { getBillingFrequencyKorean } from "../../../utils/formatter";
import { bearerAuth } from "../../../utils/helpers";
import Moment from 'moment';
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";


export const ACTION = {
  SET_PAGE_LOAD: "set-page-load",
  OPEN_UNSUB_MODAL: "open-unsub-modal",
  CLOSE_MODAL: "close-modal",
  UNSUB_START: "unsub-start",
  UNSUB_SUCCESS: "unsub-success",
  SET_SUBLIST_SUCCESS: "set-sublist-success",
}

export const initialValue = {
  pageLoading: true,
  unsubModalOpen: false,
  unsubInfo: null,
  unsubLoading: false,
  unsubSuccess: false,
  subList: null,
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.SET_PAGE_LOAD:
      return {...state, pageLoading: payload};
    case ACTION.OPEN_UNSUB_MODAL:
      return {...state, unsubModalOpen: true, unsubSuccess: false, unsubInfo: payload};
    case ACTION.CLOSE_MODAL:
      return {...state, unsubModalOpen: false};
    case ACTION.SET_SUBLIST_SUCCESS:
      return {...state, subList: payload, pageLoading: false};
    case ACTION.UNSUB_START:
      return {...state, unsubLoading: true};
    case ACTION.UNSUB_SUCCESS:
      return {...state, unsubLoading: false, unsubSuccess: true};
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}


const getUserActiveSubscriptions = async (user) => {
  try {
    
    const requestOptions = {
      method: 'GET',
      headers: {"Authorization" : bearerAuth(user)} 
    };
    const query = `?page_size=all&unsubscribe_date__isnull=true&expand=product`;
    const response = await fetch(`${API_BASE_URL}/api/subscription/user${query}`,requestOptions);
    const subscriptions = await response.json();

    if(!response.ok) throw new Error(`Failed to fetch user subscriptions`);

    return subscriptions.data;
  
  } catch (err) {
    console.error(err.message)
  }
}

const BillingSubscriptions = () => {
  const {user} = useGlobalStateContext();
  const [state, dispatch] = React.useReducer(reducer, initialValue);


  const loadDefault = React.useCallback(async () => {
    const subscriptions = await getUserActiveSubscriptions(user);
    dispatch({type: ACTION.SET_SUBLIST_SUCCESS, payload: subscriptions});   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  React.useEffect(() => {
    loadDefault();
  },[loadDefault]);



  const handleUnsubscribeSuccess = async () => {
    dispatch({type: ACTION.CLOSE_MODAL});
    //TODO: refractor later , combile with getUserActiveSubscriptions
    //!IMPORTANT, handle setSubs , they are different condition with  getUserActiveSubscriptions
    
    const subscriptions = await getUserActiveSubscriptions(user);
    dispatch({type: ACTION.SET_SUBLIST_SUCCESS, payload: subscriptions});   
  }
  

  return (
    <div className="py-4 border-b">
      <CancelSubscriptionModal subState={state} subDispatch={dispatch} onSuccess={handleUnsubscribeSuccess} />
      <div className="text-lg font-semibold pb-2">Current Subscriptions</div>
      {(() => {
         if(state.pageLoading){
          return (
            <div className="animate-pulse flex space-x-4">
            <div className="mt-4 border border-konagray/20 w-2/3 flex space-x-4 items-center py-4 px-8 rounded-xl ">
              <div className="flex flex-col flex-1 space-y-2">
                <div className="h-4 bg-slate-200 rounded w-2/3"></div>
                <div className="h-4 bg-slate-200 rounded w-1/3"></div>
                
              </div>
              <div className="flex flex-col space-y-1 items-center">
                <div className="w-32 bg-slate-200 h-8 rounded"></div>
                <div className="w-28 bg-slate-200 h-2 rounded"></div>
              </div>
            </div>
            </div>
          )
        }
        if(!state.pageLoading && (state.subList && state.subList.length > 0)){
          return (
            <div>
            {state.subList.map((item, index) => 
              <div key={index} className="my-4 border border-konagray/40 max-w-[900px] flex space-x-4 items-center py-4 px-8 rounded-xl ">
                <div className="flex flex-col flex-1">
                  <div className="text-xl font-bold">{item.product.name}</div>
                  <div className="text-base text-konasub/60">{`₩${item.product.price.toLocaleString()}`} {item.product.term > 0 && "/ " +   getBillingFrequencyKorean(item.product.term)[0] }</div>
                  
                </div>
                <div className="flex flex-col items-center space-y-2">
                  <button onClick={() => dispatch({type: ACTION.OPEN_UNSUB_MODAL, payload: item})} className="border border-konagray/40 text-konagray rounded p-2 text-sm hover:bg-konared hover:text-white hover:border-konared">Unsubscribe</button>
                  <div className="text-xs">Next Billing: <span className="">{Moment(item.next_billing_date).format("YYYY/MM/DD")}</span></div>
                </div>

              </div>)
            }
            </div>
          )
        }
        else{
          return (
            <div>
              <div>You currently have no active subscriptions.</div>
              <div className=""><NavLink to="/re-about" state={{scrollTo: "payment"}}  className="text-konared hover:underline underline-offset-4 flex items-center">View Plans and Upgrade {<ArrowLongRightIcon className="ml-1 h-4"/>}</NavLink></div>
            </div>
          )
        }
      })()}
      
    </div>
  )
}

export default BillingSubscriptions