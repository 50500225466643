import React from 'react'
import { Outlet } from "react-router-dom"
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { 
  getDefaultAddressInfo, 
  getRealEstateModelBaseDataObj, 
  getRealEstateModelVariablesObj, 
  getRealEstateSubscript, 
  RE_ACTION, 
  useReDispatchContext, 
  useReStateContext } from "../../components/context/RealEstateContext";
import { getDefaultRealEstateModelApi } from "../../utils/api/realestateApi";
import { ApiError, AppError, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";


export default function RealEstate() {
  const {user} = useGlobalStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const reState = useReStateContext();
  const reDispatch = useReDispatchContext(); 

  const loadDefault = React.useCallback(async () => {

    if((isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds)) && user){
    // if((isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds) || (reState.currentModelId && reState.model.allIds.includes(reState.currentModelId ))) && user){

      try {
        const [defaultModel, defaultAddress] = await Promise.all([
          getDefaultRealEstateModelApi(user),
          getDefaultAddressInfo(user)
        ]);
    
    
        const [modelVariables, modelBaseData] = await Promise.all([
          getRealEstateModelVariablesObj(defaultModel.id, defaultAddress.locInfo.location_code, user),
          getRealEstateModelBaseDataObj(defaultModel.id, defaultAddress.locInfo.location_code, user)
        ]);
    
    
        const modelData = {
          locationCode: defaultAddress.locInfo.location_code,
          variable: modelVariables,
          baseData: modelBaseData,
          subscript: getRealEstateSubscript(modelVariables)
        }

        reDispatch({
          type: RE_ACTION.SET_CURRENT, 
          payload: {modelInfo: defaultModel, modelData: modelData, addressInfo: defaultAddress},
        })
    
      } catch (err) {
        if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
          const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
          globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
        }
        else{
          console.error(err)
        }
      }
      
    }
  }, [user, reState])

  React.useEffect(() => {
    loadDefault();
  },[loadDefault]);

  return (
    <Outlet />
  )
}

