import React, { useReducer, useEffect, useState } from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import { CardHeader, CardWrapper, CardContent, CardHeaderFilter } from '../../components/population/CardWrapper'
import * as echarts from 'echarts'
import { getOptionForSidoMap, getOptionForSGGMap } from '../../components/charts/PopulationEchartOptions'

import PopulationEcharts from '../../components/charts/PopulationEcharts'
import DotLoader from '../../components/loaders/DotLoader'
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal'
import { handleError } from '../../utils/pop-handle-response'




const mapNames = {
	"부산광역시": "busan",
	"대구광역시": "daegu",
	"인천광역시": "incheon",
	"광주광역시": "gwangju",
	"대전광역시": "daejeon",
	"울산광역시": "ulsan",
	"제주특별자치도": "jeju",
	"강원도": "gangwondo",
	"충청북도": "chungbuk",
	"충청남도": "chungnam",
	"경상북도": "gyeongbuk",
	"경기도": "gyeonggi",
	"경상남도": "gyeongnam",
	"전라북도": "jeonbuk",
	"전라남도": "jeonnam",
	"세종특별자치시": "sejong",
	"서울특별시": "seoul",
}


const ACTION = {
	SET_INIT_DATA: 'set-init-data',
	SET_SIDO_CHANGE: 'set-sido-change',
	SET_SGG_CHART_LOADING: 'set-sgg-chart-loading'
}

const initialState = {
	sdData: null,
	sggData: null,
	sggDataFiltered: null,
	currentSD: "서울특별시",
	sidoList: null,
	initLoading: true,
	sggChartLoading: false
}


const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_INIT_DATA:
			return {
				...state,
				sdData: payload.sdData,
				sggData: payload.sggData,
				sggDataFiltered: payload.sggDataFiltered,
				sidoList: payload.sidoList,
				initLoading: false
			}
		case ACTION.SET_SIDO_CHANGE:
			return {
				...state,
				currentSD: payload.currentSD,
				sggDataFiltered: payload.sggDataFiltered,
				sggChartLoading: false
			}
		case ACTION.SET_SGG_CHART_LOADING:
			return {...state, sggChartLoading: true}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}


const getGeoJsonMap = async (filename) => {
  const response = await fetch(`maps/${filename}`, {
		headers: { "Content-Type": "application/json" },
	})
	
	//fetching in maps dir will always return response 200 
	//because wildcard was set in react router for invalid pages.
	//to handle invalid json filenames catch the json error and manually throw error 400
	try {
		return await response.json();
	}
	catch (err) {
		//throw error 400 for invalid fetch
		throw new Error(handleError(400))
	}
}

const getPopulationLocMap = async (loc_type) => {
  try {
    
    const query = `?page_size=all&loc_type=${loc_type}`
    const response = await fetch(`${API_BASE_URL}/api/population/location-map${query}`,{
			method: "GET",
			headers: {"Content-Type" : "application/json"}
		})

		if(response.ok){
			const locMap = await response.json();
			if (locMap?.data.length > 0) return locMap;
			throw new Error("Unable to fetch location map")
		}

		throw new Error(handleError(response.status));

  } catch (err) {
    throw(err)
  }
}

const getDataSource = async (varname) => {
  try {
    
    const query = `?page_size=all&name_en=${varname}`
    const response = await fetch(`${API_BASE_URL}/api/population/data-source${query}`, {
			method: "GET",
			headers: {"Content-Type" : "application/json"}
		})

		if(response.ok){
			const dataSource = await response.json();
			if (dataSource?.data.length > 0) return dataSource;
			throw new Error(`Unable to fetch data source. name=${varname}`)
		}

		throw new Error(handleError(response.status));

  } catch (err) {
    throw(err)
  }
}


function PopExtinction() {
	const [state, dispatch] = useReducer(reducer, initialState)
	const [isOpen, setIsOpen] = useState(false)
	const [isLoading, setLoading] = useState(false)

	useEffect(() => {

		const loadDefault = async () => {
			try {
					const [sdMap, sggMap, sdLocMap, movementData] = await Promise.all([
						getGeoJsonMap("sidoMap.json"),
						getGeoJsonMap(`${mapNames[state.currentSD]}.json`),
						getPopulationLocMap("sd"),
						getDataSource("extinction risk index")
					]);

					//register initial maps (sido & seoul)
					echarts.registerMap("sidoMap", sdMap)
					echarts.registerMap(mapNames[state.currentSD], sggMap)
		
					

					const sidoList = Object.fromEntries(sdLocMap.data.map(loc => [loc.name.trim(), loc.location_code.trim()]));
					const sdData = Object.fromEntries(movementData.data
						.filter(item => Object.values(sidoList).includes(item.loc_code))
						.map(i => [i.loc_code, {...i, value: Object.fromEntries(i.value)}])
					);

					
					//TODO do not load everything, 
					const sggData = Object.fromEntries(movementData.data
						.filter(item => !Object.values(sidoList).includes(item.loc_code))
						.map(i => [i.loc_code, {...i, value: Object.fromEntries(i.value)}])
					)

					const sggDataFiltered = Object.fromEntries(Object.entries(sggData).filter(([key, val]) => key.startsWith(sidoList[state.currentSD].substring(0, 2))));
					
					dispatch({
						type: ACTION.SET_INIT_DATA,
						payload: {
							sdData: sdData,
							sggData: sggData,
							sggDataFiltered: sggDataFiltered,
							sidoList: sidoList,
						}
					})	

			} catch (error) {
				setIsOpen(true)
				console.error(error.message)
				
			}
		
		}

		loadDefault();
	}, [])


	const onChartClick = async (params) => {
		if(params.componentSubType === 'map'){
			const selectedMapName = mapNames?.[params.name]
			if (!echarts.getMap(selectedMapName)) {
				dispatch({type: ACTION.SET_SGG_CHART_LOADING});
				const sggMap = await getGeoJsonMap(`${selectedMapName}.json`);
				echarts.registerMap(selectedMapName, sggMap)
			}

			//TODO: load corresponding sgg of selected SD here

			const sggDataFiltered = Object.fromEntries(Object.entries(state.sggData).filter(([key, val]) => key.startsWith(state.sidoList[params.name].substring(0, 2))));
			
			dispatch({
				type: ACTION.SET_SIDO_CHANGE,
				payload: {
					currentSD: params.name,
					sggDataFiltered: sggDataFiltered,
				}
			})
		}
		else{
			//TODO: handle error if mapname does not exists in mapper name object
		}

	}

	
	//generate SD map options only if sdData changes
	//generally this options is only loaded and set once during mount
	const sidoMapOptions = React.useMemo(() => {
		if(state.sdData){
			return getOptionForSidoMap(state.sdData, state.sidoList[state.currentSD], 'sidoMap')
		}

		return null;
  }, [state.sdData]); 


	return (
		<MainContentWrapper className="bg-konabg flex flex-col relative">
			<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
			{
				state.initLoading ?
					<DotLoader />
				:
				<div className="pt-10 pb-20 px-28  grid grid-cols-5 gap-6 overflow-hidden">
					<CardWrapper className="col-span-2 h-[650px]">
						<CardHeaderFilter title="지역선택" />
						<CardContent className="p-5 flex flex-col space-y-4" >
							<div className="flex-1 relative overflow-x-auto  overflow-y-hidden ">
								<div className="h-full w-full overflow-x-hidden">
								{
									state.sdData ?
										<PopulationEcharts options={sidoMapOptions} onEvents={onChartClick} />
									:
									<div className="h-full flex justify-center items-center"></div>
								}
								</div>
							</div>
						</CardContent>
					</CardWrapper>
					<CardWrapper className="col-span-3 overflow-x-auto">
						<CardHeader title={state.currentSD + ' 소멸위험지수'} />
						<CardContent className="p-5 flex flex-col space-y-4" >
							<div className="flex-1 relative overflow-x-auto  overflow-y-hidden ">
								<div className="h-full w-full overflow-x-hidden">
								{
									(state.sggDataFiltered && !state.sggChartLoading) ?
									<PopulationEcharts options={getOptionForSGGMap(state.sggDataFiltered, state.sidoList[state.currentSD], mapNames[state.currentSD])} />
									:
									<div className="h-full flex justify-center items-center"><DotLoader /></div>
								}
								</div>
							</div>
						</CardContent>
					</CardWrapper>
					<div className='col-span-5 flex flex-col h-fit bg-white rounded-lg shadow-md p-5 text-[12px]'>
						<div className='col-span-2 font-semibold text-base pb-2 h-fit'>
							소멸위험지수 = 65세 이상 고령인구 대비 20-39세 여성인구 비율
						</div>
						<div className='flex flex-row flex-wrap'>
							<div className='pr-4 flex flex-row h-6 space-x-2'>
								<div className='h-5 w-5 bg-[#43A047] rounded-sm '>
								</div>
								<div>초록색은 1.5 이상으로 소멸 저위험 지역</div>
							</div>
							<div className='pr-4 flex flex-row h-6 space-x-2'>
								<div className='h-5 w-5 bg-[#C0CA33] rounded-sm '>
								</div>
								<div>연두색은 1.0 이상 1.5 미만으로 소멸 위험 보통 지역</div>
							</div>
							<div className='pr-4 flex flex-row h-6 space-x-2'>
								<div className='h-5 w-5 bg-[#FBC02D] rounded-sm '>
								</div>
								<div>노란색은 0.5 이상 1.0 미만으로 소멸 위험 주의 지역</div>
							</div>
							<div className='pr-4 flex flex-row h-6 space-x-2'>
								<div className='h-5 w-5 bg-[#FB8C00] rounded-sm '>
								</div>
								<div>주황색은 0.2 이상 0.5 미만으로 소멸 위험 지역</div>
							</div>
							<div className='pr-4 flex flex-row h-6 space-x-2'>
								<div className='h-5 w-5 bg-[#F4511E] rounded-sm '>
								</div>
								<div>붉은색은 0.2 미만으로 소멸 고위험 지역</div>
							</div>
						</div>
					</div>
				</div>
			}
			
		</MainContentWrapper>
	)
}

export default PopExtinction