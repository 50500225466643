import React from 'react'
import { getChartOptionsKonaSim } from "../../components/charts/EchartOptions";
import Echarts from "../../components/charts/Echarts";
import { getKonaSimResults, useReStateContext } from "../../components/context/RealEstateContext";
import Loader3 from "../../components/loaders/Loader3";
import MainContentWrapper from "../../components/MainContentWrapper";
import EvaluationKonaSim from "../../components/realestate/EvaluationKonaSim";
import SearchBar from "../../components/realestate/SearchBar";
import SearchkakaoMap from "../../components/realestate/SearchKakaoMap";
import TableKonaSim from "../../components/realestate/TableKonaSim";
import { isEmpty } from "../../utils/helpers";



const RealEstateKonaSim = () => {
  const reState = useReStateContext();

  const konasimResult = React.useMemo(() => {
    let results = null;
    if(reState.currentAddressId){
      const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];
      results = getKonaSimResults(modelData.variable, modelData.baseData, reState.address.byId[reState.currentAddressId]);
    }

    return results;
  }, [reState.currentAddressId]); 


  return (
    <MainContentWrapper className="bg-konabg flex flex-col">
        {(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds))
          ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
          : <div>
              <div className="py-10 px-28">
                <div className="relative">
                  <div className="w-full absolute top-0 left-0 z-10 px-7 py-3">
                    <SearchBar />
                  </div>
                  <div className="rounded-xl overflow-hidden shadow-md">
                    <SearchkakaoMap location={reState.address.byId[reState.currentAddressId]} height="300px" zoomLvl={3} />
                  </div>
                </div>
              </div>

              <div className="px-28 pb-20">
                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0 overflow-hidden">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20 ">
                    <div className=" px-[32px] py-9">
                      <div className="text-3xl leading-none">부동산 가격 예측 결과</div>
                      <div className="h-[400px]">
                        {!isEmpty(konasimResult) && <Echarts options={getChartOptionsKonaSim(konasimResult)}  />}
                      </div>
                    
                    </div>
                  </div>
                </div>

                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0 overflow-hidden mt-10">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20 ">
                    <div className="px-[32px] py-9">
                      <div className="text-3xl leading-none">예측 결과 요약</div>

                      {!isEmpty(konasimResult) && <TableKonaSim konasimResult={konasimResult} /> }
                    </div>
                  </div>
                </div>
                <div className="min-h-[185px] bg-white  p-2 shadow-md relative z-0 overflow-hidden mt-10">
                  <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
                  <div className="bg-white relative z-20 ">
                    <div className="px-[32px] py-9">
                      <div className="text-3xl leading-none">KONA 분석 평가</div>
                      {!isEmpty(konasimResult) && <EvaluationKonaSim konasimResult={konasimResult} address={reState.address.byId[reState.currentAddressId]} /> }
                    </div>
                  </div>
                </div>

                <div className="pt-4">* 실제 가격은 해당 물건(주택)의 실거래 가격이 아닌, 지역별 매매가격지수를 기반으로 월 단위로 산정된 가격으로 실거래가격과 차이가 발생할 수 있음</div>

              </div>
            </div>
        }
    </MainContentWrapper>
  )
}

export default RealEstateKonaSim