import { Navigate, useLocation } from 'react-router-dom';
import RestrictedPage from "../layouts/PageRestricted";
import { ACCOUNT_ROLE } from "../utils/constants";
import { useGlobalStateContext } from "./context/GlobalContext";

const AdminRoute = ({Component}) => {
    const location = useLocation();
    const {user} = useGlobalStateContext();

    return user && user?.user_info?.role === ACCOUNT_ROLE.ADMIN ? <Component /> : <RestrictedPage />
}

export default AdminRoute