import { round as lround } from "lodash"
import { graphic, time } from "echarts"
import number_svg from "../../assets/images/chart/numbers.json"
import { data } from "autoprefixer"
import { commaizeNumber, floorToUnit, roundToUnit } from "@toss/utils"
import { calculateTicks, isEmpty } from "../../utils/helpers"

const emphasisStyle = {
  itemStyle: {
    shadowBlur: 10,
    shadowColor: "rgba(0,0,0,0.3)",
  },
}

const display = {
  "Population Data by age sex": {
    name: "과거 데이터",
    line_style: "solid",
  },
  "Population Model by age sex": {
    name: "SD 모델",
    line_style: "dashed",
  },
  "Population Forecast by age sex": {
    name: "정부 예측",
    line_style: "dotted",
  },
}

export const getChartOptionMovement = (data) => {
  const rawData = data.map(([x, y]) => [x, lround(y, 0)])

  const option = {
    animation: true,
    animationDuration: 50,

    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    grid: {
      show: true,
      left: "0%",
      right: "3%",
      bottom: "5%",
      top: "5%",
      containLabel: true,
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: false,
      interval: 5,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 9,
        interval: 9,
        minInterval: 9,
      },
      axisLabel: {
        maxInterval: 9,
        interval: 9,
        minInterval: 9,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 5,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    visualMap: [
      {
        type: "piecewise",
        min: 0,
        max: 1000000,
        inRange: {
          color: ["#475569"],
        },
        outOfRange: {
          color: ["#FF3C42"],
        },
        show: false,
      },
    ],
    series: [
      {
        name: "순이동",
        type: "bar",
        showSymbol: false,
        data: rawData,
        emphasis: emphasisStyle,
      },
    ],
  }

  return option
}

export const getChartOptionBirth = (birth_data, sidoList, isBlack) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
  ]
  let dataSeries = sidoList.map((item, index) => {
    return {
      name: birth_data[item].name,
      type: "line",
      showSymbol: true,
      showAllSymbol: index < 9 && isBlack ? false : true,
      symbol: index < 9 && isBlack ? number_svg[index + 1] : "emptyCircle",
      symbolSize: index < 9 && isBlack ? [5, 10] : 4,
      data: birth_data[item].data,
    }
  })

  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "0%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },

    color: isBlack ? "black" : colorPalette,
    series: dataSeries,
  }

  return option
}

export const getChartOptionMarriage = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      min: 0,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: ["#475569", "#FF3C42"],
    series: data,
  }

  return option
}

export const getChartOptionFemaleMarriageRate = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => roundToUnit(value, 0.01) + " %",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      min: 0,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: ["#475569", "#FF3C42"],
    series: [
      {
        name: "여성 합계 초혼율",
        type: "line",
        showSymbol: true,
        data: data,
      },
    ],
  }

  return option
}

export const getChartOptionFemaleMarriage = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => commaizeNumber(value) + " 명",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      min: 0,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: ["#475569", "#FF3C42"],
    series: [
      {
        name: "여성 초혼수",
        type: "line",
        showSymbol: true,
        data: data,
      },
    ],
  }

  return option
}

export const getChartOptionFemalePopulation = (data) => {
  const exponent = Math.floor(Math.log10(data[0][1]))
  const minval = floorToUnit(data[0][1], Math.pow(10, exponent))
  const [intervalY, minY, maxY] = calculateTicks(
    10,
    minval,
    data[data.length - 1][1]
  )

  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => commaizeNumber(value) + " 명",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      min: minY,
      max: maxY,
      interval: intervalY,
      axisLabel: {
        fontSize: 13,
      },
    },
    color: ["#475569", "#FF3C42"],
    series: [
      {
        name: "여성 인구",
        type: "line",
        showSymbol: true,
        data: data,
      },
    ],
  }

  return option
}

export const getChartOptionMarriageBar = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "2%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "value",
      boundaryGap: true,
      scale: false,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      min: 0,
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: ["#475569", "#FF3C42"],
    series: data,
  }

  return option
}

export const getChartOptionInternationalData = (
  data,
  selectedCountries,
  isBlack
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  let seriesData = selectedCountries
    .filter((name) => name in data)
    .map((country, index) => {
      return {
        name: country,
        type: "line",
        showSymbol: true,
        lineStyle: {
          width: country == "한국" ? 5 : 2,
          type: country == "한국" ? "solid" : "dotted",
        },
        showAllSymbol: false,
        symbol: index < 9 && isBlack ? number_svg[index + 1] : "emptyCircle",
        symbolSize: index < 9 && isBlack ? [5, 10] : 4,
        data: data[country],
      }
    })

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "0%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        fontSize: 13,
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: isBlack ? "black" : colorPalette,
    series: seriesData,
  }

  return option
}

export const getChartOptionDashboardFertility = (fertilityData) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  let fertilitySeries = [
    {
      name: "세대당 가구원 수",
      type: "line",
      showSymbol: true,
      symbolSize: 4,
      data: fertilityData,
    },
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "0%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        fontSize: 13,
        formatter: "{value} 명",
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
    },

    color: colorPalette,
    series: fertilitySeries,
  }

  return option
}
export const getChartOptionDashboardPop = (
  total_pop_vals,
  youngage_vals,
  workage_vals,
  oldage_vals
) => {
  const colorPalette = ["#80FFA5", "#37A2FF", "#FFBF00", "#00DDFF", "#FF0087"]
  const seriesPop = [
    {
      name: "총인구",
      type: "line",
      stack: "A",
      showSymbol: false,
      smooth: true,
      data: Object.entries(total_pop_vals).map(([key, value]) => {
        return [key, value]
      }),
    },
    {
      name: "유아, 청소년",
      type: "line",
      stack: "Total",
      showSymbol: false,
      smooth: true,
      areaStyle: {
        opacity: 0.8,
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgb(55, 162, 255)",
          },
          {
            offset: 1,
            color: "rgb(116, 21, 219)",
          },
        ]),
      },
      data: Object.entries(youngage_vals).map(([key, value]) => {
        return [key, value]
      }),
    },
    {
      name: "생산가능인구",
      type: "line",
      stack: "Total",
      showSymbol: false,
      smooth: true,
      areaStyle: {
        opacity: 0.8,
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgb(255, 191, 0)",
          },
          {
            offset: 1,
            color: "rgb(224, 62, 76)",
          },
        ]),
      },
      data: Object.entries(workage_vals).map(([key, value]) => {
        return [key, value]
      }),
    },
    {
      name: "노령인구",
      type: "line",
      stack: "Total",
      showSymbol: false,
      smooth: true,

      areaStyle: {
        opacity: 0.8,
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgb(128, 255, 165)",
          },
          {
            offset: 1,
            color: "rgb(1, 191, 236)",
          },
        ]),
      },
      data: Object.entries(oldage_vals).map(([key, value]) => {
        return [key, value]
      }),
    },
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      icon: "roundRect",
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: true,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        formatter: "{value} 명",
      },
      boundaryGap: true,
      scale: true,
      splitNumber: 4,
    },

    color: colorPalette,
    series: seriesPop,
  }

  return option
}

export const getChartOptionDashboardWelfare = (
  welfare_ratio,
  youngage_vals,
  workage_vals,
  oldage_vals,
  year_index_interval
) => {
  const colorPalette = [
    "#FF3C42",
    "#80FFA5",
    "#37A2FF",
    "#FFBF00",
    "#00DDFF",
    "#FF0087",
  ]

  let seriesWelfare = [
    {
      name: "총 부양 비율",
      type: "line",
      showSymbol: true,
      symbolSize: 8,
      yAxisIndex: 1,
      data: Object.entries(welfare_ratio)
        .filter(([key]) => year_index_interval.includes(key))
        .map(([key, value]) => {
          return [key, value]
        }),
    },
    {
      name: "유아, 청소년",
      type: "bar",
      stack: "Dependent",
      showSymbol: false,
      data: Object.entries(youngage_vals)
        .filter(([key]) => year_index_interval.includes(key))
        .map((item) => {
          return item
        }),
    },
    {
      name: "생산가능인구",
      type: "bar",
      stack: "Total",
      showSymbol: false,
      data: Object.entries(workage_vals)
        .filter(([key]) => year_index_interval.includes(key))
        .map((item) => {
          return item
        }),
    },
    {
      name: "노령인구",
      type: "bar",
      stack: "Dependent",
      showSymbol: false,
      data: Object.entries(oldage_vals)
        .filter(([key]) => year_index_interval.includes(key))
        .map((item) => {
          return item
        }),
    },
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: true,
      splitLine: {
        show: false,
      },
      maxInterval: 2,
    },
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        interval: 10000000,
        axisLabel: {
          formatter: "{value} 명",
        },
        boundaryGap: true,
      },
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        min: 0,
        interval: 30,
        axisLabel: {
          formatter: "{value} %",
        },
        boundaryGap: false,
      },
    ],

    color: colorPalette,
    series: seriesWelfare,
  }

  return option
}

export const getChartOptionDashboardHouse = (houseData, singleData) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
  ]
  let seriesHouse = [
    {
      name: "총 가구 수",
      type: "bar",
      showSymbol: false,
      yAxisIndex: 0,
      data: houseData,
    },
    {
      name: "1인가구 비율",
      type: "line",
      showSymbol: true,
      symbolSize: 8,
      yAxisIndex: 1,
      data: singleData.filter((value) => value[0] % 5 === 0),
    },
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: true,
      splitLine: {
        show: false,
      },
      maxInterval: 2,
    },
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        min: 0,
        axisLabel: {
          formatter: "{value} 가구",
        },
        boundaryGap: true,
      },
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        min: 0,
        axisLabel: {
          formatter: "{value} %",
        },
        boundaryGap: false,
      },
    ],

    color: colorPalette,
    series: seriesHouse,
  }

  return option
}

export const getChartOptionDashboardTree = (data) => {
  // const colorPalette = ['#475569', '#FF3C42', '#0040FF', '#fac858', '#91cc75', '#fc8452', '#73c0de', '#3ba272', '#9a60b4', '#ea7ccc','#900C3F',];

  const option = {
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
    },
    series: [
      {
        roam: false,
        breadcrumb: {
          show: false,
        },
        label: {
          show: true,
          formatter: "{b}\n{c}%",
        },
        colorMappingBy: "index",
        nodeClick: false,
        animationDuration: 500,
        width: "100%",
        height: "100%",
        type: "treemap",
        data: data,
        silent: true,
      },
    ],
  }

  return option
}

export const getChartOptionForecast = (data, isBlack) => {
  let series = []
  Object.keys(data).forEach((key, index) => {
    Object.entries(data[key]).map((item) => {
      series.push({
        name: display[item[0]].name + "-" + key,
        type: "line",
        lineStyle: {
          type: display[item[0]].line_style,
        },
        smooth: true,
        showSymbol: true,
        showAllSymbol: index < 9 && isBlack ? false : true,
        symbol: index < 9 && isBlack ? number_svg[index + 1] : "emptyCircle",
        symbolSize: index < 9 && isBlack ? [5, 10] : 4,
        data: item[1],
      })
    })
  })

  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        mminInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        fontSize: 13,
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: isBlack ? "black" : colorPalette,
    series: series,
  }

  return option
}

export const getChartOptionPopLocalForecast = (data, isBlack) => {
  let data_series = data.map((item, index) => {
    return {
      name: item.name,
      type: "line",
      lineStyle: {
        type: "solid",
      },
      smooth: true,
      showSymbol: true,
      showAllSymbol: index < 9 && isBlack ? false : true,
      symbol: index < 9 && isBlack ? number_svg[index + 1] : "emptyCircle",
      symbolSize: index < 9 && isBlack ? [5, 10] : 4,
      data: item.data,
    }
  })

  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#42f545",
    "#f5cb42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: isBlack ? "black" : colorPalette,
    series: data_series,
  }

  return option
}
export const getChartOptionSimAge = (data) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    colorBy: "series",
    animation: false,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: false,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: colorPalette,
    series: data,
  }

  return option
}

const getMapItemColor = (value) => {
  if (value >= 1.5) {
    return "#43A047"
  } else if (value >= 1.0) {
    return "#C0CA33"
  } else if (value >= 0.5) {
    return "#FBC02D"
  } else if (value >= 0.2) {
    return "#FB8C00"
  } else if (value === 0) {
    return "white"
  } else {
    return "#F4511E"
  }
}

export const getOptionForSidoMap = (sdData, currLocCode, mapName) => {
  const yearListWithInterval = Object.keys(sdData[currLocCode].value).filter(
    (year) => year % 5 === 0
  )

  const sidoSeries = yearListWithInterval.map((year) => ({
    series: [
      {
        data: Object.entries(sdData).map(([loc_code, sd]) => ({
          name: sdData[loc_code].name_kr.split("-")[1].trim(),
          value: sd.value[year],
          itemStyle: { color: getMapItemColor(sd.value[year]) },
        })),
      },
    ],
  }))

  const option = {
    timeline: {
      axisType: "category",
      autoPlay: true,
      playInterval: 2000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },

      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },

      data: yearListWithInterval,
      width: "100%",
      left: "center",
    },
    visualMap: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },
    select: {
      label: {
        show: false,
      },
    },
    emphasis: {
      label: {
        show: false,
      },
    },
    series: [
      {
        name: "소멸위험지수",
        type: "map",
        select: {
          label: {
            backgroundColor: "transparent",
            show: true,
          },
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        map: mapName,
        itemStyle: {
          borderColor: "white",
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 1,
          shadowColor: "#FCF1D1",
        },
        bottom: "5%",
        zoom: 1.75,
        roam: true,
        scaleLimit: {
          min: 1,
          max: 10,
        },
        label: {
          show: false,
          rotate: 25,
          backgroundColor: "rgba(219, 211, 211,0.3)",
          position: "insideBottom",
          fontWeight: "bold",
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        select: {
          itemStyle: {
            color: null,
          },
          label: {
            show: true,
          },
        },
      },
    ],
    options: sidoSeries,
  }
  return option
}

export const getOptionForSGGMap = (sggData, currSDLocCode, mapName) => {
  const yearList = Object.entries(sggData).filter(([key, val]) =>
    key.startsWith(currSDLocCode.substring(0, 2))
  )[0][1]
  const yearListWithInterval = [
    Object.keys(yearList.value)[0],
    ...Object.keys(yearList.value).filter((year) => year % 5 === 0),
  ]

  const sggSeries = yearListWithInterval.map((year) => ({
    title: { text: year + "년도" },
    series: [
      {
        data: Object.entries(sggData).map(([loc_code, sgg]) => ({
          name: sgg.name_kr
            .split("-")[1]
            .substring(sgg.name_kr.split("-")[1].indexOf(" ") + 1)
            .trim(),
          value: sgg.value[year],
          itemStyle: { color: getMapItemColor(sgg.value[year]) },
        })),
      },
    ],
  }))

  const option = {
    title: {
      padding: 10,
      textStyle: {
        fontSize: 24,
        color: "red",
      },
      right: "5%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      data: yearListWithInterval,
      width: "90%",
      left: "center",
      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },
    },
    visualMap: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },
    series: [
      {
        name: "소멸위험지수",
        type: "map",
        map: mapName,
        top: "5%",
        itemStyle: {
          borderColor: "white",
          shadowOffsetX: 0,
          shadowOffsetY: 0,
          shadowBlur: 1,
          shadowColor: "#FCF1D1",
        },
        zoom: 1,
        roam: true,
        scaleLimit: {
          min: 1,
          max: 10,
        },
        label: {
          show: false,
          rotate: 25,
          backgroundColor: "rgba(219, 211, 211,0.3)",
          position: "insideBottom",
          fontWeight: "bold",
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        select: {
          itemStyle: {
            color: null,
          },
        },
      },
    ],
    options: sggSeries,
  }
  return option
}

export const getTimelineOptionForAgeStruct = (years, data, custom_series) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    color: colorPalette,
    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "5%",
      top: "7%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 200,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: years,
      width: "90%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: "",
        splitLine: {
          show: false,
        },
        axisTick: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
        },
        axisLabel: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
          fontSize: 13,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        splitNumber: 4,
        padding: [0],
      },
    ],

    series: custom_series,

    tooltip: {
      show: true,
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },

    legend: {
      type: "scroll",
      left: "left",
      data: custom_series,
      textStyle: {
        fontSize: 14,
      },
    },

    options: data,
  }
  return option
}

export const getChartOptionSimChartDetails = (data) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#42f545",
    "#f5cb42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    colorBy: "series",
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => value.toFixed(2),
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: false,
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: colorPalette,
    series: data,
  }

  return option
}
export const getChartOptionLineForecast = (data) => {
  console.log(data)

  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => commaizeNumber(roundToUnit(value, 1)) + " 명",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 10,
        fontSize: 12,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: colorPalette,
    series: data,
  }

  return option
}

export const getChartOptionWorkingPopulationCompKonaSim = (
  popYoung,
  popOld,
  popWorking,
  popTotal,
  simResults
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const pctPopYoung = popYoung.map((val, index) => [
    val[0],
    (val[1] / popTotal[index][1]) * 100,
  ])
  const pctPopOld = popOld.map((val, index) => [
    val[0],
    (val[1] / popTotal[index][1]) * 100,
  ])
  const pctPopWorking = popWorking.map((val, index) => [
    val[0],
    (val[1] / popTotal[index][1]) * 100,
  ])

  // const [intervalX, minX, maxX] = calculateTicks(10, minval, data[data.length - 1][1]);

  let simPopMapper = []
  let simSeries = []
  if (!isEmpty(simResults)) {
    const _pctPopYoung = simResults.popYoung.map((val, index) => [
      val[0],
      (val[1] / simResults.popTotal[index][1]) * 100,
    ])
    const _pctPopOld = simResults.popOld.map((val, index) => [
      val[0],
      (val[1] / simResults.popTotal[index][1]) * 100,
    ])
    const _pctPopWorking = simResults.popWorking.map((val, index) => [
      val[0],
      (val[1] / simResults.popTotal[index][1]) * 100,
    ])

    simPopMapper = [
      simResults.popYoung,
      simResults.popWorking,
      simResults.popOld,
    ]

    simSeries = [
      {
        name: "0-14세 비중(%) - SIM",
        type: "line",
        showSymbol: false,
        data: _pctPopYoung,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 1.5,
        },
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "15-64세 비중(%) - SIM",
        type: "line",
        showSymbol: false,
        data: _pctPopWorking,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 1.5,
        },
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "65세 이상 비중(%) - SIM",
        type: "line",
        showSymbol: false,
        data: _pctPopOld,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 2.5,
        },
        emphasis: {
          focus: "series",
        },
      },
    ]
  }

  //NOTE: this is inefficient, need refactor
  const popMapper = [popYoung, popWorking, popOld, ...simPopMapper]

  const option = {
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      // valueFormatter: (value) => roundToUnit(value, 0.01) + " %",
      formatter: (params) => {
        let content = ""
        params.forEach((item, index) => {
          var spa = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;border: 2px solid ${item.color};"></span>`
          var seriesSymbol = index < 3 ? item.marker : spa
          var seriesName = item.seriesName
          var value = `${roundToUnit(item.value[1], 0.01)} %`
          var pop =
            commaizeNumber(
              roundToUnit(popMapper[index][item.dataIndex][1], 1)
            ) + " 명"
          content +=
            seriesSymbol +
            " " +
            seriesName +
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
            value +
            "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" +
            pop +
            "<br/>"
        })

        return content
      },
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    color: colorPalette,
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 10,
        fontSize: 12,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    series: [
      {
        name: "0-14세 비중(%)",
        type: "line",
        showSymbol: false,
        data: pctPopYoung,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "15-64세 비중(%)",
        type: "line",
        showSymbol: false,
        data: pctPopWorking,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "65세 이상 비중(%)",
        type: "line",
        showSymbol: false,
        data: pctPopOld,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      ...simSeries,
    ],
  }

  return option
}

export const getChartOptionWelfareCostKonaSim = (
  popWorking,
  dependencyRatioTotal,
  simResults
) => {
  const welfareBudget2023 = 23048900000000
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]
  const depRatioCurrMonth = dependencyRatioTotal[13][1]
  const rateOfChange = dependencyRatioTotal.map((val, index) => [
    val[0],
    val[1] / depRatioCurrMonth,
  ])

  const welfareBudgetSimulated = rateOfChange.map((val, index) => [
    val[0],
    welfareBudget2023 * val[1],
  ])
  const finalWelfareCost = welfareBudgetSimulated.map((val, index) => [
    val[0],
    val[1] / popWorking[index][1],
  ])
  // const [intervalX, minX, maxX] = calculateTicks(10, minval, data[data.length - 1][1]);

  let simSeries = []
  if (!isEmpty(simResults)) {
    const _depRatioCurrMonth = simResults.dependencyRatioTotal[13][1]
    const _rateOfChange = simResults.dependencyRatioTotal.map((val, index) => [
      val[0],
      val[1] / _depRatioCurrMonth,
    ])

    const _welfareBudgetSimulated = _rateOfChange.map((val, index) => [
      val[0],
      welfareBudget2023 * val[1],
    ])
    const _finalWelfareCost = _welfareBudgetSimulated.map((val, index) => [
      val[0],
      val[1] / simResults.popWorking[index][1],
    ])

    simSeries = [
      {
        name: "복지비 - SIM",
        type: "line",
        showSymbol: false,
        data: _finalWelfareCost,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 1.5,
        },
        emphasis: {
          focus: "series",
        },
      },
    ]
  }

  const option = {
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      valueFormatter: (value) => commaizeNumber(roundToUnit(value, 1)) + " 원",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },

    xAxis: {
      type: "category",
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 10,
        fontSize: 12,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: colorPalette,
    series: [
      {
        name: "복지비",
        type: "line",
        showSymbol: false,
        data: finalWelfareCost,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      ...simSeries,
    ],
  }

  return option
}

export const getChartOptionDependencyRatioKonaSim = (
  dependencyRatioYoung,
  dependencyRatioOld,
  dependencyRatioTotal,
  simResults
) => {
  const dependencyRatioYoungInterval = dependencyRatioYoung.filter(
    (val) => parseInt(val[0]) % 2 === 0
  )
  const dependencyRatioOldInterval = dependencyRatioOld.filter(
    (val) => parseInt(val[0]) % 2 === 0
  )
  const dependencyRatioTotalInterval = dependencyRatioTotal.filter(
    (val) => parseInt(val[0]) % 2 === 0
  )

  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#ffd576",
    "#475569",
    "#FF3C42",
    "#ffd576",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  let simSeries = []
  if (!isEmpty(simResults)) {
    const _dependencyRatioYoungInterval =
      simResults.dependencyRatioYoung.filter(
        (val) => parseInt(val[0]) % 2 === 0
      )
    const _dependencyRatioOldInterval = simResults.dependencyRatioOld.filter(
      (val) => parseInt(val[0]) % 2 === 0
    )
    const _dependencyRatioTotalInterval =
      simResults.dependencyRatioTotal.filter(
        (val) => parseInt(val[0]) % 2 === 0
      )

    simSeries = [
      {
        name: "유소년 부양비율 - SIM",
        type: "line",
        showSymbol: true,
        data: _dependencyRatioYoungInterval,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 1.5,
        },
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "노년 부양비율 - SIM",
        type: "line",
        showSymbol: true,
        data: _dependencyRatioOldInterval,
        smooth: true,
        lineStyle: {
          type: [5, 5],
          width: 1.5,
        },
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "총부양비율 - SIM",
        type: "bar",
        showSymbol: false,
        data: _dependencyRatioTotalInterval,
        itemStyle: {
          color: "rgba(84,112,198, 0.4)",
        },
        emphasis: {
          focus: "series",
        },
      },
    ]
  }

  const option = {
    animation: true,
    animationDuration: 500,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      valueFormatter: (value) => roundToUnit(value, 0.01) + " %",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },

    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      boundaryGap: true,
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 5,
        fontSize: 12,
      },
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      scale: true,
      splitNumber: 4,
      padding: [0],
      axisLabel: {
        fontSize: 13,
      },
    },
    color: colorPalette,
    series: [
      {
        name: "유소년 부양비율",
        type: "line",
        showSymbol: true,
        data: dependencyRatioYoungInterval,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "노년 부양비율",
        type: "line",
        showSymbol: true,
        data: dependencyRatioOldInterval,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      {
        name: "총부양비율",
        type: "bar",
        showSymbol: false,
        data: dependencyRatioTotalInterval,
        smooth: true,
        emphasis: {
          focus: "series",
        },
      },
      ...simSeries,
    ],
  }

  return option
}

export const getChartOptionHouseholdLine = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: false,
      scale: false,
      splitLine: {
        show: false,
      },
      axisTick: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
      },
      axisLabel: {
        maxInterval: 4,
        interval: 4,
        minInterval: 4,
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        fontSize: 13,
      },
      min: 0,
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: ["#475569", "#FF3C42"],
    series: data,
  }

  return option
}
export const getChartOptionHouseholdBar = (data) => {
  const option = {
    colorBy: "series",
    animation: true,
    grid: {
      show: true,
      left: "1%",
      right: "2%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },
    legend: {
      top: "bottom",
      left: "center",
      textStyle: {
        fontSize: "14",
      },
    },
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
    },
    xAxis: {
      show: true,
      type: "category",
      boundaryGap: true,
      scale: false,
      splitLine: {
        show: false,
      },
      axisLabel: {
        fontSize: 13,
      },
    },
    yAxis: {
      show: true,
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#80858c",
        },
      },
      axisLabel: {
        fontSize: 13,
      },
      min: 0,
      scale: true,
      splitNumber: 4,
      padding: [0],
    },
    color: ["#475569", "#FF3C42"],
    series: data,
  }

  return option
}

export const getTimelineOptionForMovement = (years, data, custom_series) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    color: colorPalette,
    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "5%",
    },
    timeline: {
      axisType: "category",
      autoPlay: true,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: years,
      width: "90%",
      left: "center",
      symbolSize: 5,
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: "",
        splitLine: {
          show: false,
        },
        axisTick: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
        },
        axisLabel: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        splitNumber: 4,
        padding: [0],
      },
    ],

    series: custom_series,
    visualMap: [
      {
        type: "piecewise",
        min: 0,
        max: 1000000,
        inRange: {
          color: ["#475569"],
        },
        outOfRange: {
          color: ["#FF3C42"],
        },
        show: false,
      },
    ],
    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },

    legend: {
      left: "left",
      data: custom_series,
    },

    options: data,
  }
  return option
}

export const getTimelineOptionForIntMarriage = (years, data, custom_series) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    color: colorPalette,
    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "5%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: years,
      width: "90%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: "",
        splitLine: {
          show: false,
        },
        axisTick: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
        },
        axisLabel: {
          maxInterval: 9,
          interval: 9,
          minInterval: 9,
          fontSize: 13,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        axisLabel: {
          fontSize: 13,
        },
        scale: true,
        splitNumber: 4,
        padding: [0],
      },
    ],

    series: custom_series,

    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },

    legend: {
      left: "5%",
      data: custom_series,
      textStyle: {
        fontSize: 14,
      },
    },

    options: data,
  }
  return option
}

export const getTimelineOptionForLocMarriage = (
  years,
  data,
  custom_series,
  xdata
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const option = {
    color: colorPalette,
    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "7%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: years,
      width: "90%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        formatter: function (s) {
          return s.name
        },
      },
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: xdata,
        splitLine: {
          show: false,
        },
        axisTick: {
          maxInterval: 4,
          interval: 4,
          minInterval: 4,
        },
        axisLabel: {
          maxInterval: 4,
          interval: 4,
          minInterval: 4,
          fontSize: 13,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        splitNumber: 4,
        padding: [0],
        axisLabel: {
          fontSize: 13,
        },
      },
    ],

    series: custom_series,

    tooltip: {
      trigger: "item",
      showDelay: 0,
      transitionDuration: 0.2,
    },

    legend: {
      left: "7%",
      data: custom_series,
      orient: "horizontal",
      right: 10,
      textStyle: {
        fontSize: 14,
      },
    },

    options: data,
  }
  return option
}

export const getTimelineOptionFemaleMarriageRateByAge = (
  yearList,
  optionsData,
  maxRate
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const ageList = optionsData[0].series[0].data.map((ds) => ds.name)
  const [intervalY, minY, maxY] = calculateTicks(10, 0, maxRate)

  const option = {
    color: colorPalette,

    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "1%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: yearList,
      width: "100%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        confine: true,
        formatter: function (s) {
          return s.name + "년"
        },
      },
    },

    grid: {
      left: "0.1%",
      right: "1%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: ageList,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        // // max: maxRate + (2 - (maxRate % 2)),
        // interval: 2,
        max: maxY,
        min: minY,
        interval: intervalY,
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        padding: [0],
        axisLabel: {
          fontSize: 13,
        },
      },
    ],

    series: [{ name: "여성 연령별 연간 초혼 비율", type: "bar" }],

    tooltip: {
      trigger: "axis",
      confine: true,
      valueFormatter: (value) => roundToUnit(value, 0.01) + " %",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },

    legend: {
      left: "0.1%",
      data: ["여성 연령별 연간 초혼 비율"],
      orient: "horizontal",
      right: 10,
      textStyle: {
        fontSize: 14,
      },
    },

    options: optionsData,
  }
  return option
}

export const getTimelineOptionFemaleMarriageByAge = (
  yearList,
  optionsData,
  maxRate
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const ageList = optionsData[0].series[0].data.map((ds) => ds.name)
  const [intervalY, minY, maxY] = calculateTicks(10, 0, maxRate)

  const option = {
    color: colorPalette,

    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "1%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: yearList,
      width: "100%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        confine: true,
        formatter: function (s) {
          return s.name + "년"
        },
      },
    },

    grid: {
      left: "0.1%",
      right: "3%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: ageList,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        // max: maxRate + (2 - (maxRate % 2)),
        // splitNumber: 4,
        max: maxY,
        min: minY,
        interval: intervalY,
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        padding: [0],
        axisLabel: {
          fontSize: 13,
        },
      },
    ],

    series: [{ name: "여성 연령별 초혼수", type: "bar" }],

    tooltip: {
      trigger: "axis",
      confine: true,
      valueFormatter: (value) => commaizeNumber(value) + " 명",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },

    legend: {
      left: "0.2%",
      data: ["여성 연령별 초혼수"],
      orient: "horizontal",
      right: 10,
      textStyle: {
        fontSize: 14,
      },
    },

    options: optionsData,
  }
  return option
}

export const getTimelineOptionFemalePopulationByAge = (
  yearList,
  optionsData,
  maxRate
) => {
  const colorPalette = [
    "#475569",
    "#FF3C42",
    "#36b3b3",
    "#ff8080",
    "#0040FF",
    "#fac858",
    "#91cc75",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  const ageList = optionsData[0].series[0].data.map((ds) => ds.name)
  const [intervalY, minY, maxY] = calculateTicks(5, 0, maxRate)

  const option = {
    color: colorPalette,

    title: {
      padding: 0,
      textStyle: {
        fontSize: 16,
        color: "red",
      },
      right: "1%",
    },
    timeline: {
      axisType: "category",
      autoPlay: false,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: yearList,
      width: "100%",
      left: "center",
      symbolSize: 5,
      tooltip: {
        confine: true,
        formatter: function (s) {
          return s.name + "년"
        },
      },
    },

    grid: {
      left: "0.1%",
      right: "3%",
      bottom: "15%",
      top: "10%",
      containLabel: true,
    },

    xAxis: [
      {
        show: true,
        type: "category",
        boundaryGap: true,
        scale: false,
        data: ageList,
        axisTick: {
          alignWithLabel: true,
        },
      },
    ],
    yAxis: [
      {
        show: true,
        type: "value",
        // max: maxRate + (2 - (maxRate % 2)),
        // splitNumber: 4,
        max: maxY,
        min: minY,
        interval: intervalY,
        axisLine: {
          lineStyle: {
            color: "#80858c",
          },
        },
        scale: true,
        padding: [0],
        axisLabel: {
          fontSize: 13,
        },
      },
    ],

    series: [{ name: "여성 연령별 초혼수", type: "bar" }],

    tooltip: {
      trigger: "axis",
      confine: true,
      valueFormatter: (value) => commaizeNumber(value) + " 명",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },

    legend: {
      left: "0.1%",
      data: ["여성 연령별 초혼수"],
      orient: "horizontal",
      right: 10,
      textStyle: {
        fontSize: 14,
      },
    },

    options: optionsData,
  }
  return option
}

export const getTimelineOptionDashboardTree = (years, data) => {
  const option = {
    timeline: {
      bottom: 0,
      axisType: "category",
      autoPlay: true,
      playInterval: 1000,
      label: {
        formatter: function (s) {
          return new Date(s).getFullYear()
        },
        interval: "auto",
      },
      progress: {
        lineStyle: {
          color: "#FD2023",
        },
        itemStyle: {
          color: "#FD2023",
        },
      },
      checkpointStyle: {
        color: "#FD2023",
      },
      itemStyle: {},
      data: years,
      width: "90%",
      left: "center",
      symbolSize: 5,
    },
    series: [
      {
        roam: false,
        breadcrumb: {
          show: false,
        },
        label: {
          show: true,
          formatter: "{b}\n{c}%",
        },
        colorMappingBy: "index",
        nodeClick: false,
        animationDuration: 500,
        width: "100%",
        height: "85%",
        type: "treemap",
        silent: true,
        top: 0,
      },
    ],
    options: data,
  }

  return option
}

export const getChartOptionIndustryBase = (outvarList, baseData, inputData) => {
  const colorPalette = [
    "#FF3C42",
    "#0040FF",
    "#475569",
    "#658e51",
    "#fac858",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  return {
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => commaizeNumber(parseInt(value)) + " 명",
    },
    animation: false,
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },

    color: colorPalette,
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 23,
        fontSize: 12,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: false,
      scale: true,
      axisLabel: {
        fontSize: 12,
      },
    },
    color: colorPalette,
    series: [
      {
        name: "KONASIM 노동인력 공급",
        type: "line",
        lineStyle: {
          // type: [5, 5],
          type: "solid",
          width: 1,
        },
        smooth: true,
        showSymbol: false,
        data: baseData[outvarList[0]],
      },
      {
        name: "KONASIM 산업 종사자수",
        type: "line",
        lineStyle: {
          type: "solid",
          width: 1,
        },
        smooth: false,
        showSymbol: false,
        data: baseData[outvarList[1]],
      },
      {
        name: "과거 산업 종사자수",
        type: "line",
        lineStyle: {
          // type: [10, 5],
          type: "line",
          width: 6,
        },

        smooth: true,
        showSymbol: true,
        symbolSize: 6,
        data: inputData,
      },
    ],
  }
}

export const getChartOptionIndustryMySim = (
  outvarList,
  baseData,
  simData,
  inputData
) => {
  const colorPalette = [
    "#FF3C42",
    "#0040FF",
    "#475569",
    "#658e51",
    "#fac858",
    "#fc8452",
    "#73c0de",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
    "#DE781F",
    "#26DE1F",
    "#1F85DE",
    "#D81FDE",
  ]

  //NOTE: quick solution, need revision
  const varnameKrMapper = {
    [outvarList[0]]: "노동인력 공급",
    [outvarList[1]]: "산업 종사자수",
  }

  //use 산업 종사자수 data only for output
  const simResultOption = [outvarList[1]].map((varName) => {
    return {
      name: `${simData.simInfo.name}-${varnameKrMapper[varName]}`,
      type: "line",
      showSymbol: false,
      data: simData.outputData[varName],
      lineStyle: {
        type: [5, 5],
        width: 1.5,
      },
      emphasis: {
        focus: "series",
      },
    }
  })

  return {
    tooltip: {
      trigger: "axis",
      confine: true,
      renderMode: "richText",
      valueFormatter: (value) => commaizeNumber(parseInt(value)) + " 명",
    },
    animation: false,
    legend: {
      type: "scroll",
      top: "bottom",
      left: "center",
      orient: "horizontal",
      textStyle: {
        fontSize: 14,
      },
    },
    grid: {
      show: true,
      left: "1%",
      right: "3%",
      bottom: "10%",
      top: "5%",
      containLabel: true,
    },

    color: colorPalette,
    xAxis: {
      type: "category",
      axisLabel: {
        formatter: function (value) {
          return time.format(value, "{yyyy}년")
        },
        interval: 23,
        fontSize: 12,
      },
      boundaryGap: false,
      splitLine: {
        show: true,
      },
    },
    yAxis: {
      type: "value",
      boundaryGap: false,
      scale: true,
      axisLabel: {
        fontSize: 12,
      },
    },
    color: colorPalette,
    series: [
      {
        name: "KONASIM 노동인력 공급",
        type: "line",
        lineStyle: {
          // type: [5, 5],
          type: "solid",
          width: 1,
        },
        smooth: true,
        showSymbol: false,
        data: baseData[outvarList[0]],
      },
      {
        name: "KONASIM 산업 종사자수",
        type: "line",
        lineStyle: {
          type: "solid",
          width: 1,
        },
        smooth: false,
        showSymbol: false,
        data: baseData[outvarList[1]],
      },
      {
        name: "과거 산업 종사자수",
        type: "line",
        lineStyle: {
          // type: [10, 5],
          type: "line",
          width: 6,
        },

        smooth: true,
        showSymbol: true,
        symbolSize: 6,
        data: inputData,
      },
      ...simResultOption,
    ],
  }
}
