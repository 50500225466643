import React from 'react'
import { useFieldArray, useController} from "react-hook-form";
import {BsFillQuestionCircleFill} from 'react-icons/bs'
import EventInfoModal from "../modals/EventInfoModal";
import { useReStateContext } from "../context/RealEstateContext";
import { SIM_EVENT_PARAMETERS, USEREVENT_PARAM_DEFAULT_VAL } from "../context/ReMysimContext";

const SimParamEventSlider = ({param, form}) => {
  const reState = useReStateContext();
  const [isOpen, setIsOpen] = React.useState(false)

  const modelData = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode];

  const paramInfo = {
    ...SIM_EVENT_PARAMETERS[param], 
    dateVarInfo:{...modelData.variable[SIM_EVENT_PARAMETERS[param].dateVar]},
    paramVarInfo: {...modelData.variable[SIM_EVENT_PARAMETERS[param].paramVar]}
  }


  const {fields, append, remove} = useFieldArray({ control: form.control, name: param }); //name of field array is the variable name

  const SliderWithInput = ({ control, name, paramInfo, groupLabel}) => {
    const {field} = useController({name: `${name}.value`, control});
    return (
      <div className="p-4 border rounded-xl relative">
        <span className="absolute top-0 left-2 -translate-y-1/2 text-xs text-gray-400  bg-white p-1">{groupLabel}</span>
        <div className="flex items-center space-x-4"><span>이벤트명:</span><input {...form.register(`${name}.ename`)} type="text" className="flex-1 text-xs py-2 rounded-lg border outline-none focus:ring-1 focus:ring-jadu-primary shadow-sm px-4" /></div>
        <div className="flex items-center space-x-4">
          <div className="flex space-x-1">
            <select {...form.register(`${name}.year`)} defaultValue="2022" className="text-xs py-2 rounded-lg  border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm">
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
            <select {...form.register(`${name}.month`)} defaultValue="1" className="text-xs py-2 rounded-lg  border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>
          <div className="flex-1 flex relative py-2">
            <input 
              {...field} 
              type="range"
              min={paramInfo.paramVarInfo.e_min}
              max={paramInfo.paramVarInfo.e_max}
              step={paramInfo.paramVarInfo.e_inc}
              className="w-full sim-input-slider" 
            />
            <span className="absolute bottom-1 left-0 text-[10px] text-gray-400 font-medium">{paramInfo.paramVarInfo.e_min}</span>
            <span className="absolute bottom-1 right-0 text-[10px] text-gray-400 font-medium">{paramInfo.paramVarInfo.e_max}</span>
          </div>
          <div className="w-[60px]">
            <input 
              {...field} 
              type="text" 
              className="block text-xs py-2 rounded-lg w-full border outline-none focus:ring-1 focus:ring-jadu-primary text-center shadow-sm" 
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      
    );
  }

  return (
    <div className="py-2 bg-white flex flex-col">
      <EventInfoModal isOpen={isOpen} setIsOpen={setIsOpen} eventType={param}  />
      
      <div className="flex items-center">
        <input className="inp-cbx hidden" id={param} type="checkbox" {...form.register(`${param}-check`)} />
        <label className="cbx flex items-center space-x-2" htmlFor={param}>
          <span className="h-5 w-5 flex justify-center items-center"><svg className="h-2/3 w-2/3" viewBox="0 0 12 9"><polyline points="1 5 4 8 11 1"></polyline></svg></span>
          <span className="text-xl font-semibold">{paramInfo.title}</span> 
        </label>
        <button onClick={() => setIsOpen(true)} type="button" className="ml-2 text-slate-500 hover:text-orange-400 hover:drop-shadow-md"><BsFillQuestionCircleFill size={16} className="" /></button>
      </div>
       
      <div className={`overflow-hidden transition-[height] duration-500 ${form.watch(`${param}-check`) ? "h-full" : "duration-300 h-0"}`}>
        <div className={`flex flex-col space-y-4 py-4 relative transition-opacity duration-1000 opacity-0 ${form.watch(`${param}-check`) ? "opacity-100" : "opacity-0"}`}>
          {fields.map((item, index) => {
            return (
              <SliderWithInput 
                  key={item.id}
                  groupLabel={`이벤트 ${index+1}`}
                  name={`${param}.${index}`} 
                  control={form.control} 
                  paramInfo={paramInfo}
                />
            );
          })}

          {/* TODO: current default value is 10, change this using paramInfo.paramVarInfo.data_in[0].value[0][1] later*/}
          {(fields.length < 3) && <button onClick={() => append({value: USEREVENT_PARAM_DEFAULT_VAL})} type="button" className="self-center inline-flex items-center text-xs space-x-2 ripple-gray px-2 py-1 rounded-xl"><span>Event 추가</span></button>}
        </div>
      </div>




    </div>
  )
}

export default SimParamEventSlider