import React from 'react'
import { ApiError, AppError, InvalidRequestBody, ResponseError } from "../../../utils/helpers"
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../context/GlobalContext";
import Loader3 from "../../loaders/Loader3"
import SimulationTable, { SIM_GROUP } from "./SimulationTable"
import { getPopulationModelsApi, getPopulationSimulationsApi } from "../../../utils/api/populationApi";
import { getRealEstateModelsApi, getRealEstateSimulationsApi } from "../../../utils/api/realestateApi";
import { getIndustryModelsApi, getIndustrySimulationsApi } from "../../../utils/api/industryApi";





export const ACTION = {
	LOAD_SIMULATIONS: "load-simulation",
}

const initialState = {
	realEstateSimList: [],
	populationSimList: [],
	industrySimList: [],
	isLoaded: false,
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.LOAD_SIMULATIONS:
			return {
				...state,
				realEstateSimList: payload.realEstateSimList,
				populationSimList: payload.populationSimList,
				industrySimList: payload.industrySimList,
				isLoaded: true
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}


const Simulation = () => {
	const [state, dispatch] = React.useReducer(reducer, initialState)
	const { user } = useGlobalStateContext();
	const globalDispatch = useGlobalDispatchContext();



	const loadDefault = React.useCallback(async () => {

		if(user){
			try {

				let [popModels, popSims, reModels, reSims, industryModels, industrySims] = await Promise.all([
					getPopulationModelsApi(user),
					getPopulationSimulationsApi(user),
					getRealEstateModelsApi(user),
					getRealEstateSimulationsApi(user),
					getIndustryModelsApi(user),
					getIndustrySimulationsApi(user),
				]);

				
				const popModelsObj = Object.fromEntries(popModels.map(e => [e.id, e]));
				const reModelsObj = Object.fromEntries(reModels.map(e => [e.id, e]));
				const industryModelsObj = Object.fromEntries(industryModels.map(e => [e.id, e]));

				popSims = popSims.map(sim => ({
					...sim, 
					version: popModelsObj[sim.model_id]?.version, 
					category: popModelsObj[sim.model_id]?.description
				}));

				reSims = reSims.map(sim => ({
					...sim,
					version: reModelsObj[sim.model_id]?.version,
					category: reModelsObj[sim.model_id]?.description
				}));

				industrySims = industrySims.map(sim => ({
					...sim, 
					version: industryModelsObj[sim.model_id].version,
					category: industryModelsObj[sim.model_id]?.category,
				}));


				dispatch({
					type: ACTION.LOAD_SIMULATIONS,
					payload: {
						populationSimList : popSims,
						realEstateSimList : reSims,
						industrySimList : industrySims,
					}
				})

		
			} catch (err) {
				if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
					const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
					globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
				}
				else{
					console.error(err)
				}
			}
			
		}

	}, [state.selectedIndustryInfo])

	React.useEffect(() => {
		loadDefault();
	},[]);



	return (
			<div className="flex flex-col h-full" >
				<div className="pt-10 pb-5 border-b-[1px] border-gray-200">
					<h1 className="text-2xl font-bold  text-konared">시뮬레이션</h1>
				</div>
				{!state.isLoaded
					? <div className="flex-1 flex items-center justify-center"><Loader3 /></div>
					: <>
							<SimulationTable 
								tableTitle="부동산 가격 시뮬레이션"
								simGroup={SIM_GROUP.realestate}
								simList={state.realEstateSimList}
								viewLink="/realestate/viewsim"
							/>

							<SimulationTable 
								tableTitle="인구 시뮬레이션"
								simGroup={SIM_GROUP.population}
								simList={state.populationSimList}
								viewLink="/pop-simulation-national"
							/>
							
							<SimulationTable 
								tableTitle="산업 인력 시뮬레이션"
								simGroup={SIM_GROUP.industry}
								simList={state.industrySimList}
								viewLink="/"
							/>
						</>
				}
			</div >
		)
}

export default Simulation