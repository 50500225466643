import React from 'react'
import { NavLink } from "react-router-dom";
import {BsChevronRight} from 'react-icons/bs'
import Echarts from "../charts/Echarts"
import { getChartOptionDataLibCard } from "../charts/EchartOptions"
import CountUp from 'react-countup';
import { useReStateContext } from "../context/RealEstateContext";
import { SIM_PARAMETERS } from "../context/ReMysimContext";


const CardDataLibItems = ({paramJson}) => {
  const [paramVarName, paramDesc] = paramJson;
  const reState = useReStateContext();

  const reVariables = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode].variable;
  const inputVarInfo = reVariables[SIM_PARAMETERS[paramVarName].inputVarName];
  
  const [lastDate, lastDataVal] = inputVarInfo.data_in[0].value.at(-1)
  const lastDateKr = `${lastDate.split("-")[0]}년 ${lastDate.split("-")[1]}월` 


  //set decimal

  const decimalPlace = Number.isInteger(lastDataVal) ? 0 : Math.min(String(lastDataVal).split('.')[1].length, 3)


  return (
    <div className="bg-white shadow-lg rounded-lg hover:scale-110 duration-500 hover:cursor-pointer">
      <div className="flex flex-col">
        {/* head */}
        <div className="px-4 py-6 flex items-center">
          
          <h2 className="flex-1 text-lg font-semibold">{paramDesc.title}</h2>
          <div className="flex flex-col items-end">
            <span className="font-bold text-konared text-2xl">{<CountUp end={lastDataVal} decimals={decimalPlace} separator="," duration={1.2} />}</span>
          </div>
        </div>
        {/* chart */}
        <div className="h-[100px]"><Echarts options={getChartOptionDataLibCard(inputVarInfo.data_in[0].value)} /></div>
        
        <div className="flex-1 flex flex-col p-4 space-y-6">
          {/* description */}
          <p className="p-kr text-konagray text-md line-clamp-4">{paramDesc.description}</p>
          <div className="flex items-center">
            <span className="flex-1 text-xs text-gray-400">Last Update: {lastDateKr}</span>
            <NavLink to={`/re-datalibrary-details/${paramDesc.linkID}`} className="text-md inline-flex items-center space-x-1 hover:font-bold" ><span>더 알아보기</span> <BsChevronRight /></NavLink>
          </div>
        </div>
      
      </div>
    </div>
  )
}

export default CardDataLibItems