import React, { Fragment, useRef, useReducer, useEffect, useState } from 'react'
import { CardHeader, CardWrapper, CardContent } from '../../components/population/CardWrapper'
import MainContentWrapper from '../../components/MainContentWrapper'
import DotLoader from '../../components/loaders/DotLoader'
import PopulationEcharts from '../../components/charts/PopulationEcharts'
import { getChartOptionSimAge, getChartOptionLineForecast, getTimelineOptionForAgeStruct } from '../../components/charts/PopulationEchartOptions'
import { NavLink, useLocation, useNavigate } from "react-router-dom"
import { bearerAuth } from "../../utils/helpers"
import { API_BASE_URL } from "../../utils/constants"
import APIUnavailableModal from '../../components/modals/APIUnavailableModal'
import LoadingModal from '../../components/modals/LoadingModal'
import GenerateReportModal from '../../components/modals/GenerateReportModal'
import { handleError } from '../../utils/pop-handle-response'
import { checkSubscribed } from '../../utils/pop-subscription-checker'
import { useGlobalStateContext } from "../../components/context/GlobalContext"
import { Listbox, Transition} from '@headlessui/react'
import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { CheckIcon, CheckCircleIcon as CheckCircleIconSolid } from '@heroicons/react/24/solid'
import { CheckCircleIcon } from '@heroicons/react/24/outline'




// const LOCATION_MAPPER= {
//     서울특별시 : 'sd1',
//     부산광역시 : 'sd2',
//     대구광역시 : 'sd3',
//     인천광역시 : 'sd4',
//     광주광역시 : 'sd5',
//     대전광역시 : 'sd6',
//     울산광역시 : 'sd7',
//     세종특별자치시 : 'sd8',
//     경기도 : 'sd9',
//     강원도 : 'sd10',
//     충청북도 : 'sd11',
//     충청남도 : 'sd12',
//     전라북도 : 'sd13',
//     전라남도 : 'sd14',
//     경상북도 : 'sd15',
//     경상남도 : 'sd16',
//     제주특별자치도  : 'sd17'
// }
const LOCATION_MAPPER= {
   	sd1 : '서울특별시',
	sd2: '부산광역시',
   	sd3:  '대구광역시',
   	sd4: '인천광역시',
    sd5: '광주광역시',
    sd6: '대전광역시',
    sd7: '울산광역시',
    sd8: '세종특별자치시',
    sd9: '경기도',
    sd10: '강원도',
   	sd11: '충청북도',
    sd12: '충청남도',
    sd13: '전라북도',
    sd14: '전라남도',
    sd15: '경상북도',
    sd16: '경상남도',
    sd17: '제주특별자치도' 
}
const ACTION = {
	SET_BASE_DATA: 'set-main-data',
	SET_MULTI_DATA: 'set-base-data',
	CHANGE_TIME: 'change-time',
	SET_VALUES: 'set-values',
	SIMULATE: 'simulate',
	RESET: 'reset-values'
}

const movement_options = [
    {
      value: 0,
      name: '시도간 이동 없음',
     
    },
    {
      value: 1,
      name: '상수',
     
    },
    {
      value: 2,
      name: '시간에 따라 0으로 수렴',
     
    }
  ]

  
const initialState = {
	model: null,
	main_data: null,
	popData: null,
	yearlist: null,
	curYear: 2060,
	ageData: null,
	welfareData: null,
	welfareAgeData: null,
	pregnancyData: null,
	maleAgeData: null,
	femaleAgeData: null,
	category: null,
	userMaleAgeData: null,
	userFealeAgeData: null,
	userMaleAgeDataUser: null,
	userFemaleAgeDataUser: null,
	simID: 0,
	baseSimID: null,
	inputVars: null,
	simName: null,
	simulation_input: null,
    location: '서울특별시',
    switch_val: 2,
    selectedMovement: null,
}


const reducer = (state, { type, payload }) => {

	switch (type) {
		case ACTION.SET_BASE_DATA:
			return {
				...state,
				simID: payload.simID,
                location: payload.location,
                switch_val: payload.switch_val,
                selectedMovement: payload.selectedMovement,
				model: payload.model,
				popData: payload.popData,
				ageData: payload.ageData,
				welfareData: payload.welfareData,
				welfareAgeData: payload.welfareAgeData,
				pregnancyData: payload.pregnancyData,
				yearlist: payload.yearlist,
				maleAgeData: payload.maleAgeData,
				femaleAgeData: payload.femaleAgeData,
				baseSimID: payload.baseSimID,
				simulation_input: payload.simulation_input
			}
		case ACTION.SET_MULTI_DATA:
			return {
				...state,
				simID: payload.simID,
				model: payload.model,
				popData: payload.popData,
				ageData: payload.ageData,
				welfareData: payload.welfareData,
				welfareAgeData: payload.welfareAgeData,
				pregnancyData: payload.pregnancyData,
				yearlist: payload.yearlist,
				maleAgeData: payload.maleAgeData,
				femaleAgeData: payload.femaleAgeData,
				userMaleAgeDataUser: payload.userMaleAgeDataUser,
				userFemaleAgeDataUser: payload.userFemaleAgeDataUser,
				baseSimID: payload.baseSimID,
				simulation_input: payload.simulation_input
			}
		case ACTION.SET_VALUES:
			return {
				...state,
				switch_val: payload.switch_val,
			}

		case ACTION.SIMULATE:
			return {
				...state,
				simID: payload.simID,
                switch_val: payload.switch_val,
				popData: payload.popData,
				pregnancyData: payload.pregnancyData,
				welfareData: payload.welfareData,
				welfareAgeData: payload.welfareAgeData,
				userMaleAgeDataUser: payload.userMaleAgeDataUser,
				userFemaleAgeDataUser: payload.userFemaleAgeDataUser,
				ageData: payload.ageData,

			}
		case ACTION.RESET:
			return {
				...state,
				inputvals: payload.inputvals

			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}



function SimulationRegional(props) {
	const location = useLocation()
	const simResult = useRef(null)
	const [isSimRunning, setSimRunning] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [isReportOpen, setReportOpen] = useState(false)
	const [isSubscribed, setSubscribed] = useState(false)

	let navigate = useNavigate()
	const [state, dispatch] = useReducer(reducer, initialState)
	const { user, handleLogout } = useGlobalStateContext();

	const loadBase = async (loc_name) => {
		try {
			//get model first
			let query = `?page_size=1&sort=-created_at&description=${loc_name}`
			let response = await fetch(`${API_BASE_URL}/api/population/models${query}`, {
				method: 'GET',
				headers: { "Authorization": bearerAuth(user) }
			});

			
			if (!response.ok) throw new Error(handleError(response.status))

			let model = await response.json()
			if(!model.data || model.data.length<=0) throw Error("No model was available")
			

			//setting input values
			let inputvals = []
			let defaultInputVals = []
			let simulation_input=[]

			//get input variables from the database for the model
			query = `?page_size=all&model_id=${model.data[0].id}&category__in=PARAMETER&expand=data_in`
			response = await fetch(`${API_BASE_URL}/api/population/variables${query}`, {
				method: "GET",
				headers: {"Authorization": bearerAuth(user) },
			});
			
			if (!response.ok) throw new Error(handleError(response.status))

			let model_input = await response.json()
			

			if(!model_input.data || model_input.data.length<=0) throw new Error("No simulation input data was retrieved")
			
			model_input.data.forEach(varItem=>{
				if(!varItem.data_in || varItem.data_in.length<=0) throw new Error("No initial value provided")
				                
                inputvals.push(varItem.data_in[0].value)
			})

			query = `?page_size=1&sort=-created_at&model_id=${model.data[0].id}&type=BASE`
			response = await fetch(`${API_BASE_URL}/api/population/simulations/with-datavar${query}`, {
				method: 'GET',
				headers: { "Authorization": bearerAuth(user) }
			});



			if (!response.ok) throw new Error(handleError(response.status))
			//we are expecting an array of objects
			let parseRes = await response.json()
			
			if(!parseRes.data || parseRes.data.length<=0) throw Error("No base simulation data was retrieved")
			
			// let allSimData = {}
			let year_index = []
			let popYearXAgeData = {}
			let popYearXAgeDataM = {}
			let popYearXAgeDataF = {}
			let pregnancyData = {}
			let outputData = parseRes.data[0].data_out
			let baseSimID =  parseRes.data[0].id

			if (outputData.length > 1) {
				let row = outputData[0]
				row.value.forEach((item) => {
					year_index.push(item[0])
					let ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeData[item[0]] = ageKeyVal

					ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeDataM[item[0]] = ageKeyVal

					ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeDataF[item[0]] = ageKeyVal
				})
			}


			// not sure if faster but simpler in terms of coding
			outputData.forEach((item) => {
				//store information in age
				if (item.variable.name_en.includes('Population by age sex')) {
					let age = item.variable.name_en.split(",")[1].split("]")[0].replace("A", "")
					let gender = item.variable.name_en.split(",")[0].split("[")[1]

					item.value.forEach((data_pair) => {
						// to be revised
						popYearXAgeData[data_pair[0]][age] += Math.round(data_pair[1])
						if (gender === 'M') {
							popYearXAgeDataM[data_pair[0]][age] += Math.round(data_pair[1])
						} else {
							popYearXAgeDataF[data_pair[0]][age] += Math.round(data_pair[1])
						}
					})

				} else {
					item.value.forEach((pair_val) => {
						let yearval = parseInt(pair_val[0])
						pregnancyData[yearval] = Math.round(pair_val[1])
					})
				}
			})

			//population trend across the years
			let popYearlyData = []
			year_index.forEach((y) => {
				let values = Object.values(popYearXAgeData[y])
				let sum = values.reduce((accumulator, value) => {
					return accumulator + value;
				}, 0);
				popYearlyData.push([y, sum])
			})

			let popData = getChartOptionLineForecast([{
				name: '기준 시나리오',
				type: 'line',
				lineStyle: {
					type: 'solid'
				},
				smooth: true,
				showSymbol: true,
				data: popYearlyData,
			}])

			let agebar = year_index.map(y=>{
				return {
					title: { text: y + '년도'},
					series: [
						{
								data: Object.entries(popYearXAgeDataM[y]).map(([key, val]) => { return {name: key, gender: 'male', value: val}}),
								stack: 'base'
						},
						{
								data: Object.entries(popYearXAgeDataF[y]).map(([key, val]) => { return {name: key, gender: 'female', value: val*-1}}),
								stack: 'base'
						}
					]
				}
			})
			let ageSeries =[
				{ name: '기준 시나리오 남성', type: 'bar', stack: 'base' },
				{ name: '기준 시나리오 여성', type: 'bar', stack: 'base' },
			]
			let ageData = getTimelineOptionForAgeStruct(year_index, agebar, ageSeries)
			let youngage_vals = {}
			let workage_vals = {}
			let oldage_vals = {}
			let welfare_ratio = {}

			//welfare population ratio
			year_index.forEach((y) => {
				let youngObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key < 15))
				let workingObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key >= 15 && key < 65))
				let oldObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key >= 65))

				//compute and assign
				youngage_vals[y] = Object.values(youngObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				workage_vals[y] = Object.values(workingObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				oldage_vals[y] = Object.values(oldObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				welfare_ratio[y] = parseFloat(((youngage_vals[y] + oldage_vals[y]) / workage_vals[y]).toFixed(4))
			})

			let welfareData = getChartOptionLineForecast([{
				name: '기준 시나리오',
				type: 'line',
				lineStyle: {
					type: 'solid',
				},
				smooth: true,
				showSymbol: true,
				data: Object.entries(welfare_ratio).map(([key, val]) => { return [key, val] }),
			}])

			let welfareAgeData = getChartOptionSimAge([{
				name: '기준 시나리오-노인',
				type: 'bar',
				stack: 'total',
				data: Object.entries(oldage_vals).map(([key, val]) => { return [key, val] }),
			},
			{
				name: '기준 시나리오-유아',
				type: 'bar',
				stack: 'total',
				data: Object.entries(youngage_vals).map(([key, val]) => { return [key, val] }),
			}])

			let pgData = getChartOptionLineForecast([{
				name: '기준 시나리오',
				type: 'line',
				lineStyle: {
					type: 'solid'
				},
				smooth: true,
				showSymbol: true,
				data: Object.entries(pregnancyData).map(([key, val]) => { return [key, val] }),
			}])


			return {
                location: loc_name,
                switch_val: 2,
				popData: popData,
				ageData: ageData,
				ageSeries:ageSeries,
				welfareData: welfareData,
				welfareAgeData: welfareAgeData,
				pregnancyData: pgData,
				yearlist: year_index,
				maleAgeData: popYearXAgeDataM,
				femaleAgeData: popYearXAgeDataF,
				model: parseRes.data[0],
				baseSimID: baseSimID,
				simulation_input: simulation_input
			}
			
		} catch (error) {
			setIsOpen(true)
			console.error(error.message)
			return null
		}
	}

	useEffect(() => {
		initialLoad()
	}, [])

	const initialLoad = async () => {
		
		let isUserSubscribed = false
		let initial_switch_val = 2
		try{

			setSimRunning(true)

		

			let initialData = null
			
			if (location.state && location.state.sim_id) {


				let simData = await getDataViaSimID(location.state.sim_id)

				initial_switch_val = simData.switch_val

				
				let response = await fetch(`${API_BASE_URL}/api/population/models/${simData.model_id}`, {
					method: 'GET',
					headers: { "Authorization": bearerAuth(user) }
				});

				
				if (!response.ok) throw new Error(handleError(response.status))

				let model = await response.json()
				initialData = await loadBase(model.description)
				
				
				initialData.popData.series = [...initialData.popData.series, simData.popData]
				let options = initialData.ageData.options.map(item => { return{
						title: item.title,
						series : [...item.series, ...simData.ageData.filter(x=> item.title.text === x.title.text)[0].series]
				}})

				initialData.ageData.options = options
				initialData.ageData.legend.data = [...initialData.ageData.legend.data, ...simData.ageSeries]
				initialData.ageData.series = [...initialData.ageData.series, ...simData.ageSeries]
				initialData.welfareData.series = [...initialData.welfareData.series, simData.welfareData]
				initialData.welfareAgeData.series = [...initialData.welfareAgeData.series, ...simData.welfareAgeData]
				initialData.pregnancyData.series = [...initialData.pregnancyData.series, simData.pregnancyData]
				
			}else{
				
				initialData = await loadBase('sd1')
			}


							dispatch({
									type: ACTION.SET_BASE_DATA,
									payload: {
					simID: (location.state && location.state.sim_id) ? location.state.sim_id : 0,
											location:  initialData.location,
											switch_val: initial_switch_val,
											selectedMovement: movement_options[initial_switch_val],
											popData: initialData.popData,
											ageData: initialData.ageData,
											welfareData: initialData.welfareData,
											welfareAgeData: initialData.welfareAgeData,
											pregnancyData: initialData.pregnancyData,
											yearlist: initialData.yearlist,
											maleAgeData: initialData.maleAgeData,
											femaleAgeData: initialData.femaleAgeData,
											model: initialData.model,
											baseSimID: initialData.baseSimID,
											simulation_input: initialData.simulation_input
									}
							})

				
			
			
		}catch (error) {
			setIsOpen(true)
			console.error(error.message)
		}finally{
			setSimRunning(false)
			navigate(location.pathname, { replace: true })
		}
	}

	const Simulate = async (value) => {
		
		// trim simulation name input first
		let cont = true
        var today = new Date()
		let newSimName= 'Movmnt_sim'+today.getFullYear() + (today.getMonth() + 1) + today.getDate() + today.getHours() + today.getMinutes() + today.getMilliseconds()
        
		if(cont){
			try {
				setSimRunning(true)

				// prepare the parameters
				const param = {
                    "switch movement" : value
                };

				// prepare simulation parameters
				const simJson = {
					"name": newSimName,
					"parameter": { ...param },
					"model_id": state.model.model_id,
					"type": "USER",
					"user_id": user.user_info.id
				}

				let response = await fetch(`${API_BASE_URL}/api/population/simulations`, {
					method: "POST",
					headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
					body: JSON.stringify(simJson),
				})
				
				if (!response.ok) throw new Error(handleError(response.status))

				const newSimulation = await response.json()

				if(!newSimulation) throw Error("Error creating simulation")

				const response2 = await fetch(`${API_BASE_URL}/api/population/simulations/${newSimulation.id}/run`, {
					method: "POST",
					headers: {"Authorization" : bearerAuth(user)},
				});
				if (!response2.ok)  throw new Error(handleError(response2.status))
				
				let simData = await getDataViaSimID(newSimulation.id)

				let series = [state.popData.series.filter(item => item.name === '기준 시나리오')[0]]
				series.push(simData.popData)
				let popData = getChartOptionLineForecast(series)

				series = [state.pregnancyData.series.filter(item => item.name === '기준 시나리오')[0]]
				series.push(simData.pregnancyData)
				let pgData = getChartOptionLineForecast(series)

				series = state.welfareAgeData.series.filter(item => item.name.includes('기준 시나리오')).map(item => { return item })
				series = [...series, ...simData.welfareAgeData]
				let welfareAgeData = getChartOptionSimAge(series)

				series = [state.welfareData.series.filter(item => item.name === '기준 시나리오')[0]]

				series.push(simData.welfareData)
				let welfareData = getChartOptionLineForecast(series)

				let ageOptions = state.ageData.options.map(item=>{ return{
					title: item.title,
					series: item.series.filter(s=>s.stack === 'base').map(val =>{ return val})
				}})
				
				let ageOptionsMixed = ageOptions.map(item => { 
					return{
					title: item.title,
					series : [...item.series, ...simData.ageData.filter(x=> item.title.text === x.title.text)[0].series]
				}})

				let ageSeries = [...state.ageData.series.filter(s => s.stack === 'base').map(val =>{ return val}), ...simData.ageSeries]
				let ageData = getTimelineOptionForAgeStruct(state.yearlist, ageOptionsMixed, ageSeries)
				
				dispatch({
					type: ACTION.SIMULATE,
					payload: {
						simID: newSimulation.id,
                        switch_val: value,
						popData: popData,
						pregnancyData: pgData,
						welfareData: welfareData,
						welfareAgeData: welfareAgeData,
						ageData: ageData,
						userMaleAgeDataUser: simData.userMaleAgeDataUser,
						userFemaleAgeDataUser: simData.userFemaleAgeDataUser
					}
				})
				
				simResult.current.scrollIntoView()	
			} catch (err) {
				setIsOpen(true)
				console.error(err.message)
			}finally{
				setSimRunning(false)
			}
		}
		
	}

	const getDataViaSimID = async (id) => {
		try{
			
			let query = `?page_size=1&sort=-created_at&id=${id}`
			let response = await fetch(`${API_BASE_URL}/api/population/simulations/with-datavar${query}`, {
				method: 'GET',
				headers: {"Content-Type": "application/json", "Authorization": bearerAuth(user) }
			});

			
			if (!response.ok) throw new Error(handleError(response.status))

			const parseRes = await response.json()	
			
			if(!parseRes.data || parseRes.data.length<=0) throw Error("Simulation data fetch error")
			
			let simulationName = parseRes.data[0].name
			let outputData = parseRes.data[0].data_out
			let parameters = parseRes.data[0].parameter
			let switch_value = parseRes.data[0].parameter['switch movement']
			let year_index = []
			let popYearXAgeData = {}
			let popYearXAgeDataM = {}
			let popYearXAgeDataF = {}
			let pregnancyData = {}


			// initalize the age values in each years to 0
			//get years to be used to access yearly information
			//create a population data {year} => [..age:value]
			if (outputData.length > 1) {
				
				let row = outputData[0]
				row.value.forEach((item) => {
					year_index.push(item[0])
					let ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeData[item[0]] = ageKeyVal

					ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeDataM[item[0]] = ageKeyVal

					ageKeyVal = {}
					for (let i = 0; i <= 100; i++) {
						ageKeyVal[i] = 0
					}
					popYearXAgeDataF[item[0]] = ageKeyVal
				})
			}

			// not sure if faster but simpler in terms of coding
			outputData.forEach((item) => {

				//store information in age
				if (item.variable.name_en.includes('Population by age sex')) {
					let age = item.variable.name_en.split(",")[1].split("]")[0].replace("A", "")
					let gender = item.variable.name_en.split(",")[0].split("[")[1]

					item.value.forEach((data_pair) => {
						// to be revised
						popYearXAgeData[parseInt(data_pair[0])][age] += Math.round(data_pair[1])
						if (gender === 'M') {
							popYearXAgeDataM[parseInt(data_pair[0])][age] += Math.round(data_pair[1])
						} else {
							popYearXAgeDataF[parseInt(data_pair[0])][age] += Math.round(data_pair[1])
						}
					})

				} else {
					item.value.forEach((pair_val) => {
						let yearval = parseInt(pair_val[0])
						pregnancyData[yearval] = Math.round(pair_val[1])
					})
				}
			})


			//population trend across the years
			let popYearlyData = []
			year_index.forEach((y) => {
				let values = Object.values(popYearXAgeData[y])
				let sum = values.reduce((accumulator, value) => {
					return accumulator + value;
				}, 0);
				popYearlyData.push([y, sum])
			})

			let popData = {
				name: movement_options[switch_value].name,
				type: 'line',
				lineStyle: {
					type: 'dashed'
				},
				smooth: true,
				showSymbol: true,
				data: popYearlyData,
			}

			let pgData = {
				name: movement_options[switch_value].name,
				type: 'line',
				lineStyle: {
					type: 'dashed'
				},
				smooth: true,
				showSymbol: true,
				data: Object.entries(pregnancyData).map(([key, val]) => { return [key, val] }),
			}

			let youngage_vals = {}
			let workage_vals = {}
			let oldage_vals = {}
			let welfare_ratio = {}

			//welfare population ratio
			// welfare population by age group
			year_index.forEach((y) => {
				let youngObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key < 15))
				let workingObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key >= 15 && key < 65))
				let oldObj = Object.entries(popYearXAgeData[y]).filter(([key]) => (key >= 65))

				//compute and assign
				youngage_vals[y] = Object.values(youngObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				workage_vals[y] = Object.values(workingObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				oldage_vals[y] = Object.values(oldObj).reduce((accumulator, value) => {
					return accumulator + parseInt(value[1]);
				}, 0);
				welfare_ratio[y] = parseFloat(((youngage_vals[y] + oldage_vals[y]) / workage_vals[y]).toFixed(4))
			})

			let welfareData = {
				name: movement_options[switch_value].name,
				type: 'line',
				lineStyle: {
					type: 'dashed'
				},
				smooth: true,
				showSymbol: true,
				data: Object.entries(welfare_ratio).map(([key, val]) => { return [key, val] }),
			}
			let welfareAgeData = [
				{
					name: movement_options[switch_value].name + '-노인',
					type: 'bar',
					stack: 'user',
					data: Object.entries(oldage_vals).map(([key, val]) => { return [key, val] }),
				},
				{
					name: movement_options[switch_value].name + '-유아',
					type: 'bar',
					stack: 'user',
					data: Object.entries(youngage_vals).map(([key, val]) => { return [key, val] }),
				}
			]
			let ageData = year_index.map(y=>{
				return {
					title: { text: y + '년도'},
					series: [
						{
							data: Object.entries(popYearXAgeDataM[y]).map(([key, val]) => { return {name: key, gender: 'male', value: val}}),
							stack:'user'
					},{
							data: Object.entries(popYearXAgeDataF[y]).map(([key, val]) => { return {name: key, gender: 'female', value: val*-1}}),
							stack:'user'
					}
					]
				}
			})
			let ageSeries =[
				{ name: movement_options[switch_value].name + ' 남성', type: 'bar', stack: 'user' },
				{ name:  movement_options[switch_value].name + ' 여성', type: 'bar', stack: 'user' }
			]

			return {
				model_id:  parseRes.data[0].model_id,
				switch_val:  switch_value,
				popData: popData,
				pregnancyData: pgData,
				welfareData: welfareData,
				welfareAgeData: welfareAgeData,
				userMaleAgeDataUser: popYearXAgeDataM,
				userFemaleAgeDataUser: popYearXAgeDataF,
				ageData: ageData,
				ageSeries: ageSeries,
				parameters: parameters
			}

		}catch (err){
			setIsOpen(true)
			console.error(err.message)
		}
	}
	
    const handleModelChange = async(e)=>{
        setSimRunning(true)
        
        try{
            let initialData = await loadBase(e)

            dispatch({
                type: ACTION.SET_BASE_DATA,
                payload: {
                    location: initialData.location,
                    popData: initialData.popData,
                    ageData: initialData.ageData,
                    welfareData: initialData.welfareData,
                    welfareAgeData: initialData.welfareAgeData,
                    pregnancyData: initialData.pregnancyData,
                    yearlist: initialData.yearlist,
                    maleAgeData: initialData.maleAgeData,
                    femaleAgeData: initialData.femaleAgeData,
                    model: initialData.model,
                    baseSimID: initialData.baseSimID,
                    simulation_input: initialData.simulation_input,
                    switch_val: initialData.switch_val,
                }
            })

        }catch (error) {
			setIsOpen(true)
			console.error(error.message)
		}finally{
			setSimRunning(false)
		}

    }

    const handleParamClick = (value) => {
        Simulate(value)
    }

	return (
		<MainContentWrapper className=" bg-konabg relative" >
		<APIUnavailableModal isOpen={isOpen} setIsOpen={setIsOpen} />
		<LoadingModal isOpen={isSimRunning} />
		<GenerateReportModal isOpen={isReportOpen}/>
			{/* <div className="flex flex-col select-none"> */}
				<div className="flex flex-col pt-10 pb-20 select-none px-28 bg-gray-400 bg-opacity-20  items-center ">
		
					<div className="flex flex-row space-x-7 justify-center  w-full ">
						<div className='min-w-[300px] w-2/3 text-left text-3xl font-bold text-konared pt-10 pb-5'>
							지수 설정하기
						</div>
					</div>
					<div className="flex flex-row space-x-7 justify-center  w-full ">
						
						<div className=' min-w-[300px] w-2/3 bg-white flex flex-col shadow-md'>

							<div className="h-1 bg-gray-400"></div>
							<div className="flex flex-col space-y-8 px-5 pt-5 pb-10">
								<div className='font-semibold text-xl'>시도 선택</div>
								<Listbox value={state.curSD} onChange={handleModelChange} as="div" className="z-0 h-full">
										{({ open }) => (
												<div className="relative z-20 h-full flex ">
														<Listbox.Button className={`cursor-default relative w-full h-full bg-white rounded-xl  border border-gray-300 pl-5 pr-10 py-5 text-left focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition ease-in-out duration-150 sm:text-sm sm:leading-5`}>
																<span className="block truncate">{(state.location !== null) ? LOCATION_MAPPER[state.location] : '정보 없음'}</span>
																<span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
																	<svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20"  fill="none"  stroke="currentColor">
																		<path d="M7 7l3-3 3 3m0 6l-3 3-3-3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
																	</svg>
																</span>
														</Listbox.Button>
														<Transition
																show={open}
																leave="transition ease-in duration-100"
																leaveFrom="opacity-100"
																leaveTo="opacity-0"
																className="absolute top-[50%] w-full rounded-md bg-white shadow-lg overflow-hidden -z-10"
															>
														<Listbox.Options
															static
															className="max-h-60 rounded-md pt-[35px] py-1 leading-6 shadow-xs overflow-auto focus:outline-none sm:text-sm sm:leading-5 "
														>
																{
																		(Object.keys(LOCATION_MAPPER).length>0) ? Object.keys(LOCATION_MAPPER).map((key, index) => (
																				<Listbox.Option key={index} value={key}>
																						{({ selected, active }) => (
																							<div className={`${active? "text-black bg-konahover": "text-black"} cursor-default select-none relative py-2 pl-8 pr-4`}>
																								<span className={`${selected ? "font-semibold" : "font-normal"} block truncate`}>{LOCATION_MAPPER[key]}</span>
																								{selected && (
																									<span className={`${active ? "text-konared" : "text-konared"} absolute inset-y-0 left-0 flex items-center pl-1.5`}>
																										<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" >
																											<path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"/>
																										</svg>
																									</span>
																								)}
																							</div>
																						)}
																				</Listbox.Option>

																		))
																				:
																				<Listbox.Option value="none">정보 없음</Listbox.Option>
																}
														</Listbox.Options>
														</Transition>
												</div>
										)}
								</Listbox >
								<div className='font-semibold text-xl'>시나리오 선택</div>
									<div className='flex justify-center space-x-4'>
											{movement_options.map(val => {
													return (
															<div 
																	className={`flex-1 p-5 rounded-xl border inline-flex justify-center cursor-pointer hover:bg-konared hover:text-white ${state.switch_val === val.value ? "bg-konared text-white" : ""}`}
																	onClick={() => handleParamClick(val.value)}
																	key={`${val.value}-opt`}
															>
																	<span className=''>{state.switch_val === val.value && <CheckCircleIconSolid className='h-6' />}</span>
																	<span className='flex-1 ml-5'>{val.name}</span> 
															</div>
													)
											})

											}
								</div>
							
							</div>
						</div>
					</div>
				</div>
				<div className="pt-10 pb-20 px-28 grid grid-cols-2 gap-6" ref={simResult}>
					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">
									{ state.location } 인구
								</div>
								<div className='flex text-right text-lg'>
									단위: 명
								</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 " >
							{
								state.popData ?
									<PopulationEcharts options={state.popData} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}
						</CardContent>
					</CardWrapper>
					<CardWrapper className="h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">
									연령별 인구 구조
								</div>
								<div className='flex text-right  text-lg'>
									단위: 명
								</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 flex flex-col" >
							<div className=' h-full inline-flex items-center'>
								{
									state.ageData ?
										<PopulationEcharts options={state.ageData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}
							</div>
						</CardContent>
					</CardWrapper>
					<CardWrapper className=" h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">
									가임 여성
								</div>
								<div className='flex text-right  text-lg'>
									단위: 명
								</div>
							</div>
						</CardHeader>
						<CardContent className="p-5 " >
							{
								state.pregnancyData ?
									<PopulationEcharts options={state.pregnancyData} />
									:
									<div className="h-full flex justify-center items-center"></div>
							}

						</CardContent>
					</CardWrapper>
					<CardWrapper className="col-span-2 h-[500px]">
						<CardHeader >
							<div className='w-full flex flex-row items-center'>
								<div className="flex-1">
									부양비율
								</div>
								<div className='flex text-right  text-lg'>
									단위: %, 명
								</div>
							</div>
						</CardHeader>
						<CardContent className="flex flex-row w-full space-x-4 px-5" >
							<div className='basis-1/2 py-5 ' >
								{
									state.welfareData ?
										<PopulationEcharts options={state.welfareData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}

							</div>
							<div className='basis-1/2 py-5' >
								{
									state.welfareAgeData ?
										<PopulationEcharts options={state.welfareAgeData} />
										:
										<div className="h-full flex justify-center items-center"></div>
								}

							</div>
						</CardContent>
					</CardWrapper>
				
				</div>

		</MainContentWrapper>
	)

}

export default SimulationRegional