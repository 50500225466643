import React from 'react'

const ReferenceHeader = ({children}) => {
  return (
    <div 
      className="flex bg-cover bg-center h-[240px]" 
      style={{backgroundImage: `url(${require("../../assets/images/notice_banner.jpg")})`}}
    >
      <div className="flex-1 flex items-center justify-center text-4xl font-bold text-white bg-opacity-50 bg-black">
        {children}
      </div>
    </div>
  )
}
export default ReferenceHeader