import React from 'react'
import { useForm, Controller, useController } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {bearerAuth, InvalidRequestBody, ResponseError} from "../../../utils/helpers"
import UserInfoContext, {ACTION as USER_INFO_ACTION} from "../../context/UserInfoProvider";
import zxcvbn from 'zxcvbn';
import { API_BASE_URL } from "../../../utils/constants";
import { useGlobalStateContext } from "../../context/GlobalContext";


const validationSchema = Yup.object().shape({
  password: Yup.string().required('비밀번호를 입력해주세요')
  .min(8, null),
  confirmpass: Yup.string()
    .required('비밀번호를 다시 입력해주세요')
    .oneOf([Yup.ref('password'), null], '암호가 일치하지 않습니다'),
});

const UpdatePassword = () => {

  const {user} = useGlobalStateContext();
  const {userInfoState, userInfoDispatch} = React.useContext(UserInfoContext);
  const passReq = { minStrength : 3, thresholdLength : 7 }
  const form = useForm({defaultValues: {password:"", confirmpass:""}, mode:"onChange", resolver: yupResolver(validationSchema)});

  const onSubmit = async data => {
    userInfoDispatch({type: USER_INFO_ACTION.START_SUBMIT_FORM});

    const requestOptions = {
      method: 'PATCH',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({
        transaction_id: userInfoState.transactionID, 
        form_token: userInfoState.formToken,
        password: data.password
      })
    };

    try {

      const response = await fetch(`${API_BASE_URL}/api/users/update-pw`, requestOptions);
      const userInfo = await response.json();

      if (response.status === 403) throw new ResponseError(userInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(userInfo.detail);
      
      if(response.ok && Object.keys(userInfo).length > 0 ){

        userInfoDispatch({
          type: USER_INFO_ACTION.UPDATE_PASS_SUCCESS,
          payload: userInfo
        });
      }
      else{
        throw new Error("Error in fetch request")
      }

    } catch (err) {
      if (err instanceof ResponseError) {
        form.setError('password', { type: 'custom', message: err.message }, { shouldFocus: true });
        userInfoDispatch({type: USER_INFO_ACTION.SET_LOADING, payload: false})
      }
      else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      }
      else {
        throw(err)
      }
    }

  } 


    //TODO: move to utility page
    const validatePasswordStrong = value => {
      if (value.length <= passReq.thresholdLength) return "비밀번호는 8자리 이상 입력해주세요";
      if (zxcvbn(value).score < passReq.minStrength) return "암호가 약합니다";
    };
  
    const MyInfoNewPassword = ({ control, name }) => {
      const {field, fieldState:{error, isDirty}} = useController({name, control});
      const passwordScore = zxcvbn(field.value).score;
      const passwordLength = field.value.length;
      const passwordStrong = passwordScore >= passReq.minStrength;
      const passwordLong = passwordLength > passReq.thresholdLength;
  
      const counterClass = ['inline-flex items-center justify-center text-white text-lg absolute my-auto top-0 bottom-0 right-4 h-[40px] min-w-[50px] rounded-full px-3', passwordLong ? passwordStrong ? 'bg-konasuccess' : 'bg-konawarning' : 'bg-konadanger', field.value.length > 0 ? 'visible' : 'invisible'].join(' ').trim();
      const swrapClass = ['flex h-1 divide-x-8 divide-white bg-gray-300 -ml-2 relative', field.value.length > 0 ? 'visible mb-4' : 'invisible'].join(' ').trim();
      
      //this code structure is intentional so that tailwid can purge the corresponding classes
      const styleSelector = [
        "w-[20%] bg-[#8b0000]",
        "w-[40%] bg-[#ff4500]",
        "w-[60%] bg-[#ffa500]",
        "w-[80%] bg-[#9acd32]",
        "w-[100%] bg-[#008000]",
      ]
      const strengthClass = ['h-1 absolute z-0 bg-konared transition-[width] duration-700', styleSelector[passwordScore]].join(' ').trim();
  
      let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
      if(isDirty && passwordStrong) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
      if(error || (isDirty && !passwordStrong)) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
      
  
      return (
        <div className="flex flex-col">
          <div className="flex p-4 text-lg items-center">
            <div className="flex-initial w-60 font-semibold">새 비밀번호</div>
            <div className="flex-1 flex relative">
              <input 
                {...field} 
                autoFocus
                type="password"
                placeholder="영문(대소문자), 숫자, 특수문자 포함 8자리 이상"
                className={`w-full border px-8 py-4  outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
              />
              <span className={counterClass}>{ passwordLength ? passwordLong ? `${passReq.thresholdLength}+` : passwordLength : '' }</span>
            </div>
          </div>

          <div className="ml-64 mr-4">
            <div className={swrapClass}>
              <div className={strengthClass}></div>
              <div className="flex-1 z-10"></div>
              <div className="flex-1 z-10"></div>
              <div className="flex-1 z-10"></div>
              <div className="flex-1 z-10"></div>
              <div className="flex-1 z-10"></div>
            </div>
          </div>
          {(error || field.value.length > 0) && <div className="pl-64"><span className="text-red-500 inline-flex pb-4">{(field.value.length > 0) ? validatePasswordStrong(field.value) : error?.message}</span></div>}
        </div>
        
      );
    }

  return (
    <div>
      <div className="mt-[60px] pb-5 text-lg">변경할 비밀번호를 입력해주세요.</div>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="border-t border-t-black border-b border-b-konagray/10 divide-y divide-konagray/10">
          <MyInfoNewPassword name="password" control={form.control} />

          <Controller
            render={({ field, fieldState: {isDirty, error}}) => {

              let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
              if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
              if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"

              return(
                <div className="flex flex-col">
                  <div className="flex p-4 text-lg items-center">
                    <div className="flex-initial w-60 font-semibold">새 비밀번호 확인</div>
                    <div className="flex-1">
                      <input 
                        {...field} 
                        type="password"
                        placeholder="비밀번호 다시 입력해주세요"
                        className={`w-full border px-8 py-4  outline-none transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
                      />
                    </div>
                  </div>
                  {error && <div className="pl-64"><span className="text-red-500 inline-flex pb-4">{error?.message}</span></div>}
                </div>
              )
            }}
            defaultValue=""
            name="confirmpass"
            control={form.control}
          />
        </div>

        <div className="flex justify-center pt-10 space-x-6">
          {!userInfoState.formLoading && <button onClick={() => userInfoDispatch({type: USER_INFO_ACTION.CANCEL})} type="button" className="text-lg px-8 py-4 ripple-gray min-w-[140px] inline-flex justify-center">취소</button>}
          
          <button type="submit" className="bg-konared text-white text-lg px-8 py-4 ripple-primary min-w-[140px] inline-flex justify-center" disabled={userInfoState.formLoading} >
            {userInfoState.formLoading && 
              <svg className="animate-spin mr-3 h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
            }
            <span>다음</span>
          </button>

        </div>
      </form>
    </div>  
  )
}

export default UpdatePassword