import { Dialog, Transition } from '@headlessui/react'
import React from "react"
import { NavLink } from "react-router-dom"
import { useGlobalStateContext } from "../context/GlobalContext"

const RealEstateNotSubModal = ({isOpen, setIsOpen}) => {
  const initialFocusRef = React.useRef(null)
  const {handleLogout} = useGlobalStateContext();

  return (
    <Transition appear show={isOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="m-w-72 min-h-72  transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="p-6 flex flex-col w-96 items-center text-center focus:outline-none min-h-[280px]">
                  <div className="flex-1 flex flex-col items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="url(#linear-gradient)" className="w-28 h-28">
                      <linearGradient id="linear-gradient" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                          <stop offset="0%" stopColor="#FA6161" stopOpacity="100%" />
                          <stop offset="100%" stopColor="#F7CD1C" stopOpacity="100%" />
                      </linearGradient>
                      <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
                    </svg>
                    
                    <span className="text-xl p-kr">이 기능을 사용하기 위해서는 사용자권한 up-grade가 필요합니다.</span>
                    <span className="text-xl p-kr">서비스제공범위 내용을 확인하세요.</span>
                     
                  </div>
                  <div className="flex space-x-4 mt-10">
                    <button type="button" className="text-gray-700 min-w-[120px] rounded-md px-4 py-2 border border-gray-300 shadow-sm hover:bg-gray-50 " onClick={() => setIsOpen(false)}>취소</button>
                    <NavLink to="/realestate/about" state={{scrollTo: "payment"}}  className="text-white min-w-[120px] rounded-md px-4 py-2 ripple-primary">확인하기</NavLink>
                  </div>
                  <button onClick={handleLogout} className="text-sm hover:underline mt-4">Login with another account</button>

                </div>
                

              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default RealEstateNotSubModal