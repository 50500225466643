import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import konaLogo from '../../assets/images/konai_logo.svg'
import { useForm, useController} from "react-hook-form";
import Loader3 from "../loaders/Loader3"
import { API_BASE_URL } from "../../utils/constants"
import { bearerAuth, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers"
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../context/GlobalContext"



const TosUpdateModal = ({ isOpen, setIsOpen }) => {
  const globalDispatch = useGlobalDispatchContext();
  const {user, handleLogout, checkAuth} = useGlobalStateContext();
  const [notAgree, setNotAgree] = React.useState(false);
  const initialFocusRef = React.useRef(null)

  const form = useForm({mode:"onSubmit"});

  const onSubmit = async data => {
    //only save TOS which is tracked in DB
    const terms_agreed = Object.entries(data)
    .filter(([key, val]) => val === true)
    .map(([key, val]) => parseInt(key.split("_")[1]));
    
    const requestOptions = {
      method: "PATCH",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({terms_agreed})
    };
    
    try {
      const response = await fetch(`${API_BASE_URL}/api/users/update-terms`, requestOptions);
      const parseRes = await response.json();
  
      if ([400,403].includes(response.status)) throw new ResponseError(parseRes.detail);
      if (response.status === 422) throw new InvalidRequestBody(parseRes.detail);
      if(!response.ok) throw new Error("Something went wrong. Please contact admin.")
      
      //do not await, can proceed in the background
      //this will cause globalcontext to rerender because when called, jwtoken changes
      checkAuth();  

      setIsOpen(false);
    } catch (err) {
      const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
      globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
    }
  } 


  const ErrorMessage = errors => {
    const reqMsg = "필수약관에 모두 동의해주셔야 서비스 이용할수있습니다.";
    const message = Object.keys(errors).length > 0 ? Object.values(errors)[0].type==="required" ? reqMsg : "unknown error" : null;
    return (
      <div>{message}</div>
    )
  }



  const TermsInput = ({ control, name, termInfo}) => {
    const {field} = useController({name, control, rules: { required : termInfo.is_mandatory }, defaultValue: false});
    return (
      <div className="flex items-start">
        <input className="inp-cbx hidden" id={name} type="checkbox" {...form.register(name)} />
        <label className="cbx flex-1 flex space-x-2" htmlFor={name}>
          <span className="h-5 w-5 flex justify-center items-center"><svg className="h-2/3 w-2/3" viewBox="0 0 12 9"><polyline points="1 5 4 8 11 1"></polyline></svg></span>
          <p className="p-kr flex-1 text-md text-konagray -mt-[2px]">{`[${termInfo.is_mandatory ? '필수' : '선택'}] ${termInfo.title}`}</p> 
        </label>
          
        <a className="ml-4 border border-gray-300 rounded-md py-1 px-2 text-sm text-center text-konagray hover:bg-gray-100" target="_blank" rel="noopener noreferrer" href={termInfo.url}>보기</a>
      </div>
     
    );
  }


  return (
    <Transition appear show={isOpen} as={React.Fragment} initialFocus={initialFocusRef}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div className="p-6 flex flex-col w-[400px] focus:outline-none min-h-[500px]">
                  <div className="flex justify-end"><button onClick={() => setNotAgree(true)} className="hover:text-orange-400"><XMarkIcon className="h-5" /></button></div>
                  <div className="flex flex-col space-y-4 items-start focus:outline-none" ref={initialFocusRef} tabIndex={-1}>
                    <img src={konaLogo} className="h-4" alt="" />
                    <h2 className="text-2xl font-bold">신규·변경 약관에 동의해주세요!</h2>
                  </div>
                  {!notAgree
                    ? <form className="flex-1 flex flex-col" onSubmit={form.handleSubmit(onSubmit)}>
                        <div className="flex-1">
                            {(() => {
                              if(user && user?.userUpdatedTos.length > 0 ){
                                return(
                                  <div className="flex flex-col space-y-4 py-10">
                                    

                                    {user.userUpdatedTos.map((item, index) => {
                                        return (
                                          <TermsInput name={`t_${item.id}`} control={form.control} termInfo={item} key={index} />
                                        );
                                      })
                                    }
                                  </div>
                                )
                              }
                              else{
                                return (
                                  <div className="mt-10 flex flex-col items-center space-y-2">
                                    <Loader3 />
                                    <span>약관 내용을 조회 중입니다</span>
                                  </div>
                                )
                              }
                            })()}
                        </div>
                        <div className="w-full flex flex-col items-center" >
                          <div className="text-konared text-center mb-4"><ErrorMessage {...form.formState.errors} /></div>
                          <button type="button" className="mb-1 hover:underline underline-offset-2 hover:text-konared" onClick={() => setNotAgree(true)}>동의하지 않습니다</button>
                          <button type="submit" className="w-full py-4 rounded-xl leading-none text-xl text-center text-white font-semibold ripple-primary">동의합니다</button>
                        </div>
                      </form>
                    : <div className="flex-1 flex flex-col">
                        <div className="flex-1 mt-20 p-kr text-lg text-konagray text-center">필수 이용약관에 동의하지 않으면 KONASD 서비스를 이용할 수 없습니다.</div>
                        <div className="flex space-x-4">
                          <button onClick={() => setNotAgree(false)} type="button" className="w-full py-4 rounded-xl leading-none text-xl text-center text-white font-semibold ripple-gray-outline">돌아가기</button>
                          <button onClick={handleLogout} type="button" className="w-full py-4 rounded-xl leading-none text-xl text-center text-white font-semibold ripple-primary">로그아웃</button>
                        </div>
                      </div>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default TosUpdateModal