import React from 'react'
import { useForm, Controller, useWatch} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { bearerAuth, InvalidRequestBody, ResponseError } from "../../../utils/helpers";
import { API_BASE_URL, ARS_AUTH_STATUS } from "../../../utils/constants";
import BankSelectModal from "../../payment/BankSelectModal";
import ArsModal from "../../../components/payment/ArsModal";
import BillingContext, { ACTION as BILLING_ACTION, BILLING_PAGES } from "../../context/BillingProvider";
import { getBankList } from "../../context/PaymentProvider";
import { useGlobalStateContext } from "../../context/GlobalContext";


const validationSchema = Yup.object().shape({
  bankAccount: Yup.number()
    .typeError('숫자만 입력해주세요')
    .required("계좌번호를 입력해주세요"),
});


export const ACTION = {
  OPEN_SELECT_BANK_MODAL: "open-select-bank-modal",
  SELECT_BANK_DONE: "select-bank-done",
  SET_ARS_LOADING: "set-ars-loading",
  SET_ARS_STATUS: "set-ars-status",
  START_ARS_VERIFY: "start-ars-verify",
  END_ARS_VERIFY: "end-ars-verify",
}

export const initialValue = {
  selectBankModalOpen: false,
  selectedBank: null,
  bankAccount: null,
  bankAccRegNo: null,
  arsLoading: false,
  arsInfo: null,
  arsStatus: null,
  arsModalOpen: false
}

const reducer = (state, {type, payload}) => {
  switch(type){
    case ACTION.OPEN_SELECT_BANK_MODAL:
      return {...state, selectBankModalOpen: payload}
    case ACTION.SELECT_BANK_DONE:
      return {...state, selectedBank: payload, selectBankModalOpen: false};
    case ACTION.SET_ARS_LOADING:
      return {...state, arsLoading: payload};
    case ACTION.SET_ARS_STATUS:
      return {...state, arsStatus: payload};
    case ACTION.START_ARS_VERIFY:
      return {
        ...state, arsModalOpen: true, 
        arsLoading: false, 
        arsInfo: payload.arsInfo, 
        bankAccRegNo: payload.bankAccRegNo,
        bankAccount: payload.bankAccount
    };
    case ACTION.END_ARS_VERIFY:
      return {...state, arsModalOpen: false, arsInfo: null, arsStatus: null};
    default: 
      throw new Error(`Unknown action type: ${type}`);
  }
}


const BillingBankRegistration = () => {
  const {user} = useGlobalStateContext();
  const [state, dispatch] = React.useReducer(reducer, initialValue);
  const {billingState, billingDispatch} = React.useContext(BillingContext);

  const form = useForm({mode:"onChange", resolver: yupResolver(validationSchema)});
  const bankAccount = useWatch({
    control: form.control,
    name: "bankAccount",
  });

  React.useEffect(() => {

    const loadBankList = async () => {
      let bankList = await getBankList(user);
      bankList = Object.fromEntries(bankList.map(item => [item.bank_code, item.bank_name]));
      billingDispatch({type: BILLING_ACTION.LOAD_BANK_LIST, payload: bankList});
    }

    if(billingState.bankList == null || billingState.bankList.length <= 0){
      loadBankList();
    } 
  },[billingState.bankList]);


  React.useEffect(() => {
    
    const needConfirmTOS = billingState.termsList.filter(terms => terms.is_mandatory === true && !terms.is_agree).map(terms => terms);
        
    if(needConfirmTOS.length > 0){
      billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.OAPI_TERMS});
    }
    else if(billingState.userInfo.oapiInfo.oapi_id === null){
      billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.OAPI_REGISTER});
    }


    
  },[]);



  const verifyBankAccount = async (bankAccount, selectedBank, oapi_id ) => {

    const param = {
      bank_account: bankAccount,
      bank_code: selectedBank,
      oapi_id: oapi_id
    }


    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify(param)
    };

    try {

      const response = await fetch(`${API_BASE_URL}/api/payment/verify-bankaccount`, requestOptions);
      const verifyInfo = await response.json();

      
      if ([400,403].includes(response.status)) throw new ResponseError(verifyInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(verifyInfo.detail);
      
      if(response.ok){
        return true;        
      }
      else{
        throw new Error("Error in fetch request")
      }

    } catch (err) {
      if (err instanceof ResponseError) {
        form.setError('bankAccount', { type: 'custom', message: err.message });
        throw(err)
      } else if (err instanceof InvalidRequestBody) {
        throw(err)
      } else {
        throw(err)
      }
    }
    
  } 


  const getArsNumber = async (oapi_id) => {
    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify({oapi_id: oapi_id})
    };

    try {

      const response = await fetch(`${API_BASE_URL}/api/payment/ars`, requestOptions);
      const arsInfo = await response.json();

      if ([400,403].includes(response.status)) throw new ResponseError(arsInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(arsInfo.detail);
      
      if(response.ok){
        return arsInfo;        
      }
      else{
        throw new Error("Error in fetch request")
      }

    } catch (err) {
      if (err instanceof ResponseError) {
        form.setError('bankAccount', { type: 'custom', message: err.message });
        throw(err);
      } else if (err instanceof InvalidRequestBody) {
        throw(err);
      } else {
        throw(err);
      }
    }
    
  } 


  const registerBank = async (bankAccount, bankCode, bankName, oapi_id) => {

    const param = {
      oapi_id: oapi_id,
      bank_account: bankAccount,
      bank_code: bankCode,
      bank_name: bankName,
    }

    const requestOptions = {
      method: 'POST',
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
      body: JSON.stringify(param)
    };

    try {


      const response = await fetch(`${API_BASE_URL}/api/payment/register-bankaccount`, requestOptions);
      const registerInfo = await response.json();


      if (response.status === 403) throw new ResponseError(registerInfo.detail);
      if (response.status === 422) throw new InvalidRequestBody(registerInfo.detail);
      
      if(response.ok){
        return registerInfo;        
      }
      else{
        throw new Error("Error in fetch request")
      }

    } catch (err) {
      if (err instanceof ResponseError) {
        form.setError('bankAccount', { type: 'custom', message: err.message });
        throw(err)
      } else if (err instanceof InvalidRequestBody) {
        throw(err)
      } else {
        throw(err)
      }
    }
    
  } 


  const setBankInfo = (user) => {


    fetch(`${API_BASE_URL}/api/payment/userinfo`, {
      method: "GET",
      headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
    })
    .then(response => {
      if(response.ok) return response.json();
    })
    .then(data => {
      billingDispatch({type: BILLING_ACTION.SET_BANK_ACCOUNT, payload: {bank_account: data.bank_account, bank_code: data.bank_code}});
    })
    .catch(error => {
        throw(error);
    })    
  } 

  
  const openBankSelectionModal = (open = false) => {
    dispatch({type: ACTION.OPEN_SELECT_BANK_MODAL, payload: open})
  }

  const setSelectedBank = (bankCode) => {
    dispatch({type: ACTION.SELECT_BANK_DONE, payload: bankCode});
  }
  

  let arsInterval;
  const arsVerification = () => {
    
    let timerSeconds = 0;
    arsInterval = !arsInterval && setInterval(() => {
      timerSeconds++; //reserved variable which will be used if limit of time interval will be needed

      fetch(`${API_BASE_URL}/api/payment/bankreg-status`, {
        method: "POST",
        headers: {"Content-Type" : "application/json", "Authorization" : bearerAuth(user)},
        body: JSON.stringify({regNo: state.bankAccRegNo})
      })
      .then(response => {
        if(!response.ok) throw new Error(`Error! status: ${response.status}`); 
        return response.json();
      })
      .then(data => {
        const regStatus = data.status;

        if (regStatus === ARS_AUTH_STATUS.BANK_ACC_REGISTERED) {
          clearInterval(arsInterval);
          setBankInfo(user);
        }
        else if (regStatus === ARS_AUTH_STATUS.ARS_AUTH_FAILED || regStatus === ARS_AUTH_STATUS.BANK_ACC_REG_FAILED) {
          clearInterval(arsInterval);
        }


        dispatch({type: ACTION.SET_ARS_STATUS, payload: regStatus});
      
      })
      .catch(error => {
          clearInterval(arsInterval);
          dispatch({type: ACTION.END_ARS_VERIFY});
          console.error(error.message);
      });



      //disable timer | already handled if modal is closed or if ars was canceled
      // if (timerSeconds > 10) clearInterval(arsInterval)
    }, 1000)
    
  }

  React.useEffect(() => {
    if(!state.arsModalOpen) return;
    arsVerification();
    
    return () => clearInterval(arsInterval);
  }, [state.arsModalOpen])


  const closeArs = () => {
    dispatch({type: ACTION.END_ARS_VERIFY});
    billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.BILLIN_MAIN})

  }
  
  const onSubmit = async data => {
    //show loading animation while submitted bank account is being verified and registerBank is fetched
    dispatch({type: ACTION.SET_ARS_LOADING, payload: true});

    try {
      //check if bankacount is valid
      await verifyBankAccount(data.bankAccount, state.selectedBank, billingState.userInfo.oapiInfo.oapi_id);

      //get arsnumber
      const arsInfo = await getArsNumber(billingState.userInfo.oapiInfo.oapi_id);
      

      if(arsInfo?.auth_number){

        const registerInfo = await registerBank(
          data.bankAccount, 
          state.selectedBank, 
          billingState.bankList[state.selectedBank],
          billingState.userInfo.oapiInfo.oapi_id
        )

        //set ARS verification using mocal
        dispatch({type: ACTION.START_ARS_VERIFY, payload: {arsInfo, bankAccRegNo: registerInfo.bankAccRegNo, bankAccount: data.bankAccount}});

      }

    } catch (err) {
      if (err instanceof ResponseError) {
        console.error("ResponseError", err.message, err.__detail);
        dispatch({type: ACTION.SET_ARS_LOADING, payload: false});
      } else if (err instanceof InvalidRequestBody) {
        console.error("InvalidInput", err.message, err.__detail);
      } else {
        throw(err)
      }
    }
    
  }


  return (
    <form onSubmit={form.handleSubmit(onSubmit)} autoComplete="off">
      <BankSelectModal isOpen={state.selectBankModalOpen} setIsOpen={openBankSelectionModal} bankList={billingState.bankList} setSelectedBank={setSelectedBank} />
      <ArsModal arsState={state} closeArs={closeArs} oapiInfo={billingState?.userInfo?.oapiInfo} bankList={billingState.bankList} />
      <div className="flex flex-col">
        <div className="font-bold text-2xl text-center">계좌 연결</div>
        {(bankAccount?.length > 0 && state.selectedBank !== null)
        ? <div className="mt-4 bg-konabg text-center p-4"><span className="font-semibold">ARS전화 인증을 요청해주세요.</span> <br /> <span className="text-konared">화면과 음성으로 안내하는</span> <br /> <span>주 자리 솟자를 입력하고 통화를 종류해주세요</span></div>
        :<div className="mt-4 bg-konabg text-center p-4">서비스에 연결할 본인 명의의 <br /> 계좌번호를 입력해주세요.</div>

        }
        <div className="mt-8">
          <div className=" flex flex-col">

            <div className="flex space-x-4">
              <div onClick={() => openBankSelectionModal(true)} className="flex-initial w-3/4 py-2 px-4 border border-konagray/40 rounded-lg flex items-center space-x-2 cursor-pointer">
                {state.selectedBank !== null
                  ? <><img className="h-6" src={require(`../../../assets/images/bank_logo/img_logo_${state.selectedBank}.png`)} alt="" /><span className="">{billingState.bankList[state.selectedBank]}</span></>
                  : <span className="text-konasub/40">은행을 선택해주세요</span>
                }
                
              </div>
              <button onClick={() => openBankSelectionModal(true)}  type="button" className="flex-initial w-1/4 py-2 px-4 border border-konared text-konared rounded-lg">은행선택</button>
            </div>

            <Controller
              render={({ field, fieldState: {isDirty, error}}) => {

                let formPlusStyle = "focus:ring-konainfo focus:border-blue-500"
                if(isDirty) formPlusStyle = "focus:ring-konasuccess  focus:border-green-500"
                if(error) formPlusStyle = "focus:ring-konadanger  focus:border-red-500"
                return(
                  <>
                    <input 
                      {...field} 
                      placeholder="계좌번호를 입력해주세요"
                      className={`mt-4 py-2 px-4 placeholder-konasub/40 border border-konagray/40 rounded-lg  outline-none mb-4 transition duration-0 focus:duration-300 focus:ring-opacity-30 focus:ring-4 ${formPlusStyle}`}
                    />
                    {<span className="text-sm font-semibold text-konared">{error?.message}</span>}
                  </>
                )
              }}
              defaultValue=""
              name="bankAccount"
              control={form.control}
            />

          </div>
        </div>

          
          <div className="flex flex-col space-y-2 py-10 items-center">
            <button  type="submit" className="bg-konared text-white inline-flex items-center justify-center w-48 leading-5 rounded py-4 ripple-primary" disabled={state.arsLoading}>
              {state.arsLoading && 
                <svg className="animate-spin mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              }
              <span>ARS 인증 요청하기</span>
            </button>

            {!state.arsLoading && <button type="button" onClick={() => billingDispatch({type: BILLING_ACTION.SET_FRAME_VIEW, payload: BILLING_PAGES.BILLIN_MAIN})} className="text-sm">Cancel</button>}
          </div>
        


      </div>
    </form>
  )
}

export default BillingBankRegistration