import React from 'react'
import { NavLink, useParams } from "react-router-dom"
import MainContentWrapper from "../../components/MainContentWrapper"
import {HiHome, HiChevronRight} from 'react-icons/hi'
import Echarts from "../../components/charts/Echarts"
import { getChartOptionDataLibDetails } from "../../components/charts/EchartOptions"
import ParamDescData from '../../assets/param-description.json'
import { isEmpty } from "../../utils/helpers";
import Loader3 from "../../components/loaders/Loader3";
import { useReStateContext } from "../../components/context/RealEstateContext";
import { SIM_PARAMETERS } from "../../components/context/ReMysimContext"

const ParamDescById = Object.fromEntries(
  Object.entries(ParamDescData)
  .map(([key, val], index) => [val.linkID,  {varName: key, ...val}])
)

const DataLibraryDetails = () => {
  const {linkID} = useParams();
  const reState = useReStateContext();

  if(!(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds)))
  {
    var paramDesc = ParamDescById[linkID];
    var reVariables = reState.model.byId[reState.currentModelId]?.data.byId[reState.currentLocCode].variable;
    var inputVarInfo = reVariables[SIM_PARAMETERS[paramDesc.varName].inputVarName];
    var [lastDate, lastDataVal] = inputVarInfo?.data_in[0].value.at(-1)
  }
  

  return (
    <MainContentWrapper className="flex flex-col  max-w-[1400px] mx-auto">
      {(isEmpty(reState.model.allIds) || isEmpty(reState.address.allIds))
        ? <div className="flex-1 flex justify-center items-center"><Loader3 /></div>
        : <div className="pt-10 pb-20 px-28">
            {/* breadcrumbs */}
            <nav className="flex pb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
                <li className="inline-flex items-center">
                  <NavLink to="/realestate" className="inline-flex items-center text-sm font-medium hover:text-konared">
                    <span className="mr-2"><HiHome size={16} /></span>
                    Home
                  </NavLink>
                </li>
                <li>
                  <div className="flex items-center">
                    <span><HiChevronRight size={24} /></span>
                    <NavLink to="/re-datalibrary" className="ml-2 hover:text-konared">데이터 살펴보기</NavLink>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <span className=""><HiChevronRight size={24} /></span>
                    <span className="ml-2">데이터 세부 정보</span>
                  </div>
                </li>
              </ol>
            </nav>
            {/* end of breadcrumbs */}

            <div className="flex items-end">
              <div className="flex-1 flex flex-col">
                
                <h1 className="text-4xl font-bold">{paramDesc.title}</h1>

              </div>
              <div className="flex flex-col items-end">
                <span className="text-base text-gray-600">단위: {paramDesc.unit}</span>
                <span className="text-4xl font-bold text-konared">{lastDataVal.toLocaleString()}</span>
              </div>
            </div>
            <div className="mt-2 h-[350px]"><Echarts options={getChartOptionDataLibDetails(inputVarInfo.data_in[0].value)} /></div>
            
            <div className="mt-10 text-xl p-kr text-justify leading-10">{paramDesc.description}</div>
            <div className="mt-10 text-xl flex space-x-3">
              <div>Reference: </div>
              <div className="flex-1 flex flex-col space-y-3">
                {paramDesc.link.map((link, index) => {
                  return (
                    <a key={index} className="font-semibold text-blue-700 hover:underline" target="_blank" rel="noopener noreferrer" href={link.href}>{link.text}</a>
                  );
                })}
                
              </div>
            </div>

            <div className="flex justify-center py-10"><NavLink to="/realestate/datalibrary" className="w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-primary">BACK</NavLink></div>
          </div>
      }
    </MainContentWrapper>
  )
}

export default DataLibraryDetails