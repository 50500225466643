import React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { TrashIcon } from '@heroicons/react/24/outline'
import "../../assets/css/withcheck-loader.css"
import { delay } from "../../utils/helpers"
import { useNavigate } from "react-router-dom"

const DeletePostModal = ({isOpen, info, closeFn, confirmCallback, parentUrl}) => {
  const navigate = useNavigate();
  const initialFocusRef = React.useRef(null);
  const contentRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  if(info) contentRef.current = info;


  async function onClickConfirm () {
    setIsLoading(true);
    await confirmCallback();
    setIsSuccess(true);
    await delay(600);
    navigate(parentUrl);
  }

  return (
    <Transition show={isOpen} as={React.Fragment} >
      <Dialog as="div" className="relative z-[9000]" onClose={closeFn} initialFocus={initialFocusRef}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">Delete Comment</Dialog.Title>
                      <div className="mt-2">
                        <p className="truncate block w-96 text-sm text-gray-500 leading-7 break-words p-kr">{contentRef.current?.content}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 focus:outline-none" ref={initialFocusRef} tabIndex={-1}>

                    

                    <button
                      type="button"
                      className="inline-flex w-full min-w-[60px] justify-center items-center rounded-md border border-transparent focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-konared px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700   sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => onClickConfirm()}
                    >
                      {(!isLoading && !isSuccess) && <span>확인</span>}
                      {(isSuccess) && <svg className="checkmarkSmallWhite h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmarkSmallWhite__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>}
                      {(isLoading && !isSuccess) && 
                        <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      }
                      
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full min-w-[60px] justify-center rounded-md border border-gray-300 focus:outline-none  focus-visible:ring-2 focus-visible:ring-blue-400 focus-visible:ring-offset-2 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50  sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={closeFn}
                    >
                      취소
                    </button>
                </div>
              </Dialog.Panel>


            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default DeletePostModal