import React, { useReducer, useEffect } from 'react'
import MainContentWrapper from '../../components/MainContentWrapper'
import { NavLink } from "react-router-dom"
import { API_BASE_URL, SERVICES } from '../../utils/constants'
import { bearerAuth } from '../../utils/helpers'
import { handleError } from '../../utils/pop-handle-response'
import { useGlobalStateContext } from "../../components/context/GlobalContext"

const ACTION = {
	SET_MAIN_DATA: 'set-main-data',
}

const initialState = {
	isSubscribed: false
}

const reducer = (state, { type, payload }) => {
	switch (type) {
		case ACTION.SET_MAIN_DATA:
			return {
				...state,
				isSubscribed: payload.isSubscribed
			}
		default:
			throw new Error(`Unknown action type: ${type}`);
	}
}
function SimulationWorkforce() {

	const [state, dispatch] = useReducer(reducer, initialState)
	const { user } = useGlobalStateContext();

	const checkSubscribed = async () => {

		try {

			const requestOptions = {
				method: 'GET',
				headers: { "Authorization": bearerAuth(user) }
			};
			const query = `?service_id=${SERVICES.population.id}`;
			const response = await fetch(`${API_BASE_URL}/api/subscription/verify${query}`, requestOptions);
			if (response.ok) {
				return true;
			}

			if (response.status === 403) throw new Error(handleError(response.status))
			throw new Error(`Failed to validate user subscription`);


		} catch (err) {
			console.error(err.message)
		}
	}



	useEffect(() => {

		const loadDefault = async () => {

			const isSubscribed = await checkSubscribed()

			dispatch({
				type: ACTION.SET_MAIN_DATA,
				payload: {
					isSubscribed: isSubscribed
				}
			})
		}

		loadDefault();

	}, [])



	return (
		<MainContentWrapper className=" text-center">
			<div className="flex flex-col items-center space-y-8 py-20">
				{/* <div className='h-fit text-center flex flex-col text-4xl space-y-8 '> */}
					<div className='h-[400px] pt-10 inline-block-flex  space-y-4 '>
						{/* <div className='flex justify-center'>
							<FaCogs size={120} className="text-gray-300" />
						</div> */}
                    	<div className="flex justify-center"><img className=" h-44" src={require(`../../assets/images/page_building.png`)} alt="" /></div>
						<h1 className="pt-2 pb-4 text-2xl font-bold">산업 인력 <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#456BFC] to-[#209CFC]">시뮬레이션</span><br/> 페이지 준비중입니다.</h1>

						<NavLink to="/pop-simulation-national" className="">
							<button className="bg-konared text-base text-white inline-flex leading-none rounded-lg p-4 hover:shadow-md hover:shadow-gray-300">EXPLORE 전국 시뮬레이션</button>
						</NavLink>
					</div>
				{/* </div> */}
			</div>
		</MainContentWrapper >
	)
}

export default SimulationWorkforce