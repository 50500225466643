import React from 'react'
import { UserIcon, BuildingOffice2Icon } from '@heroicons/react/24/solid'
import { ACCOUNT_TYPE } from "../../utils/constants";
import RegistrationContext, {ACTION as REG_ACTION} from "../context/RegistrationProvider";
import { useNavigate } from "react-router-dom";


const SelectRegType = () => {
  const navigate = useNavigate();
  const {registrationDispatch} = React.useContext(RegistrationContext);

  const registrationTypeSelect = (regType) => {

    registrationDispatch({type: REG_ACTION.SET_REG_TYPE, payload: regType});

    if(regType === ACCOUNT_TYPE.PERSONAL){
      navigate("/register/preg-terms");
    }
    else if (regType == ACCOUNT_TYPE.CORPORATE){
      navigate("/register/creg-terms");
    }
    else{
      throw new Error(`Unknown registration type: ${regType}`);
    }
   
  }

  return (
    <div className="px-28 pt-10 pb-20">
      <div className="flex flex-col items-center py-8">
        <h1 className="font-bold text-3xl">회원가입 유형 선택</h1>
        <p className="font-bold text-xl text-konagray mt-8">개인회원과 기업회원 중 원하는 유형을 선택해주세요.</p>
        <p className="text-xl text-konagray">코나 예측 서비스는 개인회원과 기업회원 두 개의 유형으로 가입할 수 있습니다.</p>
      </div>
      <div className="w-full max-w-5xl mx-auto flex py-12 border-t-2 border-b border-t-konagray border-b-gray-300 divide-x">
        <div className="flex-1 flex flex-col items-center">
          <div className="w-32 bg-gray-400 p-4 rounded-full">
            <UserIcon className="text-white" />
          </div>
          <button className="w-2/3 my-8 py-4 bg-konared rounded-xl text-xl text-center  text-white font-semibold ripple-primary" onClick={() => registrationTypeSelect(ACCOUNT_TYPE.PERSONAL)}>개인회원가입</button>
          <div className="flex w-full justify-center space-x-4 text-base">
            <div className="text-right text-xl font-bold">개인회원을 위한 <br /> 서비스</div>
            <div className="">
              <ul className="list-disc ml-4 text-gray-400 font-semibold">
                <li>부동산 가격 예측</li>
                <li>부동산 시뮬레이션 보고서 제공</li>
                <li>부동산 데이터 제공</li>
              </ul>
            </div>
          </div>
        </div>
        
        <div className="flex-1 flex flex-col items-center">
          <div className="w-32 bg-gray-400 p-5 rounded-full">
            <BuildingOffice2Icon className="text-white" />
          </div>
          <button className="w-2/3 my-8 py-4 rounded-xl text-xl text-center  text-white font-semibold ripple-blue" onClick={() => registrationTypeSelect(ACCOUNT_TYPE.CORPORATE)}>기업회원가입</button>
          <div className="flex w-full justify-center space-x-4 text-base">
            <div className="text-right text-xl font-bold">기업회원을 위한 <br /> 서비스</div>
            <div className="">
              <ul className="list-disc ml-4 text-gray-400 font-semibold">
                <li>부동산 가격 및 인구 예측</li>
                <li>시뮬레이션 보고서 제공</li>
                <li>컨설팅</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectRegType