import React from 'react'

export const CardWrapper = ({ className, children }) => {
  return (
    <div className={`flex flex-col rounded-xl bg-white shadow-md overflow-hidden ${className}`}>
      {children}
    </div>
  )
}
export const CardHeader = ({ bgcolor = "bg-konared", title, className, children }) => {
  return (
    <div className={`flex items-center h-14 text-white  text-xl  px-7 ${bgcolor} ${className}`}>{children ? children : title}</div>
  )
}

export const CardHeaderFilter = ({ title, className, children }) => {
  return (
    <div className={`flex items-center bg-konagray h-14 text-white text-xl px-5 ${className}`}>{children ? children : title}</div>
  )
}

export const CardContent  = ({className, children }) => {
  return (
    <div className={`flex-1 ${className}`}>{children}</div>
  )
}

export const CardFooter = ({children}) => {
  return (
    <div className="flex items-center px-7 h-12 text-sm">{children}</div>
  )
}
