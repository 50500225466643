import { NavLink, useLocation, useNavigate } from "react-router-dom";
import React, { useRef, useEffect } from 'react'
import MainContentWrapper from "../../components/MainContentWrapper";
import { ACCOUNT_ROLE, ACCOUNT_TYPE, API_BASE_URL, SERVICES } from "../../utils/constants";
import { BuildingOffice2Icon, UserCircleIcon} from '@heroicons/react/24/outline'
import { useGlobalStateContext } from "../../components/context/GlobalContext";


function MainPopulation() {
  const {user, handleLogout} = useGlobalStateContext();
  const reAboutRef = useRef(null)
  const paymentDivRef = useRef(null)
  const featureDivRef = useRef(null)
  const location = useLocation();
  const navigate = useNavigate();
  const [productList, setProductList] = React.useState();
  const [isOpen, setIsOpen] = React.useState(true);

  const executeScroll = () => reAboutRef.current.scrollIntoView()
  // run this function from an event handler or an effect to execute scroll 


  React.useEffect(() => {
    if(location?.state?.scrollTo === "payment") {
      paymentDivRef.current.scrollIntoView()    
    }else if(location?.state?.scrollTo === "features") {
      featureDivRef.current.scrollIntoView()    
    }
  },[location]);


  React.useEffect(() => {

    const loadProduct = async () => {
      try {
        const requestOptions = {
          method: 'GET',
          headers: {"Content-Type" : "application/json"} 
        };
        const query = `?page_size=all&is_active=true&service_id=${SERVICES.population.id}`
        const response = await fetch(`${API_BASE_URL}/api/product${query}`,requestOptions);
        const products = await response.json();
        
        if(products.data.length > 0){
          const productList = Object.fromEntries(products.data.map(item => [item.id, item]));
          setProductList(productList);
        }
        
  
      } catch (err) {
        console.error(err.message)
      }
    }

    //load products
    loadProduct();

  },[]);

  const handleCorporateRegistration = () => {
    navigate("/register", {state:{gotoUrl: "/population", prevPath: "/population", scrollTo: "payment", payload: {scrollTo:"payment"}, accountType: ACCOUNT_TYPE.CORPORATE}});
  }

  return (
    <MainContentWrapper>
      <div className="flex flex-col items-center space-y-8 py-20">
        <div className="flex flex-col space-y-4">
          <h1 className="text-[70px] leading-none font-bold">보다<span className="bg-clip-text text-transparent bg-gradient-to-r from-[#456BFC] to-[#209CFC]">정교한</span></h1>
          <h1 className="text-[70px] leading-none font-bold">인구 예측법</h1>
        </div>
        <div className="flex flex-col items-center text-xl">
          <p>미국 MIT의 System Dynamics 의 방법론을 우리나라 인구예측에 적용하여 </p>
          <p> 2060년까지 행정동별 인구를 예측하였습니다. 출산율이나 지역별 </p>
          <p> 인구이동 등 인구 관련 정보를 활용하여 다양한 인구 정책별 미래 </p>
          <p> 인구의 변화를 예측할 수 있습니다 </p>
        </div>
        <button onClick={executeScroll} className="bg-konared text-lg text-white inline-flex leading-none rounded-lg px-5 py-4 hover:shadow-md hover:shadow-gray-300">더 알아보기</button>
      </div>


      <div className="h-[610px] flex items-center justify-center" style={{ backgroundImage: `url(${require("../../assets/images/middle_banner_1.png")})` }}>
        <img className="w-[700px]" src={require("../../assets/images/laptop.png")} alt="" />
      </div>



      <div className="py-20 bg-konabg flex flex-col items-center space-y-14 scroll-mt-[100px]" ref={reAboutRef}>
        <h1 className="text-4xl font-bold"><span className="text-konared">인구 예측 서비스</span> 특장점</h1>

        <div className="grid grid-cols-1 gap-8">
          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">
                1. 보다 정교하고 세분화된 인구 예측 시스템
              </div>
              <div className="p-kr pt-5  pl-14 text-xl">
                <ul className="list-disc">
                  <li>System Dynamics 방법론을 적용한 지역별(시군구, 읍면동) 장기(2010년~2060년) 인구 예측 시스템 </li>
                  <li>전국 인구이동(전입전출) 데이터 정보 제공 및 성별/연령별(0세~100세) 인구 예측 정보 제공</li>
                </ul>
              </div>
            </div>
          </div>

        </div>
        <div className="grid grid-cols-1 gap-8">
          <div className="w-[1049px] min-h-[185px] bg-white rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
            <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
            <div className="bg-white relative z-20 p-10">
              <div className="text-3xl font-bold text-konared bg-red">
                2. 사용자 기반(User Friendly) 프로그램
              </div>
              <div className="p-kr pt-5 pl-14 text-xl">
                <ul className="list-disc">
                  <li>
                    다양한 변수를 활용해 시나리오 분석이 가능한 시뮬레이션 기능으로 의사결정 지원 가능
                  </li>
                  <li>
                    사용자 요구사항(Needs)에 부합하는 인구예측정보 제공 및 다양한 인구지표 상세 분석 가능
                  </li>
                  <li>
                    국내는 물론 국제연합(UN)의 현황지표, 장래 예측치와 같은 국제 인구 데이터 제공 및 비교 분석 가능
                  </li>
                </ul>
              </div>
            </div>-
          </div>

        </div>

      </div>
      <div className="px-28 py-20  scroll-mt-[100px]"  ref={featureDivRef}>
        <div className="flex flex-col justify-center items-center space-y-14">
          <span className="font-bold text-4xl">Subscription Features</span>

          <div className="grid grid-cols-1 gap-8">
            <div className="w-[1049px] min-h-[250px] rounded-lg p-2 shadow-md relative z-0 overflow-hidden border border-gray-100">
              <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
              <div className="bg-white  relative z-20 p-10 h-full flex flex-row items-center">
                <div className="flex-1 text-3xl font-bold text-konared bg-red">
                  전국 & 지역별 인구 예측
                </div>
                <div className="flex justify-center">
                  <img className=" h-64" src={require(`../../assets/images/sc_popforecast.png`)} alt="" />
                </div>
              </div>
            </div>
          </div>
          
          <div className="grid grid-cols-1 gap-8">
            <div className="w-[1049px] min-h-[250px] rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
              <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
              <div className="bg-white relative z-20 p-10 h-full flex flex-row items-center">
                <div className="flex-1 text-3xl font-bold text-konared bg-red">
                  가구수와 소멸위험
                </div>
                <div className="flex justify-center">
                  <img className=" h-64" src={require(`../../assets/images/sc_household.png`)} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8">
            <div className="w-[1049px] min-h-[250px] rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
              <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
              <div className="bg-white relative z-20 p-10 h-full flex flex-row items-center">
                <div className="flex-1 text-3xl font-bold text-konared bg-red">
                  정책 시뮬레이션
                </div>
                <div className="flex justify-center">
                  <img className=" h-64" src={require(`../../assets/images/sc_simulation.png`)} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8">
            <div className="w-[1049px] min-h-[250px] rounded-lg p-2 shadow-md relative z-0 overflow-hidden">
              <div className="h-10 w-10 bg-konared absolute top-0 left-0 z-10"></div>
              <div className="bg-white relative z-20 p-10 h-full flex flex-row items-center">
                <div className="flex-1 text-3xl font-bold text-konared bg-red">
                인구 동향 & 지표
                </div>
                <div className="flex justify-center">
                  <img className=" h-64" src={require(`../../assets/images/sc_data.png`)} alt="" />
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      
      
      <div className="px-28 py-20 scroll-mt-[100px]" ref={paymentDivRef}>
        <div className="flex flex-col justify-center items-center space-y-4">
          <span className="font-bold text-4xl">인구 예측 서비스 이용 요금 안내</span>
          {((user && user.user_info.account_type === ACCOUNT_TYPE.PERSONAL)) &&
            <div className="flex flex-col items-center text-base">
              <p className="p-kr">전문가용 시나리오 분석을 원하는 경우 아래의 이용요금을 결제한 후 이용이 가능합니다.</p>
              <p className="p-kr mt-4">결제 또는 이용요금 관련 문의(세금계산서 발행 등)는 다음 메일로 연락주시기 바랍니다</p>
              <p>(문의처 : 코나아이㈜ 시스템다이내믹스팀 <span className="font-semibold">ikyjeong@naver.com</span>)</p>
            </div>
          }
        </div>

        <div className="mt-11 flex flex-col space-y-8 mx-auto items-center min-h-[400px]">
          {
            (() => {
              if(productList && user?.user_info.role === ACCOUNT_ROLE.REGULAR) {
                const prodIdCorporateMonthly = 5;
                const prodIdCorporateYearly = 6;
                return (
                  <React.Fragment>
                    <div className="flex w-full space-x-8 justify-center">
                      {((user && user.user_info.account_type === ACCOUNT_TYPE.PERSONAL)) &&
                      <div className="flex flex-col items-center">
                        <div className="border-4 border-konared rounded-full w-20 h-20 inline-flex justify-center items-center text-6xl font-light text-konared">!</div>
                        <p className="mt-8 text-2xl">인구 예측 서비스는 개인 회원에게 제공되지 않습니다.</p>
                        <p className="text-2xl">인구 예측 서비스를 이용하실 경우 기업회원으로 가입하셔야 합니다.</p>
                        <button className="mt-16 w-[180px] py-4 rounded-xl text-xl text-center text-white font-semibold ripple-blue" onClick={handleCorporateRegistration}>기업회원가입</button>
                        <button onClick={handleLogout} className="text-base mt-4 hover:underline underline-offset-4">다른 계정으로 로그인</button>
                      </div>
                      }
                      {(!user || (user && user.user_info.account_type === ACCOUNT_TYPE.CORPORATE)) &&
                      <>
                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-indigo-50/50 border border-indigo-600">
                          <BuildingOffice2Icon className="h-14 text-indigo-600" />
                          <div className="mt-4 text-2xl font-semibold">기업 회원 월별 결제</div>
                          <div className="mt-10 text-3xl font-bold text-indigo-600">₩{productList[prodIdCorporateMonthly].price.toLocaleString()}</div>
                          <div>/month</div>
                          {!user && <NavLink to="/register" state={{gotoUrl: "/population", prevPath: "/population", scrollTo:"payment",  payload: {scrollTo: "payment"}, accountType: ACCOUNT_TYPE.CORPORATE}} className="mt-8 ripple-blue text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>}
                        </div>

                        <div className="bg-white p-8 flex-1 flex flex-col items-center rounded-3xl shadow-md  max-w-xs h-full bg-indigo-50/50 border border-indigo-600">
                          <BuildingOffice2Icon className="h-14 text-indigo-600" />
                          <div className="mt-4 text-2xl font-semibold">기업 회원 연간 결제</div>
                          <div className="mt-10 text-3xl font-bold text-indigo-600">₩{productList[prodIdCorporateYearly].price.toLocaleString()}</div>
                          <div>/year</div>
                          {!user && <NavLink to="/register" state={{gotoUrl: "/population", prevPath: "/population", scrollTo:"payment",  payload: {scrollTo: "payment"}, accountType: ACCOUNT_TYPE.CORPORATE}} className="mt-8 ripple-blue text-white text-lg text-center font-semibold rounded-lg w-full py-3">시작하기</NavLink>}
                        </div>
                      </>
                      }
                    </div>
                    {(user && user.user_info.account_type === ACCOUNT_TYPE.CORPORATE) &&
                      <div className="flex-1 flex flex-col items-center text-lg">
                        <p>기업회원의 서비스 계약기간은 1년입니다.</p>
                        <p>견적서 요청 및 이용요금의 결제는 당사 이메일(<span className="font-bold text-indigo-600">ikyjeong@naver.com</span>)로 문의 바랍니다.</p>
                      </div>
                    }
                  </React.Fragment>
                )
              }
              else {
                return (
                  <>
                    <span className="text-xl text-indigo-600 mt-4">오류가 발생했습니다. 관리자에게 문의해주세요: ikyjeong@naver.com</span>
                  </>
                )
              }
            })()
          }

          

         

        </div>

      </div>

    </MainContentWrapper>

  )
}

export default MainPopulation