import React from 'react'
import ReferenceHeader from "./ReferenceHeader"
import { Editor } from '@tinymce/tinymce-react';
import { HomeIcon, 
  ChevronRightIcon,  } from '@heroicons/react/24/solid'
import { ApiError, AppError, delay, InvalidRequestBody, isEmpty, ResponseError } from "../../utils/helpers";
import { GLOBAL_ACTION, useGlobalDispatchContext, useGlobalStateContext } from "../../components/context/GlobalContext";
import { addNoticeApi } from "../../utils/api/referenceApi";
import { NavLink, useNavigate } from "react-router-dom";


function BreadCrumbs() {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3 text-konagray text-sm font-medium">
        <li className="inline-flex items-center">
          <NavLink to="/" className="inline-flex items-center text-sm font-medium hover:text-konared">
            <span className="mr-2"><HomeIcon className="h-4" /></span>
            Home
          </NavLink>
        </li>
        <li>
          <div className="flex items-center">
            <span><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference" className="ml-2 hover:text-konared">자료실</NavLink>
          </div>
        </li>
        <li aria-current="page">
          <div className="flex items-center">
            <span className=""><ChevronRightIcon className="h-4" /></span>
            <NavLink to="/reference/notice" className="ml-2 hover:text-konared">공지사항</NavLink>
          </div>
        </li>
      </ol>
    </nav>
  )
}



const CreateNotice = () => {
  const navigate = useNavigate();
  const { user } = useGlobalStateContext();
  const globalDispatch = useGlobalDispatchContext();
  const editorRef = React.useRef(null);
  const titleRef = React.useRef(null);
  const [isLoading, setIsloading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  const submitForm = async () => {
    const title = titleRef.current.value;
    const content = editorRef.current.getContent();

    if (title === "" || title.length < 1) {
      alert("Title field must not be empty");
      return;
    }

    if (content === "" || content.length < 1) {
      alert("Content field must not be empty");
      return;
    }

    try {

      setIsloading(true);

      const post = await addNoticeApi(title, content, user);

      setIsloading(false);
      setIsSuccess(true);
      await delay(600);
      navigate("/reference/notice/" + post.id);

    } catch (err) {
      if([AppError, ApiError, ResponseError, InvalidRequestBody].map(e => err instanceof e).some(Boolean)){
        const apiErrorMsg= {title: "KONASD 서비스 오류 안내", message: err.message};
        globalDispatch({type: GLOBAL_ACTION.SET_API_ERROR, payload: {apiErrorMsg}})
      }
      else{
        console.error(err)
      }
    }
    
  }

  return (
    <div className="flex-1 flex flex-col pb-10">
      <ReferenceHeader>공지사항</ReferenceHeader>
      <div className="flex justify-center">
        <div className="flex-1 max-w-[1280px] p-10">
          <div className="flex pb-10">
            <div className="flex-1 flex flex-col">
              <div className="flex items-center pb-5">
                <div className="flex text-3xl font-bold text-konared">Add New 공지사항</div>
                <div className="flex-1 flex justify-end"><BreadCrumbs /></div>
              </div>
              <div className="">
                <div className="text-konagray/80 font-semibold">TITLE</div>
                <input ref={titleRef} type="text" className="border-2 border-[#eee] rounded-lg p-2 text-lg w-full" />
              </div>
              <div className="mt-5">
                <div className="text-konagray/80 font-semibold">CONTENT</div>
                <Editor
                  onInit={(evt, editor) => editorRef.current = editor}
                  apiKey="4t67ijph1wg1orbfdz5szia18hr2sni3goxpyr76aojxbskp"
                  init={{
                    height: 500,
                    menubar: "file edit view insert format tools table help",
                    plugins: "preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link media template codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons",
                    toolbar: 'undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample',
                    toolbar_mode: 'sliding',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                  }}
                />
                
              </div>
              <div className="py-5 flex justify-center">
                <button onClick={submitForm} className="ripple-primary text-white p-3 min-w-[200px] rounded-lg flex justify-center space-x-2" disabled={isLoading || isSuccess}>
                      {(isSuccess) && <svg className="checkmarkSmallWhite h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmarkSmallWhite__circle" cx="26" cy="26" r="25" fill="none"/><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>}
                      {(isLoading && !isSuccess) && 
                        <svg className="animate-spin  h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                      }
                      <span>SUBMIT</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateNotice